import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useInterval} from "react-use";
import {TIME_CONST_ONE_SECOND} from "@motv-webapp/lib";

const CountdownContainer = styled.div((props: {sizeInPixels: number}) => ({
    height: props.sizeInPixels,
    position: "relative" as "relative",
    width: props.sizeInPixels,
}));

const SecondsParagraph = styled.p((props: {color: string, sizeInPixels: number}) =>({
    color: props.color,
    fontSize: `${props.sizeInPixels / 2}px`,
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    margin: 0,
    transform: "translate(-50%, -50%)",
}));

const StyledCircle = styled.circle`
  
  animation-duration: 1s;
  animation-name: secondOut;
  animation-iteration-count: ${(props: {circumference: number, secondsToCountdown: number, strokeWidth: string}) => props.secondsToCountdown};
  stroke-dasharray: 0, ${(props: {circumference: number, secondsToCountdown: number, strokeWidth: string}) => props.circumference};
  stroke-width: ${(props: {circumference: number, secondsToCountdown: number, strokeWidth: string}) => props.strokeWidth};

  @keyframes secondOut {
    from {
      stroke-dasharray: ${(props: {circumference: number, secondsToCountdown: number, strokeWidth: string}) => props.circumference}, ${(props: {circumference: number}) => props.circumference};
    }
    to {
      stroke-dasharray: 0, ${(props: {circumference: number, secondsToCountdown: number, strokeWidth: string}) => props.circumference};
    }
  }
`

type Props = {
    color: string
    onCountdownFinished: () => void
    secondsToCountdown: number
    sizeInPixels: number
};

const radiusPercentOfSize = 46

const Countdown = ({color, onCountdownFinished, secondsToCountdown, sizeInPixels}: Props) => {
    const [currentSeconds, setCurrentSeconds] = useState(secondsToCountdown)

    useEffect(() => {
        setCurrentSeconds(secondsToCountdown)
    }, [secondsToCountdown])

    useInterval(() => {
        setCurrentSeconds(prevState => prevState - 1)
    }, TIME_CONST_ONE_SECOND)

    useEffect(() => {
        if (currentSeconds < 1) {
            onCountdownFinished()
        }
    }, [currentSeconds])

    return (
        <CountdownContainer {...{sizeInPixels}}>
            <svg height={sizeInPixels} width={sizeInPixels}>
                <StyledCircle
                    circumference={2*Math.PI*(sizeInPixels*radiusPercentOfSize/100)}
                    cx="50%"
                    cy="50%"
                    r={`${radiusPercentOfSize}%`}
                    stroke={color}
                    strokeWidth="6.5%"
                    fill="none"
                    strokeLinecap={"round"}
                    transform={`rotate(-90, ${sizeInPixels/2}, ${sizeInPixels/2})`}
                    {...{secondsToCountdown}}
                />
            </svg>
            <SecondsParagraph {...{color, sizeInPixels}}>
                {currentSeconds}
            </SecondsParagraph>
        </CountdownContainer>
    );
};

export default Countdown;
