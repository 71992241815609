import './App.css';
import ChannelsPage from "./pages/ChannelsPage";
import DetailCategoryPage from "./pages/DetailCategoryPage";
import DetailEventPage from "./pages/DetailEventPage";
import DetailRecordingPage from "./pages/DetailRecordingPage";
import DetailVodPage from "./pages/DetailVodPage";
import ErrorPage from "./pages/ErrorPage";
import GuidePage from "./pages/GuidePage";
import HomepagePage from "./pages/HomepagePage";
import LoadingSpinner from "./components/LoadingSpinner/LoadingSpinner";
import LoginPage from "./pages/LoginPage";
import MobileAppBanner from "./components/MobileAppBanner/MobileAppBanner";
import Player from "./components/Player/Player";
import PortalPage from "./pages/PortalPage";
import React, {useEffect, useLayoutEffect, useState} from 'react';
import SelectProfilePage from "./pages/SelectProfilePage";
import SettingsPage from "./pages/SettingsPage";
import Snackbar from "./components/Snackbar/Snackbar";
import VodPage from "./pages/VodPage";
import YoutubePlayer from "./components/Player/YoutubePlayer";
import {BrowserHistory} from "history";
import {Helmet} from "react-helmet";
import {IntlProvider} from "react-intl";
import {Navigate, Routes, Route, useLocation, Router, BrowserRouterProps, useNavigate} from "react-router-dom";
import {createGlobalStyle} from "styled-components";
import {getClientConfig} from "@motv-webapp/config";
import {initFirebase, receiveFirebaseNotification, requestForToken} from "./firebase/firebase";
import {isAndroid, isDesktop, isIOS} from "react-device-detect";
import {
    channelGetStartupChannel,
    channel_startupChannel,
    error_error_mw,
    error_error_sms,
    fetch_mw_deviceGetData,
    motvApiLoginWithToken,
    motvGetPortal,
    portal_deviceHash,
    portal_deviceInfo,
    portal_favicon,
    portal_motv_portals_android,
    portal_motv_portals_id,
    portal_motv_portals_ios,
    portal_motv_portals_sections_v2,
    resetUrlForRedirect,
    selectLanguage,
    selectProfile,
    setDeviceInfo,
    setUrlForRedirect,
    universal_youtubeSelectedItem,
    useAppDispatch,
    useAppLanguage,
    useAppSelector,
    user_customers_token,
    user_profiles,
    user_selectedLanguage,
    user_selectedProfile,
    vendorGetData,
    vendor_vendors_id,
    universal_urlForRedirect,
    universalEdgeSpeedCheck,
    universalLoadBlacklistedEdges,
    motvApiLoginWithDevice
} from "@motv-webapp/redux";
import {
    COLOR_INPUT_PLACEHOLDER_GREY,
    COLOR_SYSTEM_BLUE,
    PORTAL_SECTION_ENUM,
    STORAGE_CONSTANT_AUTHORIZATION,
    STORAGE_CONSTANT_PROFILE_ID,
    STORAGE_NAVIGATE_STARTUP,
    customHistory,
    findGetParameter,
    getLocalStorage,
    getSessionStorage,
    setSessionStorage,
    translate,
    TIME_CONST_ONE_MINUTE, setLocalStorage,
} from "@motv-webapp/lib";
import {useInterval, useTimeoutFn} from "react-use";
import SavedPage from "./pages/SavedPage";

const GlobalStyle = createGlobalStyle`
  a {
    color: ${COLOR_SYSTEM_BLUE};
    cursor: pointer;
    text-decoration: none;
  }

  input::-moz-placeholder {
    color: ${COLOR_INPUT_PLACEHOLDER_GREY};
    opacity: 1
  }

  input:-ms-input-placeholder {
    color: ${COLOR_INPUT_PLACEHOLDER_GREY};
  }

  input::-webkit-input-placeholder {
    color: ${COLOR_INPUT_PLACEHOLDER_GREY};
  }
`

function App() {
    const [appLoaded, setAppLoaded] = useState(false)
    const [messages, setMessages] = useState({})
    const [showMobileAppBanner, setShowMobileAppBanner] = useState(false)
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false)
    const dispatch = useAppDispatch();
    const androidAppId = useAppSelector(portal_motv_portals_android)
    const iosAppId = useAppSelector(portal_motv_portals_ios)
    const deviceHash = useAppSelector(portal_deviceHash)
    const deviceInfo = useAppSelector(portal_deviceInfo)
    const deviceLoginEnabled = getClientConfig().deviceLoginEnabled
    const favicon = useAppSelector(portal_favicon)
    const language = useAppSelector(user_selectedLanguage)
    const mwError = useAppSelector(error_error_mw)
    const portalId = useAppSelector(portal_motv_portals_id)
    const smsError = useAppSelector(error_error_sms)
    const userProfiles = useAppSelector(user_profiles)
    const vendorId = useAppSelector(vendor_vendors_id)
    const youtubeSelectedItem = useAppSelector(universal_youtubeSelectedItem)
    const defaultLanguage = useAppLanguage()
    const profileIdFromSession = getSessionStorage(STORAGE_CONSTANT_PROFILE_ID)
    console.log()
    useEffect(() => {
        if (!deviceInfo || !deviceHash || !userProfiles) return
        initFirebase()
        requestForToken()?.then(googleToken => {
            googleToken && userProfiles[0].profiles_id && fetch_mw_deviceGetData({
                devicesIdentification: deviceInfo,
                devicesHash: deviceHash,
                googleToken,
            }, btoa(userProfiles[0].profiles_id.toString()))
                .then(() => navigator?.serviceWorker?.addEventListener("message", receiveFirebaseNotification))
        })

        return () => {
            navigator?.serviceWorker?.removeEventListener("message", receiveFirebaseNotification);
        }
    }, [deviceHash, deviceInfo, userProfiles, navigator])

    useEffect(() => {
        dispatch(selectLanguage(defaultLanguage))
        setLocalStorage("language", defaultLanguage)
    }, [userProfiles])// eslint-disable-line

    useEffect(() => {
        const tokenFromLocalStorage = getLocalStorage(STORAGE_CONSTANT_AUTHORIZATION)
        const tokenFromUrlParameter = findGetParameter("appToken")
        if (tokenFromLocalStorage) dispatch(motvApiLoginWithToken({token: tokenFromLocalStorage}))
        else if (tokenFromUrlParameter) dispatch(motvApiLoginWithToken({token: tokenFromUrlParameter}))
    }, [])// eslint-disable-line

    useEffect(() => {
        if (!language) return
        setMessages({
            ...(JSON.parse(JSON.stringify(translate))[language]) && JSON.parse(JSON.stringify(translate))[language],
        })
    }, [language])

    useEffect(() => {
        dispatch(motvGetPortal({motvPortalsId: getClientConfig().portalId}))
        dispatch(setDeviceInfo())
        dispatch(vendorGetData({vendorsId: getClientConfig().vendorId}))
    }, [])// eslint-disable-line

    // select profile if some is saved in session storage
    useEffect(() => {
        const profileFromUrlParameter = findGetParameter("profileId")
        if (profileIdFromSession && userProfiles) {
            let profile = userProfiles.find(item => item?.profiles_id?.toString() === atob(profileIdFromSession))
            profile && dispatch(selectProfile(profile))
        } else if (profileFromUrlParameter && userProfiles) {
            let profile = userProfiles.find(item => item?.profiles_id?.toString() === profileFromUrlParameter)
            profile && dispatch(selectProfile(profile))
        }
    }, [profileIdFromSession, userProfiles])

    useEffect(() => {
        //TODO add speed checks
        if (portalId && deviceInfo && deviceHash && vendorId) {
            setAppLoaded(true)
        } else {
            setAppLoaded(false)
        }
    }, [portalId, deviceInfo, deviceHash, vendorId])

    useEffect(() => {
        if (getSessionStorage("mobileAppBanner")) return
        if ((isAndroid && androidAppId) || (isIOS && iosAppId)) {
            setShowMobileAppBanner(true)
        }
    }, [androidAppId, iosAppId])

    useEffect(() => {
        if (appLoaded && (mwError?.status === -1 || smsError?.status === -1)) setShowErrorSnackbar(true)
    }, [appLoaded, mwError, smsError])

    // EDGE CHECK START
    useEffect(() => {dispatch(universalLoadBlacklistedEdges())}, [])
    useTimeoutFn(() => dispatch(universalEdgeSpeedCheck()), 5000)
    useInterval(() => dispatch(universalLoadBlacklistedEdges()), TIME_CONST_ONE_MINUTE * 5)
    useInterval(() => dispatch(universalEdgeSpeedCheck()), TIME_CONST_ONE_MINUTE * 100)

    return (
        <IntlProvider locale={language ? language === "mn" ? "en" : language : "en"} messages={messages}>
            {appLoaded
                ? (<CustomRouter history={customHistory}>
                    <NavigateToStartupChannel>
                        <NavigateToStartupPage>
                            <>
                                <GlobalStyle/>
                                <Helmet>
                                    {<link rel="icon" href={`data:image/png;base64,${favicon}`}/>}
                                    {<link rel="apple-touch-icon" href={`data:image/png;base64,${favicon}`}/>}
                                </Helmet>
                                <Routes>
                                    <Route path="/" element={
                                        <RequireAuth>
                                            <RequireProfile>
                                                <HomepagePage/>
                                            </RequireProfile>
                                        </RequireAuth>
                                    }/>
                                    <Route path="/category/:mediaId" element={
                                        <RequireAuth>
                                            <RequireProfile>
                                                <DetailCategoryPage/>
                                            </RequireProfile>
                                        </RequireAuth>
                                    }/>
                                    <Route path="/category/detail/:mediaId" element={ //handles deeplink
                                        <RequireAuth>
                                            <RequireProfile>
                                                <DetailCategoryPage/>
                                            </RequireProfile>
                                        </RequireAuth>
                                    }/>
                                    <Route path="/channels" element={
                                        <RequireAuth>
                                            <RequireProfile>
                                                <ChannelsPage/>
                                            </RequireProfile>
                                        </RequireAuth>
                                    }/>
                                    <Route path="/event/:mediaId" element={
                                        <RequireAuth>
                                            <RequireProfile>
                                                <DetailEventPage/>
                                            </RequireProfile>
                                        </RequireAuth>
                                    }/>
                                    <Route path="/event/detail/:mediaId" element={ //handles deeplink
                                        <RequireAuth>
                                            <RequireProfile>
                                                <DetailEventPage/>
                                            </RequireProfile>
                                        </RequireAuth>
                                    }/>
                                    <Route path="/tv/player/:mediaId" element={ //handles deeplink
                                        <RequireAuth>
                                            <RequireProfile>
                                                <Player playerType={"tv"}/>
                                            </RequireProfile>
                                        </RequireAuth>
                                    }/>
                                    <Route path="/tv/player/:mediaId/:timestamp" element={ //handles deeplink
                                        <RequireAuth>
                                            <RequireProfile>
                                                <Player playerType={"tv"}/>
                                            </RequireProfile>
                                        </RequireAuth>
                                    }/>
                                    <Route path="/tv/player/:mediaId/:timestamp/:preferredOffset" element={ //handles deeplink
                                        <RequireAuth>
                                            <RequireProfile>
                                                <Player playerType={"tv"}/>
                                            </RequireProfile>
                                        </RequireAuth>
                                    }/>
                                    <Route path="/guide" element={
                                        <RequireAuth>
                                            <RequireProfile>
                                                <GuidePage/>
                                            </RequireProfile>
                                        </RequireAuth>
                                    }/>
                                    <Route path="/vod" element={
                                        <RequireAuth>
                                            <RequireProfile>
                                                <VodPage/>
                                            </RequireProfile>
                                        </RequireAuth>
                                    }/>
                                    <Route path="/vod/:mediaId" element={
                                        <RequireAuth>
                                            <RequireProfile>
                                                <DetailVodPage/>
                                            </RequireProfile>
                                        </RequireAuth>
                                    }/>
                                    <Route path="/vod/detail/:mediaId" element={ //handles deeplink
                                        <RequireAuth>
                                            <RequireProfile>
                                                <DetailVodPage/>
                                            </RequireProfile>
                                        </RequireAuth>
                                    }/>
                                    <Route path="/vod/player/:mediaId" element={ //handles deeplink
                                        <RequireAuth>
                                            <RequireProfile>
                                                <Player playerType={"vod"}/>
                                            </RequireProfile>
                                        </RequireAuth>
                                    }/>
                                    <Route path="/recording/:mediaId" element={
                                        <RequireAuth>
                                            <RequireProfile>
                                                <DetailRecordingPage/>
                                            </RequireProfile>
                                        </RequireAuth>
                                    }/>
                                    <Route path="/recording/detail/:mediaId" element={ //handles deeplink
                                        <RequireAuth>
                                            <RequireProfile>
                                                <DetailRecordingPage/>
                                            </RequireProfile>
                                        </RequireAuth>
                                    }/>
                                    <Route path="/recording/player/:mediaId" element={ //handles deeplink
                                        <RequireAuth>
                                            <RequireProfile>
                                                <Player playerType={"recording"}/>
                                            </RequireProfile>
                                        </RequireAuth>
                                    }/>
                                    <Route path="/saved" element={
                                        <RequireAuth>
                                            <RequireProfile>
                                                <SavedPage/>
                                            </RequireProfile>
                                        </RequireAuth>
                                    }/>
                                    <Route path="/saved/:slug" element={
                                        <RequireAuth>
                                            <RequireProfile>
                                                <SavedPage/>
                                            </RequireProfile>
                                        </RequireAuth>
                                    }/>
                                    <Route path="/login" element={deviceLoginEnabled ? <Navigate to={"/"} /> : <LoginPage/>}/>
                                    <Route path="/login/:panel" element={deviceLoginEnabled ? <Navigate to={"/"} /> : <LoginPage/>}/>
                                    <Route path="/login/:panel/:step" element={deviceLoginEnabled ? <Navigate to={"/"} /> : <LoginPage/>}/>
                                    <Route path="/select-profile/*" element={
                                        <RequireAuth>
                                            {deviceLoginEnabled ? <Navigate to={"/"} /> : <SelectProfilePage/>}
                                        </RequireAuth>
                                    }/>
                                    <Route path="/select-profile/:dialog" element={
                                        <RequireAuth>
                                            {deviceLoginEnabled ? <Navigate to={"/"} /> : <SelectProfilePage/>}
                                        </RequireAuth>
                                    }/>
                                    <Route path="/select-profile/:dialog/:subDialog/" element={
                                        <RequireAuth>
                                            {deviceLoginEnabled ? <Navigate to={"/"} /> : <SelectProfilePage/>}
                                        </RequireAuth>
                                    }/>
                                    <Route path="/settings" element={
                                        <RequireAuth>
                                            <RequireProfile>
                                                {deviceLoginEnabled ? <Navigate to={"/"} /> : <SettingsPage/>}
                                            </RequireProfile>
                                        </RequireAuth>
                                    }/>
                                    <Route path="/settings/:slug" element={
                                        <RequireAuth>
                                            <RequireProfile>
                                                {deviceLoginEnabled ? <Navigate to={"/"} /> : <SettingsPage/>}
                                            </RequireProfile>
                                        </RequireAuth>
                                    }/>
                                    <Route path="/settings/:slug/:secondSlug" element={
                                        <RequireAuth>
                                            <RequireProfile>
                                                {deviceLoginEnabled ? <Navigate to={"/"} /> : <SettingsPage/>}
                                            </RequireProfile>
                                        </RequireAuth>
                                    }/>
                                    <Route path="/info/:name" element={<PortalPage/>}/>
                                </Routes>
                            </>
                        </NavigateToStartupPage>
                    </NavigateToStartupChannel>
                </CustomRouter>)
                : (mwError?.status === -1 || smsError?.status === -1) ? <ErrorPage/> : <LoadingSpinner/>}
            {youtubeSelectedItem && <YoutubePlayer/>}
            {!isDesktop && showMobileAppBanner && (
                <MobileAppBanner setShowMobileAppBanner={setShowMobileAppBanner}/>
            )}
            {showErrorSnackbar &&
              <Snackbar defaultMessage={"Something went wrong."} messageId={"message_something_went_wrong"}
                        setSnackbarOpen={setShowErrorSnackbar}/>}
        </IntlProvider>
    );
}

const CustomRouter = ({
                          basename,
                          children,
                          window,
                          history
                      }: BrowserRouterProps & { history: BrowserHistory }) => {
    const [state, setState] = useState({
        action: history.action,
        location: history.location
    });

    useLayoutEffect(() => history.listen(setState), [history]);

    return (
        <Router
            basename={basename}
            children={children}
            location={state.location}
            navigationType={state.action}
            navigator={history}
        />
    );
};

function RequireAuth({children}: { children: JSX.Element }) {
    let location = useLocation();
    const dispatch = useAppDispatch();
    const token = useAppSelector(user_customers_token);
    const smsError = useAppSelector(error_error_sms)
    const tokenFromLocalStorage = getLocalStorage(STORAGE_CONSTANT_AUTHORIZATION)
    const deviceLoginEnabled = getClientConfig().deviceLoginEnabled
    const deviceHash = useAppSelector(portal_deviceHash)
    const vendorId = useAppSelector(vendor_vendors_id)

    if (!token && (!tokenFromLocalStorage || smsError)) {
        if (deviceLoginEnabled) {
            if (smsError?.status === -1) return <ErrorPage />
            dispatch(motvApiLoginWithDevice({vendors_id: vendorId, devices_hash: deviceHash}))
            return children
        }
        return <Navigate to="/login" state={{from: location}} replace/>;
    }

    return children;
}

function RequireProfile({children}: { children: JSX.Element }) {
    let location = useLocation();
    const profile = useAppSelector(user_selectedProfile);
    const userProfiles = useAppSelector(user_profiles)
    const profileIdFromSession = getSessionStorage(STORAGE_CONSTANT_PROFILE_ID)
    const profileFromUrlParameter = findGetParameter("profileId")
    const deviceLoginEnabled = getClientConfig().deviceLoginEnabled
    const dispatch = useAppDispatch()

    if (!userProfiles) return null
    if(deviceLoginEnabled) {
        dispatch(selectProfile(userProfiles[0]))
    }
    if (profileFromUrlParameter) {
        let profile = userProfiles.find(item => item?.profiles_id?.toString() === profileFromUrlParameter)
        profile && dispatch(selectProfile(profile))
    } else if (!profile && !profileIdFromSession) {
        location.pathname && location.pathname !== "/" && dispatch(setUrlForRedirect(location.pathname))
        return <Navigate to="/select-profile" state={{from: location}} replace/>;
    }

    return children;
}

function NavigateToStartupChannel({children}: { children: JSX.Element }) {
    const profile = useAppSelector(user_selectedProfile);
    const startupChannel = useAppSelector(channel_startupChannel)
    const token = useAppSelector(user_customers_token);
    const dispatch = useAppDispatch()
    const navigate = useNavigate()


    useEffect(() => {
        if (token && profile) dispatch(channelGetStartupChannel())
    }, [token, profile])

    useEffect(() => {
        if (profile && startupChannel?.channels_id && !getSessionStorage("startupChannel_started")) {
            setSessionStorage("startupChannel_started", "true")
            navigate(`/tv/player/${startupChannel.channels_id}`)
        }
    }, [profile, startupChannel?.channels_id])

    return children;
}

function NavigateToStartupPage({children}: { children: JSX.Element }) {
    const sectionsAllowedV2 = useAppSelector(portal_motv_portals_sections_v2)
    const profile = useAppSelector(user_selectedProfile);
    const urlForRedirect = useAppSelector(universal_urlForRedirect)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const navigateToStartupPageDone = getSessionStorage(STORAGE_NAVIGATE_STARTUP)

    useEffect(() => {
        // after selecting profile go to url customer came for or to startup page
        if (profile && sectionsAllowedV2 && !navigateToStartupPageDone) {
            navigate(urlForRedirect
                ? urlForRedirect
                : sectionsAllowedV2[0] === PORTAL_SECTION_ENUM.LIVE
                    ? "/channels"
                    : sectionsAllowedV2[0] === PORTAL_SECTION_ENUM.RECORDINGS
                        ? "/saved/recordings"
                        : sectionsAllowedV2[0] === PORTAL_SECTION_ENUM.MY_LIST
                            ? "/saved/my-list"
                        : sectionsAllowedV2[0] === PORTAL_SECTION_ENUM.VOD
                            ? "/vod"
                            : "/", {replace: true})
            dispatch(resetUrlForRedirect())
            setSessionStorage(STORAGE_NAVIGATE_STARTUP, "true")
        }
    }, [profile?.profiles_id, sectionsAllowedV2]) //profile.profiles_id dependency due to unexpected updating of profile object

    return children;
}

export default App;
