import React, {useEffect, useState} from 'react';
import {logOutUser, selectProfile, user_customers_profiles_id} from "@motv-webapp/redux";
import {useAppDispatch, useAppSelector} from "@motv-webapp/redux";
import styled from "styled-components";
import {FormattedMessage} from "react-intl";
import {COLOR_OFFWHITE_90} from "@motv-webapp/lib";
import NewProfileDialog from "./NewProfileDialog";
import EnterPinDialog from "./EnterPinDialog";
import {useNavigate, useParams} from "react-router-dom";
import { debounce } from "debounce";
import {truncate} from "@motv-webapp/lib";
import PinDialog from "../PinDialog/PinDialog";
import {ProfileEntity} from "@motv-webapp/lib";

const CONST_ENTER_PIN_DIALOG = "enterPin"
const CONST_NEW_PROFILE_DIALOG_STEP_ONE = "newProfileStepOne"
const CONST_NEW_PROFILE_DIALOG_STEP_TWO = "newProfileStepTwo"

const ProfileSelectorContainer = styled.div(props => ({
    gap: "2rem",
    display: "flex",
    flexWrap: "wrap",
    width: "51rem",
    "@media (max-width: 850px)": {
        width: "37.75rem",
    },
    "@media (max-width: 630px)": {
        flexWrap: "nowrap",
        justifyContent: "flex-start",
        marginLeft: "calc(50% - 5.625rem)",
        marginRight: "calc(50% - 5.625rem)",
        minWidth: "fit-content",
    }
}));

const ProfileButton = styled.button((props: { avatar: string | undefined | null }) => ({
    backgroundColor: "#000",
    backgroundImage: `url(${props.avatar})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    border: 0,
    borderRadius: "50%",
    cursor: "pointer",
    height: "11.25rem",
    marginBottom: "4rem",
    padding: 0,
    position: "relative" as "relative",
    width: "11.25rem",
}));

const ProfileLabel = styled.div(props => ({
    bottom: "-2rem",
    display: "flex",
    justifyContent: "center",
    position: "absolute" as "absolute",
    width: "100%",
    "& > p": {
        color: "white",
        fontFamily: "Roboto",
        fontSize: "1rem",
        fontWeight: 500,
        margin: 0,
        padding: 0,
        whiteSpace: "nowrap",
    },
    "& > img": {
        height: "1rem",
    }
}));

const LogOutButton = styled.button(props => ({
    background: "transparent",
    bottom: "4rem",
    border: 0,
    color: COLOR_OFFWHITE_90,
    fontSize: "0.8rem",
    fontWeight: 500,
    padding: 0,
    position: "fixed",
    right: "4rem",
}));

type Props = {
    profiles: Array<ProfileEntity>
};

const ProfileSelector = ({profiles}: Props) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [confirmPinDialogOpen, setConfirmPinDialogOpen] = useState(false)
    const [newProfileDialogOpen, setNewProfileDialogOpen] = useState(false)
    const [profileToConfirmWithPin, setProfileToConfirmWithPin] = useState<ProfileEntity|{}>({})
    const {dialog, subDialog} = useParams()
    const primaryProfileId = useAppSelector(user_customers_profiles_id)
    const primaryProfile = profiles.find(profile => profile.profiles_id === primaryProfileId)
    // const primaryProfile = useMemo(() => profiles.find(profile => profile.profiles_id === primaryProfileId), [profiles, primaryProfileId])

    const handleLogout = () => {
        dispatch(logOutUser())
    }

    const handleSelectProfile = (profile: ProfileEntity) => {
        dispatch(selectProfile(profile))
    }

    const handleNewProfile = () => {
        // in case that the primary profile is protected by pin and the login pin confirmation is forced, confirm primary profile pin
        if (!!primaryProfile!.profiles_pin && !!primaryProfile!.profiles_login_requires_pin) {
            setConfirmPinDialogOpen(true)
        } else {
            navigate(`/select-profile/${CONST_NEW_PROFILE_DIALOG_STEP_ONE}`)
        }
    }
    const debouncedHandleNewProfile = debounce(handleNewProfile, 100)

    const handleNewProfilePinConfirmed = () => {
        setConfirmPinDialogOpen(false)
        navigate(`/select-profile/${CONST_NEW_PROFILE_DIALOG_STEP_ONE}`)
    }

    const handleOpenEnterPinDialog = (profile: ProfileEntity) => {
        navigate(`/select-profile/${CONST_ENTER_PIN_DIALOG}/${profile.profiles_id}`)
        setProfileToConfirmWithPin(profile)
    }

    const handleCloseEnterPinDialog = () => {
        setConfirmPinDialogOpen(false)
    }

    useEffect(() => {
        if (dialog === CONST_ENTER_PIN_DIALOG && subDialog) {
            let profile = profiles.find(profile => profile.profiles_id === parseInt(subDialog))
            if (profile) setProfileToConfirmWithPin(profile)
        } else if (dialog === CONST_NEW_PROFILE_DIALOG_STEP_ONE || dialog === CONST_NEW_PROFILE_DIALOG_STEP_TWO) {
            if (!newProfileDialogOpen) setNewProfileDialogOpen(true)
        } else {
            setProfileToConfirmWithPin({})
            setNewProfileDialogOpen(false)
        }
    }, [dialog, subDialog]) // eslint-disable-line

    return (
        //dynamically calculated justifyContent due to different behaviour in first and second line of ProfileButtons
        <ProfileSelectorContainer style={{justifyContent: profiles.length < 4 ? "center" : "flex-start"}}>
            {profiles && profiles.map((profile: ProfileEntity) => {
                return (
                    <ProfileButton avatar={profile.image}
                                   key={profile.profiles_id}
                                   onClick={
                                       (profile.profiles_pin && profile.profiles_login_requires_pin)
                                           ? () => handleOpenEnterPinDialog(profile)
                                           : () => handleSelectProfile(profile)
                                   }
                    >
                        <ProfileLabel>
                            <p>{truncate(profile.profiles_name!, 20)}</p>
                            {!!profile.profiles_pin && !!profile.profiles_login_requires_pin &&
                              <img src={"images/icons/icon_lock.svg"} alt={"lock icon"}/>}
                        </ProfileLabel>
                    </ProfileButton>
                )
            })}
            <ProfileButton avatar={"/images/icons/icon_add_circle.svg"} style={{backgroundSize: "8rem"}}
                           onClick={debouncedHandleNewProfile}>
                <ProfileLabel>
                    <p><FormattedMessage id={"label_settings_new_profile"} defaultMessage={"New Profile"}/></p>
                </ProfileLabel>
            </ProfileButton>
            <LogOutButton onClick={handleLogout}>
                <FormattedMessage id={"label_log_out"} defaultMessage={"Log out"}/>
            </LogOutButton>
            {newProfileDialogOpen && <NewProfileDialog {...{CONST_NEW_PROFILE_DIALOG_STEP_ONE, CONST_NEW_PROFILE_DIALOG_STEP_TWO}} />}
            {(profileToConfirmWithPin && !!Object.keys(profileToConfirmWithPin).length) && <EnterPinDialog {...{profileToConfirmWithPin, handleSelectProfile}} />}
            {confirmPinDialogOpen && <PinDialog onConfirm={handleNewProfilePinConfirmed} onClose={handleCloseEnterPinDialog} profileToConfirmWithPin={primaryProfile!} messageId={"message_pin_of_profile"} messageDefaultMessage={"To continue enter PIN for profile:"} profileName={primaryProfile?.profiles_name!} />}
        </ProfileSelectorContainer>
    );
};

export default ProfileSelector;
