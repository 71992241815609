"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
exports.__esModule = true;
exports.user_customers_autogenerated = exports.user_customers_recording_by_profile = exports.user_customers_recording_used = exports.user_customers_recording_length = exports.user_device_motv_social_registration_completed = exports.user_qrCodeForLogin = exports.user_profiles = exports.user_selectedProfile = exports.user_selectedLanguage = exports.user_customers_profiles_id = exports.user_customers_token = exports.user_customers_id = exports.unselectProfile = exports.selectProfile = exports.selectLanguage = exports.userReducer = exports.logOutUser = exports.resetChannelOrder = exports.updateChannelOrder = exports.updateProfile = exports.deleteProfile = exports.getProfiles = exports.CreateProfileWithFakeProfileHeader = exports.RegisterWithFacebook = exports.LoginWithApple = exports.RegisterWithApple = exports.LoginWithGoogle = exports.LoginWithQRCode = exports.customerGetData = exports.customerRequestLoginQRCode = exports.motvApiUpdateCustomerV2 = exports.motvApiRegister = exports.motvApiLoginWithToken = exports.motvApiLoginV2 = exports.motvApiLoginWithDevice = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var userAPI_1 = require("./userAPI");
var lib_1 = require("@motv-webapp/lib");
var lib_2 = require("@motv-webapp/lib");
var lib_3 = require("@motv-webapp/lib");
var lib_4 = require("@motv-webapp/lib");
var error_1 = require("../error/error");
var portalAPI_1 = require("../portal/portalAPI");
var channel_1 = require("../channel/channel");
var universal_1 = require("../universal/universal");
var initialState = {
    status: 'idle',
    device_motv_id: undefined,
    device_motv_viewers_id: undefined,
    device_motv_login: undefined,
    device_motv_motv_portals_id: undefined,
    device_motv_motv_id: undefined,
    device_motv_profiles_id: undefined,
    device_motv_social_registration_completed: undefined,
    device_google_email: undefined,
    device_google_id: undefined,
    device_google_viewers_id: undefined,
    customers_id: undefined,
    customers_vendors_id: undefined,
    customers_login: undefined,
    customers_created: undefined,
    customers_users_id: undefined,
    customers_token: undefined,
    customers_profiles_id: undefined,
    customers_password_changed: undefined,
    customers_dvb_c_regions_id: undefined,
    customers_dvb_s_regions_id: undefined,
    customers_dvb_t_regions_id: undefined,
    customers_dvb_t2_regions_id: undefined,
    customers_device_count: undefined,
    customers_recording_length: undefined,
    customers_recording_used: undefined,
    customers_locked: undefined,
    profiles: undefined,
    customers_recommendation_engine_enabled: undefined,
    customers_recording_by_profile: undefined,
    selectedProfile: undefined,
    selectedLanguage: undefined,
    qrCodeForLogin: undefined,
    customers_autogenerated: undefined
};
exports.motvApiLoginWithDevice = (0, toolkit_1.createAsyncThunk)('user/fetchApiLoginWithDevice', function (body, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, userAPI_1.fetch_sms_devicesMotvApiLoginWithDevice)(body)];
            case 1:
                response = _a.sent();
                if (!(0, lib_4.isResponseSuccessful)(response)) {
                    thunkAPI.dispatch((0, error_1.addSmsError)({
                        response: [{ errorAt: "fetchApiLoginWithDevice" }],
                        status: -1
                    }));
                    return [2 /*return*/, thunkAPI.rejectWithValue({ errorAt: "fetchApiLoginWithDevice" })];
                }
                thunkAPI.dispatch((0, error_1.removeAllErrors)());
                return [4 /*yield*/, response.response];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); });
exports.motvApiLoginV2 = (0, toolkit_1.createAsyncThunk)('user/fetchApiLoginV2', function (body, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, userAPI_1.fetch_sms_devicesMotvApiLoginV2)(body)];
            case 1:
                response = _a.sent();
                if (!(0, lib_4.isResponseSuccessful)(response)) {
                    response ? thunkAPI.dispatch((0, error_1.addSmsError)(response)) : thunkAPI.dispatch((0, error_1.addSmsError)({
                        response: [{ errorAt: "fetchApiLoginV2" }],
                        status: -1
                    }));
                    return [2 /*return*/, thunkAPI.rejectWithValue({ errorAt: "motvApiLoginV2" })];
                }
                thunkAPI.dispatch((0, error_1.removeAllErrors)());
                return [4 /*yield*/, response.response];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); });
exports.motvApiLoginWithToken = (0, toolkit_1.createAsyncThunk)('user/apiLoginWithToken', function (body, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, userAPI_1.fetch_sms_devicesMotvApiLoginWithToken)(body)];
            case 1:
                response = _a.sent();
                if (!(0, lib_4.isResponseSuccessful)(response)) {
                    response ? thunkAPI.dispatch((0, error_1.addSmsError)(response)) : thunkAPI.dispatch((0, error_1.addSmsError)({
                        response: [{ errorAt: "apiLoginWithToken" }],
                        status: -1
                    }));
                    (0, lib_1.setLocalStorage)(lib_3.STORAGE_CONSTANT_AUTHORIZATION, "");
                    return [2 /*return*/, thunkAPI.rejectWithValue({ errorAt: "motvApiLoginWithToken" })];
                }
                thunkAPI.dispatch((0, error_1.removeAllErrors)());
                return [4 /*yield*/, response.response];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); });
exports.motvApiRegister = (0, toolkit_1.createAsyncThunk)('user/apiRegister', function (body, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, portalAPI_1.fetch_sms_devicesMotvApiRegisterV2)(body)];
            case 1:
                response = _a.sent();
                if (!(0, lib_4.isResponseSuccessful)(response)) {
                    response ? thunkAPI.dispatch((0, error_1.addSmsError)(response)) : thunkAPI.dispatch((0, error_1.addSmsError)({
                        response: [{ errorAt: "apiRegister" }],
                        status: -1
                    }));
                    return [2 /*return*/, thunkAPI.rejectWithValue({ errorAt: "motvApiRegister" })];
                }
                thunkAPI.dispatch((0, error_1.removeAllErrors)());
                return [4 /*yield*/, response.response];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); });
exports.motvApiUpdateCustomerV2 = (0, toolkit_1.createAsyncThunk)('user/apiUpdateCustomerV2', function (body, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, portalAPI_1.fetch_sms_devicesMotvApiUpdateCustomerV2)(body)];
            case 1:
                response = _a.sent();
                // if (!isResponseSuccessful(response)) thunkAPI.dispatch(addSmsError(response))
                if (!(0, lib_4.isResponseSuccessful)(response)) {
                    response ? thunkAPI.dispatch((0, error_1.addSmsError)(response)) : thunkAPI.dispatch((0, error_1.addSmsError)({
                        response: [{ errorAt: "apiUpdateCustomerV2" }],
                        status: -1
                    }));
                    return [2 /*return*/, thunkAPI.rejectWithValue({ errorAt: "apiUpdateCustomerV2" })];
                }
                return [4 /*yield*/, response.response];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); });
exports.customerRequestLoginQRCode = (0, toolkit_1.createAsyncThunk)('user/fetchCustomerRequestLoginQRCode', function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, userAPI_1.fetch_mw_customerRequestLoginQRCode)(body)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.response];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); });
exports.customerGetData = (0, toolkit_1.createAsyncThunk)('user/fetchCustomerGetData', function (body, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, userAPI_1.fetch_mw_customerGetData)()];
            case 1:
                response = _a.sent();
                if (!(0, lib_4.isResponseSuccessful)(response))
                    thunkAPI.rejectWithValue({ errorAt: "customerGetData" });
                thunkAPI.dispatch((0, error_1.removeAllErrors)());
                return [4 /*yield*/, response.response];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); });
exports.LoginWithQRCode = (0, toolkit_1.createAsyncThunk)('user/loginWithQRCode', function (body, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var QRCodeData, response;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, (0, userAPI_1.fetch_mw_customerGetQRCodeData)(body)];
            case 1:
                QRCodeData = _b.sent();
                if (!(0, lib_4.isResponseSuccessful)(QRCodeData))
                    return [2 /*return*/, thunkAPI.rejectWithValue({ errorAt: "LoginWithQRCode_response" })];
                if (!((_a = QRCodeData === null || QRCodeData === void 0 ? void 0 : QRCodeData.response) === null || _a === void 0 ? void 0 : _a.login_qr_token))
                    return [2 /*return*/, thunkAPI.rejectWithValue({ errorAt: "LoginWithQRCode_token" })];
                return [4 /*yield*/, (0, userAPI_1.fetch_sms_devicesMotvApiLoginWithToken)({ token: QRCodeData.response.login_qr_token })];
            case 2:
                response = _b.sent();
                return [4 /*yield*/, response.response];
            case 3: return [2 /*return*/, _b.sent()];
        }
    });
}); });
exports.LoginWithGoogle = (0, toolkit_1.createAsyncThunk)('user/loginWithGoogle', function (body, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, userAPI_1.fetch_sms_devicesGoogleRegister)(body)];
            case 1:
                response = _a.sent();
                if (!(0, lib_4.isResponseSuccessful)(response)) {
                    response ? thunkAPI.dispatch((0, error_1.addSmsError)(response)) : thunkAPI.dispatch((0, error_1.addSmsError)({
                        response: [{ errorAt: "loginWithGoogle" }],
                        status: -1
                    }));
                    return [2 /*return*/, thunkAPI.rejectWithValue({ errorAt: "LoginWithGoogle" })];
                }
                thunkAPI.dispatch((0, error_1.removeAllErrors)());
                return [4 /*yield*/, response.response];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); });
exports.RegisterWithApple = (0, toolkit_1.createAsyncThunk)('user/registerWithApple', function (body, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, userAPI_1.fetch_sms_devicesAppleRegister)(body)];
            case 1:
                response = _a.sent();
                if (!(0, lib_4.isResponseSuccessful)(response)) {
                    response ? thunkAPI.dispatch((0, error_1.addSmsError)(response)) : thunkAPI.dispatch((0, error_1.addSmsError)({
                        response: [{ errorAt: "registerWithApple" }],
                        status: -1
                    }));
                    return [2 /*return*/, thunkAPI.rejectWithValue({ errorAt: "RegisterWithApple" })];
                }
                thunkAPI.dispatch((0, error_1.removeAllErrors)());
                return [4 /*yield*/, response.response];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); });
exports.LoginWithApple = (0, toolkit_1.createAsyncThunk)('user/loginWithApple', function (body, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, userAPI_1.fetch_sms_devicesAppleLogin)(body)];
            case 1:
                response = _a.sent();
                if (!(0, lib_4.isResponseSuccessful)(response)) {
                    response ? thunkAPI.dispatch((0, error_1.addSmsError)(response)) : thunkAPI.dispatch((0, error_1.addSmsError)({
                        response: [{ errorAt: "loginWithApple" }],
                        status: -1
                    }));
                    return [2 /*return*/, thunkAPI.rejectWithValue({ errorAt: "LoginWithApple" })];
                }
                thunkAPI.dispatch((0, error_1.removeAllErrors)());
                return [4 /*yield*/, response.response];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); });
exports.RegisterWithFacebook = (0, toolkit_1.createAsyncThunk)('user/registerWithFacebook', function (body, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, userAPI_1.fetch_sms_devicesFacebookRegister)(body)];
            case 1:
                response = _a.sent();
                if (!(0, lib_4.isResponseSuccessful)(response)) {
                    response ? thunkAPI.dispatch((0, error_1.addSmsError)(response)) : thunkAPI.dispatch((0, error_1.addSmsError)({
                        response: [{ errorAt: "registerWithFacebook" }],
                        status: -1
                    }));
                    return [2 /*return*/, thunkAPI.rejectWithValue({ errorAt: "RegisterWithFacebook" })];
                }
                thunkAPI.dispatch((0, error_1.removeAllErrors)());
                return [4 /*yield*/, response.response];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); });
exports.CreateProfileWithFakeProfileHeader = (0, toolkit_1.createAsyncThunk)(// use when creating profile from place where no active profile is chosen at the moment
'user/createProfileWithFakeProfileHeader', function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, userAPI_1.fetch_mw_profileUpdate)(params.body, params.fakeProfileHeader)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.response];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); });
exports.getProfiles = (0, toolkit_1.createAsyncThunk)(// use when getting profiles
'user/getProfiles', function () { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, userAPI_1.fetch_mw_profileGetProfiles)()];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.response];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); });
exports.deleteProfile = (0, toolkit_1.createAsyncThunk)(// use when deleting profile from settings/profile dialog
'user/deleteProfile', function (body, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, userAPI_1.fetch_mw_profileDeleteProfile)(body)];
            case 1:
                response = _a.sent();
                if ((0, lib_4.isResponseSuccessful)(response)) {
                    thunkAPI.dispatch((0, exports.getProfiles)());
                }
                return [2 /*return*/];
        }
    });
}); });
exports.updateProfile = (0, toolkit_1.createAsyncThunk)(// use when creating profile from place where active profile is chosen
'user/updateProfile', function (body, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, userAPI_1.fetch_mw_profileUpdate)(body)];
            case 1:
                response = _a.sent();
                if ((0, lib_4.isResponseSuccessful)(response)) {
                    thunkAPI.dispatch((0, exports.getProfiles)());
                }
                return [2 /*return*/];
        }
    });
}); });
exports.updateChannelOrder = (0, toolkit_1.createAsyncThunk)(// use when updating channel order from drag and drop grid in channels page
'user/updateChannelOrder', function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, userAPI_1.fetch_mw_profileUpdateChannelOrder)(body)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.response];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); });
exports.resetChannelOrder = (0, toolkit_1.createAsyncThunk)(// use when updating channel order from drag and drop grid in channels page
'user/resetChannelOrder', function (body, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, userAPI_1.fetch_mw_profileResetChannelOrder)(body)];
            case 1:
                response = _a.sent();
                if ((0, lib_4.isResponseSuccessful)(response)) {
                    thunkAPI.dispatch((0, channel_1.channelGetSubscribedAndLockedChannels)({ body: {} }));
                }
                return [2 /*return*/];
        }
    });
}); });
exports.logOutUser = (0, toolkit_1.createAsyncThunk)('user/logOutUser', function (_, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        (0, lib_2.removeSessionStorage)(lib_3.STORAGE_CONSTANT_PROFILE_ID);
        (0, lib_1.removeLocalStorage)(lib_3.STORAGE_CONSTANT_AUTHORIZATION);
        (0, lib_2.removeSessionStorage)(lib_3.STORAGE_NAVIGATE_STARTUP);
        thunkAPI.dispatch((0, universal_1.invalidateCache)());
        return [2 /*return*/];
    });
}); });
exports.userReducer = (0, toolkit_1.createSlice)({
    name: 'user',
    initialState: initialState,
    reducers: {
        selectLanguage: function (state, action) {
            state.selectedLanguage = action.payload;
        },
        selectProfile: function (state, action) {
            state.selectedProfile = action.payload;
            if (action.payload.profiles_id)
                (0, lib_2.setSessionStorage)(lib_3.STORAGE_CONSTANT_PROFILE_ID, btoa(action.payload.profiles_id.toString()));
        },
        unselectProfile: function (state) {
            (0, lib_2.setSessionStorage)(lib_3.STORAGE_CONSTANT_PROFILE_ID, "");
            (0, lib_2.removeSessionStorage)(lib_3.STORAGE_NAVIGATE_STARTUP);
            state.selectedProfile = undefined;
        }
    },
    extraReducers: function (builder) {
        builder
            .addCase(exports.motvApiLoginWithDevice.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.motvApiLoginWithDevice.fulfilled, updateUserFromPayload)
            .addCase(exports.motvApiLoginWithDevice.rejected, function (state) {
            state.status = 'failed';
        })
            .addCase(exports.motvApiLoginV2.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.motvApiLoginV2.fulfilled, updateUserFromPayload)
            .addCase(exports.motvApiLoginV2.rejected, function (state) {
            state.status = 'failed';
        })
            .addCase(exports.motvApiLoginWithToken.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.motvApiLoginWithToken.fulfilled, updateUserFromPayload)
            .addCase(exports.motvApiLoginWithToken.rejected, function (state) {
            state.status = 'failed';
        })
            .addCase(exports.motvApiRegister.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.motvApiRegister.fulfilled, updateUserFromPayload)
            .addCase(exports.motvApiRegister.rejected, function (state) {
            state.status = 'failed';
        })
            .addCase(exports.motvApiUpdateCustomerV2.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.motvApiUpdateCustomerV2.fulfilled, function (state) {
            state.status = 'idle';
            state.device_motv_social_registration_completed = 1;
        })
            .addCase(exports.motvApiUpdateCustomerV2.rejected, function (state) {
            state.status = 'failed';
        })
            .addCase(exports.customerGetData.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.customerGetData.fulfilled, updateUserFromPayload)
            .addCase(exports.customerGetData.rejected, function (state) {
            state.status = 'failed';
        })
            .addCase(exports.customerRequestLoginQRCode.pending, function (state) {
            state.status = "loading";
        })
            .addCase(exports.customerRequestLoginQRCode.fulfilled, function (state, action) {
            state.status = "idle";
            state.qrCodeForLogin = action.payload;
        })
            .addCase(exports.LoginWithQRCode.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.LoginWithQRCode.fulfilled, updateUserFromPayload)
            .addCase(exports.LoginWithQRCode.rejected, function (state) {
            state.status = 'failed';
        })
            .addCase(exports.LoginWithGoogle.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.LoginWithGoogle.fulfilled, updateUserFromPayload)
            .addCase(exports.LoginWithGoogle.rejected, function (state) {
            state.status = 'failed';
        })
            .addCase(exports.RegisterWithApple.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.RegisterWithApple.fulfilled, updateUserFromPayload)
            .addCase(exports.RegisterWithApple.rejected, function (state) {
            state.status = 'failed';
        })
            .addCase(exports.LoginWithApple.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.LoginWithApple.fulfilled, updateUserFromPayload)
            .addCase(exports.LoginWithApple.rejected, function (state) {
            state.status = 'failed';
        })
            .addCase(exports.RegisterWithFacebook.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.RegisterWithFacebook.fulfilled, updateUserFromPayload)
            .addCase(exports.RegisterWithFacebook.rejected, function (state) {
            state.status = 'failed';
        })
            .addCase(exports.getProfiles.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.getProfiles.fulfilled, function (state, action) {
            state.status = 'idle';
            state.profiles = action.payload;
        })
            .addCase(exports.logOutUser.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.logOutUser.fulfilled, function (state) {
            Object.assign(state, initialState);
        });
    }
});
var updateUserFromPayload = function (state, action) {
    state.status = 'idle';
    state.device_motv_id = action.payload.device_motv_id;
    state.device_motv_viewers_id = action.payload.device_motv_viewers_id;
    state.device_motv_login = action.payload.device_motv_login;
    state.device_motv_motv_portals_id = action.payload.device_motv_motv_portals_id;
    state.device_motv_motv_id = action.payload.device_motv_motv_id;
    state.device_motv_profiles_id = action.payload.device_motv_profiles_id;
    state.device_motv_social_registration_completed = action.payload.device_motv_social_registration_completed;
    state.device_google_email = action.payload.device_google_email;
    state.device_google_id = action.payload.device_google_id;
    state.device_google_viewers_id = action.payload.device_google_viewers_id;
    state.customers_id = action.payload.customers_id;
    state.customers_vendors_id = action.payload.customers_vendors_id;
    state.customers_login = action.payload.customers_login;
    state.customers_created = action.payload.customers_created;
    state.customers_users_id = action.payload.customers_users_id;
    state.customers_token = action.payload.customers_token;
    state.customers_profiles_id = action.payload.customers_profiles_id;
    state.customers_password_changed = action.payload.customers_password_changed;
    state.customers_dvb_c_regions_id = action.payload.customers_dvb_c_regions_id;
    state.customers_dvb_s_regions_id = action.payload.customers_dvb_s_regions_id;
    state.customers_dvb_t_regions_id = action.payload.customers_dvb_t_regions_id;
    state.customers_dvb_t2_regions_id = action.payload.customers_dvb_t2_regions_id;
    state.customers_device_count = action.payload.customers_device_count;
    state.customers_recording_length = action.payload.customers_recording_length;
    state.customers_recording_used = action.payload.customers_recording_used;
    state.customers_locked = action.payload.customers_locked;
    state.profiles = action.payload.profiles;
    state.customers_recommendation_engine_enabled = action.payload.customers_recommendation_engine_enabled;
    state.customers_recording_by_profile = action.payload.customers_recording_by_profile;
    state.customers_autogenerated = action.payload.customers_autogenerated;
    if (action.payload.customers_token)
        (0, lib_1.setLocalStorage)(lib_3.STORAGE_CONSTANT_AUTHORIZATION, action.payload.customers_token);
};
exports.selectLanguage = (_a = exports.userReducer.actions, _a.selectLanguage), exports.selectProfile = _a.selectProfile, exports.unselectProfile = _a.unselectProfile;
// Selectors
var user_customers_id = function (state) { return state.user.customers_id; };
exports.user_customers_id = user_customers_id;
var user_customers_token = function (state) { return state.user.customers_token; };
exports.user_customers_token = user_customers_token;
var user_customers_profiles_id = function (state) { return state.user.customers_profiles_id; };
exports.user_customers_profiles_id = user_customers_profiles_id;
var user_selectedLanguage = function (state) { return state.user.selectedLanguage; };
exports.user_selectedLanguage = user_selectedLanguage;
var user_selectedProfile = function (state) { return state.user.selectedProfile; };
exports.user_selectedProfile = user_selectedProfile;
var user_profiles = function (state) { return state.user.profiles; };
exports.user_profiles = user_profiles;
var user_qrCodeForLogin = function (state) { return state.user.qrCodeForLogin; };
exports.user_qrCodeForLogin = user_qrCodeForLogin;
var user_device_motv_social_registration_completed = function (state) { return state.user.device_motv_social_registration_completed; };
exports.user_device_motv_social_registration_completed = user_device_motv_social_registration_completed;
var user_customers_recording_length = function (state) { return state.user.customers_recording_length; };
exports.user_customers_recording_length = user_customers_recording_length;
var user_customers_recording_used = function (state) { return state.user.customers_recording_used; };
exports.user_customers_recording_used = user_customers_recording_used;
var user_customers_recording_by_profile = function (state) { return state.user.customers_recording_by_profile; };
exports.user_customers_recording_by_profile = user_customers_recording_by_profile;
var user_customers_autogenerated = function (state) { return state.user.customers_autogenerated; };
exports.user_customers_autogenerated = user_customers_autogenerated;
exports["default"] = exports.userReducer.reducer;
