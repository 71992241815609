import React, {useEffect, useState} from "react";
import styled from "styled-components";
import RecordingCard from "./RecordingCard";
import {isAfter} from "date-fns";
import Error from "../ErrorHandle/Error";
import {FormattedMessage, useIntl} from "react-intl";
import {COLOR_WHITE, RecommendationCardEntity, TYPEFACE_HEADLINE} from "@motv-webapp/lib";

const MyListComponentContainer = styled.div(props => ({
    margin: "32px 0 80px 64px",
    "@media (max-width: 870px)": {
        margin: "16px 0 80px 16px",
    }
}));

const MyListItemsContainer = styled.div(props => ({
    margin: "32px 0 0 0",
}));

const MyListSubtitleHeader = styled.div(props => ({
    ...TYPEFACE_HEADLINE,
    color: COLOR_WHITE,
    marginBottom: 24,
}));

const MyListCardsContainer = styled.div(props => ({
    display: "flex",
    flexWrap: "wrap",
    gap: 16,
}));

interface MyListRow {
    title: string
    id: string
    data: Array<RecommendationCardEntity>
}

type Props = {
    isEdit: boolean
    myListFull: Array<RecommendationCardEntity> | undefined
    myListIds: Array<RecommendationCardEntity> | undefined
}
const MyList = ({isEdit, myListFull, myListIds}: Props) => {
    const intl = useIntl()
    const [myListRows, setMyListRows] = useState<Array<MyListRow> | undefined>([])

    const sortMyListToRows = () => {
        let mappedRows = [] as Array<MyListRow>
        let rowReady = {title: "Ready", id: "label_ready", data: []} as MyListRow
        let rowScheduled = {title: "Scheduled", id: "label_scheduled", data: []} as MyListRow

        myListFull?.forEach((mediaItem) => {
            if (!mediaItem.end || isAfter(Date.now(), new Date(mediaItem.end))) {
                rowReady.data.push(mediaItem)
            } else if (!isAfter(Date.now(), new Date(mediaItem.end))) {
                rowScheduled.data.push(mediaItem)
            }
        })
        if (!!rowReady.data.length) {
            mappedRows.push(rowReady)
        }
        if (!!rowScheduled.data.length) {
            mappedRows.push(rowScheduled)
        }
        setMyListRows(mappedRows)
    }

    useEffect(() => {
        sortMyListToRows()
    }, [myListFull])

    return (
        <>
            {myListRows && !!myListRows.length ? (
                <MyListComponentContainer>
                    {myListRows.map((row) => (
                        <MyListItemsContainer key={row.id}>
                            {(myListRows?.length > 1 || myListRows[0].id === "label_scheduled") && <MyListSubtitleHeader>
                                <FormattedMessage id={row.id} defaultMessage={row.title}/>
                            </MyListSubtitleHeader>}
                            <MyListCardsContainer>
                                {row.data.map((mediaItem) => {
                                    //If you remove recording in EditMode, hide card instantly, remove mediaItem from myListFull but keep id in myListIds for future implementation of canceling removing card from my list
                                    if (myListIds && !myListIds.find(x => x.id === mediaItem.id)) return
                                    return <RecordingCard key={mediaItem.id} {...{mediaItem, isEdit}}
                                    />
                                })}
                            </MyListCardsContainer>
                        </MyListItemsContainer>
                    ))}
                </MyListComponentContainer>
            ) : (
                <Error src={"/images/icons/icon_ghost_filled.svg"}
                       labelHeadline={intl.formatMessage({
                           id: "label_no_recordings",
                           defaultMessage: "Nothing to see here - yet"
                       })}
                       labelMessage={intl.formatMessage({
                           id: "label_no_mylist_message",
                           defaultMessage: "To add something to My List, go to some event and click on the Add to My List button"
                       })}/>
            )}
        </>
    )
}

export default MyList
