"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
exports.__esModule = true;
exports.myList_myList = exports.myListReducer = exports.myListGetMyListFull = exports.myListRemoveFromMyList = exports.myListAddToMyList = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var universal_1 = require("../universal/universal");
var myListAPI_1 = require("./myListAPI");
var lib_1 = require("@motv-webapp/lib");
var initialState = {
    status: "idle",
    myList: undefined,
    lastUpdated: undefined,
    ignoreCache: false
};
exports.myListAddToMyList = (0, toolkit_1.createAsyncThunk)('myList/addToMyList', function (body, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, myListAPI_1.fetch_mw_recommendationEngineAddToMyList)(body)];
            case 1:
                response = _a.sent();
                if ((0, lib_1.isResponseSuccessful)(response)) {
                    thunkAPI.dispatch((0, universal_1.universalGetMyList)());
                }
                return [2 /*return*/];
        }
    });
}); });
exports.myListRemoveFromMyList = (0, toolkit_1.createAsyncThunk)('myList/removeFromMyList', function (body, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, myListAPI_1.fetch_mw_recommendationEngineRemoveFromMyList)(body)];
            case 1:
                response = _a.sent();
                if ((0, lib_1.isResponseSuccessful)(response)) {
                    thunkAPI.dispatch((0, universal_1.universalGetMyList)());
                }
                return [2 /*return*/];
        }
    });
}); });
exports.myListGetMyListFull = (0, toolkit_1.createAsyncThunk)('myList/getMyListFull', function (arg, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, myList, universal, response;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = thunkAPI.getState(), myList = _a.myList, universal = _a.universal;
                if (!(myList.lastUpdated
                    && (myList.lastUpdated + (lib_1.TIME_CONST_ONE_MINUTE * lib_1.TIME_CONST_NUMBER_OF_MINUTES_TO_CACHE)) > Date.now()
                    && (universal.invalidateCache ? myList.lastUpdated > universal.invalidateCache : true)
                    && !myList.ignoreCache
                    && !arg.ignoreCache)) return [3 /*break*/, 1];
                return [2 /*return*/, myList.myList];
            case 1: return [4 /*yield*/, (0, myListAPI_1.fetch_mw_recommendationEngineGetMyListFull)({ includeFuture: true })];
            case 2:
                response = _b.sent();
                return [4 /*yield*/, response.response];
            case 3: return [2 /*return*/, _b.sent()];
        }
    });
}); });
exports.myListReducer = (0, toolkit_1.createSlice)({
    name: 'myList',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder
            .addCase(exports.myListAddToMyList.fulfilled, function (state) {
            state.ignoreCache = true;
        })
            .addCase(exports.myListRemoveFromMyList.fulfilled, function (state) {
            state.ignoreCache = true;
        })
            .addCase(exports.myListGetMyListFull.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.myListGetMyListFull.fulfilled, function (state, action) {
            state.status = 'idle';
            state.myList = action.payload;
            state.lastUpdated = Date.now();
            state.ignoreCache = false;
        });
    }
});
exports._b = _a = exports.myListReducer.actions;
// Selectors
var myList_myList = function (state) { return state.myList.myList; };
exports.myList_myList = myList_myList;
exports["default"] = exports.myListReducer.reducer;
