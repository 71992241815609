import React from 'react';
import styled from "styled-components";
import {useAppSelector} from "@motv-webapp/redux";
import {vendor_vendors_accent_color} from "@motv-webapp/redux";
import "./loadingSpinner.css";
import Div100vh from "react-div-100vh";
import {COLOR_NEUTRAL} from "@motv-webapp/lib";

const SpinnerContainer = styled(Div100vh)((props: {$gray: boolean}) => ({
    alignItems: "center",
    backgroundColor:  props.$gray ? "rgba(0, 0, 0, 0.4)" : COLOR_NEUTRAL,
    display: "flex",
    justifyContent: "center",
    position: "absolute" as "absolute",
    width: "100vw",
    zIndex: 997,
}));

type Props = {
    gray?: boolean
};

const LoadingSpinner = ({gray = false}: Props) => {
    const accentColor = useAppSelector(vendor_vendors_accent_color)

    return (
        <SpinnerContainer $gray={gray} >
            <div className="lds-ripple">
                <div style={{borderColor: `${accentColor}`}}/>
                <div style={{borderColor: `${accentColor}`}}/>
            </div>
        </SpinnerContainer>
    );
};

export default LoadingSpinner;
