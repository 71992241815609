"use strict";
exports.__esModule = true;
exports.getLanguage = exports.getProfilesId = exports.getAuthorization = void 0;
var localStorage_1 = require("./localStorage");
var sessionStorage_1 = require("./sessionStorage");
var constants_1 = require("../constants");
var getAuthorization = function (noAuth) {
    if (noAuth === void 0) { noAuth = false; }
    return ((0, localStorage_1.getLocalStorage)(constants_1.STORAGE_CONSTANT_AUTHORIZATION) && !noAuth)
        ? "Bearer ".concat((0, localStorage_1.getLocalStorage)(constants_1.STORAGE_CONSTANT_AUTHORIZATION))
        : undefined;
};
exports.getAuthorization = getAuthorization;
var getProfilesId = function (noAuth) {
    if (noAuth === void 0) { noAuth = false; }
    return ((0, localStorage_1.getLocalStorage)(constants_1.STORAGE_CONSTANT_AUTHORIZATION) && (0, sessionStorage_1.getSessionStorage)(constants_1.STORAGE_CONSTANT_PROFILE_ID) && !noAuth)
        ? (0, sessionStorage_1.getSessionStorage)(constants_1.STORAGE_CONSTANT_PROFILE_ID)
        : undefined;
};
exports.getProfilesId = getProfilesId;
var getLanguage = function () { return (0, localStorage_1.getLocalStorage)('language')
    ? btoa((0, localStorage_1.getLocalStorage)('language'))
    : undefined; };
exports.getLanguage = getLanguage;
