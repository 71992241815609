"use strict";
exports.__esModule = true;
exports.deleteAllEmptyKeysInJson = void 0;
var deleteAllEmptyKeysInJson = function (json) {
    if (json === void 0) { json = {}; }
    Object.keys(json).forEach(function (k) {
        if (json[k] === undefined ||
            json[k] === '') {
            delete json[k];
        }
    });
    return json;
};
exports.deleteAllEmptyKeysInJson = deleteAllEmptyKeysInJson;
