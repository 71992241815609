import React, {useState} from "react";
import styled from "styled-components";
import {COLOR_SECONDARY} from "@motv-webapp/lib";
import {removeDevice} from "@motv-webapp/redux";
import {FormattedMessage} from "react-intl";
import {format, parseISO} from "date-fns";
import {useAppDispatch} from "@motv-webapp/redux";
import {useWindowSize} from "react-use";
import {DeviceEntity} from "@motv-webapp/lib";

const DeviceCardContainer = styled.div((props: {}) => ({
    padding: "16px",
    width: "420px",
    background: COLOR_SECONDARY,
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column" as "column",
    position: "relative" as "relative",
    "@media (max-width: 980px)": {
        width: "100%",
    },
}));

const DeviceTypeContainer = styled.div((props: {}) => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
    "& img": {
        height: "32px",
        "@media (max-width: 960px)": {
            height: "20px"
        }
    },
    "& > p": {
        padding: 0,
        margin: "0 0 0 8px",
        fontSize: "1.563rem",
        fontWeight: 600,
        lineHeight: 1,
        "@media (max-width: 960px)": {
            fontSize: "1rem",
        }
    },
}));

const DeviceInfoContainer = styled.div((props: {}) => ({
    width: "100%",
    display: "flex",
    flexWrap: "wrap" as "wrap",
    justifyContent: "space-between",
    marginTop: "8px",
    overflow: "hidden",
    columnGap: "16px",
    "& > p": {
        flex: 1,
        fontSize: "1rem",
        fontWeight: 500,
        lineHeight: 1,
        margin: 0,
        overflow: "hidden",
        padding: 0,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap" as "nowrap",
        "@media (max-width: 960px)": {
            fontSize: "0.8rem",
        }
    },
}));

const DeviceInfoContainerEndText = styled.div((props: {}) => ({
    display: "flex",
    flex: 2,
    fontSize: "1rem",
    fontWeight: 500,
    justifyContent: "flex-end",
    lineHeight: 1,
    whiteSpace: "nowrap" as "nowrap",
    "@media (max-width: 960px)": {
        fontSize: "0.8rem",
    },
    "@media (max-width: 870px)": {
        flex: 1,
    },
    "@media (max-width: 500px)": {
        flex: 2,
    },
}));

const StyledButton = styled.button((props: {}) => ({
    background: "transparent",
    border: 0,
    padding: 0,
    cursor: "pointer",
    display: "flex",
    "& img": {
        width: "11px",
        height: "11px",
    },
    position: "absolute" as "absolute",
    top: "16px",
    right: "16px",
}));

type Props = {
    device: DeviceEntity
}

const DevicesCard = ({device}: Props) => {
    const [hover, setHover] = useState(false)
    const {width} = useWindowSize();
    const dispatch = useAppDispatch();

    const handleClickRemoveButton = () => {
        if (device?.devices_id) {
            dispatch(removeDevice({devicesId: device.devices_id}))
        }
    }

    const handleMouseEnter = () => {
        setHover(true)
    }

    const handleMouseLeave = () => {
        setHover(false)
    }

    return (
        <>
            <DeviceCardContainer onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <DeviceTypeContainer>
                    {device.devices_type === "web player" && (
                        <>
                            <img src={"/images/icons/icon_device_desktop.svg"} alt={""}/>
                            <p>Desktop</p>
                        </>
                    )}
                    {device.devices_type === "ios" && (
                        <>
                            <img src={"/images/icons/icon_device_iphone.svg"} alt={""}/>
                            <p>iOS</p>
                        </>
                    )}
                    {device.devices_type === "android" && (
                        <>
                            <img src={"/images/icons/icon_device_android.svg"} alt={""}/>
                            <p>Android</p>
                        </>
                    )}
                    {(device.devices_type === "android tv" || device.devices_type === "tizen" ||
                        device.devices_type === "webos" || device.devices_type === "tvos" ||
                        device.devices_type === "roku") && (
                        <>
                            <img src={"/images/icons/icon_device_tv.svg"} alt={""}/>
                            <p>TV</p>
                        </>
                    )}
                </DeviceTypeContainer>
                <DeviceInfoContainer>
                    <p>{device.devices_identification}</p>
                    <DeviceInfoContainerEndText>
                        <FormattedMessage id={"label_device_last_used"}
                                          defaultMessage={"Last used"}/>
                        : {device.devices_used && format(parseISO(device.devices_used), "dd.MM.yyyy HH:mm")}
                    </DeviceInfoContainerEndText>
                </DeviceInfoContainer>
                <DeviceInfoContainer>
                    <p>{device.devices_ip}</p>
                    <DeviceInfoContainerEndText>
                        <FormattedMessage id={"label_device_added"} defaultMessage={"Added"}/>
                        : {device.devices_added && format(parseISO(device.devices_added), "dd.MM.yyyy HH:mm")}
                    </DeviceInfoContainerEndText>
                </DeviceInfoContainer>
                <StyledButton onClick={handleClickRemoveButton}>
                    {(width < 871 || hover) &&
                        <img src={"/images/icons/icon_close.svg"} alt={""}/>}
                </StyledButton>
            </DeviceCardContainer>
        </>
    )
}
export default DevicesCard
