import ConfirmDialog from "../components/Dialog/ConfirmDialog";
import CustomerSettings from "../components/Settings/CustomerSettings/CustomerSettings";
import DevicesSettings from "../components/Settings/DevicesSettings/DevicesSettings";
import Header from "../components/Header/Header";
import ProfilesSettings from "../components/Settings/ProfilesSettings/ProfilesSettings";
import QrLoginSettings from "../components/Settings/QrLoginSettings/QrLoginSettings";
import React, {useState, MouseEvent, useEffect} from 'react';
import styled, {createGlobalStyle} from "styled-components";
import {FormattedMessage} from "react-intl";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {useWindowSize} from "react-use";
import {
    COLOR_BLACK,
    COLOR_INPUT_FIELD_GREY,
    COLOR_NEUTRAL,
    COLOR_OFFWHITE_90,
    COLOR_WHITE,
    ProfileEntity,
    TYPEFACE_BODY_MEDIUM,
    TYPEFACE_HEADLINE_2,
} from "@motv-webapp/lib";
import {
    logOutUser,
    portal_motv_portals_account_update_enabled,
    useAppDispatch,
    useAppSelector,
    user_selectedProfile,
    vendor_vendors_accent_color, vendor_vendors_qr_code,
} from "@motv-webapp/redux";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${COLOR_NEUTRAL};
  }

  ,
`
const SettingsPageContainer = styled.div(props => ({
    background: COLOR_NEUTRAL,
    color: COLOR_WHITE,
    minHeight: "100vh",
    overflowX: "hidden",
}));
const SettingsComponentContainer = styled.div(props => ({
    display: "flex",
    margin: "136px 0 32px 64px",
    padding: "0 64px 0 0",
    "@media (max-width: 870px)": {
        justifyContent: "center",
        margin: "91px 0 96px 32px",
    },
}));
const SettingsNavigationContainer = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 136px)",
    borderRight: `3px solid ${COLOR_INPUT_FIELD_GREY}`,
    paddingRight: "64px",
    position: "fixed",
    "@media (max-width: 870px)": {
        width: "100%",
        borderRight: 0,
        paddingRight: 0,
        position: "static",
        height: "auto"
    }
}));
const SettingsMainContentContainer = styled.div(props => ({
    marginLeft: "416px",
    width: "100%",
    "@media (max-width: 870px)": {
        marginLeft: 0,
    }
}));
const SettingsScrollableContentContainer = styled.div((props: { width: number, step: number }) => ({
    "@media (max-width: 870px)": {
        width: "100%",
        height: "auto",
    },
}));

const SubPageHeader = styled.h1(props => ({
    lineHeight: 1,
    margin: "0 0 24px 0",
    padding: 0,
    "@media (max-width: 870px)": {
        marginBottom: "32px",
    },
}));

const SettingsNavigationHeaderContainer = styled.div(props => ({
    display: "flex",
    alignItems: "center",
    width: "285px",
    "@media (max-width: 870px)": {
        width: "auto",
    },
}));
const ProfileImageContainer = styled.div(props => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: COLOR_BLACK,
    borderRadius: "50%",
    height: "94px",
    width: "94px",
    "& img": {
        borderRadius: "50%",
        height: "94px",
        width: "94px",
        objectFit: "cover"
    }
}));
const ProfileInfoContainer = styled.div(props => ({
    marginLeft: "24px",
    overflow: "hidden",
}));
const ProfileInfoItemName = styled.div(props => ({
    ...TYPEFACE_HEADLINE_2,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
}));
const ProfileInfoItemEmail = styled.div(props => ({
    ...TYPEFACE_BODY_MEDIUM,
    marginTop: "16px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
}));
const NavigationBodyContainer = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    marginTop: "40px",
}));

interface StyledNavLinkProps {
    readonly $accentColor: string | undefined | null;
}

const StyledNavLink = styled(NavLink)<StyledNavLinkProps>`
  color: ${COLOR_OFFWHITE_90};
  border-bottom: 2px solid ${COLOR_INPUT_FIELD_GREY};
  padding: 24px 0 24px 16px;
  line-height: 1;
  font-size: 1rem;
  font-weight: 500;

  :hover {
    color: ${COLOR_WHITE};
  }

  &.active {
    color: ${COLOR_WHITE};
    border-bottom: ${`2px ${COLOR_WHITE} solid`};
  }

  @media (max-width: 870px) {
    padding-left: 18.3px;
  }
`
const StyledButton = styled.button((props: {}) => ({
    background: "transparent",
    border: 0,
    padding: 0,
    marginBottom: "16px",
    cursor: "pointer",
    display: "flex",
    "& img": {
        width: "40px",
        height: "40px",
    },
}));

const DialogContainer = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    color: COLOR_WHITE,

}));

const DialogHeader = styled.div(props => ({
    ...TYPEFACE_HEADLINE_2,
    "@media (max-width: 500px)": {
        textAlign: "center",
    }
}))
const DialogText = styled.div(props => ({
    ...TYPEFACE_BODY_MEDIUM,
    margin: "44px 0",
    "@media (max-width: 500px)": {
        textAlign: "center",
    }
}))

const SettingsPage = () => {
    const {slug, secondSlug} = useParams();
    const {width} = useWindowSize();
    const navigate = useNavigate();
    const dispatch = useAppDispatch()
    const customerEditEnabled = useAppSelector(portal_motv_portals_account_update_enabled)
    const selectedProfile = useAppSelector(user_selectedProfile);
    const qrLoginEnabled = useAppSelector(vendor_vendors_qr_code)
    const $accentColor = useAppSelector(vendor_vendors_accent_color)
    const [dataChangedUrl, setDataChangedUrl] = useState("")
    const [isDataChangedDialogOpen, setIsDataChangedDialogOpen] = useState(false);
    const [profileToEdit, setProfileToEdit] = useState({} as ProfileEntity);
    const [profileToEditInitial, setProfileToEditInitial] = useState({} as ProfileEntity);
    const [isPinControlEnabled, setIsPinControlEnabled] = useState(profileToEdit.profiles_pin_enabled === 1);
    const [step, setStep] = useState(1);
    const [whiteListedChannelsChanged, setWhiteListedChannelsChanged] = useState(false);

    useEffect(() => {
        if (slug === "profiles" && secondSlug === String(selectedProfile?.profiles_id) && selectedProfile) {
            setProfileToEdit(selectedProfile)
            setStep(2)
        }
    }, [secondSlug, selectedProfile])

    const hasFormDataChanged = () => {
        if (profileToEdit.profiles_name !== profileToEditInitial.profiles_name) return true
        else if (profileToEdit.image !== profileToEditInitial.image) return true
        else if (profileToEdit.profiles_pin !== profileToEditInitial.profiles_pin) return true
        else if (profileToEdit.profiles_birthday !== profileToEditInitial.profiles_birthday) return true
        else if (profileToEdit.profiles_age && (Number(profileToEdit.profiles_age) !== profileToEditInitial.profiles_age)) return true
        else if (isPinControlEnabled !== (profileToEditInitial.profiles_pin_enabled === 1)) return true
        else return whiteListedChannelsChanged
    }

    const handleNavClick = (e: MouseEvent<HTMLAnchorElement>, to: string) => {
        e.preventDefault()
        if (to === "/settings/logout") {
            handleLogout();
        } else if ((slug === "profiles" && hasFormDataChanged()) && step !== 1) {
            setDataChangedUrl(to)
            setIsDataChangedDialogOpen(true);
        } else {
            setStep(1)
            navigate(to)
        }
    }

    const handleLogout = () => {
        dispatch(logOutUser())
    }

    const handleCloseUnsavedChangesDialog = () => {
        setIsDataChangedDialogOpen(false)
    }

    const handleConfirmDataChanged = () => {
        setStep(1)
        setIsDataChangedDialogOpen(false)
        setWhiteListedChannelsChanged(false)
        navigate(dataChangedUrl)
    }

    const handleBackButtonClick = () => {
        if (step === 1) {
            navigate("/settings")
        } else if (step === 2 && hasFormDataChanged()) {
            setIsDataChangedDialogOpen(true);
        } else setStep(step - 1)
    }

    const navigateToRoot = () => {
        navigate("/settings")
        setStep(1)
    }

    return (
        <>
            <SettingsPageContainer>
                <GlobalStyle/>
                <Header/>
                <SettingsComponentContainer>
                    {(width > 870 || slug === undefined) && (
                        <SettingsNavigationContainer>
                            <SettingsNavigationHeaderContainer>
                                <ProfileImageContainer>
                                    {selectedProfile?.image && <img src={selectedProfile.image} alt={""}/>}
                                </ProfileImageContainer>
                                <ProfileInfoContainer>
                                    <ProfileInfoItemName>
                                        {selectedProfile?.profiles_name}
                                    </ProfileInfoItemName>
                                    <ProfileInfoItemEmail>
                                        {selectedProfile?.customers_login}
                                    </ProfileInfoItemEmail>
                                </ProfileInfoContainer>
                            </SettingsNavigationHeaderContainer>
                            <NavigationBodyContainer>
                                {!!customerEditEnabled && <StyledNavLink
                                  onClick={(e: MouseEvent<HTMLAnchorElement>) => handleNavClick(e, "/settings/customer")}
                                  key={"label_settings_customer"} to={"/settings/customer"} {...{$accentColor}}>
                                  <FormattedMessage id={"label_settings_customer"} defaultMessage={"Customer"}/>
                                </StyledNavLink>}
                                <StyledNavLink
                                    onClick={(e: MouseEvent<HTMLAnchorElement>) => handleNavClick(e, "/settings/profiles")}
                                    key={"label_settings_profiles"} to={"/settings/profiles"} {...{$accentColor}}>
                                    <FormattedMessage id={"label_settings_profiles"} defaultMessage={"Profiles"}/>
                                </StyledNavLink>
                                <StyledNavLink
                                    onClick={(e: MouseEvent<HTMLAnchorElement>) => handleNavClick(e, "/settings/devices")}
                                    key={"label_settings_devices"} to={"/settings/devices"} {...{$accentColor}}>
                                    <FormattedMessage id={"label_settings_devices"} defaultMessage={"Devices"}/>
                                </StyledNavLink>
                                {!!qrLoginEnabled &&
                                  <StyledNavLink
                                    onClick={(e: MouseEvent<HTMLAnchorElement>) => handleNavClick(e, "/settings/qrlogin")}
                                    key={"label_qr_login"} to={"/settings/qrlogin"} {...{$accentColor}}>
                                    <FormattedMessage id={"label_qr_login"} defaultMessage={"QR Login"}/>
                                  </StyledNavLink>}
                                <StyledNavLink
                                    onClick={(e: MouseEvent<HTMLAnchorElement>) => handleNavClick(e, "/settings/logout")}
                                    key={"label_log_out"} to={"/settings/logout"} {...{$accentColor}}>
                                    <FormattedMessage id={"label_log_out"} defaultMessage={"Log out"}/>
                                </StyledNavLink>
                            </NavigationBodyContainer>
                        </SettingsNavigationContainer>
                    )}
                    {slug !== undefined && (
                        <SettingsMainContentContainer>
                            {(width <= 870 || step !== 1) && (
                                <StyledButton onClick={handleBackButtonClick}>
                                    <img src={"/images/icons/icon_arrow_back.svg"} alt={""}/>
                                </StyledButton>
                            )}
                            <SubPageHeader>
                                {slug === "customer" &&
                                  <FormattedMessage id={"label_settings_customer"} defaultMessage={"Customer"}/>}
                                {slug === "profiles" && (
                                    <>
                                        {step === 1 && <FormattedMessage id={"label_settings_profiles"}
                                                                         defaultMessage={"Profiles"}/>}
                                        {step === 2 &&
                                          <FormattedMessage id={"label_profile"} defaultMessage={"Profile"}/>}
                                        {step === 3 && <FormattedMessage id={"select_channels"}
                                                                         defaultMessage={"Select channels"}/>}
                                    </>
                                )}
                                {slug === "devices" &&
                                  <FormattedMessage id={"label_settings_devices"} defaultMessage={"Devices"}/>}
                                {slug === "qrlogin" &&
                                  <FormattedMessage id={"label_qr_login"} defaultMessage={"QR Login"}/>}
                            </SubPageHeader>
                            <SettingsScrollableContentContainer width={width} step={step}>
                                {slug === "customer" && <CustomerSettings afterSubmit={navigateToRoot}/>}
                                {slug === "profiles" && (
                                    <ProfilesSettings setIsDataChangedDialogOpen={setIsDataChangedDialogOpen}
                                                      step={step}
                                                      setStep={setStep}
                                                      profileToEdit={profileToEdit}
                                                      setProfileToEdit={setProfileToEdit}
                                                      setProfileToEditInitial={setProfileToEditInitial}
                                                      setWhiteListedChannelsChanged={setWhiteListedChannelsChanged}
                                                      hasFormDataChanged={hasFormDataChanged}
                                                      setIsPinControlEnabled={setIsPinControlEnabled}
                                                      isPinControlEnabled={isPinControlEnabled}
                                    />
                                )}
                                {slug === "devices" && <DevicesSettings/>}
                                {slug === "qrlogin" && <QrLoginSettings/>}
                            </SettingsScrollableContentContainer>
                        </SettingsMainContentContainer>
                    )}
                </SettingsComponentContainer>
            </SettingsPageContainer>
            {isDataChangedDialogOpen && (
                <ConfirmDialog handleCloseDialog={handleCloseUnsavedChangesDialog}
                               onConfirmButtonClick={handleConfirmDataChanged}>
                    <DialogContainer>
                        <DialogHeader>
                            <FormattedMessage id={"message_unsaved_changes_heading"}
                                              defaultMessage={"Unsaved changes"}/>
                        </DialogHeader>
                        <DialogText>
                            <FormattedMessage id={"message_unsaved_changes_message"}
                                              defaultMessage={"All your changes will be deleted and you will no longer be able to access them."}/>
                        </DialogText>
                    </DialogContainer>
                </ConfirmDialog>
            )}
        </>
    );
};

export default SettingsPage;
