import React, {Dispatch, SetStateAction} from 'react';
import AvatarButton from "../Button/AvatarButton";
import LanguageButton from "../Button/LanguageButton";
import NotificationButton from "../Button/NotificationButton";
import SearchButton from "../Button/SearchButton";
import styled from "styled-components";
import {useAppSelector, user_customers_autogenerated} from "@motv-webapp/redux";

const UtilityMenuContainer = styled.div(props => ({
    alignItems: "center",
    display: "flex",
    flexGrow: 1,
    gap: "1.5rem",
    justifyContent: "flex-end",
    position: "relative",
}));

type Props = {
    setBlackBackground: Dispatch<SetStateAction<boolean>>
};

const UtilityMenu = ({setBlackBackground}: Props) => {
    const customerAutogenerated = useAppSelector(user_customers_autogenerated)
    return (
        <UtilityMenuContainer>
            <SearchButton {...{setBlackBackground}} />
            <NotificationButton/>
            <LanguageButton headerMode={true}/>
            {!customerAutogenerated && <AvatarButton/>}
        </UtilityMenuContainer>
    );
};

export default UtilityMenu;
