import React, {Dispatch, SetStateAction, useEffect} from 'react';
import {useAppSelector} from "@motv-webapp/redux";
import {channel_channel_categories, channel_channels} from "@motv-webapp/redux";
import styled from "styled-components";
import FilterButton from "../Button/FilterButton";
import {FormattedMessage} from "react-intl";
import {COLOR_WHITE_80} from "@motv-webapp/lib";
import ScrollContainer from "react-indiana-drag-scroll";
import {CHANNEL_TYPE_ENUM} from "@motv-webapp/lib";
import {getLocalStorage, setLocalStorage} from "@motv-webapp/lib";

const ChannelListFilterContainer = styled(ScrollContainer).attrs({className: "scroll-container"})(props => ({
    alignItems: "center",
    display: "flex",
    marginBottom: 24,
    minHeight: 32
}));

const VerticalDivider = styled.div(props => ({
    background: COLOR_WHITE_80,
    height: "1.5rem",
    margin: "0 8px",
    minWidth: 2,
}));

type Props = {
    filterButtonBackgroundColor?: string | undefined
    setTypeFilter: Dispatch<SetStateAction<string | undefined>>
    setCategoryFilter: Dispatch<SetStateAction<number | undefined>>
};

const ChannelListFilter = ({filterButtonBackgroundColor, setTypeFilter, setCategoryFilter}: Props) => {
    const channels = useAppSelector(channel_channels)
    const channelCategories = useAppSelector(channel_channel_categories)

    useEffect(() => {
        const localStorageSelectedFilter = getLocalStorage("selectedFilter")
        if (localStorageSelectedFilter === CHANNEL_TYPE_ENUM.RADIO || localStorageSelectedFilter === CHANNEL_TYPE_ENUM.CHANNEL) {
            document.getElementById(localStorageSelectedFilter)?.classList.add("selectedFilter")
            setTypeFilter(localStorageSelectedFilter)
            setCategoryFilter(undefined)
        } else if (Number(localStorageSelectedFilter)) {
            document.getElementById(`category${localStorageSelectedFilter?.toString()}`)?.classList.add("selectedFilter")
            setTypeFilter(undefined)
            setCategoryFilter(Number(localStorageSelectedFilter))
        } else {
            document.getElementById("allFilter")?.classList.add("selectedFilter")
            setTypeFilter(undefined)
            setCategoryFilter(undefined)
        }
    }, [])

    const handleAllButton = () => {
        setTypeFilter(undefined)
        setCategoryFilter(undefined)
        document.querySelector(".selectedFilter")?.classList.remove("selectedFilter")
        document.getElementById("allFilter")?.classList.add("selectedFilter")
        setLocalStorage("selectedFilter","allFilter")
    }

    const handleTypeButton = (type: CHANNEL_TYPE_ENUM) => {
        setTypeFilter(type)
        setCategoryFilter(undefined)
        document.querySelector(".selectedFilter")?.classList.remove("selectedFilter")
        document.getElementById(type)?.classList.add("selectedFilter")
        setLocalStorage("selectedFilter",type)
    }

    const handleCategoryButton = (categoryId: number) => {
        setTypeFilter(undefined)
        setCategoryFilter(categoryId)
        document.querySelector(".selectedFilter")?.classList.remove("selectedFilter")
        document.getElementById(`category${categoryId.toString()}`)?.classList.add("selectedFilter")
        setLocalStorage("selectedFilter",categoryId.toString())
    }

    return (
        <ChannelListFilterContainer>
            <FilterButton
                id={"allFilter"}
                onClick={handleAllButton}
                backgroundColor={filterButtonBackgroundColor}
            >
                <FormattedMessage id={"label_all"} defaultMessage={"All"}/>
            </FilterButton>
            {channels?.some(channel => channel.channels_type === CHANNEL_TYPE_ENUM.CHANNEL)
                && <FilterButton
                onClick={() => handleTypeButton(CHANNEL_TYPE_ENUM.CHANNEL)}
                id={CHANNEL_TYPE_ENUM.CHANNEL}
                backgroundColor={filterButtonBackgroundColor}
              >
                <FormattedMessage id={"section_tv"} defaultMessage={"TV"}/>
              </FilterButton>}
            {channels?.some(channel => channel.channels_type === CHANNEL_TYPE_ENUM.RADIO)
                && <FilterButton
                onClick={() => handleTypeButton(CHANNEL_TYPE_ENUM.RADIO)}
                id={CHANNEL_TYPE_ENUM.RADIO}
                backgroundColor={filterButtonBackgroundColor}
              >
                <FormattedMessage id={"section_radios"} defaultMessage={"Radios"}/>
              </FilterButton>}
            {channelCategories?.length && <VerticalDivider/>}
            {channelCategories?.length && channelCategories?.map((category) => {
                return (
                    <FilterButton
                        key={category.channels_categories_id}
                        id={`category${category.channels_categories_id?.toString()}`}
                        onClick={() => handleCategoryButton(category.channels_categories_id!)}
                        backgroundColor={filterButtonBackgroundColor}
                    >
                        {category.channels_categories_name!}
                    </FilterButton>
                )
            })}
        </ChannelListFilterContainer>
    );
};

export default ChannelListFilter;
