import React, {MouseEvent, useEffect, useMemo, useState} from "react";
import {addHours, differenceInMinutes, getUnixTime, isAfter, parseISO, startOfDay} from "date-fns";
import styled from "styled-components";
import {
    COLOR_NEUTRAL,
    COLOR_SECONDARY,
    COLOR_OFFWHITE_90,
    COLOR_OFFWHITE,
} from "@motv-webapp/lib";
import {TYPEFACE_CAPTION, TYPEFACE_WEB_CAPTION_2} from "@motv-webapp/lib";
import {RecommendationCardEntity} from "@motv-webapp/lib";
import {useInterval} from "react-use";
import {TIME_CONST_ONE_MINUTE} from "@motv-webapp/lib";
import {useAppSelector} from "@motv-webapp/redux";
import {user_selectedLanguage} from "@motv-webapp/redux";
import {RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM} from "@motv-webapp/lib";
import {useNavigate} from "react-router-dom";
import ProgressBar from "../ProgressBar/ProgressBar";

const EpgRowItemContainer = styled.div((props: { width: number, lastItem: boolean }) => ({
    boxSizing: "border-box" as "border-box",
    padding: "0 2px",
    paddingRight: props.lastItem ? 0 : "2px",
    position: "relative" as "relative",
    width: props.width,
}));

const EpgRowItem = styled.div((props: { width: number, hover: boolean, lastItem: boolean, isOverflowing: boolean }) => ({
    background: props.hover ? COLOR_OFFWHITE : COLOR_SECONDARY,
    borderRadius: 8,
    borderBottomRightRadius: props.lastItem ? 0 : "8px",
    borderTopRightRadius: props.lastItem ? 0 : "8px",
    boxSizing: "border-box" as "border-box",
    color: props.hover ? COLOR_NEUTRAL : COLOR_OFFWHITE_90,
    cursor: props.hover ? "pointer" : "default",
    display: (props.hover && props.isOverflowing && !props.lastItem) ? "inline-block" : "block",
    height: "72px",
    overflow: "hidden" as "hidden",
    padding: props.width <= 18 ? (props.hover ? "12px" : "12px 0") : "12px",
    paddingRight: props.lastItem ? 0 : "12px",
    position: "relative" as "relative",
    textOverflow: "ellipsis" as "ellipsis",
    whiteSpace: "nowrap" as "nowrap",
    width: (props.hover && props.isOverflowing && !props.lastItem) ? ("auto") : (props.lastItem ? props.width - 2 : props.width - 4),
    zIndex: props.hover ? 1 : 0,
}));

const EpgRowItemInfoContainer = styled.div(props => ({
    alignItems: "center",
    alignContent: "flex-end",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "16px",
}));

const EpgRowItemTime = styled.div((props: { hover: boolean }) => ({
    ...TYPEFACE_CAPTION,
    color: props.hover ? COLOR_SECONDARY : COLOR_OFFWHITE_90,
    overflow: "hidden",
    textOverflow: "ellipsis",
}));

const EpgRowItemGenre = styled.div((props: { hover: boolean }) => ({
    ...TYPEFACE_WEB_CAPTION_2,
    color: props.hover ? COLOR_SECONDARY : COLOR_OFFWHITE_90,
    overflow: "hidden",
    textOverflow: "ellipsis",
}));

type Props = {
    mediaItem: RecommendationCardEntity
    selectedDate: Date
    MINUTE_TO_PIXELS: number
};
const TVGuideRowStyleItem = ({mediaItem, selectedDate, MINUTE_TO_PIXELS}: Props) => {
    const [hover, setHover] = useState(false)
    const [isLastItem, setIsLastItem] = useState(false)
    const [isLive, setIsLive] = useState(false)
    const [isOverflowing, setIsOverflowing] = useState(false)
    const navigate = useNavigate()
    const language = useAppSelector(user_selectedLanguage)

    useEffect(() => {
        setIsLive((getUnixTime(parseISO(mediaItem?.start!)) < getUnixTime(Date.now()) && (getUnixTime(Date.now()) < getUnixTime(parseISO(mediaItem?.end!)))))
    }, [])

    useInterval(() => {
        setIsLive((getUnixTime(parseISO(mediaItem?.start!)) < getUnixTime(Date.now()) && (getUnixTime(Date.now()) < getUnixTime(parseISO(mediaItem?.end!)))))
    }, TIME_CONST_ONE_MINUTE)

    const handleMouseEnter = (e: MouseEvent<HTMLDivElement>) => {
        setHover(true)
        const target = e.target as HTMLDivElement
        if (target.scrollWidth > target.clientWidth) {
            setIsOverflowing(true)
        }
    }

    const handleMouseLeave = () => {
        setHover(false)
    }

    const handleClick = () => {
        switch (mediaItem.type) {
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV:
                navigate(`/event/${mediaItem.id}`)
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.RECORDING:
                navigate(`/recording/${mediaItem.id}`)
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.VOD:
                navigate(`/vod/${mediaItem.id}`)
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.YOUTUBE:
                navigate(`/vod/${mediaItem.id}`)
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.CATEGORY:
                navigate(`/category/${mediaItem.id}`)
                break
            default:
                return
        }
    }

    const calculateWidth = () => {
        if (isAfter(Date.parse(mediaItem.end!), addHours(startOfDay(selectedDate), 25))) {
            return differenceInMinutes(addHours(startOfDay(selectedDate), 25), new Date(mediaItem.start!)) * MINUTE_TO_PIXELS
        } else return differenceInMinutes(new Date(mediaItem.end!), new Date(mediaItem.start!)) * MINUTE_TO_PIXELS
    }

    const widthCalculation = useMemo(() => calculateWidth(),[selectedDate])

    useEffect(() => {
        setIsLastItem(isAfter(Date.parse(mediaItem.end!), addHours(startOfDay(selectedDate), 25)))
    },[])

    return (
        <EpgRowItemContainer width={widthCalculation}
                             lastItem={isLastItem}>
            <EpgRowItem isOverflowing={isOverflowing}
                        lastItem={isLastItem}
                        hover={hover} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
                        onClick={handleClick} width={widthCalculation}>
                {isLive && !hover && (
                    <ProgressBar progress={(getUnixTime(new Date()) - getUnixTime(parseISO(mediaItem?.start!))) /
                        (getUnixTime(parseISO(mediaItem?.end!)) - getUnixTime(parseISO(mediaItem?.start!))) * 100}
                    />
                )}
                {mediaItem.title}
                <EpgRowItemInfoContainer>
                    <EpgRowItemTime hover={hover}>
                        {new Intl.DateTimeFormat(language, {
                            hour: 'numeric',
                            minute: 'numeric'
                        }).format(Date.parse(mediaItem.start!))}
                    </EpgRowItemTime>
                    {mediaItem.genres?.length && (
                        <EpgRowItemGenre hover={hover}>
                            {mediaItem.genres.substring(0, mediaItem.genres.indexOf(",", 0)).length ? mediaItem.genres.substring(0, mediaItem.genres.indexOf(",", 0)) : mediaItem.genres}
                        </EpgRowItemGenre>
                    )}
                </EpgRowItemInfoContainer>
            </EpgRowItem>
        </EpgRowItemContainer>

    )
}

export default TVGuideRowStyleItem;
