import DetailDescription from "../components/DetailView/DetailDescription";
import DetailHero from "../components/DetailView/DetailHero";
import Header from "../components/Header/Header";
import MainRows from "../components/Rows/MainRows";
import React, {useEffect} from 'react';
import styled from "styled-components";
import {useParams} from "react-router-dom";
import {
    COLOR_NEUTRAL,
    COLOR_WHITE,
    isRecommendationCardType
} from "@motv-webapp/lib";
import {
    channelGetSubscribedChannels,
    setClearCacheFalse,
    universalGetMyList,
    universalGetMyRecordings,
    universalSelectedItemVodGetDataV2,
    universal_clearCache,
    universal_selectedItem,
    universal_selectedItemRows,
    useAppDispatch,
    useAppSelector,
} from "@motv-webapp/redux";

const DetailContainer = styled.div(props => ({
    background: COLOR_NEUTRAL,
    color: COLOR_WHITE,
    minHeight: "100vh",
    overflowX: "hidden",
}));

const MobileSpacer = styled.div(props =>({
        height: 40,
}));

const DetailVodPage = () => {
    const {mediaId} = useParams()
    const dispatch = useAppDispatch()
    const mediaItem = useAppSelector(universal_selectedItem)
    const rows = useAppSelector(universal_selectedItemRows)
    const clearCache = useAppSelector(universal_clearCache)

    useEffect(() => {
        dispatch(universalGetMyList())
        dispatch(channelGetSubscribedChannels({body: {}}))
        dispatch(universalGetMyRecordings())
    }, []) // eslint-disable-line

    useEffect(() => {
        mediaId && dispatch(universalSelectedItemVodGetDataV2({body: {vodsId: parseInt(mediaId)}}))
    }, [mediaId]) // eslint-disable-line

    useEffect(() => {
        if (clearCache) {
            dispatch(universalGetMyList())
            dispatch(channelGetSubscribedChannels({body: {}, ignoreCache: true}))
            dispatch(universalGetMyRecordings())
            mediaId && dispatch(universalSelectedItemVodGetDataV2({body: {vodsId: parseInt(mediaId)}, ignoreCache: true}))
            dispatch(setClearCacheFalse())
        }
    }, [clearCache]) // eslint-disable-line

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
    }, [])

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }, [mediaId])

    return (
        <DetailContainer>
            <Header/>
            {mediaItem && isRecommendationCardType(mediaItem) &&
                <>
                <DetailHero {...{mediaItem}} />
                  <DetailDescription {...{mediaItem}} />
                  {rows && <MainRows disableHeaderSpacing={true} {...{rows}} />}
                <MobileSpacer />
              </>
            }
        </DetailContainer>
    );
};

export default DetailVodPage;
