import React, {MouseEvent, useEffect, useRef, useState} from 'react';
import {SubscribedChannelEntity} from "@motv-webapp/lib";
import styled from "styled-components";
import {
    COLOR_NEUTRAL_90,
    COLOR_TERTIARY,
    COLOR_OFFWHITE, COLOR_OFFWHITE_90, COLOR_PRIMARY_100, COLOR_WHITE
} from "@motv-webapp/lib";
import {useAppDispatch, useAppSelector} from "@motv-webapp/redux";
import {vendor_vendors_accent_color} from "@motv-webapp/redux";
import {channel_epg} from "@motv-webapp/redux";
import {getUnixTime, isAfter, isBefore, parseISO} from "date-fns";
import {TYPEFACE_BODY_EXTRABOLD} from "@motv-webapp/lib";
import IconButtonTransparent from "../Button/IconButtonTransparent";
import {
    universal_favouriteChannels, universal_selectedItem,
    universalAddFavouriteChannel,
    universalRemoveFavouriteChannel
} from "@motv-webapp/redux";
import {truncate} from "@motv-webapp/lib";
import {RecommendationCardEntity} from "@motv-webapp/lib";
import ProgressBar from "../ProgressBar/ProgressBar";
import {useInterval} from "react-use";
import {TIME_CONST_ONE_MINUTE} from "@motv-webapp/lib";
import Color from "color";
import {useNavigate} from "react-router-dom";

const SideControllersChannelItemContainer = styled.div((props: { accentColor: string | null | undefined, hover: boolean, isLive: boolean }) => ({
    alignItems: "center",
    background: props.hover
        ? COLOR_OFFWHITE
        : props.isLive
            ? props.accentColor
                ? Color(props.accentColor).darken(0.3).rgb().string()
                : Color(COLOR_PRIMARY_100).alpha(0.5).rgb().string()
            : COLOR_NEUTRAL_90,
    borderRadius: 8,
    color: props.hover ? COLOR_TERTIARY : COLOR_WHITE,
    cursor: "pointer",
    display: "flex",
    marginRight: 12,
    minHeight: 96,
    height: 96,
    overflow: "hidden",
    position: "relative" as "relative",
}));

const ChannelImage = styled.div((props: { channelImage: string | undefined }) => ({
    backgroundImage: `url(${props.channelImage})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    height: 78,
    marginLeft: 16,
    marginRight: 16,
    minWidth: 78,
    width: 78,
}));

const ChannelInfo = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    gap: 8,
}));

const ChannelInfoChannelName = styled.div(props => ({
    ...TYPEFACE_BODY_EXTRABOLD,
}));

type Props = {
    channelItem: SubscribedChannelEntity
};

const SideControllersChannelsItem = ({channelItem}: Props) => {
    const [hover, setHover] = useState(false)
    const [isLive, setIsLive] = useState(false)
    const [liked, setLiked] = useState(false)
    const [progress, setProgress] = useState(0)
    const accentColor = useAppSelector(vendor_vendors_accent_color)
    const currentlyPlaying = useAppSelector(universal_selectedItem) as RecommendationCardEntity
    const epgItems = useAppSelector(channel_epg)
    const favouriteChannels = useAppSelector(universal_favouriteChannels)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const channelItemRef = useRef<HTMLDivElement>(null)

    const currentEpg = epgItems?.find(item => item.channels_id === channelItem.channels_id && isBefore(new Date(item.start!), new Date()) && isAfter(new Date(item.end!), new Date()))

    useEffect(() => {
        setProgress((getUnixTime(new Date()) - getUnixTime(parseISO(currentEpg?.start!))) / (getUnixTime(parseISO(currentEpg?.end!)) - getUnixTime(parseISO(currentEpg?.start!))) * 100)
    }, [])

    useInterval(() => {
        setProgress((getUnixTime(new Date()) - getUnixTime(parseISO(currentEpg?.start!))) / (getUnixTime(parseISO(currentEpg?.end!)) - getUnixTime(parseISO(currentEpg?.start!))) * 100)
    }, TIME_CONST_ONE_MINUTE)

    useEffect(() => {
        if (!favouriteChannels) return
        setLiked(favouriteChannels.includes(channelItem.channels_id!))
    }, [favouriteChannels])

    useEffect(() => {
        currentlyPlaying.channels_id === channelItem.channels_id
            ? setIsLive(true)
            : setIsLive(false)
    }, [currentlyPlaying])

    useEffect(() => {
        if (!isLive || (channelItemRef.current?.offsetTop && channelItemRef.current?.offsetTop > 10000)) return
        setTimeout(() => channelItemRef.current?.scrollIntoView({block: "center", behavior: "smooth"}), 300)
    }, [isLive])

    const handleClickFavouriteButton = (e: MouseEvent<HTMLButtonElement|HTMLImageElement>) => {
        e.stopPropagation()
        if (liked) {
            dispatch(universalRemoveFavouriteChannel({channelsId: channelItem.channels_id!}))
        } else {
            dispatch(universalAddFavouriteChannel({channelsId: channelItem.channels_id!}))
        }
    }

    const handleMouseEnter = () => {
        setHover(true)
    }

    const handleMouseLeave = () => {
        setHover(false)
    }

    const handleClick = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        navigate(`/tv/player/${channelItem.channels_id}`, {replace: true})
    }

    return (
        <SideControllersChannelItemContainer
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            ref={channelItemRef}
            {...{accentColor, hover, isLive}}
        >
            <ChannelImage channelImage={channelItem.channels_logo}/>
            <ChannelInfo>
                {channelItem.channels_name &&
                  <ChannelInfoChannelName>{channelItem.channels_name}</ChannelInfoChannelName>
                }
                {currentEpg?.title &&
                  <div>{truncate(currentEpg.title, 50, true)}</div>
                }
            </ChannelInfo>
            <IconButtonTransparent
                img={liked ? "/images/icons/icon_favorite.svg" : "/images/icons/icon_favorite_not_yet.svg"}
                imageFilter={hover ? "brightness(0) saturate(100%) invert(5%) sepia(2%) saturate(3257%) hue-rotate(182deg) brightness(105%) contrast(96%)" : "brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(1524%) hue-rotate(254deg) brightness(83%) contrast(69%)"}
                onClick={handleClickFavouriteButton}
                size={20}
                style={{marginLeft: 16, marginRight: 16}}
            />
            <ProgressBar overrideColor={hover ? COLOR_OFFWHITE_90 : undefined} progress={progress}/>
        </SideControllersChannelItemContainer>
    );
};

export default SideControllersChannelsItem;
