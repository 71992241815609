import React, {useState} from 'react';
import {RecommendationCardEntity} from "@motv-webapp/lib";
import styled from "styled-components";
import {useAppSelector} from "@motv-webapp/redux";
import {user_selectedLanguage} from "@motv-webapp/redux";
import {TYPEFACE_CAPTION} from "@motv-webapp/lib";
import {COLOR_BLACK_30, COLOR_OFFWHITE_70, COLOR_OFFWHITE_90} from "@motv-webapp/lib";

const PlayerMediaCardContainer = styled.div(props => ({
    width: 250,
}));

const MediaImage = styled.div((props: { src: string }) => ({
    background: `url(${props.src})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    borderRadius: 6,
    height: 140,
    marginBottom: 12,
    position: "relative" as "relative",
    width: 250,
}));

const HoverOverlay = styled.div(props => ({
    background: COLOR_BLACK_30,
    height: "100%",
    position: "absolute",
    width: "100%",
}));

const PlayButton = styled.img(props =>({
    cursor: "pointer",
    height: 56,
    left: "50%",
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: 56,
}));

const Title = styled.div(props => ({
    marginBottom: 8,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
}));

const Info = styled.div(props => ({
    ...TYPEFACE_CAPTION,
    alignItems: "center",
    color: COLOR_OFFWHITE_90,
    display: "flex",
    justifyContent: "space-between",
    "& > div": {
        display: "flex",
        flexDirection: "column",
        "& > div": {
            marginBottom: 9,
        },
        "& > p": {
            color: COLOR_OFFWHITE_70,
            margin: 0,
        },
    },
    "& > img": {
        height: 30,
    },
}));


type Props = {
    handlePlay: () => void
    mediaCard: RecommendationCardEntity
    type?: "play" | "replay"
};

const PlayerMediaCard = ({handlePlay, mediaCard, type = "play"}: Props) => {
    const [hover, setHover] = useState(false)
    const [playHover, setPlayHover] = useState(false)
    const language = useAppSelector(user_selectedLanguage)

    const handleMouseEnter = () => {
        setHover(true)
    }

    const handleMouseLeave = () => {
        setHover(false)
    }

    const handlePlayHoverMouseEnter = () => {
        setPlayHover(true)
    }

    const handlePlayHoverMouseLeave = () => {
        setPlayHover(false)
    }

    return (
        <PlayerMediaCardContainer>
            <MediaImage
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                src={mediaCard.image_widescreen ? mediaCard.image_widescreen : mediaCard.image!}
            >
                {hover &&
                  <HoverOverlay>
                      {type === "replay"
                          ? <PlayButton
                              alt={""}
                              onClick={handlePlay}
                              onMouseEnter={handlePlayHoverMouseEnter}
                              onMouseLeave={handlePlayHoverMouseLeave}
                              src={`/images/icons/${playHover ? "icon_replay_circle_hover" : "icon_replay_circle"}.svg`}
                          />
                          : <PlayButton
                              alt={""}
                              onClick={handlePlay}
                              onMouseEnter={handlePlayHoverMouseEnter}
                              onMouseLeave={handlePlayHoverMouseLeave}
                              src={`/images/icons/${playHover ? "icon_play_circle_hover" : "icon_play_circle"}.svg`}
                          />
                      }
                  </HoverOverlay>
                }
            </MediaImage>
            {mediaCard?.title && <Title>{mediaCard.title}</Title>}
            <Info>
                <div>
                    {mediaCard.episode && <div>{mediaCard.episode}</div>}
                    {mediaCard?.start && <p>{new Intl.DateTimeFormat(language, {
                        month: 'long', day: 'numeric',
                        hour: 'numeric', minute: 'numeric',
                        hour12: false,
                    }).format(Date.parse(mediaCard.start))}</p>}
                </div>
                {mediaCard?.channels_logo &&
                  <img src={mediaCard.channels_logo} alt={""}/>
                }
            </Info>
        </PlayerMediaCardContainer>
    );
};

export default PlayerMediaCard;
