"use strict";
exports.__esModule = true;
exports.RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM = void 0;
var RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM;
(function (RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM) {
    RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM["YOUTUBE"] = "Youtube";
    // PORNHUB = "Pornhub",
    // XVIDEOS = "XVideos",
    RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM["TV"] = "TV";
    RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM["RECORDING"] = "Recording";
    RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM["VOD"] = "VOD";
    RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM["CHANNEL"] = "Channel";
    RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM["PERSON"] = "Person";
    RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM["CATEGORY"] = "Category";
    RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM["GENRE"] = "Genre";
    RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM["IMAGE"] = "Image";
    RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM["VIDEO"] = "Video";
    RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM["ADMOB"] = "Admob";
})(RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM = exports.RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM || (exports.RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM = {}));
