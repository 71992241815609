import React from "react";
import styled from "styled-components";
import CustomerEdit from "../../Customer/CustomerEdit";
import {useWindowSize} from "react-use";

const CustomerMainContainer = styled.div(props => ({
    display: "flex",
    "@media (max-width: 870px)": {
        justifyContent: "center",
    },
}));

type Props = {
    afterSubmit?: () => void
};

const CustomerSettings = ({afterSubmit}: Props) => {
    const {width} = useWindowSize()

    return (
        <CustomerMainContainer>
            <CustomerEdit isWide={width <= 870} {...{afterSubmit}} />
        </CustomerMainContainer>
    )
}

export default CustomerSettings;
