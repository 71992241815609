"use strict";
exports.__esModule = true;
exports.findGetParameter = void 0;
var findGetParameter = function (parameterName) {
    var result = null, tmp = [];
    window.location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
        tmp = item.split("=");
        if (tmp[0] === parameterName)
            result = decodeURIComponent(tmp[1]);
    });
    return result;
};
exports.findGetParameter = findGetParameter;
