import React, {MouseEvent} from 'react';
import styled from "styled-components";
import {COLOR_INPUT_FIELD_GREY} from "@motv-webapp/lib";

const StyledButton = styled.button(props => ({
    alignItems: "center",
    backgroundColor: COLOR_INPUT_FIELD_GREY,
    border: 0,
    borderRadius: 10,
    boxShadow: "3px 3px 10px 1px rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    display: "flex",
    height: "4.1875rem",
    justifyContent: "center",
    width: "4.1875rem",
    "& img": {
        height: "2rem",
    }
}));

type Props = {
    disabled?: boolean
    img: string
    onClick?: () => void
};

const IconButton = ({disabled = false, img, onClick}: Props) => {
    const handleImgClick = (e: MouseEvent<HTMLImageElement>) => {
        if (!onClick) return
        e.stopPropagation()
        onClick()
    }

    return (
        <StyledButton {...{disabled, onClick}}><img src={img} alt={""} onClick={handleImgClick} /></StyledButton>
    );
};

export default IconButton;
