"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
exports.__esModule = true;
exports.channel_cachedChannelsEpg = exports.channel_startupChannel = exports.channel_epg = exports.channel_channel_categories = exports.channel_channels = exports.channel_status = exports.setCachedEpgs = exports.setCachedChannelsEpg = exports.channelReducer = exports.channelGetStartupChannel = exports.channelGetChannelCategories = exports.epgGetUpdatedEventsV2forCache = exports.epgGetUpdatedEventsV2 = exports.channelGetSubscribedAndLockedChannels = exports.channelGetSubscribedChannels = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var lib_1 = require("@motv-webapp/lib");
var channelAPI_1 = require("./channelAPI");
var initialState = {
    status: "idle",
    channels: undefined,
    channelCategories: undefined,
    epg: undefined,
    lastUpdatedChannels: undefined,
    lastUpdatedChannelsMode: undefined,
    lastUpdatedChannelCategories: undefined,
    startupChannel: undefined,
    cachedChannelsEpg: []
};
exports.channelGetSubscribedChannels = (0, toolkit_1.createAsyncThunk)('channel/getSubscribedChannels', function (arg, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, channel, universal, response;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = thunkAPI.getState(), channel = _a.channel, universal = _a.universal;
                if (!(channel.lastUpdatedChannels
                    && (channel.lastUpdatedChannels + (lib_1.TIME_CONST_ONE_MINUTE * lib_1.TIME_CONST_NUMBER_OF_MINUTES_TO_CACHE)) > Date.now()
                    && (universal.invalidateCache ? channel.lastUpdatedChannels > universal.invalidateCache : true)
                    && channel.lastUpdatedChannelsMode === 1
                    && !arg.ignoreCache)) return [3 /*break*/, 1];
                return [2 /*return*/, channel.channels];
            case 1: return [4 /*yield*/, (0, channelAPI_1.fetch_mw_channelGetSubscribedChannels)(arg.body)];
            case 2:
                response = _b.sent();
                return [4 /*yield*/, response.response];
            case 3: return [2 /*return*/, _b.sent()];
        }
    });
}); });
exports.channelGetSubscribedAndLockedChannels = (0, toolkit_1.createAsyncThunk)('channel/getSubscribedAndLockedChannels', function (arg, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, channel, universal, response;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = thunkAPI.getState(), channel = _a.channel, universal = _a.universal;
                if (!(channel.lastUpdatedChannels
                    && (channel.lastUpdatedChannels + (lib_1.TIME_CONST_ONE_MINUTE * lib_1.TIME_CONST_NUMBER_OF_MINUTES_TO_CACHE)) > Date.now()
                    && (universal.invalidateCache ? channel.lastUpdatedChannels > universal.invalidateCache : true)
                    && channel.lastUpdatedChannelsMode === 2
                    && !arg.ignoreCache)) return [3 /*break*/, 1];
                return [2 /*return*/, channel.channels];
            case 1: return [4 /*yield*/, (0, channelAPI_1.fetch_mw_channelGetSubscribedAndLockedChannels)(arg.body)];
            case 2:
                response = _b.sent();
                return [4 /*yield*/, response.response];
            case 3: return [2 /*return*/, _b.sent()];
        }
    });
}); });
exports.epgGetUpdatedEventsV2 = (0, toolkit_1.createAsyncThunk)('channel/epgGetUpdatedEventsV2', function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, channelAPI_1.fetch_mw_epgGetUpdatedEventsV2)(body)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.response];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); });
exports.epgGetUpdatedEventsV2forCache = (0, toolkit_1.createAsyncThunk)('channel/epgGetUpdatedEventsV2forCache', function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, channelAPI_1.fetch_mw_epgGetUpdatedEventsV2)(body)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.response];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); });
exports.channelGetChannelCategories = (0, toolkit_1.createAsyncThunk)('channel/getChannelCategories', function (arg, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, channel, universal, response;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = thunkAPI.getState(), channel = _a.channel, universal = _a.universal;
                if (!(channel.lastUpdatedChannelCategories
                    && (channel.lastUpdatedChannelCategories + (lib_1.TIME_CONST_ONE_MINUTE * lib_1.TIME_CONST_NUMBER_OF_MINUTES_TO_CACHE)) > Date.now()
                    && (universal.invalidateCache ? channel.lastUpdatedChannelCategories > universal.invalidateCache : true)
                    && !arg.ignoreCache)) return [3 /*break*/, 1];
                return [2 /*return*/, channel.channelCategories];
            case 1: return [4 /*yield*/, (0, channelAPI_1.fetch_mw_channelGetChannelCategories)()];
            case 2:
                response = _b.sent();
                return [4 /*yield*/, response.response];
            case 3: return [2 /*return*/, _b.sent()];
        }
    });
}); });
exports.channelGetStartupChannel = (0, toolkit_1.createAsyncThunk)('channel/getStartupChannel', function () { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, channelAPI_1.fetch_mw_getStartupChannel)()];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.response];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); });
exports.channelReducer = (0, toolkit_1.createSlice)({
    name: 'channel',
    initialState: initialState,
    reducers: {
        // removeAllErrors: () => initialState,
        // addSmsError: (state, action: PayloadAction<ErrorType>) => {
        //     state.error_sms = action.payload
        // }
        setCachedChannelsEpg: function (state, action) {
            state.cachedChannelsEpg = action.payload;
        },
        setCachedEpgs: function (state, action) {
            state.epg = action.payload;
        }
    },
    extraReducers: function (builder) {
        builder
            .addCase(exports.channelGetSubscribedChannels.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.channelGetSubscribedChannels.fulfilled, function (state, action) {
            state.status = 'idle';
            state.channels = action.payload;
            state.lastUpdatedChannels = Date.now();
            state.lastUpdatedChannelsMode = 1;
        })
            .addCase(exports.channelGetSubscribedAndLockedChannels.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.channelGetSubscribedAndLockedChannels.fulfilled, function (state, action) {
            state.status = 'idle';
            state.channels = action.payload;
            state.lastUpdatedChannels = Date.now();
            state.lastUpdatedChannelsMode = 2;
        })
            .addCase(exports.epgGetUpdatedEventsV2.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.epgGetUpdatedEventsV2.fulfilled, function (state, action) {
            state.status = 'idle';
            state.epg = action.payload;
        }).addCase(exports.epgGetUpdatedEventsV2forCache.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.epgGetUpdatedEventsV2forCache.fulfilled, updateEpgFromPayload)
            .addCase(exports.channelGetChannelCategories.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.channelGetChannelCategories.fulfilled, function (state, action) {
            state.status = 'idle';
            state.channelCategories = action.payload;
            state.lastUpdatedChannelCategories = Date.now();
        })
            .addCase(exports.channelGetStartupChannel.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.channelGetStartupChannel.fulfilled, function (state, action) {
            state.startupChannel = action.payload;
        });
    }
});
var updateEpgFromPayload = function (state, action) {
    if (action.payload) {
        if (state.epg) {
            var fetchedEpg = state.epg.concat(action.payload);
            state.epg = fetchedEpg.reduce(function (result, epg) {
                return result.find(function (item) { return item.id === epg.id; }) ? result : __spreadArray(__spreadArray([], result, true), [epg], false);
            }, []);
        }
        else {
            state.epg = action.payload;
        }
        state.status = 'idle';
    }
};
exports.setCachedChannelsEpg = (_a = exports.channelReducer.actions, _a.setCachedChannelsEpg), exports.setCachedEpgs = _a.setCachedEpgs;
// Selectors
var channel_status = function (state) { return state.channel.status; };
exports.channel_status = channel_status;
var channel_channels = function (state) { return state.channel.channels; };
exports.channel_channels = channel_channels;
var channel_channel_categories = function (state) { return state.channel.channelCategories; };
exports.channel_channel_categories = channel_channel_categories;
var channel_epg = function (state) { return state.channel.epg; };
exports.channel_epg = channel_epg;
var channel_startupChannel = function (state) { return state.channel.startupChannel; };
exports.channel_startupChannel = channel_startupChannel;
var channel_cachedChannelsEpg = function (state) { return state.channel.cachedChannelsEpg; };
exports.channel_cachedChannelsEpg = channel_cachedChannelsEpg;
exports["default"] = exports.channelReducer.reducer;
