import React from 'react';
import styled from "styled-components";
import {useAppSelector, vendor_vendors_accent_color} from "@motv-webapp/redux";

const ProgressBarContainer = styled.div((props:{overrideHeight: number | string | undefined, overrideBorderRadius: number | string | undefined}) =>({
    background: "rgba(255, 255, 255, 0.15)",
    bottom: 0,
    borderRadius: props.overrideBorderRadius ? props.overrideBorderRadius : 0,
    height: props.overrideHeight ? props.overrideHeight : 5,
    left:0,
    position: "absolute" as "absolute",
    width: "100%"
}));

const ProgressColor = styled.div((props: {accentColor: string | undefined | null, overrideColor: string | undefined, progress: number, overrideBorderRadius: number | string | undefined}) =>({
    background: `${props.overrideColor ? props.overrideColor : props.accentColor}`,
    borderRadius: props.overrideBorderRadius ? props.overrideBorderRadius : "0 6px 6px 0",
    height: "100%",
    width: `${props.progress}%`,
}));

type Props = {
    progress: number
    overrideColor?: string
    overrideHeight?: number | string
    overrideBorderRadius?: number | string
};

const ProgressBar = ({overrideColor, overrideHeight, overrideBorderRadius, progress}: Props) => {
    const accentColor = useAppSelector(vendor_vendors_accent_color)
    return (
        <ProgressBarContainer {...{overrideHeight, overrideBorderRadius}}>
            <ProgressColor {...{accentColor, overrideColor, progress, overrideBorderRadius}} />
        </ProgressBarContainer>
    );
};

export default ProgressBar;
