import React, {MouseEvent, useEffect, useState} from "react";
import styled from "styled-components";
import {differenceInDays, getUnixTime, isAfter} from "date-fns";
import Color from "color";
import ReadableDate from "../ReadableDate/ReadableDate";
import {FormattedMessage} from "react-intl";
import {useNavigate, useParams} from "react-router-dom";
import {
    COLOR_BLACK,
    COLOR_OFFWHITE_70,
    COLOR_SECONDARY,
    COLOR_TERTIARY_90,
    COLOR_WHITE,
    RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM,
    RecommendationCardEntity,
    TYPEFACE_CAPTION,
    TYPEFACE_WEB_BODY_2_MEDIUM,
    imageResizeUrl,
} from "@motv-webapp/lib";
import {
    removeFromMyListOnlyFromRedux,
    removeFromRecordingsOnlyFromRedux,
    selectYoutubeItem,
    useAppDispatch,
    useAppSelector,
    user_selectedLanguage,
} from "@motv-webapp/redux";

const RecordingCardContainer = styled.div((props: { hoverCard: boolean, isEdit: boolean }) => ({
    background: props.hoverCard ? COLOR_SECONDARY : "transparent",
    borderRadius: 8,
    boxSizing: "border-box" as "border-box",
    cursor: "pointer",
    display: "flex",
    height: 80,
    justifyContent: props.isEdit ? "space-between" : "start",
    position: "relative" as "relative",
    width: 340,
}));

const RecordingCardImageContainer = styled.div((props) => ({
    alignItems: "center",
    borderRadius: 8,
    display: "flex",
    flexShrink: 0,
    justifyContent: "center",
    position: "relative" as "relative",
    width: 128,
    "& img": {
        borderRadius: 8,
        objectFit: "cover",
        height: "100%",
        width: "100%",
    }
}));
const RecordingCardImageOverlay = styled.div((props) => ({
    alignItems: "center",
    background: Color(COLOR_BLACK).alpha(0.2).rgb().string(),
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    left: 0,
    position: "absolute" as "absolute",
    right: 0,
    top: 0,
    width: 128,
    "& img": {
        height: 60,
        width: 60
    }
}));
const RecordingCardInfoContainer = styled.div((props: { isEdit: boolean }) => ({
    display: "flex" as "flex",
    flexDirection: "column" as "column",
    flexGrow: 1,
    margin: props.isEdit ? "10px 0 0 12px" : "10px 12px 0 12px",
    overflow: "hidden" as "hidden",
    textOverflow: "ellipsis" as "ellipsis",
    whiteSpace: "nowrap" as "nowrap",
    "& > p": {
        ...TYPEFACE_WEB_BODY_2_MEDIUM,
        color: COLOR_WHITE,
        overflow: "hidden" as "hidden",
        textOverflow: "ellipsis" as "ellipsis",
        whiteSpace: "nowrap" as "nowrap",
        padding: 0,
        margin: 0
    },
}));
const RecordingCardDateTimeChannelContainer = styled.div(props => ({
    alignItems: "center",
    display: "flex",
    gap: 8,
    marginTop: 6,
    "& > p": {
        ...TYPEFACE_CAPTION,
        color: COLOR_OFFWHITE_70,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        padding: 0,
        margin: 0
    },
    "& > img": {
        height: 30,
    },
}));

const RemoveRecordingButton = styled.button(props => ({
    background: "transparent",
    border: 0,
    "& img": {
        height: 24,
        width: 24,
    },
}));

const ExpirationInfo = styled.div(props => ({
    ...TYPEFACE_CAPTION,
    color: COLOR_TERTIARY_90,
}));

type Props = {
    mediaItem: RecommendationCardEntity
    isEdit: boolean
}
const RecordingCard = ({mediaItem, isEdit}: Props) => {
    const [hoverCard, setHoverCard] = useState(false);
    const [hoverThumbnail, setHoverThumbnail] = useState(false);
    const [hoverRemoveButton, setHoverRemoveButton] = useState(false);
    const [expirationDaysNumber, setExpirationDaysNumber] = useState(-1);
    const [playable, setPlayable] = useState(false);
    const language = useAppSelector(user_selectedLanguage)
    const dispatch = useAppDispatch()
    const {slug} = useParams();
    const navigate = useNavigate()

    const handleThumbnailClick = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        if (isEdit) return
        if (mediaItem.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.RECORDING) {
            if (mediaItem.follow) {
                navigate(`/recording/player/${mediaItem.id}/${getUnixTime(mediaItem.follow)}`)
            } else {
                navigate(`/recording/player/${mediaItem.id}`)
            }
        } else if (mediaItem.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV) {
            if (!mediaItem.start) return
            let start = new Date(mediaItem.start)
            navigate(`/tv/player/${mediaItem.channels_id}/${getUnixTime(start)}`)
        } else if (mediaItem.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.VOD) {
            navigate(`/vod/player/${mediaItem.id}`)
        } else if (mediaItem.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.YOUTUBE) {
            dispatch(selectYoutubeItem(mediaItem))
        }
    }

    const handleCardClick = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        if (isEdit) return
        switch (mediaItem.type) {
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV:
                navigate(`/event/${mediaItem.id}`)
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.RECORDING:
                navigate(`/recording/${mediaItem.id}`)
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.VOD:
                navigate(`/vod/${mediaItem.id}`)
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.YOUTUBE:
                navigate(`/vod/${mediaItem.id}`)
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.CATEGORY:
                navigate(`/category/${mediaItem.id}`)
                break
            default:
                return
        }
    }

    const handleRemoveItem = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        if (!mediaItem.id || !mediaItem.type) return
        if (slug === "recordings") {
            dispatch(removeFromRecordingsOnlyFromRedux(mediaItem.id))
        } else if (slug === "my-list") {
            dispatch(removeFromMyListOnlyFromRedux({type: mediaItem.type, id: mediaItem.id}))
        }
    }

    const handleHoverCard = () => {
        setHoverCard(true)
    }
    const handleExitHoverCard = () => {
        setHoverCard(false)
    }
    const handleHoverThumbnail = () => {
        setHoverThumbnail(true)
    }
    const handleExitHoverThumbnail = () => {
        setHoverThumbnail(false)
    }
    const handleHoverRemoveButton = () => {
        setHoverRemoveButton(true)
    }
    const handleExitHoverRemoveButton = () => {
        setHoverRemoveButton(false)
    }

    useEffect(() => {
        if (mediaItem.expiration) {
            let dayDifference = differenceInDays(new Date(mediaItem.expiration), Date.now())
            setExpirationDaysNumber(dayDifference)
        }
        if (mediaItem.playable !== false) {
            if (mediaItem.end) {
                setPlayable(isAfter(Date.now(), new Date(mediaItem.end)) && !isEdit)
            } else if (mediaItem.type !== RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.CATEGORY){
                setPlayable(true)
            }
        } else setPlayable(false)

    }, [mediaItem.playable, mediaItem.expiration])

    return (
        <RecordingCardContainer onMouseEnter={handleHoverCard}
                                onMouseLeave={handleExitHoverCard} onClick={handleCardClick} {...{hoverCard, isEdit}}>
            <RecordingCardImageContainer onMouseEnter={handleHoverThumbnail} onMouseLeave={handleExitHoverThumbnail}>
                {hoverThumbnail && playable && (
                    <RecordingCardImageOverlay onClick={handleThumbnailClick}>
                        <img src={"/images/icons/icon_play_circle_hover.svg"} alt={""}/>
                    </RecordingCardImageOverlay>
                )}
                <img src={mediaItem.image_widescreen ? mediaItem.image_widescreen : mediaItem.image} alt={""}/>
            </RecordingCardImageContainer>
            <RecordingCardInfoContainer {...{isEdit}}>
                <p>
                    {mediaItem.title}
                </p>
                <RecordingCardDateTimeChannelContainer>
                    {mediaItem.start && (
                        <>
                            <p>
                                <ReadableDate date={mediaItem.start}/>
                                {" "}
                                {new Intl.DateTimeFormat(language, {
                                    hour: "numeric",
                                    minute: "numeric"
                                }).format(new Date(mediaItem.start))}
                            </p>
                            <p>|</p>
                        </>
                    )}
                    {mediaItem.channels_logo && (
                        <img src={imageResizeUrl(mediaItem.channels_logo, 40)} alt={""}/>
                    )}
                </RecordingCardDateTimeChannelContainer>
                {expirationDaysNumber >= 0 && (
                    <ExpirationInfo>
                        <FormattedMessage id={"label_expires_in"}
                                          defaultMessage={"Expires in {s}"} values={{
                            s: "",
                        }}/>{" "}
                        {new Intl.NumberFormat(language, { style: "unit", unit: "day", }).format(expirationDaysNumber)}
                    </ExpirationInfo>
                )}
            </RecordingCardInfoContainer>
            <div>
                {isEdit && (
                    <RemoveRecordingButton onClick={handleRemoveItem}
                                           onMouseEnter={handleHoverRemoveButton}
                                           onMouseLeave={handleExitHoverRemoveButton}
                    >
                        <img
                            src={hoverRemoveButton ? "/images/icons/icon_close.svg" : "/images/icons/icon_close_offwhite_70.svg"}
                            alt={""}/>
                    </RemoveRecordingButton>
                )}
            </div>

        </RecordingCardContainer>
    )
}

export default RecordingCard
