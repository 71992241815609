import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {AnimatePresence, motion, useAnimation} from "framer-motion";
import {RecommendationCardEntity} from "@motv-webapp/lib";
import {
    COLOR_SECONDARY
} from "@motv-webapp/lib";
import ChannelCardActionBar from "./ChannelCardActionBar";
import {CHANNEL_CARD_HEIGHT, CHANNEL_CARD_WIDTH} from "@motv-webapp/lib";
import {imageResizeUrl} from "@motv-webapp/lib";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "@motv-webapp/redux";
import {searchOpenFalse} from "@motv-webapp/redux";

const ChannelCardContainer = styled.div(props => ({

}));

const ChannelCardBox = styled(motion.div)(props => ({
    background: COLOR_SECONDARY,
    borderRadius: 6,
    cursor: "pointer",
    display: "flex",
    height: CHANNEL_CARD_HEIGHT,
    overflow: "hidden",
    position: "relative" as "relative",
    width: CHANNEL_CARD_WIDTH,
    "& > img": {
        maxHeight: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    }
}));

const BlurBox = styled.div(props =>({
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    filter: "blur(60px)",
    height: "100%",
    width: "100%",
    zIndex: 100,
}));

const ChannelCardInfo = styled(motion.div)(props => ({
    boxSizing: "border-box",
    height: "2rem",
    marginTop: "0.75rem",
    position: "relative",
    width: CHANNEL_CARD_WIDTH,
}));

const ChannelCardInfoTitle = styled.p(props => ({
    margin: "0 0 0.5rem 0",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
}));

type Props = {
    mediaCard: RecommendationCardEntity
};

const ChannelCard = ({mediaCard}: Props) => {
    const [hover, setHover] = useState(false)
    const controls = useAnimation()
    const controlsFiller = useAnimation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (hover) {
            controls.start({
                scale: 1.1
            })
            controlsFiller.start({
                height: "calc(200px * 0.05)",
            })
        } else {
            controls.start({
                scale: 1.0
            })
            controlsFiller.start({
                height: 0,
            })
        }
    }, [hover])

    const handleOnMouseEnter = () => {
        setHover(true)
    }

    const handleOnMouseLeave = () => {
        setHover(false)
    }

    const handleClick= () => {
        navigate(`/tv/player/${mediaCard.channels_id}`)
        dispatch(searchOpenFalse())
    }

 return (
  <ChannelCardContainer onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
   <ChannelCardBox
       animate={controls}
       onClick={handleClick}
   >
       <BlurBox style={{
           backgroundImage: `url(${imageResizeUrl(mediaCard.channels_logo, CHANNEL_CARD_WIDTH)})`,
       }}/>
    <img src={imageResizeUrl(mediaCard.channels_logo, CHANNEL_CARD_WIDTH)} alt={""} />
       {hover && <ChannelCardActionBar {...{mediaCard}} />}
   </ChannelCardBox>
      <ChannelCardInfo>
          <AnimatePresence>
              {hover && <motion.div key={`filler-${mediaCard.id}`} animate={controlsFiller}/>}
              {mediaCard.channels_name && hover && <ChannelCardInfoTitle>{mediaCard.channels_name}</ChannelCardInfoTitle>}
          </AnimatePresence>
      </ChannelCardInfo>
  </ChannelCardContainer>
 );
};

export default ChannelCard;
