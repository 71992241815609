"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
exports.__esModule = true;
exports.universal_youtubeSelectedItem = exports.universal_urlForRedirect = exports.universal_selectedItemRows = exports.universal_selectedItem = exports.universal_searchRows = exports.universal_searchOpen = exports.universal_onVideoMessage = exports.universal_notifications = exports.universal_nextRecommendedItems = exports.universal_myRecordingIds = exports.universal_myList = exports.universal_favouriteChannels = exports.universal_edgesBlacklisted = exports.universal_edgesUnreachable = exports.universal_edgesReachable = exports.universal_clearCache = exports.removeFromMyListOnlyFromRedux = exports.removeFromRecordingsOnlyFromRedux = exports.resetUrlForRedirect = exports.setUrlForRedirect = exports.setClearCacheFalse = exports.setClearCacheTrue = exports.setOnVideoMessage = exports.setNotifications = exports.searchOpenFalse = exports.searchOpenTrue = exports.selectYoutubeItem = exports.invalidateCache = exports.deselectYoutubeItem = exports.universalReducer = exports.universalGetNextPlaybackItems = exports.universalGetSearchRows = exports.universalRemoveFavouriteChannel = exports.universalAddFavouriteChannel = exports.universalGetFavouriteChannels = exports.universalGetMyRecordings = exports.universalGetMyList = exports.universalSelectedItemGetEventRecommendationRows = exports.universalSelectedItemVodGetDataV2 = exports.universalSelectedItemRecordingGetDataV2 = exports.universalSelectedItemGetUpdatedEventsV2 = exports.universalSelectedItemGetCategory = exports.universalLoadBlacklistedEdges = exports.universalEdgeSpeedCheck = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var universalAPI_1 = require("./universalAPI");
var recordingAPI_1 = require("../recording/recordingAPI");
var channelAPI_1 = require("../channel/channelAPI");
var lib_1 = require("@motv-webapp/lib");
var initialState = {
    status: "idle",
    clearCache: false,
    edgesReachable: undefined,
    edgesUnreachable: undefined,
    edgesBlacklisted: undefined,
    favouriteChannels: undefined,
    myList: undefined,
    myRecordingIds: undefined,
    nextRecommendedItems: undefined,
    notifications: undefined,
    onVideoMessage: undefined,
    searchOpen: false,
    searchRows: undefined,
    selectedItem: undefined,
    selectedItemRows: undefined,
    selectedItemLastUpdated: undefined,
    urlForRedirect: undefined,
    invalidateCache: undefined,
    youtubeSelectedItem: undefined
};
exports.universalEdgeSpeedCheck = (0, toolkit_1.createAsyncThunk)('universal/edgeSpeedCheck', function (_) { return __awaiter(void 0, void 0, void 0, function () {
    var edges, singleSpeedCheck;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, universalAPI_1.fetch_mw_edgeGetEdges)()];
            case 1:
                edges = _a.sent();
                console.log(edges);
                if (!(0, lib_1.isResponseSuccessful)(edges))
                    return [2 /*return*/];
                singleSpeedCheck = function (edge) {
                    return new Promise(function (resolve) {
                        var start = Date.now();
                        var xhr = new XMLHttpRequest();
                        xhr.open('GET', edge.edges_speed_check_url, true);
                        xhr.timeout = 4000;
                        xhr.onload = function () {
                            var end = Date.now();
                            resolve({ edgeId: edge.edges_id, time: end - start });
                        };
                        xhr.ontimeout = function () {
                            resolve({ edgeId: edge.edges_id, time: null });
                        };
                        xhr.onerror = function () {
                            resolve({ edgeId: edge.edges_id, time: null });
                        };
                        xhr.send(null);
                    });
                };
                return [2 /*return*/, Promise.all(edges.response.map(function (edge) {
                        return singleSpeedCheck(edge);
                    })).then(function (values) {
                        var reachable = values
                            .filter(function (edge) { return !!edge.time; })
                            .sort(function (a, b) { return a.time - b.time; })
                            .map(function (edge) { return edge.edgeId; });
                        var unreachable = values
                            .filter(function (edge) { return !edge.time; })
                            .map(function (edge) { return edge.edgeId; });
                        return [reachable, unreachable];
                    })];
        }
    });
}); });
exports.universalLoadBlacklistedEdges = (0, toolkit_1.createAsyncThunk)('universal/loadBlacklistedEdges', function (_) { return __awaiter(void 0, void 0, void 0, function () {
    var blacklistedEdges, validBlacklistedEdges, validBlacklistedEdgesToRedux;
    return __generator(this, function (_a) {
        blacklistedEdges = (0, lib_1.getLocalStorage)(lib_1.STORAGE_BLACKLISTED_EDGES);
        if (blacklistedEdges) {
            validBlacklistedEdges = JSON.parse(blacklistedEdges).filter(function (edge) { return edge.time + lib_1.TIME_CONST_ONE_MINUTE * 30 > Date.now(); });
            validBlacklistedEdgesToRedux = validBlacklistedEdges.map(function (edge) { return edge.edgeId; });
            (0, lib_1.setLocalStorage)(lib_1.STORAGE_BLACKLISTED_EDGES, JSON.stringify(validBlacklistedEdges));
            return [2 /*return*/, validBlacklistedEdgesToRedux];
        }
        return [2 /*return*/, []];
    });
}); });
exports.universalSelectedItemGetCategory = (0, toolkit_1.createAsyncThunk)('universal/selectedItemGetCategory', function (arg, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var universal, response;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                universal = thunkAPI.getState().universal;
                if (!(((_a = universal.selectedItem) === null || _a === void 0 ? void 0 : _a.categories_id) && universal.selectedItem.categories_id === arg.body.categoriesId && !arg.ignoreCache)) return [3 /*break*/, 1];
                return [2 /*return*/, { category: universal.selectedItem, rows: universal.selectedItemRows }];
            case 1: return [4 /*yield*/, (0, universalAPI_1.fetch_mw_recommendationEngineGetCategory)(arg.body)];
            case 2:
                response = _b.sent();
                return [4 /*yield*/, response.response];
            case 3: return [2 /*return*/, _b.sent()];
        }
    });
}); });
exports.universalSelectedItemGetUpdatedEventsV2 = (0, toolkit_1.createAsyncThunk)('universal/selectedItemGetUpdatedEventsV2', function (arg, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var universal, response;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                universal = thunkAPI.getState().universal;
                if (!(universal.selectedItemLastUpdated
                    && arg.body.ids
                    && universal.selectedItem && (0, lib_1.isRecommendationCardType)(universal.selectedItem)
                    && ((_a = universal.selectedItem) === null || _a === void 0 ? void 0 : _a.id) === arg.body.ids[0]
                    && (universal.invalidateCache ? universal.selectedItemLastUpdated > universal.invalidateCache : true)
                    && !arg.ignoreCache)) return [3 /*break*/, 1];
                return [2 /*return*/, [universal.selectedItem]];
            case 1: return [4 /*yield*/, (0, channelAPI_1.fetch_mw_epgGetUpdatedEventsV2)(arg.body)];
            case 2:
                response = _b.sent();
                thunkAPI.dispatch((0, exports.universalSelectedItemGetEventRecommendationRows)({
                    type: response.response[0].type,
                    id: response.response[0].id
                }));
                return [4 /*yield*/, response.response];
            case 3: return [2 /*return*/, _b.sent()];
        }
    });
}); });
exports.universalSelectedItemRecordingGetDataV2 = (0, toolkit_1.createAsyncThunk)('universal/selectedItemRecordingGetDataV2', function (arg, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var universal, response;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                universal = thunkAPI.getState().universal;
                if (!(universal.selectedItemLastUpdated
                    && universal.selectedItem && (0, lib_1.isRecommendationCardType)(universal.selectedItem)
                    && ((_a = universal.selectedItem) === null || _a === void 0 ? void 0 : _a.id) === arg.body.epgEventsId
                    && (universal.invalidateCache ? universal.selectedItemLastUpdated > universal.invalidateCache : true)
                    && !arg.ignoreCache)) return [3 /*break*/, 1];
                return [2 /*return*/, universal.selectedItem];
            case 1: return [4 /*yield*/, (0, recordingAPI_1.fetch_mw_recordingGetDataV2)(arg.body)];
            case 2:
                response = _b.sent();
                thunkAPI.dispatch((0, exports.universalSelectedItemGetEventRecommendationRows)({
                    type: response.response.type,
                    id: response.response.id
                }));
                return [4 /*yield*/, response.response];
            case 3: return [2 /*return*/, _b.sent()];
        }
    });
}); });
exports.universalSelectedItemVodGetDataV2 = (0, toolkit_1.createAsyncThunk)('universal/selectedItemVodGetDataV2', function (arg, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var universal, response;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                universal = thunkAPI.getState().universal;
                if (!(universal.selectedItemLastUpdated
                    && universal.selectedItem && (0, lib_1.isRecommendationCardType)(universal.selectedItem)
                    && ((_a = universal.selectedItem) === null || _a === void 0 ? void 0 : _a.id) === arg.body.vodsId
                    && (universal.invalidateCache ? universal.selectedItemLastUpdated > universal.invalidateCache : true)
                    && !arg.ignoreCache)) return [3 /*break*/, 1];
                return [2 /*return*/, universal.selectedItem];
            case 1: return [4 /*yield*/, (0, universalAPI_1.fetch_mw_vodGetDataV2)(arg.body)];
            case 2:
                response = _b.sent();
                thunkAPI.dispatch((0, exports.universalSelectedItemGetEventRecommendationRows)({
                    type: response.response.type,
                    id: response.response.id
                }));
                return [4 /*yield*/, response.response];
            case 3: return [2 /*return*/, _b.sent()];
        }
    });
}); });
exports.universalSelectedItemGetEventRecommendationRows = (0, toolkit_1.createAsyncThunk)('universal/selectedItemGetEventRecommendationRows', function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, universalAPI_1.fetch_mw_recommendationEngineGetEventRecommendationRows)(body)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.response];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); });
exports.universalGetMyList = (0, toolkit_1.createAsyncThunk)('universal/getMyList', function () { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, universalAPI_1.fetch_mw_recommendationEngineGetMyList)()];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.response];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); });
exports.universalGetMyRecordings = (0, toolkit_1.createAsyncThunk)('universal/getRecordingsIdsByProfile', function () { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, universalAPI_1.fetch_mw_recordingGetRecordingsIdsByProfile)()];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.response];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); });
exports.universalGetFavouriteChannels = (0, toolkit_1.createAsyncThunk)('universal/getFavoriteChannels', function () { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, universalAPI_1.fetch_mw_profileGetFavoriteChannels)()];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.response];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); });
exports.universalAddFavouriteChannel = (0, toolkit_1.createAsyncThunk)('universal/addFavouriteChannel', function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, universalAPI_1.fetch_mw_profileAddFavoriteChannel)(body)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.response];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); });
exports.universalRemoveFavouriteChannel = (0, toolkit_1.createAsyncThunk)('universal/removeFavouriteChannel', function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, universalAPI_1.fetch_mw_profileRemoveFavoriteChannel)(body)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.response];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); });
exports.universalGetSearchRows = (0, toolkit_1.createAsyncThunk)('universal/getSearchRows', function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, universalAPI_1.fetch_mw_recommendationEngineSearch)(body)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.response];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); });
exports.universalGetNextPlaybackItems = (0, toolkit_1.createAsyncThunk)('universal/getNextPlaybackItems', function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, universalAPI_1.fetch_mw_recommendationGetNextPlaybackItems)(body)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.response];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); });
exports.universalReducer = (0, toolkit_1.createSlice)({
    name: 'universal',
    initialState: initialState,
    reducers: {
        invalidateCache: function (state) {
            state.invalidateCache = Date.now();
        },
        searchOpenTrue: function (state) {
            state.searchOpen = true;
        },
        searchOpenFalse: function (state) {
            state.searchOpen = false;
        },
        selectYoutubeItem: function (state, action) {
            state.youtubeSelectedItem = action.payload;
        },
        deselectYoutubeItem: function (state) {
            state.youtubeSelectedItem = undefined;
        },
        setNotifications: function (state, action) {
            state.notifications = action.payload;
        },
        setOnVideoMessage: function (state, action) {
            state.onVideoMessage = action.payload;
        },
        setClearCacheTrue: function (state) {
            state.clearCache = true;
            state.invalidateCache = Date.now();
        },
        setClearCacheFalse: function (state) {
            state.clearCache = false;
        },
        setUrlForRedirect: function (state, action) {
            state.urlForRedirect = action.payload;
        },
        resetUrlForRedirect: function (state) {
            state.urlForRedirect = undefined;
        },
        removeFromRecordingsOnlyFromRedux: function (state, action) {
            var _a;
            state.myRecordingIds = (_a = state.myRecordingIds) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return item !== action.payload; });
        },
        removeFromMyListOnlyFromRedux: function (state, action) {
            var _a;
            state.myList = (_a = state.myList) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return !(item.type === action.payload.type && item.id === action.payload.id); });
        }
        // removeAllErrors: () => initialState,
    },
    extraReducers: function (builder) {
        builder
            .addCase(exports.universalEdgeSpeedCheck.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.universalEdgeSpeedCheck.rejected, function (state) {
            state.status = 'failed';
        })
            .addCase(exports.universalEdgeSpeedCheck.fulfilled, function (state, action) {
            state.status = 'idle';
            if (action.payload) {
                state.edgesReachable = action.payload[0];
                state.edgesUnreachable = action.payload[1];
            }
        })
            .addCase(exports.universalLoadBlacklistedEdges.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.universalLoadBlacklistedEdges.fulfilled, function (state, action) {
            state.status = 'idle';
            state.edgesBlacklisted = action.payload;
        })
            .addCase(exports.universalSelectedItemGetUpdatedEventsV2.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.universalSelectedItemGetUpdatedEventsV2.fulfilled, function (state, action) {
            state.status = 'idle';
            state.selectedItem = action.payload[0];
            state.selectedItemLastUpdated = Date.now();
        })
            .addCase(exports.universalSelectedItemVodGetDataV2.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.universalSelectedItemVodGetDataV2.fulfilled, function (state, action) {
            state.status = 'idle';
            state.selectedItem = action.payload;
            state.selectedItemLastUpdated = Date.now();
        })
            .addCase(exports.universalSelectedItemGetEventRecommendationRows.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.universalSelectedItemGetEventRecommendationRows.fulfilled, function (state, action) {
            state.status = 'idle';
            state.selectedItemRows = action.payload;
        })
            .addCase(exports.universalSelectedItemGetCategory.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.universalSelectedItemGetCategory.fulfilled, function (state, action) {
            state.status = 'idle';
            state.selectedItem = action.payload.category;
            state.selectedItemRows = action.payload.rows;
        })
            .addCase(exports.universalSelectedItemRecordingGetDataV2.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.universalSelectedItemRecordingGetDataV2.fulfilled, function (state, action) {
            state.status = 'idle';
            state.selectedItem = action.payload;
            state.selectedItemLastUpdated = Date.now();
        })
            .addCase(exports.universalGetMyList.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.universalGetMyList.fulfilled, function (state, action) {
            state.status = 'idle';
            state.myList = action.payload;
        })
            .addCase(exports.universalGetMyRecordings.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.universalGetMyRecordings.fulfilled, function (state, action) {
            state.status = 'idle';
            state.myRecordingIds = action.payload;
        })
            .addCase(exports.universalGetFavouriteChannels.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.universalGetFavouriteChannels.fulfilled, function (state, action) {
            state.status = 'idle';
            state.favouriteChannels = action.payload;
        })
            .addCase(exports.universalAddFavouriteChannel.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.universalAddFavouriteChannel.fulfilled, function (state, action) {
            state.status = 'idle';
            state.favouriteChannels = action.payload;
        })
            .addCase(exports.universalRemoveFavouriteChannel.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.universalRemoveFavouriteChannel.fulfilled, function (state, action) {
            state.status = 'idle';
            state.favouriteChannels = action.payload;
        })
            .addCase(exports.universalGetSearchRows.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.universalGetSearchRows.fulfilled, function (state, action) {
            state.status = 'idle';
            state.searchRows = action.payload;
        })
            .addCase(exports.universalGetNextPlaybackItems.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.universalGetNextPlaybackItems.fulfilled, function (state, action) {
            state.status = 'idle';
            state.nextRecommendedItems = action.payload;
        });
    }
});
exports.deselectYoutubeItem = (_a = exports.universalReducer.actions, _a.deselectYoutubeItem), exports.invalidateCache = _a.invalidateCache, exports.selectYoutubeItem = _a.selectYoutubeItem, exports.searchOpenTrue = _a.searchOpenTrue, exports.searchOpenFalse = _a.searchOpenFalse, exports.setNotifications = _a.setNotifications, exports.setOnVideoMessage = _a.setOnVideoMessage, exports.setClearCacheTrue = _a.setClearCacheTrue, exports.setClearCacheFalse = _a.setClearCacheFalse, exports.setUrlForRedirect = _a.setUrlForRedirect, exports.resetUrlForRedirect = _a.resetUrlForRedirect, exports.removeFromRecordingsOnlyFromRedux = _a.removeFromRecordingsOnlyFromRedux, exports.removeFromMyListOnlyFromRedux = _a.removeFromMyListOnlyFromRedux;
// Selectors
var universal_clearCache = function (state) { return state.universal.clearCache; };
exports.universal_clearCache = universal_clearCache;
var universal_edgesReachable = function (state) { return state.universal.edgesReachable; };
exports.universal_edgesReachable = universal_edgesReachable;
var universal_edgesUnreachable = function (state) { return state.universal.edgesUnreachable; };
exports.universal_edgesUnreachable = universal_edgesUnreachable;
var universal_edgesBlacklisted = function (state) { return state.universal.edgesBlacklisted; };
exports.universal_edgesBlacklisted = universal_edgesBlacklisted;
var universal_favouriteChannels = function (state) { return state.universal.favouriteChannels; };
exports.universal_favouriteChannels = universal_favouriteChannels;
var universal_myList = function (state) { return state.universal.myList; };
exports.universal_myList = universal_myList;
var universal_myRecordingIds = function (state) { return state.universal.myRecordingIds; };
exports.universal_myRecordingIds = universal_myRecordingIds;
var universal_nextRecommendedItems = function (state) { return state.universal.nextRecommendedItems; };
exports.universal_nextRecommendedItems = universal_nextRecommendedItems;
var universal_notifications = function (state) { return state.universal.notifications; };
exports.universal_notifications = universal_notifications;
var universal_onVideoMessage = function (state) { return state.universal.onVideoMessage; };
exports.universal_onVideoMessage = universal_onVideoMessage;
var universal_searchOpen = function (state) { return state.universal.searchOpen; };
exports.universal_searchOpen = universal_searchOpen;
var universal_searchRows = function (state) { return state.universal.searchRows; };
exports.universal_searchRows = universal_searchRows;
var universal_selectedItem = function (state) { return state.universal.selectedItem; };
exports.universal_selectedItem = universal_selectedItem;
var universal_selectedItemRows = function (state) { return state.universal.selectedItemRows; };
exports.universal_selectedItemRows = universal_selectedItemRows;
var universal_urlForRedirect = function (state) { return state.universal.urlForRedirect; };
exports.universal_urlForRedirect = universal_urlForRedirect;
var universal_youtubeSelectedItem = function (state) { return state.universal.youtubeSelectedItem; };
exports.universal_youtubeSelectedItem = universal_youtubeSelectedItem;
exports["default"] = exports.universalReducer.reducer;
