import React from 'react';
import styled, {createGlobalStyle} from "styled-components";
import {COLOR_NEUTRAL, COLOR_WHITE} from "@motv-webapp/lib";
import Header from "../components/Header/Header";
import TvGuide from "../components/Guide/TVGuide";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${COLOR_NEUTRAL};
  }

  ,
`

const GuideContainer = styled.div(props => ({
    background: COLOR_NEUTRAL,
    color: COLOR_WHITE,
    minHeight: "100vh",
    overflowX: "hidden",
}));

const GuidePage = () => {
    return (
        <GuideContainer>
            <GlobalStyle/>
            <Header/>
            <TvGuide/>
        </GuideContainer>
    );
};

export default GuidePage;
