import React from 'react';
import {RecommendationCardEntity} from "@motv-webapp/lib";
import styled from "styled-components";
import {FormattedMessage} from "react-intl";
import Separator from "../Separator/Separator";
import {COLOR_WHITE_60} from "@motv-webapp/lib";

const DetailDescriptionContainer = styled.div(props => ({
    display: "flex",
    gap: "4rem",
    marginBottom: "3rem",
    marginTop: -30,
    padding: "0 4rem",
    "@media (max-width: 800px)": {
        flexDirection: "column",
        marginTop: 80,
        padding: "0 2rem",
    }
}));

const Description = styled.div(props => ({
    flex: 1,
    zIndex: 200,
}));

const ProductionInfo = styled.div(props => ({
    minWidth: 300,
    width: "25%",
    zIndex: 200,
    "& > p": {
        color: COLOR_WHITE_60,
        fontSize: "0.8rem",
        marginTop: 0,
        marginBottom: "1rem",
    }
}));

type Props = {
    mediaItem: RecommendationCardEntity
};

const DetailDescription = ({mediaItem}: Props) => {
    return (
        <DetailDescriptionContainer>
            <Description>
                {mediaItem?.description}
            </Description>
            <ProductionInfo>
                {mediaItem?.actors &&
                  <p>
                    <FormattedMessage id={"label_actors"} defaultMessage={"Actors"}/>
                      {`: ${mediaItem.actors}`}
                  </p>
                }
                {mediaItem?.directors &&
                  <p>
                    <FormattedMessage id={"label_directors"} defaultMessage={"Directors"}/>
                      {`: ${mediaItem.directors}`}

                  </p>
                }
                {mediaItem?.genres &&
                  <p>
                      {mediaItem.genres}
                      {(mediaItem.genres && (mediaItem.origin || mediaItem.released)) && <Separator />}
                      {mediaItem.origin}
                      {mediaItem.origin && mediaItem.released && <Separator />}
                      {mediaItem.released}
                  </p>
                }
            </ProductionInfo>
        </DetailDescriptionContainer>
    );
};

export default DetailDescription;
