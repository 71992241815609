import React, {useState} from 'react';
import {NotificationEntity} from "@motv-webapp/lib";
import styled from "styled-components";
import {
    COLOR_NEUTRAL,
    COLOR_NEUTRAL_90, COLOR_SECONDARY,
    COLOR_DANGER_100,
    COLOR_WHITE_60
} from "@motv-webapp/lib";
import {useAppSelector} from "@motv-webapp/redux";
import {user_selectedLanguage} from "@motv-webapp/redux";
import {differenceInCalendarDays} from "date-fns";
import {capitalizeFirstLetter} from "@motv-webapp/lib";
import {TYPEFACE_WEB_BODY_2_MEDIUM, TYPEFACE_WEB_CAPTION_2} from "@motv-webapp/lib";

const NotificationContainer = styled.div((props: { hover: boolean, pointer: boolean }) => ({
    alignItems: "center",
    background: props.hover ? COLOR_NEUTRAL_90 : COLOR_SECONDARY,
    borderBottom: `1px solid ${COLOR_NEUTRAL}`,
    cursor: props.pointer ? "pointer" : "initial",
    display: "flex",
    padding: "8px 12px",
    justifyContent: "space-between",
}));

const Wrapper = styled.div(props => ({
    display: "flex",
}));

const UnreadIndicator = styled.div(props => ({
    marginRight: 10,
    "& > span": {
        width: 6
    },
    "& > div": {
        backgroundColor: COLOR_DANGER_100,
        borderRadius: "50%",
        height: 6,
        marginTop: 4,
        width: 6,
    }
}));

const NotificationBody = styled.div(props => ({}));

const NotificationDate = styled.div(props => ({
    ...TYPEFACE_WEB_CAPTION_2,
    color: COLOR_WHITE_60,
    marginBottom: 2,
}));

const NotificationTitle = styled.div(props => ({
    ...TYPEFACE_WEB_BODY_2_MEDIUM,
    marginBottom: 2,
}));

const NotificationText = styled.div(props => ({
    ...TYPEFACE_WEB_CAPTION_2,
}));

const NotificationImage = styled.div((props: { image: string }) => ({
    background: `url(${props.image})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: 6,
    height: 54,
    minWidth: 96,
    width: 96,
}));

type Props = {
    notificationItem: NotificationEntity
};

const Notifications = ({notificationItem}: Props) => {
    const [hover, setHover] = useState(false)
    const language = useAppSelector(user_selectedLanguage)
    let rtf = new Intl.RelativeTimeFormat(language, {
        numeric: "auto",
    })

    const handleMouseEnter = () => {
        setHover(true)
    }

    const handleMouseLeave = () => {
        setHover(false)
    }

    const handleClick = () => {
        if (notificationItem.link) window.location.href = notificationItem.link
    }

    return (
        <NotificationContainer onClick={handleClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} pointer={!!notificationItem.link} {...{hover}} >
            <Wrapper>
                <UnreadIndicator>{notificationItem.read ? <span/> : <div/>}</UnreadIndicator>
                <NotificationBody>
                    <NotificationDate>{capitalizeFirstLetter(rtf.format(-differenceInCalendarDays(Date.now(), notificationItem.timestamp), "days"))}</NotificationDate>
                    {notificationItem?.notificationObject?.title &&
                      <NotificationTitle>{notificationItem.notificationObject.title}</NotificationTitle>}
                    {notificationItem?.notificationObject?.body &&
                      <NotificationText>{notificationItem.notificationObject.body}</NotificationText>}
                </NotificationBody>
            </Wrapper>
            {
                notificationItem?.notificationObject?.image &&
              <NotificationImage image={notificationItem.notificationObject.image}/>
            }
        </NotificationContainer>
    )
        ;
};

export default Notifications;
