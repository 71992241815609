import React, {MouseEvent, useState} from 'react';
import styled from "styled-components";
import {COLOR_TERTIARY, COLOR_WHITE} from "@motv-webapp/lib";

const LineContainer = styled.div((props: {hover: boolean}) => ({
    background: props.hover ? COLOR_TERTIARY :  "transparent",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 24px",
    width: 130,
    "& > div": {
        alignItems: "center",
        display: "flex",
        gap: 8,
        "& > p": {
            color: COLOR_WHITE,
            fontSize: "0.8rem",
            fontWeight: 500,
            margin: 0,
        },
    },
}));

type Props = {
    label: string
    onClick: (e: MouseEvent<HTMLDivElement>) => void
    tick?: boolean
};

const PlayerDialogShortLine = ({label, onClick, tick = false}: Props) => {
    const [hover, setHover] = useState(false)

    const handleMouseEnter = () => {
        setHover(true)
    }

    const handleMouseLeave = () => {
        setHover(false)
    }

    return (
        <LineContainer onClick={onClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} {...{hover}}>
            <div>
                <p>
                    {label}
                </p>
                {tick && <img style={{height: 12}} src={"/images/icons/icon_tick.svg"} alt={""}/>}
            </div>
        </LineContainer>
    );
};

export default PlayerDialogShortLine;
