import React, {ChangeEvent} from "react";
import styled from "styled-components";
import {COLOR_BLACK} from "@motv-webapp/lib";

const ImagePickerContainer = styled.div(props => ({
    display: "flex",
    gap: "16px",
    flexWrap: "wrap",
    width: "464px",
    "@media (max-width: 630px)": {
        width: "400px",
    },
    "@media (max-width: 550px)": {
        width: "288px",
    }
}));

const ImagePickerButton = styled.button((props: { avatar: string }) => ({
    background: COLOR_BLACK,
    backgroundImage: `url(${props.avatar})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    border: 0,
    borderRadius: "50%",
    height: "80px",
    width: "80px",
}));

const ImagePickerUploadButton = styled.label.attrs({htmlFor: "file-upload"})((props: { avatar: string }) => ({
    background: COLOR_BLACK,
    backgroundImage: `url(${props.avatar})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    border: 0,
    borderRadius: "50%",
    cursor: "pointer",
    height: "80px",
    width: "80px",
}));

interface Props {
    predefinedProfileImages: string[]
    handleSelectNewPredefinedImage: (image: string) => void
    handleUploadNewImage: (e: ChangeEvent<HTMLInputElement>) => void
}

const ImagePicker = ({predefinedProfileImages,handleSelectNewPredefinedImage,handleUploadNewImage} : Props) => {
    return (
        <ImagePickerContainer>
            {predefinedProfileImages && predefinedProfileImages.map((image) => {
                return (
                    <ImagePickerButton avatar={image}
                                       onClick={() => handleSelectNewPredefinedImage(image)}
                                       key={image}/>
                )
            })}
            <input id="file-upload" type="file" style={{display: "none"}}
                   onChange={handleUploadNewImage} accept="image/*"/>
            <ImagePickerUploadButton avatar={"/images/icons/icon_add_circle.svg"}/>
        </ImagePickerContainer>
    )
}

export default ImagePicker;
