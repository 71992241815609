import React from 'react';
import styled, {createGlobalStyle} from "styled-components";
import {COLOR_NEUTRAL, COLOR_WHITE} from "@motv-webapp/lib";
import MainRows from "../Rows/MainRows";
import {useAppSelector} from "@motv-webapp/redux";
import {universal_searchRows} from "@motv-webapp/redux";
import {FormattedMessage} from "react-intl";

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
  ,
`

const SearchResultsContainer = styled.div(props => ({
    background: COLOR_NEUTRAL,
    left: 0,
    height: "calc(100vh - 70px)",
    overflowY: "scroll",
    overflowX: "hidden",
    paddingTop: 30,
    position: "fixed",
    top: 70,
    width: "100vw",
    zIndex: -1,
}));

const NoResultMessage = styled.h1(props =>({
    color: COLOR_WHITE,
    textAlign: "center",
    width: "100%",
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
}));

type Props = {};

const SearchResults = ({}: Props) => {
    const searchRows = useAppSelector(universal_searchRows)

    return (
        <SearchResultsContainer data-testid={"searchResultsContainer"}>
            <GlobalStyle/>
            {(searchRows && searchRows.length)
                ? (<><MainRows disableHeaderSpacing={true} rows={searchRows}/></>)
                : <NoResultMessage><FormattedMessage id={"label_no_results"} defaultMessage={"No results"} /></NoResultMessage>}
        </SearchResultsContainer>
    );
};

export default SearchResults;
