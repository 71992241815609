"use strict";
exports.__esModule = true;
exports.useViewport = exports.useAppLanguage = exports.useAppSelector = exports.useAppDispatch = void 0;
var react_redux_1 = require("react-redux");
var lib_1 = require("@motv-webapp/lib");
var config_1 = require("@motv-webapp/config");
var react_1 = require("react");
// Use throughout your app instead of plain `useDispatch` and `useSelector`
var useAppDispatch = function () { return (0, react_redux_1.useDispatch)(); };
exports.useAppDispatch = useAppDispatch;
exports.useAppSelector = react_redux_1.useSelector;
var useAppLanguage = function () {
    var language = ((0, lib_1.getLocalStorage)("language"))
        || ((0, config_1.getClientConfig)().languages.length === 1 && (0, config_1.getClientConfig)().languages[0])
        || (navigator.languages && navigator.languages[0])
        || navigator.language
        || ((0, config_1.getClientConfig)().languages[0])
        || "en";
    var languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
    return ((0, config_1.getClientConfig)().languages.find(function (langCode) { return langCode === languageWithoutRegionCode; }) !== undefined)
        ? languageWithoutRegionCode
        : (0, config_1.getClientConfig)().languages[0];
};
exports.useAppLanguage = useAppLanguage;
var useViewport = function () {
    var _a = (0, react_1.useState)(window.innerWidth), width = _a[0], setWidth = _a[1];
    (0, react_1.useEffect)(function () {
        var handleWindowResize = function () { return setWidth(window.innerWidth); };
        window.addEventListener("resize", handleWindowResize);
        return function () { return window.removeEventListener("resize", handleWindowResize); };
    }, []);
    // Return the width so we can use it in our components
    return { width: width };
};
exports.useViewport = useViewport;
