import React, {MouseEvent, useEffect, useState} from 'react';
import {
    COLOR_NEUTRAL,
    COLOR_OFFWHITE_70,
    COLOR_OFFWHITE_90,
    COLOR_PRIMARY_100,
    COLOR_SECONDARY,
    COLOR_WHITE,
    RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM,
    RecommendationCardEntity,
    SubscribedChannelEntity,
    TIME_CONST_ONE_MINUTE,
    TYPEFACE_CAPTION,
    TYPEFACE_WEB_CAPTION_2,
    imageResizeUrl,
    truncate,
} from "@motv-webapp/lib";
import {getUnixTime, isAfter, isBefore, parseISO} from "date-fns";
import styled from "styled-components";
import {
    channel_channels,
    myListAddToMyList,
    myListRemoveFromMyList,
    recordingAddRecording,
    recordingRemoveRecording,
    selectYoutubeItem,
    universal_myList,
    universal_myRecordingIds,
    useAppDispatch,
    useAppSelector,
    user_customers_recording_length,
    user_customers_recording_used,
    user_selectedLanguage,
    vendor_vendors_accent_color,
} from "@motv-webapp/redux";
import {useNavigate} from "react-router-dom";
import {useInterval} from "react-use";
import Color from "color"

const ItemOuterContainer = styled.div((props: { withImage: boolean }) => ({
    position: "relative" as "relative",
    height: props.withImage ? 280 : 74,
}));

const ItemContainer = styled.div((props: { accentColor: string | null | undefined, hover: boolean, isLive: boolean }) => ({
    background: props.hover ? COLOR_WHITE : props.isLive ? Color(props.accentColor ? props.accentColor : COLOR_PRIMARY_100).alpha(0.5).rgb().string() : COLOR_SECONDARY,
    borderRadius: 8,
    cursor: "pointer",
    marginBottom: 8,
    overflow: "hidden",
    position: props.hover ? "absolute" as "absolute" : "initial" as "initial",
    top: 0,
    width: 250,
    zIndex: props.hover ? 1 : "initial",
}));

const ProgressBar = styled.div((props: { accentColor: string | null | undefined, progress: number, withImage: boolean }) => ({
    background: Color(props.accentColor ? props.accentColor : COLOR_PRIMARY_100).alpha(0.5).rgb().string(),
    borderRadius: `${props.withImage ? "0" : "8px"} ${props.progress < 98 ? "0" : "8px"} ${props.progress < 98 ? "0" : props.withImage ? "0" : "8px"} 8px`,
    height: props.withImage ? 119 : 64,
    left: 0,
    position: "absolute" as "absolute",
    top: props.withImage ? 140 : 0,
    width: `${props.progress}%`,
    zIndex: -1,
}));

const ItemImage = styled.div((props: { image: string }) => ({
    background: COLOR_SECONDARY,
    backgroundImage: `url(${props.image})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: 140,
    position: "relative" as "relative",
}));

const ItemInfo = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    fontWeight: 500,
    gap: 8,
    padding: 12,
}));

const ItemInfoTimeAndGenre = styled.div((props: { hover: boolean }) => ({
    ...TYPEFACE_CAPTION,
    alignItems: "center",
    color: props.hover ? COLOR_NEUTRAL : COLOR_OFFWHITE_70,
    display: "flex",
    justifyContent: "space-between",
}));

const ItemInfoGenreBadge = styled.div((props: { hover: boolean }) => ({
    ...TYPEFACE_WEB_CAPTION_2,
    color: props.hover ? COLOR_NEUTRAL : COLOR_OFFWHITE_70,
}));

const ItemInfoTitle = styled.div((props: { hover: boolean }) => ({
    color: props.hover ? COLOR_NEUTRAL : COLOR_WHITE,
    overflow: "hidden",
    textOverflow: props.hover ? "initial" : "ellipsis",
    fontWeight: 500,
    whiteSpace: props.hover ? "initial" as "initial" : "nowrap" as "nowrap",
}));

const ItemInfoDesc = styled.div((props: { hover: boolean }) => ({
    color: props.hover ? COLOR_NEUTRAL : COLOR_OFFWHITE_90,
    fontSize: "0.8rem",
    height: "2.2rem",
    marginBottom: 12,
}));

const ActionBarContainer = styled.div(props => ({
    height: "1.3rem",
    marginBottom: 8,
    position: "relative"
}));

const ActionBarInImageContainer = styled.div(props => ({
    backgroundImage: "linear-gradient(to bottom, rgba(16, 17, 19, 0) 0%, rgba(16, 17, 19, 0.5) 100%)",
    bottom: 0,
    position: "absolute" as "absolute",
    right: 0,
    width: "100%"
}));

const ActionButtonsContainer = styled.div((props: { withImage: boolean }) => ({

    display: "flex",
    gap: "0.5rem",
    justifyContent: "flex-end",
    marginBottom: "0.5rem",
    marginTop: "0.5rem",
    marginRight: "0.5rem",
    "& img": {
        filter: props.withImage ? "initial" : "brightness(0) saturate(100%)",
    }
}));

const ActionButton = styled.button(props => ({
    background: "transparent",
    border: 0,
    display: "flex",
    height: "1.3rem",
    padding: 0,
    width: "1.3rem",
    "& > img": {
        height: "1.3rem",
    }
}));

type Props = {
    mediaItem: RecommendationCardEntity
    withImage?: boolean
};

const TvGuideColumnStyleItem = ({mediaItem, withImage = false}: Props) => {
    const [hover, setHover] = useState(false)
    const [recordable, setRecordable] = useState(false)
    const [playable, setPlayable] = useState(false)
    const [isInMyList, setIsInMyList] = useState(false)
    const [isInMyRecordings, setIsInMyRecordings] = useState(false)
    const [isLive, setIsLive] = useState(false)
    const dispatch = useAppDispatch()
    const accentColor = useAppSelector(vendor_vendors_accent_color)
    const channels = useAppSelector(channel_channels) as Array<SubscribedChannelEntity>
    const customersRecordingLength = useAppSelector(user_customers_recording_length)
    const customersRecordingUsed = useAppSelector(user_customers_recording_used)
    const language = useAppSelector(user_selectedLanguage)
    const myList = useAppSelector(universal_myList)
    const myRecordingIds = useAppSelector(universal_myRecordingIds)
    const navigate = useNavigate()

    useEffect(() => {
        setIsLive((getUnixTime(parseISO(mediaItem?.start!)) < getUnixTime(Date.now()) && (getUnixTime(Date.now()) < getUnixTime(parseISO(mediaItem?.end!)))))
    }, [])

    useInterval(() => {
        setIsLive((getUnixTime(parseISO(mediaItem?.start!)) < getUnixTime(Date.now()) && (getUnixTime(Date.now()) < getUnixTime(parseISO(mediaItem?.end!)))))
    }, TIME_CONST_ONE_MINUTE)

    useEffect(() => {
        if (!mediaItem.start
            || !channels?.find(channel => channel.channels_id === mediaItem.channels_id)?.channels_recording_length
            || !channels?.find(channel => channel.channels_id === mediaItem.channels_id)?.customers_recording_length
            || !channels?.find(channel => channel.channels_id === mediaItem.channels_id)?.channels_recording_length) return
        const now = Date.now();
        const start = Date.parse(mediaItem.start);
        setRecordable(
            (mediaItem.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV || mediaItem.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.RECORDING) && (
                !!channels.find(channel => channel.channels_id === mediaItem.channels_id) &&
                channels.find(channel => channel.channels_id === mediaItem.channels_id)?.channels_recording_length! > 0 &&
                now - channels.find(channel => channel.channels_id === mediaItem.channels_id)?.customers_recording_length! * TIME_CONST_ONE_MINUTE < start &&
                now - channels.find(channel => channel.channels_id === mediaItem.channels_id)?.channels_recording_length! * TIME_CONST_ONE_MINUTE < start &&
                !!mediaItem?.duration &&
                customersRecordingLength - customersRecordingUsed > mediaItem.duration)
        )
    }, [])

    useEffect(() => {
        if (mediaItem.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV) {
            if (!mediaItem.start
                || !channels?.find(channel => channel.channels_id === mediaItem.channels_id)?.customers_recording_length) return
            const now = Date.now();
            const start = Date.parse(mediaItem.start);
            setPlayable(
                start < now &&
                now - channels.find(channel => channel.channels_id === mediaItem.channels_id)!.customers_recording_length! * TIME_CONST_ONE_MINUTE < start
            );
        } else if (mediaItem.type !== RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.CATEGORY
            && mediaItem.type !== RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.CHANNEL
            && mediaItem.type !== RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.PERSON
            && mediaItem.type !== RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.GENRE
            && mediaItem.type !== RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.IMAGE
            && mediaItem.type !== RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.VIDEO
            && mediaItem.type !== RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.ADMOB
        ) {
            setPlayable(true)
        }
    }, [])

    useEffect(() => {
        myList?.some(item => item.id === mediaItem.id) ? setIsInMyList(true) : setIsInMyList(false)
    }, [myList])

    useEffect(() => {
        mediaItem.id && myRecordingIds?.includes(mediaItem.id) ? setIsInMyRecordings(true) : setIsInMyRecordings(false)
    }, [myRecordingIds])

    const handleMouseEnter = () => {
        setHover(true)
    }

    const handleMouseLeave = () => {
        setHover(false)
    }

    const handlePlayButton = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        switch (mediaItem.type) {
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV:
                if (!mediaItem.start || !mediaItem.end) return
                let now = new Date()
                let start = new Date(mediaItem.start)
                let end = new Date(mediaItem.end)
                isAfter(now, start) && isBefore(now, end)
                    ? navigate(`/tv/player/${mediaItem.channels_id}`)
                    : navigate(`/tv/player/${mediaItem.channels_id}/${getUnixTime(start)}`)
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.RECORDING:
                navigate(`/recording/player/${mediaItem.id}`)
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.VOD:
                navigate(`/vod/player/${mediaItem.id}`)
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.YOUTUBE:
                dispatch(selectYoutubeItem(mediaItem))
                break
            default:
                return
        }
    }

    const handleMyListButton = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        if (mediaItem.type && mediaItem.id) {
            if (isInMyList) {
                dispatch(myListRemoveFromMyList({type: mediaItem.type, id: mediaItem.id}))
            } else {
                dispatch(myListAddToMyList({type: mediaItem.type, id: mediaItem.id}))
            }
        }
    }

    const handleRecordButton = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        if (mediaItem.id) {
            if (isInMyRecordings) {
                dispatch(recordingRemoveRecording({epgEventsId: mediaItem.id}))
            } else {
                dispatch(recordingAddRecording({epgEventsId: mediaItem.id}))
            }
        }
    }

    const handleClick = () => {
        switch (mediaItem.type) {
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV:
                navigate(`/event/${mediaItem.id}`)
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.RECORDING:
                navigate(`/recording/${mediaItem.id}`)
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.VOD:
                navigate(`/vod/${mediaItem.id}`)
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.YOUTUBE:
                navigate(`/vod/${mediaItem.id}`)
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.CATEGORY:
                navigate(`/category/${mediaItem.id}`)
                break
            default:
                return
        }
    }

    return (
        <ItemOuterContainer {...{withImage}} >
            <ItemContainer onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
                           onClick={handleClick} {...{accentColor, hover, isLive}} >
                {isLive && !hover &&
                  <ProgressBar progress={(getUnixTime(new Date()) - getUnixTime(parseISO(mediaItem?.start!))) /
                      (getUnixTime(parseISO(mediaItem?.end!)) - getUnixTime(parseISO(mediaItem?.start!))) * 100} {...{
                      accentColor,
                      withImage
                  }} />}
                {withImage &&
                  <ItemImage image={imageResizeUrl( mediaItem.image_widescreen ? mediaItem.image_widescreen : mediaItem.image, 250)}>
                      {hover && withImage &&
                        <ActionBarInImageContainer>
                          <ActionButtonsContainer {...{withImage}} >
                            <ActionButton onClick={handleMyListButton}>
                              <img
                                src={isInMyList ? "/images/icons/icon_list_remove.svg" : "/images/icons/icon_list_add.svg"}
                                alt={""}/>
                            </ActionButton>
                              {(mediaItem.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV || mediaItem.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.RECORDING)
                                  && (recordable || isInMyRecordings) &&
                                <ActionButton onClick={handleRecordButton}>
                                  <img
                                    src={isInMyRecordings ? "/images/icons/icon_record_remove.svg" : "/images/icons/icon_record.svg"}
                                    alt={""}/>
                                </ActionButton>
                              }
                              {/*Don't simplify 'mediaItem.playable !== false' to 'mediaItem.playable. It has to be true when mediaItem.playable = null.*/}
                              {(playable || isInMyRecordings || mediaItem.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.VOD) && !mediaItem.locked && mediaItem.playable !== false &&
                                <ActionButton onClick={handlePlayButton}>
                                  <img src={"/images/icons/icon_play.svg"} alt={""}/>
                                </ActionButton>
                              }
                          </ActionButtonsContainer>
                        </ActionBarInImageContainer>
                      }
                  </ItemImage>
                }
                <ItemInfo>
                    <ItemInfoTimeAndGenre {...{hover}} >
                        {new Intl.DateTimeFormat(language, {
                            hour: 'numeric',
                            minute: 'numeric'
                        }).format(Date.parse(mediaItem.start!))}
                        {mediaItem?.genres?.length &&
                          <ItemInfoGenreBadge {...{hover}} >
                              {mediaItem.genres.substring(0, mediaItem.genres.indexOf(",", 0)).length ? mediaItem.genres.substring(0, mediaItem.genres.indexOf(",", 0)) : mediaItem.genres}
                          </ItemInfoGenreBadge>
                        }
                    </ItemInfoTimeAndGenre>
                    <ItemInfoTitle {...{hover}} >
                        {mediaItem.title}
                    </ItemInfoTitle>
                    {(withImage || hover) && <ItemInfoDesc {...{hover}} >
                        {truncate(mediaItem.description!, 100, true)}
                    </ItemInfoDesc>}
                </ItemInfo>
                {hover && !withImage &&
                    (<ActionBarContainer>
                        <ActionButtonsContainer {...{withImage}} >
                            <ActionButton onClick={handleMyListButton}>
                                <img
                                    src={isInMyList ? "/images/icons/icon_list_remove.svg" : "/images/icons/icon_list_add.svg"}
                                    alt={""}/>
                            </ActionButton>
                            {(mediaItem.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV || mediaItem.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.RECORDING)
                                && (recordable || isInMyRecordings) &&
                              <ActionButton onClick={handleRecordButton}>
                                <img
                                  src={isInMyRecordings ? "/images/icons/icon_record_remove.svg" : "/images/icons/icon_record.svg"}
                                  alt={""}/>
                              </ActionButton>
                            }
                            {/*Don't simplify 'mediaItem.playable !== false' to 'mediaItem.playable. It has to be true when mediaItem.playable = null.*/}
                            {(playable || isInMyRecordings || mediaItem.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.VOD) && !mediaItem.locked && mediaItem.playable !== false &&
                              <ActionButton onClick={handlePlayButton}>
                                <img src={"/images/icons/icon_play.svg"} alt={""}/>
                              </ActionButton>
                            }
                        </ActionButtonsContainer>
                    </ActionBarContainer>)
                }
            </ItemContainer>
        </ItemOuterContainer>
    );
};

export default TvGuideColumnStyleItem;
