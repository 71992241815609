import React, {MouseEvent, useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {
    COLOR_NEUTRAL_90,
    COLOR_OFFWHITE,
    COLOR_OFFWHITE_50,
    COLOR_OFFWHITE_90,
    COLOR_PRIMARY_100,
    COLOR_TERTIARY,
    COLOR_WHITE,
    RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM,
    RecommendationCardEntity,
    SubscribedChannelEntity,
    TIME_CONST_ONE_MINUTE,
    TYPEFACE_BODY_MEDIUM,
    TYPEFACE_CAPTION,
    TYPEFACE_WEB_CAPTION_2,
    imageResizeUrl,
    isRecommendationCardType,
} from "@motv-webapp/lib";
import {
    channel_channels,
    myListAddToMyList,
    myListRemoveFromMyList,
    recordingAddRecording,
    recordingRemoveRecording,
    universal_myList,
    universal_myRecordingIds,
    universal_selectedItem,
    useAppDispatch,
    useAppSelector,
    user_customers_recording_length,
    user_customers_recording_used,
    user_selectedLanguage,
    vendor_vendors_accent_color,
} from "@motv-webapp/redux";
import IconButtonTransparent from "../Button/IconButtonTransparent";
import {useNavigate} from "react-router-dom";
import {getUnixTime, isAfter, isBefore, parseISO} from "date-fns";
import {useInterval} from "react-use";
import Color from "color";
import ProgressBar from "../ProgressBar/ProgressBar";

const SideControllersEpgItemContainer = styled.div((props: { accentColor: string | null | undefined, hover: boolean, playable: boolean, isCurrentlyPlaying: boolean}) => ({
    background: props.playable
        ? props.hover
            ? COLOR_OFFWHITE
            : props.isCurrentlyPlaying
                ? props.accentColor
                    ? Color(props.accentColor).darken(0.3).rgb().string()
                    : Color(COLOR_PRIMARY_100).alpha(0.5).rgb().string()
                : COLOR_NEUTRAL_90
        : (props.hover && props.isCurrentlyPlaying) ? COLOR_OFFWHITE : COLOR_NEUTRAL_90,
    borderRadius: 8,
    color: (props.playable || props.isCurrentlyPlaying) ? props.hover ? COLOR_TERTIARY : COLOR_WHITE : COLOR_OFFWHITE_50,
    cursor: props.playable ? "pointer" : "default",
    display: "flex",
    marginRight: 12,
    minHeight: 80,
    height: 80,
    overflow: "hidden",
    position: "relative" as "relative",
}));

const Thumbnail = styled.div((props: { epgImage: string | undefined, hover: boolean, isLive: boolean, playable: boolean }) => ({
    background: (props.playable || props.isLive) ? props.hover ? "rgba(227, 226, 230, 0.4)" : "transparent" : "rgba(0, 0, 0, 0.5)",
    backgroundBlendMode: (props.playable || props.isLive) ? "lighten" : "darken",
    backgroundImage: `url(${props.epgImage})`,
    backgroundSize: "cover",
    height: 80,
    marginRight: 10,
    minWidth: 142,
    width: 142,
}));

const EpgInfo = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "12px 12px 0 0",
    overflow: "hidden",
}));

const TimeAndGenre = styled.div(props => ({
    alignItems: "center",
    display: "flex",
    marginBottom: 12,
    justifyContent: "space-between",
    "& > div": {
        ...TYPEFACE_CAPTION,
    },
    "& > p": {
        ...TYPEFACE_WEB_CAPTION_2,
        margin: 0,
    }
}));

const TitleAndActionButtons = styled.div(props => ({
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    minHeight: 24,
    "& > p": {
        ...TYPEFACE_BODY_MEDIUM,
        margin: 0,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
}));

const ActionButtons = styled.div(props => ({
    display: "flex",
    gap: 4,
    marginLeft: 4,
}));

type Props = {
    epgItem: RecommendationCardEntity
};

const SideControllersEpgItem = ({epgItem}: Props) => {
    const [recordable, setRecordable] = useState(false)
    const [isCurrentlyPlaying, setIsCurrentlyPlaying] = useState(false)
    const [isInMyList, setIsInMyList] = useState(false)
    const [isInMyRecordings, setIsInMyRecordings] = useState(false)
    const [isLive, setIsLive] = useState(false)
    const [playable, setPlayable] = useState(false)
    const [progress, setProgress] = useState(0)
    const [hover, setHover] = useState(false)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const accentColor = useAppSelector(vendor_vendors_accent_color)
    const channels = useAppSelector(channel_channels) as Array<SubscribedChannelEntity>
    const customersRecordingLength = useAppSelector(user_customers_recording_length)
    const customersRecordingUsed = useAppSelector(user_customers_recording_used)
    const currentlyPlaying = useAppSelector(universal_selectedItem) as RecommendationCardEntity
    const language = useAppSelector(user_selectedLanguage)
    const myList = useAppSelector(universal_myList)
    const myRecordingIds = useAppSelector(universal_myRecordingIds)
    const epgItemRef = useRef<HTMLDivElement>(null)

    const setAllStates = () => {
        setPlayable(
            Date.parse(epgItem.start!) < Date.now() &&
            Date.now() - channels?.find(channel => channel.channels_id === epgItem.channels_id)?.customers_recording_length! * TIME_CONST_ONE_MINUTE < Date.parse(epgItem.start!)
        );
        // === if isLive
        if (getUnixTime(parseISO(epgItem?.start!)) < getUnixTime(Date.now()) && (getUnixTime(Date.now()) < getUnixTime(parseISO(epgItem?.end!)))) {
            setIsLive(true)
            setProgress((getUnixTime(new Date()) - getUnixTime(parseISO(epgItem?.start!))) / (getUnixTime(parseISO(epgItem?.end!)) - getUnixTime(parseISO(epgItem?.start!))) * 100)
        }
        setIsCurrentlyPlaying(currentlyPlaying?.id === epgItem.id)
    }

    useEffect(() => {
        setAllStates()
    }, [])

    useInterval(() => {
        setAllStates()
    }, TIME_CONST_ONE_MINUTE)

    // changes background and live indicator immediately after changing the event
    useEffect(() => {
        setIsCurrentlyPlaying(currentlyPlaying?.id === epgItem.id)
    }, [currentlyPlaying, epgItem])

    useEffect(() => {
        if (!isCurrentlyPlaying || (epgItemRef.current?.offsetTop && epgItemRef.current?.offsetTop > 10000)) return
        //the setTimeout is there to scroll not interfering with the AnimatePresence animation
        setTimeout(() => epgItemRef.current?.scrollIntoView({block: "center", behavior: "smooth"}), 300)
        isLive && setProgress((getUnixTime(new Date()) - getUnixTime(parseISO(epgItem?.start!))) / (getUnixTime(parseISO(epgItem?.end!)) - getUnixTime(parseISO(epgItem?.start!))) * 100)
    }, [isCurrentlyPlaying, epgItemRef.current?.offsetTop])

    useEffect(() => {
        if (!epgItem || !isRecommendationCardType(epgItem)) return
        if (!epgItem.start
            || !channels?.find(channel => channel.channels_id === epgItem.channels_id)?.channels_recording_length
            || !channels?.find(channel => channel.channels_id === epgItem.channels_id)?.customers_recording_length
            || !channels?.find(channel => channel.channels_id === epgItem.channels_id)?.channels_recording_length) return
        const now = Date.now();
        const start = Date.parse(epgItem.start);
        setRecordable(
            (epgItem.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV || epgItem.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.RECORDING) && (
                !!channels.find(channel => channel.channels_id === epgItem.channels_id) &&
                channels.find(channel => channel.channels_id === epgItem.channels_id)?.channels_recording_length! > 0 &&
                now - channels.find(channel => channel.channels_id === epgItem.channels_id)?.customers_recording_length! * TIME_CONST_ONE_MINUTE < start &&
                now - channels.find(channel => channel.channels_id === epgItem.channels_id)?.channels_recording_length! * TIME_CONST_ONE_MINUTE < start &&
                !!epgItem?.duration &&
                customersRecordingLength - customersRecordingUsed > epgItem.duration
            )
        )
    }, [epgItem?.id, channels])

    useEffect(() => {
        myList?.some(item => item.id === epgItem?.id) ? setIsInMyList(true) : setIsInMyList(false)
    }, [myList, epgItem])

    useEffect(() => {
        epgItem?.id && myRecordingIds?.includes(epgItem?.id) ? setIsInMyRecordings(true) : setIsInMyRecordings(false)
    }, [myRecordingIds, epgItem])

    const handleMouseEnter = () => {
        setHover(true)
    }

    const handleMouseLeave = () => {
        setHover(false)
    }

    const handleClick = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        if (!playable) return
        switch (epgItem.type) {
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV:
                if (!epgItem.start || !epgItem.end) return
                let now = new Date()
                let start = new Date(epgItem.start)
                let end = new Date(epgItem.end)
                isAfter(now, start) && isBefore(now, end)
                    ? navigate(`/tv/player/${epgItem.channels_id}`, {replace: true})
                    : navigate(`/tv/player/${epgItem.channels_id}/${getUnixTime(start)}`, {replace: true})
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.RECORDING:
                navigate(`/recording/player/${epgItem.id}`, {replace: true})
                break
            default:
                return
        }
    }

    const handleMyListButton = (e: MouseEvent<HTMLButtonElement | HTMLImageElement>) => {
        e.stopPropagation()
        if (epgItem.type && epgItem.id) {
            if (isInMyList) {
                dispatch(myListRemoveFromMyList({type: epgItem.type, id: epgItem.id}))
            } else {
                dispatch(myListAddToMyList({type: epgItem.type, id: epgItem.id}))
            }
        }
    }

    const handleRecordButton = (e: MouseEvent<HTMLButtonElement | HTMLImageElement>) => {
        e.stopPropagation()
        if (epgItem.id) {
            if (isInMyRecordings) {
                dispatch(recordingRemoveRecording({epgEventsId: epgItem.id}))
            } else {
                dispatch(recordingAddRecording({epgEventsId: epgItem.id}))
            }
        }
    }

    const handleCategoryButton = (e: MouseEvent<HTMLButtonElement | HTMLImageElement>) => {
        e.stopPropagation()
        if (epgItem.categories_id) {
            navigate(`/category/${epgItem.categories_id}`)
        }
    }

    return (
        <SideControllersEpgItemContainer
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            ref={epgItemRef}
            {...{accentColor, hover, playable, isCurrentlyPlaying}}
        >
            <Thumbnail
                epgImage={imageResizeUrl(epgItem.image_widescreen ? epgItem.image_widescreen : epgItem.image, 142)}
                {...{hover, isLive, playable}}
            />
            <EpgInfo>
                <TimeAndGenre>
                    <div>
                        {new Intl.DateTimeFormat(language, {
                            hour: 'numeric',
                            minute: 'numeric'
                        }).format(Date.parse(epgItem.start!))}
                    </div>
                    {epgItem?.genres?.length &&
                      <p>
                          {epgItem.genres.substring(0, epgItem.genres.indexOf(",", 0)).length ? epgItem.genres.substring(0, epgItem.genres.indexOf(",", 0)) : epgItem.genres}
                      </p>
                    }
                </TimeAndGenre>
                <TitleAndActionButtons>
                    <p>
                        {epgItem.title}
                    </p>
                    <ActionButtons>
                        {hover ?
                            (
                                <>
                                    <IconButtonTransparent
                                        imageFilter={(playable || isLive) ? "brightness(0) saturate(100%) invert(5%) sepia(2%) saturate(3257%) hue-rotate(182deg) brightness(105%) contrast(96%)" : "brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(1524%) hue-rotate(254deg) brightness(83%) contrast(69%)"}
                                        img={isInMyList ? "/images/icons/icon_list_remove.svg" : "/images/icons/icon_list_add.svg"}
                                        onClick={handleMyListButton}
                                        size={24}
                                    />
                                    {(epgItem?.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV || epgItem?.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.RECORDING) && (recordable || isInMyRecordings) &&
                                      <IconButtonTransparent
                                        imageFilter={(playable || isLive) ? "brightness(0) saturate(100%) invert(5%) sepia(2%) saturate(3257%) hue-rotate(182deg) brightness(105%) contrast(96%)" : "brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(1524%) hue-rotate(254deg) brightness(83%) contrast(69%)"}
                                        img={isInMyRecordings ? "/images/icons/icon_record_remove.svg" : "/images/icons/icon_record.svg"}
                                        onClick={handleRecordButton}
                                        size={24}
                                      />}
                                    {epgItem?.categories_id && epgItem?.categories_name && !window.location.pathname.includes("category") &&
                                      <IconButtonTransparent
                                        imageFilter={(playable || isLive) ? "brightness(0) saturate(100%) invert(5%) sepia(2%) saturate(3257%) hue-rotate(182deg) brightness(105%) contrast(96%)" : "brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(1524%) hue-rotate(254deg) brightness(83%) contrast(69%)"}
                                        img={"/images/icons/icon_category.svg"}
                                        onClick={handleCategoryButton}
                                        size={24}
                                      />}
                                </>)
                            : isLive &&
                          // the direct implementation of SVG is here due to the need of usage of accentColor as there is no way to predetermine accentColor by filter
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            enableBackground="new 0 0 24 24"
                            height="24px"
                            viewBox="0 0 24 24" width="24px"
                            fill={isCurrentlyPlaying ? "#E3E2E6" : accentColor ? accentColor : COLOR_PRIMARY_100}
                          >
                            <rect fill="none" height="24" width="24"/>
                            <path
                              d="M8.54,8.54c0.35,0.35,0.37,0.88,0.1,1.29C8.24,10.45,8,11.2,8,12c0,0.8,0.24,1.55,0.64,2.17c0.27,0.41,0.24,0.95-0.11,1.29 c-0.43,0.43-1.17,0.4-1.51-0.11C6.38,14.4,6,13.24,6,12c0-1.21,0.36-2.33,0.97-3.28C7.33,8.18,8.08,8.08,8.54,8.54z M15.46,15.46 c0.43,0.43,1.17,0.4,1.51-0.11C17.62,14.4,18,13.24,18,12c0-1.24-0.38-2.4-1.03-3.36c-0.34-0.5-1.08-0.54-1.51-0.11 c-0.35,0.35-0.37,0.88-0.11,1.29C15.76,10.45,16,11.2,16,12c0,0.8-0.24,1.55-0.64,2.17C15.09,14.58,15.12,15.12,15.46,15.46z M12,10 c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S13.1,10,12,10z M18.32,18.32c0.42,0.42,1.12,0.39,1.5-0.08C21.18,16.53,22,14.36,22,12 s-0.82-4.53-2.18-6.24c-0.37-0.47-1.07-0.5-1.5-0.08c-0.36,0.36-0.4,0.92-0.08,1.32c1.1,1.37,1.76,3.11,1.76,5s-0.66,3.63-1.76,5 C17.92,17.39,17.96,17.96,18.32,18.32z M5.68,5.68c-0.42-0.42-1.12-0.39-1.5,0.08C2.82,7.47,2,9.64,2,12s0.82,4.53,2.18,6.24 c0.37,0.47,1.07,0.5,1.5,0.08c0.36-0.36,0.4-0.92,0.08-1.32C4.66,15.63,4,13.89,4,12s0.66-3.63,1.76-5 C6.08,6.61,6.04,6.04,5.68,5.68z"/>
                          </svg>
                        }
                    </ActionButtons>
                </TitleAndActionButtons>
            </EpgInfo>
            {isLive && <ProgressBar overrideColor={hover ? COLOR_OFFWHITE_90 : undefined} progress={progress}/>}
        </SideControllersEpgItemContainer>
    );
};

export default SideControllersEpgItem;
