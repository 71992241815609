import React, {useState} from "react";
import styled from "styled-components";
import RecordingSpaceComponent from "./RecordingSpaceComponent";
import RecordingDetailDialog from "./RecordingDetailDialog";
import RecordingCard from "./RecordingCard";
import {useIntl} from "react-intl";
import Error from "../ErrorHandle/Error";
import {COLOR_WHITE, RecommendationRowEntity, TYPEFACE_HEADLINE} from "@motv-webapp/lib";
import {isAfter} from "date-fns";

const RecordingsComponentContainer = styled.div(props => ({
    margin: "32px 0 80px 64px",
    "@media (max-width: 870px)": {
        margin: "16px 0 80px 16px",
    }
}));

const RecordingItemsContainer = styled.div(props => ({
    margin: "32px 0 0 0",
}));

const RecordingsSubtitleHeader = styled.div(props => ({
    ...TYPEFACE_HEADLINE,
    color: COLOR_WHITE,
    marginBottom: 24,
}));

const RecordingCardsContainer = styled.div(props => ({
    display: "flex",
    flexWrap: "wrap",
    gap: 16,
}));

const ErrorContainer = styled.div(props => ({
    marginRight:64,
    "@media (max-width: 870px)": {
        marginRight:16,
    }
}));


type Props = {
    isEdit: boolean
    recordingRows: Array<RecommendationRowEntity> | undefined
    recordingIds: number[] | undefined
}
const Recordings = ({isEdit, recordingRows, recordingIds}: Props) => {
    const [detailsOpen, setDetailsOpen] = useState(false)
    const intl = useIntl()

    const handleToggleDetails = () => {
        setDetailsOpen(!detailsOpen)
    }

    return (
        <RecordingsComponentContainer>
            <RecordingSpaceComponent {...{handleToggleDetails}}/>
            {detailsOpen && (
                <RecordingDetailDialog {...{handleToggleDetails}}/>
            )}
            {recordingRows && !!recordingRows.length ? (
                <>
                    {recordingRows && recordingRows.map((row) => (
                        <RecordingItemsContainer key={row.id}>
                            {(recordingRows.length > 1 || (recordingRows[0].data?.length && isAfter(Date.parse(recordingRows[0].data[0].end!), Date.now()))) &&
                              <RecordingsSubtitleHeader>
                                {row.title}
                            </RecordingsSubtitleHeader>}
                            <RecordingCardsContainer>
                                {row.data && row.data.map((mediaItem) => {
                                    if (!mediaItem.id) return
                                    if (recordingIds && !recordingIds.includes(mediaItem.id)) return
                                    return <RecordingCard key={mediaItem.id} {...{mediaItem, isEdit}}
                                    />
                                })}
                            </RecordingCardsContainer>
                        </RecordingItemsContainer>
                    ))}
                </>
            ) : (
                <ErrorContainer>
                    <Error src={"/images/icons/icon_ghost_filled.svg"}
                           labelHeadline={intl.formatMessage({
                               id: "label_no_recordings",
                               defaultMessage: "Nothing to see here - yet"
                           })}
                           labelMessage={intl.formatMessage({
                               id: "label_no_recordings_message",
                               defaultMessage: "To add something to Recordings, go to some event and click on the Add to Recordings button"
                           })}/>
                </ErrorContainer>
            )}
        </RecordingsComponentContainer>
    )
}

export default Recordings
