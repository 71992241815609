"use strict";
var _a;
exports.__esModule = true;
exports.error_same_error_mw_counter = exports.error_error_mw = exports.error_error_sms = exports.removeAllErrors = exports.addSmsError = exports.addMwError = exports.errorReducer = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var lib_1 = require("@motv-webapp/lib");
var initialState = {
    status: "idle",
    error_mw: undefined,
    same_error_mw_counter: 0,
    error_player: undefined,
    error_sms: undefined
};
exports.errorReducer = (0, toolkit_1.createSlice)({
    name: 'error',
    initialState: initialState,
    reducers: {
        removeAllErrors: function (state) {
            state.error_sms = undefined;
            state.error_mw = undefined;
        },
        addMwError: function (state, action) {
            if ((0, lib_1.getLocalStorage)("prev_error_mw") && action.payload.status === JSON.parse((0, lib_1.getLocalStorage)("prev_error_mw")).status) {
                if ((0, lib_1.getLocalStorage)("same_error_mw_counter")) {
                    var errorCounterFromLocalStorage = (0, lib_1.getLocalStorage)("same_error_mw_counter");
                    state.same_error_mw_counter = Number(errorCounterFromLocalStorage) + 1;
                    (0, lib_1.setLocalStorage)("same_error_mw_counter", String(Number(errorCounterFromLocalStorage) + 1));
                }
                else {
                    state.same_error_mw_counter = 1;
                    (0, lib_1.setLocalStorage)("same_error_mw_counter", String(1));
                }
            }
            else {
                state.same_error_mw_counter = 0;
                (0, lib_1.setLocalStorage)("same_error_mw_counter", String(0));
            }
            state.error_mw = action.payload;
            (0, lib_1.setLocalStorage)("prev_error_mw", JSON.stringify(state.error_mw));
        },
        addSmsError: function (state, action) {
            state.error_sms = action.payload;
        }
    }
});
exports.addMwError = (_a = exports.errorReducer.actions, _a.addMwError), exports.addSmsError = _a.addSmsError, exports.removeAllErrors = _a.removeAllErrors;
// Selectors
var error_error_sms = function (state) { return state.error.error_sms; };
exports.error_error_sms = error_error_sms;
var error_error_mw = function (state) { return state.error.error_mw; };
exports.error_error_mw = error_error_mw;
var error_same_error_mw_counter = function (state) { return state.error.same_error_mw_counter; };
exports.error_same_error_mw_counter = error_same_error_mw_counter;
exports["default"] = exports.errorReducer.reducer;
