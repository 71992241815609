import React, {Dispatch, MouseEvent, SetStateAction} from 'react';
import styled from "styled-components";
import {PLAYER_SIDE_CONTROLLER_PANEL_ENUM} from "@motv-webapp/lib";
import SideControllersChannels from "./SideControllersChannels";
import SideControllersEpg from "./SideControllersEpg";
import SideControllersDesc from "./SideControllersDesc";
import {COLOR_SECONDARY} from "@motv-webapp/lib";
import {useAppSelector} from "@motv-webapp/redux";
import {universal_selectedItem} from "@motv-webapp/redux";
import {RecommendationCardEntity} from "@motv-webapp/lib";
import {RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM} from "@motv-webapp/lib";
import {AnimatePresence, motion} from "framer-motion";

const SideControllersButtonContainer = styled.div((props: { sideControllersPanel: PLAYER_SIDE_CONTROLLER_PANEL_ENUM | undefined }) => ({
    display: "flex",
    flexDirection: "column" as "column",
    gap: 16,
    position: "absolute" as "absolute",
    right: !!props.sideControllersPanel ? 455 : 64,
    zIndex: 999,
}));

const StyledButton = styled.button((props: { active: boolean }) => ({
    alignItems: "center",
    border: 0,
    borderRadius: "50%",
    background: props.active ? "rgba(255, 255, 255, 0.7)" : "rgba(48, 45, 45, 0.7)",
    display: "flex",
    height: 56,
    justifyContent: "center",
    width: 56,
    "& > img": {
        filter: props.active ? "brightness(0) saturate(100%) invert(10%) sepia(8%) saturate(519%) hue-rotate(314deg) brightness(92%) contrast(80%)" : "none",
        height: 25,
    }
}));

const SidePanelContainer = styled(motion.div)(props => ({
    background: COLOR_SECONDARY,
    height: "100vh",
    position: "absolute",
    right: 0,
    top: 0,
    width: 420,
    zIndex: 999,
}));

type Props = {
    sideControllersPanel: PLAYER_SIDE_CONTROLLER_PANEL_ENUM | undefined,
    setSideControllersPanel: Dispatch<SetStateAction<PLAYER_SIDE_CONTROLLER_PANEL_ENUM | undefined>>
};

const SideControllers = ({sideControllersPanel, setSideControllersPanel}: Props) => {
    const currentlyPlaying = useAppSelector(universal_selectedItem) as RecommendationCardEntity

    const handleClickChannels = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        sideControllersPanel === PLAYER_SIDE_CONTROLLER_PANEL_ENUM.CHANNELS
            ? setSideControllersPanel(undefined)
            : setSideControllersPanel(PLAYER_SIDE_CONTROLLER_PANEL_ENUM.CHANNELS)
    }

    const handleClickEpg = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        sideControllersPanel === PLAYER_SIDE_CONTROLLER_PANEL_ENUM.EPG
            ? setSideControllersPanel(undefined)
            : setSideControllersPanel(PLAYER_SIDE_CONTROLLER_PANEL_ENUM.EPG)
    }

    const handleClickDesc = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        sideControllersPanel === PLAYER_SIDE_CONTROLLER_PANEL_ENUM.DESC
            ? setSideControllersPanel(undefined)
            : setSideControllersPanel(PLAYER_SIDE_CONTROLLER_PANEL_ENUM.DESC)
    }

    const stopPropagation = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
    }

    return (
        <>
            {/*outside click is handled in Player.tsx in handleClickOnPlayer function*/}
            <SideControllersButtonContainer {...{sideControllersPanel}}>
                {currentlyPlaying?.type && (currentlyPlaying.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV || currentlyPlaying.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.RECORDING) &&
                  <>
                    <StyledButton
                      active={sideControllersPanel === PLAYER_SIDE_CONTROLLER_PANEL_ENUM.CHANNELS}
                      onClick={handleClickChannels}
                    >
                      <img alt={""} src={"/images/icons/icon_channels.svg"}/>
                    </StyledButton>
                    <StyledButton
                      active={sideControllersPanel === PLAYER_SIDE_CONTROLLER_PANEL_ENUM.EPG}
                      onClick={handleClickEpg}
                    >
                      <img alt={""} src={"/images/icons/icon_guide.svg"}/>
                    </StyledButton>
                  </>
                }
                <StyledButton
                    active={sideControllersPanel === PLAYER_SIDE_CONTROLLER_PANEL_ENUM.DESC}
                    onClick={handleClickDesc}
                >
                    <img alt={""} src={"/images/icons/icon_info_filled.svg"}/>
                </StyledButton>
            </SideControllersButtonContainer>
            <AnimatePresence>
                {sideControllersPanel &&
                  <SidePanelContainer
                    onClick={stopPropagation}
                    key={"sidePanel"}
                    initial={{x: "100%"}}
                    animate={{x: 0}}
                    exit={{x: "100%"}}
                    transition={{type: "tween", duration: 0.5}}
                  >
                      {sideControllersPanel === PLAYER_SIDE_CONTROLLER_PANEL_ENUM.CHANNELS && <SideControllersChannels/>}
                      {sideControllersPanel === PLAYER_SIDE_CONTROLLER_PANEL_ENUM.EPG && <SideControllersEpg/>}
                      {sideControllersPanel === PLAYER_SIDE_CONTROLLER_PANEL_ENUM.DESC && <SideControllersDesc/>}
                  </SidePanelContainer>
                }
            </AnimatePresence>
        </>
    );
};

export default SideControllers;
