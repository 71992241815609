import React, {useState} from 'react';
import styled from "styled-components";
import OutsideClickHandler from "react-outside-click-handler";
import {useAppDispatch, useAppSelector} from "@motv-webapp/redux";
import {logOutUser, unselectProfile, user_selectedProfile} from "@motv-webapp/redux";
import {
    COLOR_NEUTRAL,
    COLOR_NEUTRAL_90,
    COLOR_BLACK,
    COLOR_WHITE
} from "@motv-webapp/lib";
import {FormattedMessage} from "react-intl";
import {useNavigate} from "react-router-dom";
import {invalidateCache} from "@motv-webapp/redux";
import {TYPEFACE_BODY_MEDIUM, TYPEFACE_CAPTION, TYPEFACE_WEB_CAPTION_2_BOLD} from "@motv-webapp/lib";


const AvatarButtonContainer = styled.div(props => ({
}));

const StyledButton = styled.button((props: {}) => ({
    background: COLOR_BLACK,
    border: 0,
    borderRadius: "50%",
    boxShadow: "3px 3px 10px 1px rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    display: "flex",
    height: "1.375rem",
    padding: 0,
    width: "1.375rem",
    "& img": {
        borderRadius: "50%",
        height: 22,
        width: 22,
    },
}));

const AvatarMenu = styled.div(props => ({
    ...TYPEFACE_BODY_MEDIUM,
    alignItems: "flex-end",
    backgroundColor: COLOR_NEUTRAL,
    borderRadius: 6,
    color: COLOR_WHITE,
    display: "flex",
    flexDirection: "column",
    lineHeight: "1.5",
    padding: "1rem",
    position: "absolute",
    right: 0,
    top: 32,
    whiteSpace: "nowrap",
    zIndex: 800,
    "& hr": {
        border: 0,
        backgroundColor: COLOR_NEUTRAL_90,
        height: 1,
        margin: "6px 0 0 0",
        width: "100%",
    },
    "& > p": {
        ...TYPEFACE_WEB_CAPTION_2_BOLD,
        color: "rgba(255, 255, 255, 0.6)",
        margin: 0,
    }
}));

const AvatarMenuButton = styled.button(props => ({
    ...TYPEFACE_CAPTION,
    background: "transparent",
    border: 0,
    color: COLOR_WHITE,
    marginTop: "0.5rem",
    padding: 0,
}));

type Props = {};

const AvatarButton = ({}: Props) => {
    const [avatarMenuOpen, setAvatarMenuOpen] = useState(false)
    const selectedProfile = useAppSelector(user_selectedProfile)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const handleOpenOrCloseMenu = () => {
        if (avatarMenuOpen) {
            setAvatarMenuOpen(false)
        } else {
            setAvatarMenuOpen(true)
        }
    }

    const handleChangeProfile = () => {
        dispatch(unselectProfile())
        dispatch(invalidateCache())
    }

    const handleGoToSettings = () => {
        navigate("/settings")
    }

    const handleLogout = () => {
        dispatch(logOutUser())
    }

    return (
        <AvatarButtonContainer data-testid={"avatarBtnContainer"}>
            <OutsideClickHandler
                onOutsideClick={() => {
                    setAvatarMenuOpen(false)
                }}
            >
                <StyledButton onClick={handleOpenOrCloseMenu} data-testid={"avatarBtnBtn"}>
                    {selectedProfile?.image && <img src={selectedProfile.image} alt={""}/>}
                </StyledButton>
                {avatarMenuOpen && selectedProfile &&
                    (
                        <AvatarMenu data-testid={"avatarBtnAvatarMenu"}>
                            {selectedProfile.profiles_name}
                            <p>{selectedProfile.customers_login}</p>
                            <hr/>
                            <AvatarMenuButton onClick={handleChangeProfile} data-testid={"avatarBtnProfileBtn"}>
                                <FormattedMessage id={"label_change_profile"} defaultMessage={"Change profile"}/>
                            </AvatarMenuButton>
                            <AvatarMenuButton onClick={handleGoToSettings} data-testid={"avatarBtnSettingsBtn"}>
                                <FormattedMessage id={"label_settings"} defaultMessage={"Settings"}/>
                            </AvatarMenuButton>
                            <AvatarMenuButton onClick={handleLogout} data-testid={"avatarBtnLogoutBtn"}>
                                <FormattedMessage id={"label_log_out"} defaultMessage={"Log out"}/>
                            </AvatarMenuButton>
                        </AvatarMenu>
                    )
                }
            </OutsideClickHandler>
        </AvatarButtonContainer>
    );
};

export default AvatarButton;
