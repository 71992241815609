import React from "react";
import styled from "styled-components";
import ProgressBar from "../ProgressBar/ProgressBar";
import {FormattedMessage} from "react-intl";
import {
    COLOR_BLACK,
    COLOR_NEUTRAL_90,
    COLOR_OFFWHITE_90,
    COLOR_WHITE,
    ProfileEntity,
    TYPEFACE_CAPTION
} from "@motv-webapp/lib";

const RecordingProfileCardContainer = styled.div(props => ({
    background: COLOR_NEUTRAL_90,
    borderRadius: 12,
    boxSizing: "border-box",
    display: "flex",
    gap: 12,
    height: 82,
    padding: 16,
    width: 360,
}));

const ProfileImageContainer = styled.div(props => ({
    height: "48px",
    width: "48px",
    borderRadius: "50%",
    background: COLOR_BLACK,
    "& img": {
        borderRadius: "50%",
        height: "48px",
        width: "48px",
        objectFit: "cover"
    },
}));

const ProfileInfoContainer = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    position: "relative",
}));

const ProfileName = styled.div(props => ({
    ...TYPEFACE_CAPTION,
    fontWeight: 600,
    color: COLOR_WHITE,
    marginBottom: 8,
}));

const ProfileMinutesUsed = styled.div(props => ({
    ...TYPEFACE_CAPTION,
    color: COLOR_OFFWHITE_90,
    display: "flex",
    justifyContent: "space-between"
}));


type Props = {
    profile: ProfileEntity;
    recordingByProfile: any
    recordingUsed: any
}
const RecordingProfileCard = ({profile, recordingByProfile, recordingUsed}: Props) => {

    return (
        <RecordingProfileCardContainer>
            <ProfileImageContainer>
                {profile.image && <img src={profile.image} alt={""}/>}
            </ProfileImageContainer>
            <ProfileInfoContainer>
                <ProfileName>{profile.profiles_name}</ProfileName>
                {profile.profiles_id && (
                    <>
                        <ProfileMinutesUsed>
                            <div>
                                <FormattedMessage id={"label_minutes_x_of_z"}
                                                  defaultMessage={"{s1} of {s2} minutes"} values={{
                                    s1: recordingByProfile[profile.profiles_id],
                                    s2: recordingUsed,
                                }}/>
                            </div>
                            <div>{(recordingUsed && recordingByProfile[profile.profiles_id]) ? `${Math.round(recordingByProfile[profile.profiles_id] / recordingUsed * 100)}%` : "0%"}
                            </div>
                        </ProfileMinutesUsed>
                        <div>
                            <ProgressBar progress={recordingByProfile[profile.profiles_id] / recordingUsed * 100} overrideHeight={8} overrideBorderRadius={6}/>
                        </div>
                    </>
                )}
            </ProfileInfoContainer>
        </RecordingProfileCardContainer>
    )
}

export default RecordingProfileCard