import React, {useEffect, useState} from 'react';
import {channel_epg} from "@motv-webapp/redux";
import styled from "styled-components";
import {useAppSelector} from "@motv-webapp/redux";
import {imageResizeUrl} from "@motv-webapp/lib";
import {
    COLOR_SECONDARY,
    COLOR_WHITE,
    COLOR_OFFWHITE_50
} from "@motv-webapp/lib";
import ProgressBar from "../ProgressBar/ProgressBar";
import {getUnixTime, isAfter, isBefore, parseISO} from 'date-fns';
import ChannelCardActionBar from "../MediaCard/ChannelCardActionBar";
import {SubscribedChannelEntity} from "@motv-webapp/lib";
import {useNavigate} from "react-router-dom";
import MediaCardBadge from "../MediaCard/MediaCardBadge";
import {motion, useAnimation} from "framer-motion";
import {TYPEFACE_BODY_MEDIUM, TYPEFACE_CAPTION} from "@motv-webapp/lib";
import {fetch_mw_vendorGetLockedChannelTextImage} from "@motv-webapp/redux";
import {isResponseSuccessful} from "@motv-webapp/lib";

const ChannelListItemContainer = styled(motion.div)((props: { $isChannelReorderEnabled: boolean }) => ({
    cursor: props.$isChannelReorderEnabled ? "grab" : "pointer" as "pointer",
    display: "flex",
    width: 340,
    position: "relative" as "relative",
}));

const ChannelLogoContainer = styled.div(props => ({
    marginRight: 16,
}));

const ChannelCardBox = styled.div(props => ({
    background: COLOR_SECONDARY,
    borderRadius: 6,
    display: "flex",
    height: 80,
    overflow: "hidden",
    position: "relative" as "relative",
    width: 128,
    "& > img": {
        maxHeight: "70%",
        maxWidth: "70%",
        overflow: "hidden",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    }
}));

const BlurBox = styled.div(props => ({
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    filter: "blur(60px)",
    height: "100%",
    width: "100%",
    zIndex: 100,
}));

const ChannelInfoContainer = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "hidden",
    whiteSpace: "nowrap",
}));

const ChannelName = styled.div(props => ({
    ...TYPEFACE_BODY_MEDIUM,
    lineHeight: 1.2,
    marginBottom: "0.5rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
}));

const CurrentlyPlaying = styled.div(props => ({
    ...TYPEFACE_CAPTION,
    color: COLOR_OFFWHITE_50,
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%"
}));

const DragAndDropIcon = styled.div(props => ({
    color: COLOR_WHITE,
    display: "flex",
    "& img": {
        transform: "rotate(90deg)",
        width: "11px",
        height: "11px",
    },
    position: "absolute" as "absolute",
    top: "8px",
    right: "8px",
}));

type Props = {
    channel: SubscribedChannelEntity
    isChannelReorderEnabled: boolean
};

const ChannelListItem = ({channel, isChannelReorderEnabled}: Props) => {
    const [hover, setHover] = useState(false)
    const [lockedUrl, setLockedUrl] = useState(undefined)
    const [lockedUrlLoaded, setLockedUrlLoaded] = useState(false)
    const [clickedBeforeLockedUrlLoaded, setClickedBeforeLockedUrlLoaded] = useState(false)
    const controls = useAnimation()
    const epgItems = useAppSelector(channel_epg)
    const navigate = useNavigate()

    const currentEpg = epgItems?.find(item => item.channels_id === channel.channels_id && isBefore(new Date(item.start!), new Date()) && isAfter(new Date(item.end!), new Date()))

    useEffect(() => {
        if (clickedBeforeLockedUrlLoaded) {
            lockedUrl
                ? window.location.href = lockedUrl
                : !isChannelReorderEnabled && navigate(`/tv/player/${channel.channels_id}`)
        }
    }, [lockedUrl])

    const handleClick = () => {
        if (channel?.channels_locked) {
            if (lockedUrlLoaded) {
                lockedUrl
                    ? window.location.href = lockedUrl
                    : !isChannelReorderEnabled && navigate(`/tv/player/${channel.channels_id}`)
            } else {
                setClickedBeforeLockedUrlLoaded(true)
            }
        } else {
            navigate(`/tv/player/${channel.channels_id}`)
        }
    }

    useEffect(() => {
        if (hover && !isChannelReorderEnabled) {
            controls.start({
                scale: 1.1
            })
        } else {
            controls.start({
                scale: 1.0
            })
        }
    }, [hover, isChannelReorderEnabled])

    useEffect(() => {
        if (channel?.channels_locked) {
            fetch_mw_vendorGetLockedChannelTextImage({channelsId: channel.channels_id!})
                .then(res => {
                        if (isResponseSuccessful(res)) {
                            setLockedUrl(res.response.vendors_locked_item_url)
                        }
                        setLockedUrlLoaded(true)
                    }
                )
        }
    }, [channel])

    const handleOnMouseEnter = () => {
        setHover(true)
    }

    const handleOnMouseLeave = () => {
        setHover(false)
    }

    return (
        <ChannelListItemContainer animate={controls}
                                  onClick={handleClick}
                                  $isChannelReorderEnabled={isChannelReorderEnabled}
                                  onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
            <ChannelLogoContainer>
                <ChannelCardBox
                >
                    <BlurBox style={{
                        backgroundImage: `url(${imageResizeUrl(channel.channels_logo, 128)})`,
                    }}/>
                    <img src={imageResizeUrl(channel.channels_logo, 128)} alt={""}/>
                    <ChannelCardActionBar isChannelReorderEnabled={isChannelReorderEnabled} mediaCard={channel}
                                          smallFormat={true}/>
                    <ProgressBar progress={
                        (getUnixTime(new Date()) - getUnixTime(parseISO(currentEpg?.start!))) /
                        (getUnixTime(parseISO(currentEpg?.end!)) - getUnixTime(parseISO(currentEpg?.start!))) * 100}/>
                    {(!!channel.channels_locked || !!currentEpg?.locked) &&
                      <MediaCardBadge type={"locked"}/>
                    }
                </ChannelCardBox>
            </ChannelLogoContainer>
            <ChannelInfoContainer>
                <ChannelName>{channel.channels_name}</ChannelName>
                <CurrentlyPlaying>{currentEpg?.title}</CurrentlyPlaying>
            </ChannelInfoContainer>
            {hover && isChannelReorderEnabled && (
                <DragAndDropIcon>
                    <img src={"/images/icons/icon_drag_indicator.svg"} alt={""}/>
                </DragAndDropIcon>
            )}
        </ChannelListItemContainer>
    );
};

export default ChannelListItem;
