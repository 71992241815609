import React, {useEffect, useState} from 'react';
import styled, {createGlobalStyle} from "styled-components";
import Header from "../components/Header/Header";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {FormattedMessage, useIntl} from "react-intl";
import PrimaryButton from "../components/Button/PrimaryButton";
import Recordings from "../components/Saved/Recordings";
import MyList from "../components/Saved/MyList";
import {
    portal_motv_portals_sections_v2,
    recording_rows,
    recordingGetRecordingsByProfileV2,
    setClearCacheFalse,
    universal_clearCache, universal_myList,
    universal_myRecordingIds,
    universalGetMyList,
    universalGetMyRecordings,
    useAppDispatch,
    useAppSelector,
    user_customers_recording_length,
    vendor_vendors_accent_color,
    myList_myList,
    myListGetMyListFull,
    myListRemoveFromMyList,
    recordingRemoveRecording,
    setClearCacheTrue
} from "@motv-webapp/redux";
import {
    BUTTON_SIZE_ENUM, BUTTON_TYPE_ENUM,
    COLOR_NEUTRAL,
    COLOR_OFFWHITE,
    COLOR_OFFWHITE_70,
    COLOR_OFFWHITE_90, COLOR_WHITE,
    getLocalStorage,
    PORTAL_SECTION_ENUM,
    setLocalStorage
} from "@motv-webapp/lib";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${COLOR_NEUTRAL};
  }

  ,
`

const SavedPageContainer = styled.div(props => ({
    background: COLOR_NEUTRAL,
    color: COLOR_WHITE,
    minHeight: "100vh",
    overflowX: "hidden",
}));

const SavedPageNavigationContainer = styled.div(props => ({
    display: "flex",
    justifyContent: "space-between",
    margin: "100px 64px 0 64px",
    "@media (max-width: 870px)": {
        flexDirection: "column",
        gap: 16,
        margin: "80px 16px 0 16px",
    }
}));
const NavLinksContainer = styled.div(props => ({
    display: "flex",
    gap: 24,

}));
const EditButtonContainer = styled.div(props => ({
    display: "flex",
    justifyContent: "flex-end",
}));

interface StyledNavLinkProps {
    readonly $accentColor: string | undefined | null;
}

const StyledNavLink = styled(NavLink)<StyledNavLinkProps>`
  color: ${COLOR_OFFWHITE_70};
  padding: 0 0 12px 0;
  font-family: Roboto, sans-serif;
  font-size: 1.953rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;

  :hover {
    color: ${COLOR_OFFWHITE_90};
  }

  &.active {
    color: ${COLOR_OFFWHITE};
    border-bottom: ${`2px ${COLOR_OFFWHITE} solid`};
  }

  @media (max-width: 870px) {
    font-size: 1rem;
    font-weight: 500;
    font-stretch: normal;
  }
`

const SavedPage = () => {
    const dispatch = useAppDispatch()
    const [isEdit, setIsEdit] = useState(false)
    const clearCache = useAppSelector(universal_clearCache)
    const $accentColor = useAppSelector(vendor_vendors_accent_color)
    const recordingRows = useAppSelector(recording_rows)
    const recordingIds = useAppSelector(universal_myRecordingIds)
    const myListFull = useAppSelector(myList_myList)
    const myListIds = useAppSelector(universal_myList)
    const sectionsAllowedV2 = useAppSelector(portal_motv_portals_sections_v2)
    const customerRecordingsLength = useAppSelector(user_customers_recording_length)
    const {slug} = useParams();
    const intl = useIntl();
    const navigate = useNavigate();

    const handleToggleEdit = () => {
        if (isEdit) {
            setIsEdit(false)
            let myListItemsToRemove = myListFull.filter(item => !myListIds.map(idItem => idItem.id).includes(item.id));
            myListItemsToRemove.forEach((item) => {
                dispatch(myListRemoveFromMyList({type: item.type!, id: item.id!}))
            })
            let recordingItemsToRemove: Array<number> = []
            recordingRows.forEach(row => {
                let filteredRow = row.data?.filter(item => !recordingIds.includes(item.id!))
                if (filteredRow) recordingItemsToRemove.push(...filteredRow.map(item => item.id!))
            })
            recordingItemsToRemove.forEach((item) => {
                dispatch(recordingRemoveRecording({epgEventsId: item}))
            })
            dispatch(setClearCacheTrue())
        } else {
            setIsEdit(true)
        }
    }

    const handleNavLinkClick = (savedPageActiveTab: string) => () => {
        setLocalStorage("savedPageActiveTab", savedPageActiveTab)
    }

    useEffect(() => {
        dispatch(myListGetMyListFull({}))
        dispatch(recordingGetRecordingsByProfileV2({}))
        dispatch(universalGetMyList())
        dispatch(universalGetMyRecordings())
    }, []) // eslint-disable-line

    useEffect(() => {
        if (clearCache) {
            dispatch(myListGetMyListFull({ignoreCache: true}))
            dispatch(recordingGetRecordingsByProfileV2({ignoreCache: true}))
            dispatch(universalGetMyList())
            dispatch(universalGetMyRecordings())
            dispatch(setClearCacheFalse())
        }
    }, [clearCache]) // eslint-disable-line

    useEffect(() => {
        if (!sectionsAllowedV2 || slug) return
        if (getLocalStorage("savedPageActiveTab") === "recordings" && sectionsAllowedV2.includes(PORTAL_SECTION_ENUM.RECORDINGS) && (customerRecordingsLength && customerRecordingsLength > 0)) {
            navigate("/saved/recordings")
        } else if (getLocalStorage("savedPageActiveTab") === "my-list" && sectionsAllowedV2.includes(PORTAL_SECTION_ENUM.MY_LIST)) {
            navigate("/saved/my-list")
        } else if (sectionsAllowedV2.includes(PORTAL_SECTION_ENUM.RECORDINGS) && (customerRecordingsLength && customerRecordingsLength > 0)) {
            navigate("/saved/recordings")
        } else if (sectionsAllowedV2.includes(PORTAL_SECTION_ENUM.MY_LIST)) {
            navigate("/saved/my-list")
        }
    }, [window.location.pathname, customerRecordingsLength])

    return (
        <SavedPageContainer>
            <GlobalStyle/>
            <Header/>
            <SavedPageNavigationContainer>
                <NavLinksContainer>
                    {!!(customerRecordingsLength && customerRecordingsLength > 0 && sectionsAllowedV2.includes(PORTAL_SECTION_ENUM.RECORDINGS)) && (
                        <StyledNavLink to={"/saved/recordings"} {...{$accentColor}}
                                       onClick={handleNavLinkClick("recordings")}>
                            <FormattedMessage id={"section_recordings"} defaultMessage={"Recordings"}/>
                        </StyledNavLink>
                    )}
                    {sectionsAllowedV2.includes(PORTAL_SECTION_ENUM.MY_LIST) &&
                      <StyledNavLink to={"/saved/my-list"} {...{$accentColor}} onClick={handleNavLinkClick("my-list")}>
                        <FormattedMessage id={"label_my_list"} defaultMessage={"My List"}/>
                      </StyledNavLink>}
                </NavLinksContainer>
                {((slug === "my-list" && myListFull && !!myListFull.length) || (slug === "recordings" && recordingRows && !!recordingRows.length)) && (
                    <EditButtonContainer>
                        <PrimaryButton onClick={handleToggleEdit}
                                       beforeImage={isEdit ? "/images/icons/icon_done.svg" : "/images/icons/icon_list_normal.svg"}
                                       buttonType={BUTTON_TYPE_ENUM.SECONDARY} buttonSize={BUTTON_SIZE_ENUM.MEDIUM}
                                       label={isEdit ? intl.formatMessage({
                                           id: "label_done",
                                           defaultMessage: "Done"
                                       }) : intl.formatMessage({id: "label_edit", defaultMessage: "Edit"})}/>
                    </EditButtonContainer>
                )}
            </SavedPageNavigationContainer>
            {slug === "recordings" && (customerRecordingsLength && customerRecordingsLength > 0) &&
              <Recordings {...{isEdit, recordingRows, recordingIds}}/>
            }
            {slug === "my-list" &&
              <MyList {...{isEdit, myListFull, myListIds}}/>
            }
        </SavedPageContainer>
    );
};

export default SavedPage;
