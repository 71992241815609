import React from 'react';
import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {useWindowSize} from "react-use";
import {
    COLOR_OFFWHITE,
    COLOR_OFFWHITE_90,
    COLOR_SECONDARY,
    COLOR_WHITE,
    PORTAL_SECTION_ENUM,
    removeLocalStorage
} from "@motv-webapp/lib";
import {
    portal_motv_portals_sections,
    portal_motv_portals_sections_v2,
    useAppSelector,
    user_customers_recording_length,
    vendor_vendors_accent_color
} from "@motv-webapp/redux";


const MainMenuContainer = styled.div(props => ({
    alignItems: "center",
    display: "flex",
}));

interface StyledNavLinkProps {
    readonly $accentColor: string | undefined | null;
}

const StyledNavLink = styled(NavLink)<StyledNavLinkProps>`
  border-bottom: 3px transparent solid;
  color: ${COLOR_OFFWHITE_90};
  font-weight: 500;
  padding-bottom: 2px;
  margin-right: 1rem;
  white-space: nowrap;

  &.active {
    color: ${COLOR_WHITE};
    font-weight: 800;
    border-bottom: ${props => `3px ${props.$accentColor} solid`};
  }

  :hover {
    color: ${COLOR_OFFWHITE};
  }
`

const MobileMainMenuContainer = styled.div(props => ({
    alignItems: "center",
    background: COLOR_SECONDARY,
    bottom: 0,
    display: "flex",
    height: "4rem",
    justifyContent: "space-around",
    left: 0,
    position: "fixed",
    width: "100%"
}));

const MobileStyledNavLink = styled(NavLink)(props => ({
    color: COLOR_OFFWHITE_90,
    display: "flex",
    fontSize: "0.8rem",
    fontWeight: 500,
    flexDirection: "column",
    textAlign: "center",
    whiteSpace: "nowrap",
    "& > img": {
        height: 30,
        marginBottom: 3
    }
}));

type Props = {};

const MainMenu = ({}: Props) => {
    const $accentColor = useAppSelector(vendor_vendors_accent_color)
    const sectionsAllowed = useAppSelector(portal_motv_portals_sections)
    const sectionsAllowedV2 = useAppSelector(portal_motv_portals_sections_v2)
    const customerRecordingsLength = useAppSelector(user_customers_recording_length)
    const {width} = useWindowSize()

    const handleTVGuideClick = () => {
        removeLocalStorage("tvGuideSelectedDate")
        removeLocalStorage("tvGuideRowsScrollX")
        removeLocalStorage("tvGuideRowsScrollY")
        removeLocalStorage("selectedFilter")
    }

    return (width > 870
            ? (<MainMenuContainer key={"mainMenu"}>
                {sectionsAllowedV2
                    ? sectionsAllowedV2.map((sectionName, i) => {
                        if (sectionName === PORTAL_SECTION_ENUM.HOMEPAGE) {
                            return (
                                <StyledNavLink to={"/"} style={{order: i}} key={sectionName} {...{$accentColor}}
                                               end>
                                    <FormattedMessage id={"section_home"} defaultMessage={"Home"}/>
                                </StyledNavLink>
                            )
                        } else if (sectionName === PORTAL_SECTION_ENUM.LIVE) {
                            return (
                                <React.Fragment key={sectionName}>
                                    <StyledNavLink to={"/channels"} style={{order: i}}
                                                   {...{$accentColor}} >
                                        <FormattedMessage id={"section_channels"} defaultMessage={"Channels"}/>
                                    </StyledNavLink>
                                    <StyledNavLink to={"/guide"} style={{order: i}} onClick={handleTVGuideClick}
                                                   key={`${sectionName}-guide`} {...{$accentColor}} >
                                        <FormattedMessage id={"section_tvguide"} defaultMessage={"TV Guide"}/>
                                    </StyledNavLink>
                                </React.Fragment>
                            )
                        } else if (sectionName === PORTAL_SECTION_ENUM.VOD) {
                            return (
                                <StyledNavLink to={"/vod"} style={{order: i}}
                                               key={sectionName} {...{$accentColor}} >
                                    <FormattedMessage id={"section_vod"} defaultMessage={"VOD"}/>
                                </StyledNavLink>
                            )
                        } else if (sectionName === PORTAL_SECTION_ENUM.RECORDINGS && (customerRecordingsLength && customerRecordingsLength > 0)) {
                            return (
                                <StyledNavLink to={"/saved"} style={{order: i}}
                                               key={sectionName} {...{$accentColor}} >
                                    <FormattedMessage id={"label_saved"} defaultMessage={"Saved"}/>
                                </StyledNavLink>
                            )
                        } else if (sectionName === PORTAL_SECTION_ENUM.MY_LIST) {
                            if (sectionsAllowedV2.includes(PORTAL_SECTION_ENUM.RECORDINGS) && (customerRecordingsLength && customerRecordingsLength > 0)) return
                            return (
                                <StyledNavLink to={"/saved"} style={{order: i}}
                                               key={sectionName} {...{$accentColor}} >
                                    <FormattedMessage id={"label_saved"} defaultMessage={"Saved"}/>
                                </StyledNavLink>
                            )
                        }
                    })
                    : sectionsAllowed && Object.entries(sectionsAllowed).map(([key, value]) => {
                    if (!!value) {
                        if (key === PORTAL_SECTION_ENUM.HOMEPAGE) {
                            return (
                                <StyledNavLink to={"/"} style={{order: 1}} key={key} {...{$accentColor}} end>
                                    <FormattedMessage id={"section_home"} defaultMessage={"Home"}/>
                                </StyledNavLink>
                            )
                        } else if (key === PORTAL_SECTION_ENUM.LIVE) {
                            return (
                                <React.Fragment key={key}>
                                    <StyledNavLink to={"/channels"} style={{order: 2}}
                                                   {...{$accentColor}} >
                                        <FormattedMessage id={"section_channels"} defaultMessage={"Channels"}/>
                                    </StyledNavLink>
                                    <StyledNavLink to={"/guide"} style={{order: 4}} onClick={handleTVGuideClick}
                                                   key={`${key}-guide`} {...{$accentColor}} >
                                        <FormattedMessage id={"section_tvguide"} defaultMessage={"TV Guide"}/>
                                    </StyledNavLink>
                                </React.Fragment>
                            )
                        } else if (key === PORTAL_SECTION_ENUM.VOD) {
                            return (
                                <StyledNavLink to={"/vod"} style={{order: 6}} key={key} {...{$accentColor}} >
                                    <FormattedMessage id={"section_vod"} defaultMessage={"VOD"}/>
                                </StyledNavLink>
                            )
                        } else if (key === PORTAL_SECTION_ENUM.RECORDINGS && (customerRecordingsLength && customerRecordingsLength > 0)) {
                            return (
                                <StyledNavLink to={"/saved"} style={{order: 5}} key={key} {...{$accentColor}}>
                                    <FormattedMessage id={"label_saved"} defaultMessage={"Saved"}/>
                                </StyledNavLink>
                            )
                        } else if (key === PORTAL_SECTION_ENUM.MY_LIST) {
                            if (sectionsAllowed.hasOwnProperty(PORTAL_SECTION_ENUM.RECORDINGS) && (customerRecordingsLength && customerRecordingsLength > 0)) return
                            return (
                                <StyledNavLink to={"/saved"} style={{order: 5}} key={key} {...{$accentColor}}>
                                    <FormattedMessage id={"label_saved"} defaultMessage={"Saved"}/>
                                </StyledNavLink>
                            )
                        }
                    }
                })
                }
            </MainMenuContainer>)
            : (<MobileMainMenuContainer key={"mainMenuMobile"}>
                {sectionsAllowedV2
                    ? sectionsAllowedV2.map((sectionName, i) => {
                        if (sectionName === PORTAL_SECTION_ENUM.HOMEPAGE) {
                            return (
                                <MobileStyledNavLink to={"/"} style={{order: i}} key={sectionName} {...{$accentColor}} >
                                    <img src={"/images/icons/icon_home.svg"} alt={""}/>
                                    <FormattedMessage id={"section_home"} defaultMessage={"Home"}/>
                                </MobileStyledNavLink>
                            )
                        } else if (sectionName === PORTAL_SECTION_ENUM.LIVE) {
                            return (
                                <React.Fragment key={sectionName}>
                                    <MobileStyledNavLink to={"/channels"} style={{order: i}}
                                                         {...{$accentColor}} >
                                        <img src={"/images/icons/icon_channels.svg"} alt={""}/>
                                        <FormattedMessage id={"section_channels"} defaultMessage={"Channels"}/>
                                    </MobileStyledNavLink>
                                    <MobileStyledNavLink to={"/guide"} style={{order: i}} onClick={handleTVGuideClick}
                                                         key={`${sectionName}-guide`} {...{$accentColor}} >
                                        <img src={"/images/icons/icon_guide.svg"} alt={""}/>
                                        <FormattedMessage id={"section_tvguide"} defaultMessage={"TV Guide"}/>
                                    </MobileStyledNavLink>
                                </React.Fragment>
                            )
                        } else if (sectionName === PORTAL_SECTION_ENUM.VOD) {
                            return (
                                <MobileStyledNavLink to={"/vod"} style={{order: i}}
                                                     key={sectionName} {...{$accentColor}} >
                                    <img src={"/images/icons/icon_vod.svg"} alt={""}/>
                                    <FormattedMessage id={"section_vod"} defaultMessage={"VOD"}/>
                                </MobileStyledNavLink>
                            )
                        } else if (sectionName === PORTAL_SECTION_ENUM.RECORDINGS && (customerRecordingsLength && customerRecordingsLength > 0)) {
                            return (
                                <MobileStyledNavLink to={"/saved"} style={{order: i}}
                                                     key={sectionName} {...{$accentColor}} >
                                    <img src={"/images/icons/icon_record.svg"} alt={""}/>
                                    <FormattedMessage id={"label_saved"} defaultMessage={"Saved"}/>
                                </MobileStyledNavLink>
                            )
                        } else if (sectionName === PORTAL_SECTION_ENUM.MY_LIST) {
                            if (sectionsAllowedV2.includes(PORTAL_SECTION_ENUM.RECORDINGS) && (customerRecordingsLength && customerRecordingsLength > 0)) return
                            return (
                                <MobileStyledNavLink to={"/saved"} style={{order: i}}
                                                     key={sectionName} {...{$accentColor}} >
                                    <img src={"/images/icons/icon_record.svg"} alt={""}/>
                                    <FormattedMessage id={"label_saved"} defaultMessage={"Saved"}/>
                                </MobileStyledNavLink>
                            )
                        }
                    })
                    : sectionsAllowed && Object.entries(sectionsAllowed).map(([key, value]) => {
                    if (!!value) {
                        if (key === PORTAL_SECTION_ENUM.HOMEPAGE) {
                            return (
                                <MobileStyledNavLink to={"/"} style={{order: 1}} key={key} {...{$accentColor}} >
                                    <img src={"/images/icons/icon_home.svg"} alt={""}/>
                                    <FormattedMessage id={"section_home"} defaultMessage={"Home"}/>
                                </MobileStyledNavLink>
                            )
                        } else if (key === PORTAL_SECTION_ENUM.LIVE) {
                            return (
                                <React.Fragment key={key}>
                                    <MobileStyledNavLink to={"/channels"} style={{order: 2}}
                                                         key={key} {...{$accentColor}} >
                                        <img src={"/images/icons/icon_channels.svg"} alt={""}/>
                                        <FormattedMessage id={"section_channels"} defaultMessage={"Channels"}/>
                                    </MobileStyledNavLink>
                                    <MobileStyledNavLink to={"/guide"} style={{order: 4}} onClick={handleTVGuideClick}
                                                         key={`${key}-guide`} {...{$accentColor}} >
                                        <img src={"/images/icons/icon_guide.svg"} alt={""}/>
                                        <FormattedMessage id={"section_tvguide"} defaultMessage={"TV Guide"}/>
                                    </MobileStyledNavLink>
                                </React.Fragment>
                            )
                        } else if (key === PORTAL_SECTION_ENUM.VOD) {
                            return (
                                <MobileStyledNavLink to={"/vod"} style={{order: 6}} key={key} {...{$accentColor}} >
                                    <img src={"/images/icons/icon_vod.svg"} alt={""}/>
                                    <FormattedMessage id={"section_vod"} defaultMessage={"VOD"}/>
                                </MobileStyledNavLink>
                            )
                        } else if (key === PORTAL_SECTION_ENUM.RECORDINGS && (customerRecordingsLength && customerRecordingsLength > 0)) {
                            return (
                                <MobileStyledNavLink to={"/saved"} style={{order: 5}}
                                                     key={key} {...{$accentColor}} >
                                    <img src={"/images/icons/icon_record.svg"} alt={""}/>
                                    <FormattedMessage id={"label_saved"} defaultMessage={"Saved"}/>
                                </MobileStyledNavLink>
                            )
                        } else if (key === PORTAL_SECTION_ENUM.MY_LIST) {
                            if (sectionsAllowed.hasOwnProperty(PORTAL_SECTION_ENUM.RECORDINGS) && (customerRecordingsLength && customerRecordingsLength > 0)) return
                            return (
                                <MobileStyledNavLink to={"/saved"} style={{order: 5}}
                                                     key={key} {...{$accentColor}} >
                                    <img src={"/images/icons/icon_record.svg"} alt={""}/>
                                    <FormattedMessage id={"label_saved"} defaultMessage={"Saved"}/>
                                </MobileStyledNavLink>
                            )
                        }
                    }
                })
                }
            </MobileMainMenuContainer>)
    );
};

export default MainMenu;
