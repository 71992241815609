import React, {Dispatch, SetStateAction} from 'react';
import styled from "styled-components";
import Countdown from "../Countdown/Countdown";
import {COLOR_NEUTRAL, COLOR_WHITE} from "@motv-webapp/lib";
import {FormattedMessage} from "react-intl";

const SnackbarContainer = styled.div(props =>({
    alignItems: "center",
    background: COLOR_WHITE,
    borderRadius: 24,
    bottom: 16,
    color: COLOR_NEUTRAL,
    display: "flex",
    gap: 8,
    left: "50%",
    marginLeft: 16,
    marginRight: 16,
    padding: 16,
    position: "absolute",
    transform: "translate(-50%, 0)",
    "& > p": {
        margin: 0,
    }
}));

type Props = {
    defaultMessage: string,
    messageId: string,
    setSnackbarOpen: Dispatch<SetStateAction<boolean>>
};

const Snackbar = ({defaultMessage, messageId, setSnackbarOpen}: Props) => {
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false)
    }

 return (
  <SnackbarContainer>
      <Countdown color={COLOR_NEUTRAL} onCountdownFinished={handleCloseSnackbar} secondsToCountdown={5} sizeInPixels={20} />
      <p><FormattedMessage id={messageId} defaultMessage={defaultMessage} /></p>
  </SnackbarContainer>
 );
};

export default Snackbar;
