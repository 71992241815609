import React from 'react';
import Dialog from "../Dialog/Dialog";
import styled from "styled-components";
import {useAppDispatch, useAppSelector} from "@motv-webapp/redux";
import {deselectYoutubeItem, universal_youtubeSelectedItem} from "@motv-webapp/redux";
import {COLOR_WHITE} from "@motv-webapp/lib";

const CloseButton = styled.button(props => ({
    background: "transparent",
    border: 0,
    height: "2.6rem",
    padding: 0,
    position: "absolute",
    right: "0.9rem",
    top: "0.9rem",
    width: "2.6rem",
    "& img": {
        height: "2.6rem",
        width: "2.6rem",
    }
}));

const YoutubeTitle = styled.h2(props =>({
    color: COLOR_WHITE,
    marginTop: 72,
}));

const YoutubeEmbed = styled.div({
    overflow: "hidden",
    paddingBottom: "70vh",
    position: "relative",
    height: 0,
    marginBottom: "16px",
    width: 853,
    "& iframe": {
        left: 0,
        top: 0,
        height: "100%",
        width: "100%",
        position: "absolute",
    },
});

const YoutubeDesc = styled.p(props =>({
    color: COLOR_WHITE,
    width: 853,
}));

type Props = {

};

const YoutubePlayer = ({}: Props) => {
    const mediaItem = useAppSelector(universal_youtubeSelectedItem)
    const dispatch = useAppDispatch()

    const handleCloseDialog = () => {
        dispatch(deselectYoutubeItem())
    }

 return (
  <Dialog handleCloseDialog={handleCloseDialog}>
      <CloseButton onClick={handleCloseDialog}>
          <img src={"/images/icons/icon_close.svg"} alt={""}/>
      </CloseButton>
      <YoutubeTitle>{mediaItem?.title}</YoutubeTitle>
      <YoutubeEmbed>
          <iframe
              width="853"
              height="480"
              src={`https://www.youtube.com/embed/${mediaItem?.youtube_id!}?autoplay=1`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
          />
      </YoutubeEmbed>
      <YoutubeDesc>
          {mediaItem?.description}
      </YoutubeDesc>
  </Dialog>
 );
};

export default YoutubePlayer;
