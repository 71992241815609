import React, {useEffect, useState} from 'react';
import {AnimatePresence, motion, usePresence} from "framer-motion"
import styled from "styled-components";
import Input from "../InputFields/Input";
import {
    COLOR_DANGER_100,
    COLOR_WHITE,
    COLOR_WHITE_60,
    ERROR_CODE_SMS_INCORRECT_USERNAME_PASSWORD,
    INPUT_FIELD_TYPE_ENUM,
    SOCIAL_SITES_APPLE,
    SOCIAL_SITES_FACEBOOK,
    SOCIAL_SITES_GOOGLE,
} from "@motv-webapp/lib";
import {
    LoginWithApple,
    LoginWithGoogle,
    RegisterWithApple,
    RegisterWithFacebook,
    error_error_sms,
    motvApiLoginV2,
    portal_motv_portals_custom_lost_password_open,
    portal_motv_portals_custom_registration_open,
    portal_motv_portals_facebook_client_id,
    portal_motv_portals_google_client_id,
    portal_motv_portals_id,
    portal_motv_portals_lost_password_enabled,
    portal_motv_portals_portal_apple_client_id, portal_motv_portals_registration_enabled,
    portal_motv_portals_social_sites,
    portal_motv_portals_url,
    removeAllErrors,
    useAppDispatch,
    useAppSelector,
    vendor_vendors_id,
    vendor_vendors_qr_code,
    portal_motv_portals_terms_url,
    portal_motv_portals_contact_url,
} from "@motv-webapp/redux";
import PrimaryButton from "../Button/PrimaryButton";
import {FormattedMessage, useIntl} from "react-intl";
import IconButton from "../Button/IconButton";
import LoginHeader from "./LoginHeader";
import {GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline} from 'react-google-login';
import AppleLogin from 'react-apple-login'
// @ts-ignore
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import Div100vh from "react-div-100vh";
import {useNavigate} from "react-router-dom";
import {debounce} from "debounce";
import {ReactFacebookFailureResponse, ReactFacebookLoginInfo} from "react-facebook-login";
import {getClientConfig} from "@motv-webapp/config";

const LoginPanelContainer = styled(motion(Div100vh))(props => ({
    alignItems: "center",
    backgroundColor: "transparent",
    color: COLOR_WHITE,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "absolute",
    right: 0,
    width: "40vw",
    "@media (max-width: 800px)": {
        alignItems: "flex-start",
        width: "100vw",
    }
}));

const FakeTop = styled.div(props =>({
    flexGrow: 1
}));

const LoginPanelContent = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    width: "20rem",
    "& h1": {
        marginTop: 0,
    },
    "@media (max-width: 800px)": {
        margin: "8rem 0 6rem 0",
    }
}));

const ErrorMessage = styled(motion.p)(props => ({
    color: COLOR_DANGER_100,
    fontSize: "0.8rem",
    fontWeight: 700,
    marginTop: "-0.7rem",
}));

const ForgottenPassword = styled.a(props => ({
    alignSelf: "flex-end",
    color: COLOR_WHITE,
    cursor: "pointer",
    fontSize: "0.8rem",
    fontWeight: "700",
    marginBottom: "1.5rem",
    textDecoration: "none",
}));

const CreateAccount = styled.p(props => ({
    fontSize: "0.8rem",
    fontWeight: 700,
    marginBottom: 0,
}));

const TermsAndContact = styled.p(props => ({
    alignItems: "end",
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    justifySelf: "end",
    fontSize: "0.8rem",
    fontWeight: 700,
    marginBottom: 0,
    marginTop: 32,
    paddingBottom: 32,
    "& a": {
        marginLeft: 8,
        marginRight: 8,
    }
}));

const OrDivider = styled.div(props => ({
    alignItems: "center",
    color: COLOR_WHITE_60,
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 32,
    marginTop: 24,
    "& hr": {
        display: "inline-block",
        height: 0,
        verticalAlign: "sub",
        width: "44%",
    },
    "& p": {
        display: "inline-block",
        fontSize: "0.8rem",
        fontWeight: 700,
        margin: "0 10px",
        textTransform: "uppercase",
    }
}));

const AlternativeLogin = styled.div(props => ({
    display: "flex",
    justifyContent: "center",
    gap: "1rem"
}));

type Props = {
    PANEL_QR: string,
    PANEL_FORGOTTEN_PASSWORD: string,
    PANEL_CREATE_ACCOUNT: string,
};

const LoginPanel = ({PANEL_QR, PANEL_FORGOTTEN_PASSWORD, PANEL_CREATE_ACCOUNT}: Props) => {
    const [disabledAllFields, setDisabledAllFields] = useState(false)
    const [login, setLogin] = useState("")
    const [loginError, setLoginError] = useState("")
    const [password, setPassword] = useState("")
    const [passwordError, setPasswordError] = useState("")
    const allowedSocialSites = useAppSelector(portal_motv_portals_social_sites)
    const appleClientId = useAppSelector(portal_motv_portals_portal_apple_client_id)
    const customLostPasswordLink = useAppSelector(portal_motv_portals_custom_lost_password_open)
    const customRegistrationLink = useAppSelector(portal_motv_portals_custom_registration_open)
    const facebookClientId = useAppSelector(portal_motv_portals_facebook_client_id)
    const forgottenPasswordEnabled = useAppSelector(portal_motv_portals_lost_password_enabled)
    const googleClientId = useAppSelector(portal_motv_portals_google_client_id)
    const motvPortalsId = useAppSelector(portal_motv_portals_id)
    const portalUrl = useAppSelector(portal_motv_portals_url)
    const qrLoginEnabled = useAppSelector(vendor_vendors_qr_code)
    const registrationEnabled = useAppSelector(portal_motv_portals_registration_enabled)
    const smsError = useAppSelector(error_error_sms)
    const termsUrl = useAppSelector(portal_motv_portals_terms_url)
    const contactUrl = useAppSelector(portal_motv_portals_contact_url)
    const vendors_id = useAppSelector(vendor_vendors_id)
    const intl = useIntl()
    const dispatch = useAppDispatch()
    let navigate = useNavigate();

    //need to use focus on login field after AnimatePresence has done the animation, otherwise it will break DOM
    const [isPresent, safeToRemove] = usePresence()
    useEffect(() => {
        !isPresent && safeToRemove && setTimeout(safeToRemove, 0)
        isPresent && setTimeout(() => {
            document.getElementById("motvPortalLogin")?.focus()
        }, 1000)
    }, [isPresent]) // eslint-disable-line

    const handleQrLogin = () => {
        navigate(`/login/${PANEL_QR}`);
    }

    const handleForgottenPassword = () => {
        customLostPasswordLink ? window.location.href = customLostPasswordLink : navigate(`/login/${PANEL_FORGOTTEN_PASSWORD}/0`);
    }
    const debouncedHandleForgottenPassword = debounce(handleForgottenPassword, 200)

    const handleCreateAccountPanel = () => {
        customRegistrationLink ? window.location.href = customRegistrationLink : navigate(`/login/${PANEL_CREATE_ACCOUNT}`);
    }
    const debouncedHandleCreateAccountPanel = debounce(handleCreateAccountPanel, 200)

    const handleLogin = async () => {
        setDisabledAllFields(true)
        setLoginError("")
        setPasswordError("")
        await dispatch(removeAllErrors())
        if (!login || login === "") {
            setLoginError("message_required_field")
            setDisabledAllFields(false)
            return
        }
        if (!password || password === "") {
            setPasswordError("message_required_field")
            setDisabledAllFields(false)
            return
        }

        vendors_id && await dispatch(motvApiLoginV2({login, password, vendors_id}));
        setDisabledAllFields(false)
    }

    const handleGoogleLoginSuccess = (googleUserResponse: GoogleLoginResponse | GoogleLoginResponseOffline) => {
        if ("getAuthResponse" in googleUserResponse && (googleClientId && motvPortalsId)) dispatch(LoginWithGoogle({
            clientId: googleClientId,
            motvPortalsId,
            token: googleUserResponse.getAuthResponse().id_token
        }))
    }

    const handleGoogleLoginFailure = (error: string) => {
        console.log("google login failed:", error)
    }

    const handleAppleCallback = (response: { user: { firstName: string; lastName: string; }; authorization: { id_token: string; code: string; }; }) => {
        if (response.user && motvPortalsId) {
            dispatch(
                RegisterWithApple({
                    motvPortalsId,
                    identityToken: btoa(response.authorization.id_token),
                    authorizationCode: btoa(response.authorization.code),
                    firstname: response.user.firstName,
                    lastname: response.user.lastName,
                })
            );
        } else if (response.authorization) {
            dispatch(LoginWithApple({identityToken: btoa(response.authorization.id_token)}))
        }
    };

    const handleCallbackFacebook = (userInfo: ReactFacebookLoginInfo | ReactFacebookFailureResponse) => {
        if ("accessToken" in userInfo) {
            motvPortalsId && dispatch(RegisterWithFacebook({motvPortalsId, token: userInfo.accessToken}))
        }
    }

    return (
        <LoginPanelContainer
            initial={{x: "100%"}}
            animate={{x: 0}}
            exit={{x: "100%"}}
            transition={{type: "spring", duration: 1}}
        >
            <LoginHeader disableGoBack={true}/>
            {(termsUrl || contactUrl) &&
                <FakeTop />
            }
            <LoginPanelContent>
                <h1><FormattedMessage id={"label_log_in"} defaultMessage={"Log in"}/></h1>
                <Input
                    autocomplete={"username"}
                    id={"motvPortalLogin"}
                    disabled={disabledAllFields}
                    errorMessageId={loginError}
                    type={INPUT_FIELD_TYPE_ENUM.TEXT}
                    onChange={setLogin}
                    onEnter={handleLogin}
                    placeholder={intl.formatMessage({id: 'label_login', defaultMessage: 'Login'})}
                />
                <Input
                    autocomplete={"current-password"}
                    id={"motvPortalLoginPwd"}
                    disabled={disabledAllFields}
                    errorMessageId={passwordError}
                    type={INPUT_FIELD_TYPE_ENUM.PASSWORD}
                    onChange={setPassword}
                    onEnter={handleLogin}
                    placeholder={intl.formatMessage({id: 'label_password', defaultMessage: 'Password'})}
                />
                <AnimatePresence>
                    {smsError && <ErrorMessage
                      data-testid={"loginErrorMessage"}
                      initial={{y: -20, height: 0}}
                      animate={{y: 0, height: "auto"}}
                      exit={{y: -20, height: 0}}
                      transition={{type: "tween", duration: 0.4}}
                    >
                        {smsError.status === ERROR_CODE_SMS_INCORRECT_USERNAME_PASSWORD &&
                          <FormattedMessage id={"message_incorrect_login"}
                                            defaultMessage={"Incorrect combination of login and password."}/>}
                    </ErrorMessage>}
                </AnimatePresence>
                {!!forgottenPasswordEnabled &&
                  <ForgottenPassword onClick={debouncedHandleForgottenPassword}>
                    <FormattedMessage id={"label_forgot_password"} defaultMessage={"Forgotten password"}/>
                  </ForgottenPassword>}
                <PrimaryButton
                    dataTestId={"loginButton"}
                    label={intl.formatMessage({id: "label_log_in", defaultMessage: "Log in"})}
                    disabled={disabledAllFields}
                    onClick={handleLogin}
                />
                {!!registrationEnabled && <CreateAccount>
                  <FormattedMessage id={"label_have_account_question"}
                                    defaultMessage={"Don't have an account?"}/>
                    {" "}
                  <a onClick={debouncedHandleCreateAccountPanel}> {/*eslint-disable-line*/}
                    <FormattedMessage id={"label_create_account"}
                                      defaultMessage={"Create an Account"}/>
                  </a>
                </CreateAccount>}
                {(!!qrLoginEnabled
                        || (googleClientId && allowedSocialSites && allowedSocialSites.includes(SOCIAL_SITES_GOOGLE))
                        || (appleClientId && portalUrl && allowedSocialSites && allowedSocialSites.includes(SOCIAL_SITES_APPLE))
                        || facebookClientId && !!allowedSocialSites?.length && allowedSocialSites.includes(SOCIAL_SITES_FACEBOOK))
                    && <OrDivider>
                    <hr/>
                    <p><FormattedMessage id={"label_or"} defaultMessage={"Or"}/></p>
                    <hr/>
                  </OrDivider>}
                <AlternativeLogin>
                    {!!qrLoginEnabled && <IconButton img={"/images/icons/icon_qr_code.svg"} onClick={handleQrLogin}/>}
                    {googleClientId
                        && allowedSocialSites && allowedSocialSites.includes(SOCIAL_SITES_GOOGLE)
                        && <GoogleLogin
                        render={renderProps => <IconButton img={"/images/icons/icon_google.png"}
                                                           onClick={renderProps.onClick}/>}
                        clientId={googleClientId}
                        buttonText="Login"
                        onSuccess={handleGoogleLoginSuccess}
                        onFailure={handleGoogleLoginFailure}
                      />}
                    {appleClientId && portalUrl
                        && allowedSocialSites && allowedSocialSites.includes(SOCIAL_SITES_APPLE)
                        && <AppleLogin
                        render={renderProps => <IconButton img={"/images/icons/icon_apple.png"}
                                                           onClick={renderProps.onClick}/>}
                        clientId={appleClientId}
                        redirectURI={portalUrl}
                        usePopup={true}
                        callback={handleAppleCallback}
                        scope={"name email"}/>}
                    {facebookClientId
                        && !!allowedSocialSites?.length && allowedSocialSites.includes(SOCIAL_SITES_FACEBOOK)
                        && <FacebookLogin
                        render={(renderProps: { onClick: (() => void) }) => <IconButton
                            img={"/images/icons/icon_facebook.png"} onClick={renderProps.onClick}/>}
                        appId={facebookClientId}
                        autoLoad={false}
                        fields="name,email,picture"
                        callback={handleCallbackFacebook}
                      />
                    }
                </AlternativeLogin>
            </LoginPanelContent>
            {(termsUrl || contactUrl) &&
              <TermsAndContact>
                  {termsUrl &&
                    <a href={termsUrl} target={"_blank"}>
                      <FormattedMessage id={"label_terms_and_conditions"} defaultMessage={"Terms & Conditions"}/>
                    </a>
                  }
                  {termsUrl && contactUrl &&
                    <>
                      |
                    </>
                  }
                  {contactUrl &&
                    <a href={contactUrl} target={"_blank"}>
                      Contact Us
                    </a>
                  }
              </TermsAndContact>
            }
        </LoginPanelContainer>
    );
};

export default LoginPanel;
