import React, {useState, MouseEvent} from 'react';
import styled from "styled-components";
import {COLOR_TERTIARY, COLOR_WHITE} from "@motv-webapp/lib";

const LineContainer = styled.div((props: {hover: boolean}) => ({
    background: props.hover ? COLOR_TERTIARY :  "transparent",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 20px",
    width: 215,
    "& > div": {
        alignItems: "center",
        display: "flex",
        gap: 8,
        "& > p": {
            color: COLOR_WHITE,
            fontSize: "0.8rem",
            fontWeight: 500,
            margin: 0,
        },
    },
}));

type Props = {
    icon?: string
    label: string
    onClick: (e: MouseEvent<HTMLDivElement>) => void
    value?: string
};

const PlayerDialogLine = ({icon, label, onClick, value}: Props) => {
    const [hover, setHover] = useState(false)

    const handleMouseEnter = () => {
        setHover(true)
    }

    const handleMouseLeave = () => {
        setHover(false)
    }

    return (
        <LineContainer onClick={onClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} {...{hover}}>
            <div>
                {icon ? <img style={{height: 16}} src={icon} alt={""}/> : <div style={{width: 16}} />}
                <p>
                    {label}
                </p>
            </div>
            {value &&
                <div>
                <p>
                    {value}
                </p>
                <img style={{height: 8}} src={"/images/icons/icon_chevron_forward.svg"} alt={""}/>
            </div>
            }
        </LineContainer>
    );
};

export default PlayerDialogLine;
