import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import ProfileCard from "./ProfileCard/ProfileCard";
import {useAppDispatch, useAppSelector} from "@motv-webapp/redux";
import {
    updateProfile,
    user_customers_profiles_id,
    user_profiles,
    user_selectedProfile
} from "@motv-webapp/redux";
import styled from "styled-components";
import ProfileCreateEdit from "./ProfileCreateEdit/ProfileCreateEdit";
import PrimaryButton from "../../Button/PrimaryButton";
import {COLOR_BLACK, COLOR_WHITE} from "@motv-webapp/lib";
import AllowedChannels from "./AllowedChannels/AllowedChannels";
import {format, formatISO, parseISO} from "date-fns";
import SettingsImagePickerContainer from "./ProfileCreateEdit/SettingsImagePickerContainer";
import {fetch_mw_profileGetPredefinedProfileImages} from "@motv-webapp/redux";
import {isResponseSuccessful} from "@motv-webapp/lib";
import {vendor_vendors_id} from "@motv-webapp/redux";
import Dialog from "../../Dialog/Dialog";
import PinDialog from "../../PinDialog/PinDialog";
import {createFileFromUri} from "@motv-webapp/lib";
import {ProfileEntity} from "@motv-webapp/lib";
import {channel_channels, channelGetSubscribedChannels} from "@motv-webapp/redux";
import {BUTTON_TYPE_ENUM} from "@motv-webapp/lib";
import {useNavigate} from "react-router-dom";
import {invalidateCache} from "@motv-webapp/redux";

const ProfilesContainer = styled.div(props => ({
    display: "flex",
    flexWrap: "wrap",
    gap: "16px",
    width: "100%",
}));
const EditProfileContainer = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "320px",
    "@media (max-width: 870px)": {
        alignItems: "center",
        maxWidth: "100%",
        width: "auto"
    },
}));
const EditProfileImageContainer = styled.div((props: { hover: boolean, profileToEditImage: string }) => ({
    display: "flex",
    padding: 0,
    margin: "40px 0 64px 0",
    position: "relative" as "relative",
    backgroundImage: `url(${props.profileToEditImage})`,
    backgroundColor: COLOR_BLACK,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "184px",
    height: "184px",
    borderRadius: "50%",
    "& > p": {
        position: "absolute" as "absolute",
        alignItems: "center",
        background: "rgba(0, 0, 0, 0.5)",
        borderRadius: "50%",
        color: COLOR_WHITE,
        display: props.hover ? "flex" : "none",
        width: "184px",
        height: "184px",
        fontWeight: 800,
        justifyContent: "center",
        margin: 0,
        cursor: "pointer",
    },
    "@media (max-width: 870px)": {
        marginTop: 0,
        justifyContent: "center",
    }
}));

type Props = {
    step: number;
    setStep: Dispatch<SetStateAction<number>>
    setIsDataChangedDialogOpen: Dispatch<SetStateAction<boolean>>
    profileToEdit: ProfileEntity
    setProfileToEdit: Dispatch<SetStateAction<ProfileEntity>>
    setProfileToEditInitial: Dispatch<SetStateAction<ProfileEntity>>
    setWhiteListedChannelsChanged: Dispatch<SetStateAction<boolean>>
    hasFormDataChanged: () => boolean
    isPinControlEnabled: boolean
    setIsPinControlEnabled: Dispatch<SetStateAction<boolean>>

};

const ProfilesSettings = ({
                              step,
                              setStep,
                              setProfileToEdit,
                              setProfileToEditInitial,
                              profileToEdit,
                              setIsPinControlEnabled,
                              setWhiteListedChannelsChanged, isPinControlEnabled
                          }: Props) => {

    const [confirmPinDialogOpen, setConfirmPinDialogOpen] = useState(false)
    const [isEditImageDialogOpen, setIsEditImageDialogOpen] = useState(false);
    const [profilePictureChanged, setProfilePictureChanged] = useState(false);
    const [isParentalControlEnabled, setIsParentalControlEnabled] = useState(profileToEdit.profiles_protect === 1);
    const [errors, setErrors] = useState<{ [key: string]: string | undefined }>({})
    const [disableAllFields, setDisableAllFields] = useState(false)
    const [hover, setHover] = useState(false)
    const [predefinedProfileImages, setPredefinedProfileImages] = useState([])
    const [allowedChannels, setAllowedChannels] = useState([] as number[]);
    const dispatch = useAppDispatch();
    const intl = useIntl()
    const navigate = useNavigate()
    const allChannels = useAppSelector(channel_channels)
    const profiles = useAppSelector(user_profiles);
    const selectedProfile = useAppSelector(user_selectedProfile);
    const mainProfileId = useAppSelector(user_customers_profiles_id);
    const vendorsId = useAppSelector(vendor_vendors_id)
    const primaryProfile = profiles && profiles.find(profile => profile.profiles_id === mainProfileId)

    useEffect(() => {
        dispatch(channelGetSubscribedChannels({
            body: {
                whitelisting: false,
                mcastOnly: true,
                bcastOnly: true,
                allDevices: true
            },
            ignoreCache: true
        }))
    }, []) //eslint-disable-line

    const handleMouseEnter = () => {
        setHover(true)
    }

    const handleMouseLeave = () => {
        setHover(false)
    }

    const handleOpenPinDialog = () => {
        setConfirmPinDialogOpen(true);
    }

    const handleCloseEnterPinDialog = () => {
        setConfirmPinDialogOpen(false)
    }

    const handleProfilePinConfirmed = () => {
        setStep(2)
        setConfirmPinDialogOpen(false)
    }

    const handleSelectProfileToEdit = (profile: ProfileEntity) => {
        setProfilePictureChanged(false);
        setErrors({})
        setProfileToEdit({
            ...profile,
            ...((profile.profiles_birthday) ? {"profiles_birthday": format(parseISO(profile.profiles_birthday), "yyyy-MM-dd")} : {}),
        })
        setProfileToEditInitial({
            ...profile,
            ...((profile.profiles_birthday) ? {"profiles_birthday": format(parseISO(profile.profiles_birthday), "yyyy-MM-dd")} : {}),
        })
        setIsParentalControlEnabled(profile.profiles_protect === 1)
        setIsPinControlEnabled(profile.profiles_pin_enabled === 1)
        profile.channels_whitelisting && allChannels && setAllowedChannels(!!profile.channels_whitelisting.length ? profile.channels_whitelisting : allChannels.map(channel => channel.channels_id!))
        selectedProfile?.profiles_pin && (selectedProfile.profiles_id === mainProfileId) ? handleOpenPinDialog() : setStep(2);
    }

    const handleAddProfile = () => {
        setProfilePictureChanged(true)
        setErrors({})
        handleSetPredefinedImage(predefinedProfileImages[0])
        setIsParentalControlEnabled(false)
        setIsPinControlEnabled(false)
        setAllowedChannels([])
        selectedProfile?.profiles_pin && (selectedProfile.profiles_id === mainProfileId) ? handleOpenPinDialog() : setStep(2);
    }

    const handleChange = (key: string, value: string) => {
        setProfileToEdit({...profileToEdit, [key]: value})
    }

    const handleValidation = () => {
        let formValid = true;
        if (!profileToEdit.image) {
            setErrors(prevState => ({...prevState, "image": "label_choose_image"}))
            formValid = false
        }
        if (profileToEdit.profiles_name === "" || !profileToEdit.profiles_name) {
            setErrors(prevState => ({...prevState, "profiles_name": "message_required_field"}))
            formValid = false
        }
        if (profiles) {
            for (let i = 0; i < profiles.length; i++) {
                if (profiles[i].profiles_name === profileToEdit.profiles_name && (profiles[i].profiles_id !== profileToEdit.profiles_id)) {
                    setErrors(prevState => ({...prevState, "profiles_name": "message_duplicate_profile_name"}))
                    formValid = false
                }
            }
        }
        if (profileToEdit.profiles_name && profileToEdit.profiles_name.length > 40) {
            setErrors(prevState => ({...prevState, "profiles_name": "label_name_too_long"}))
            formValid = false
        }
        if (profileToEdit.profiles_pin) {
            const patternRegExp = new RegExp("^(\\d{4})?$")
            if (!patternRegExp.test(profileToEdit.profiles_pin?.toString())) {
                setErrors(prevState => ({...prevState, "profiles_pin": "label_enter_pin"}))
                formValid = false
            }
        }
        return formValid
    }

    const mapImageForMW = () => {
        return (profileToEdit.image && profileToEdit.image !== "") ? profileToEdit.image.substring(profileToEdit.image.indexOf(';base64,') + ';base64,'.length, profileToEdit.image.length) : ""
    }

    const handleSubmitCreateEditProfile = () => {
        setDisableAllFields(true)
        setErrors({})
        if (!handleValidation()) {
            setDisableAllFields(false)
            return
        }

        if (profileToEdit.profiles_id) {
            if (selectedProfile && (selectedProfile.profiles_id === mainProfileId)) {
                dispatch(updateProfile({
                    profilesId: profileToEdit.profiles_id, data: {
                        image: profilePictureChanged ? mapImageForMW() : undefined,
                        profiles_name: profileToEdit.profiles_name ? profileToEdit.profiles_name : undefined,
                        profiles_pin: profileToEdit.profiles_pin ? profileToEdit.profiles_pin : null,
                        profiles_birthday: profileToEdit.profiles_birthday ? formatISO(new Date(profileToEdit.profiles_birthday)) : undefined,
                        profiles_protect: isParentalControlEnabled ? 1 : 0,
                        profiles_age: profileToEdit.profiles_age ? Number(profileToEdit.profiles_age) : undefined,
                        profiles_pin_enabled: isPinControlEnabled ? 1 : 0,
                        channels_whitelisting: allowedChannels,
                    }
                }))
            } else {
                dispatch(updateProfile({
                    profilesId: profileToEdit.profiles_id, data: {
                        image: profilePictureChanged ? mapImageForMW() : undefined,
                        profiles_name: profileToEdit.profiles_name ? profileToEdit.profiles_name : undefined,
                        profiles_pin: profileToEdit.profiles_pin ? profileToEdit.profiles_pin : undefined,
                        profiles_pin_enabled: isPinControlEnabled ? 1 : 0,
                        profiles_age: undefined,
                        profiles_birthday: undefined,
                        profiles_protect: undefined,
                        channels_whitelisting: undefined,
                    }
                }))
            }
        } else {
            dispatch(updateProfile({
                profilesId: null,
                data: {
                    image: profilePictureChanged ? mapImageForMW() : undefined,
                    profiles_name: profileToEdit.profiles_name ? profileToEdit.profiles_name : undefined,
                    profiles_pin: profileToEdit.profiles_pin ? profileToEdit.profiles_pin : undefined,
                    profiles_birthday: profileToEdit.profiles_birthday ? formatISO(new Date(profileToEdit.profiles_birthday)) : undefined,
                    profiles_protect: isParentalControlEnabled ? 1 : 0,
                    profiles_age: profileToEdit.profiles_age ? Number(profileToEdit.profiles_age) : undefined,
                    profiles_pin_enabled: isPinControlEnabled ? 1 : 0,
                    channels_whitelisting: allowedChannels,
                }
            }))
        }
        setDisableAllFields(false)
        setErrors({})
        setStep(1)
        setWhiteListedChannelsChanged(false)
        setProfilePictureChanged(false);
        dispatch(invalidateCache())
        navigate("/settings/profiles")
    }

    const handleSelectAllowedChannels = () => {
        setStep(3)
    }

    const handleOpenImageEditor = () => {
        setIsEditImageDialogOpen(true)
    }

    const handleCloseImageEditor = () => {
        setIsEditImageDialogOpen(false)
    }

    const handleSetPredefinedImage = (image: string) => {
        createFileFromUri(image).then((file: File) => {
            const reader = new FileReader();
            reader.addEventListener("load", function () {
                const emptyProfile = {} as ProfileEntity;
                setProfileToEdit({...emptyProfile, "image": reader.result ? reader.result as string : ""})
                setProfileToEditInitial({...emptyProfile, "image": reader.result ? reader.result as string : ""})
            });
            if (file) {
                reader.readAsDataURL(file);
            }
        });
    }

    useEffect(() => {
        vendorsId && fetch_mw_profileGetPredefinedProfileImages({vendorsId: vendorsId})
            .then(response => {
                if (isResponseSuccessful(response)) {
                    setPredefinedProfileImages(response.response)
                }
            })
    }, []) //eslint-disable-line

    return (
        <>
            {step === 1 && selectedProfile && (
                <ProfilesContainer>
                    {selectedProfile.profiles_id === mainProfileId ? (
                        <>
                            {profiles && profiles.map((profile) => (
                                <ProfileCard key={profile.profiles_id} profile={profile}
                                             onClick={() => handleSelectProfileToEdit(profile)}/>
                            ))}
                            <ProfileCard onClick={handleAddProfile}/>
                        </>
                    ) : (
                        <ProfileCard onClick={() => handleSelectProfileToEdit(selectedProfile)}
                                     profile={selectedProfile}/>
                    )}
                    {confirmPinDialogOpen &&
                        <PinDialog onConfirm={handleProfilePinConfirmed} onClose={handleCloseEnterPinDialog}
                                   profileToConfirmWithPin={primaryProfile!}
                                   messageId={"message_pin_of_profile"}
                                   messageDefaultMessage={"To continue enter PIN for profile:"}
                                   profileName={primaryProfile?.profiles_name!}/>}
                </ProfilesContainer>
            )}

            {step === 2 && (
                <EditProfileContainer>
                    <EditProfileImageContainer onClick={handleOpenImageEditor}
                                               hover={hover}
                                               onMouseEnter={handleMouseEnter}
                                               onMouseLeave={handleMouseLeave}
                                               profileToEditImage={profileToEdit.image ? profileToEdit.image : "/images/icons/icon_add_circle.svg"}>
                        <p>
                            <FormattedMessage id={"label_edit"} defaultMessage={"Edit"}/>
                        </p>
                    </EditProfileImageContainer>
                    <ProfileCreateEdit disableAllFields={disableAllFields} errors={errors} profile={profileToEdit}
                                       isPinControlEnabled={isPinControlEnabled}
                                       setIsPinControlEnabled={setIsPinControlEnabled}
                                       onChange={handleChange}/>
                    <PrimaryButton
                        buttonType={BUTTON_TYPE_ENUM.OFFWHITE}
                        label={intl.formatMessage({id: "label_submit", defaultMessage: "Submit"})}
                        onClick={handleSubmitCreateEditProfile}
                        style={{
                                marginBottom: 16,
                                marginTop: 8,
                                width: "100%"
                            }}
                        />
                    {selectedProfile && (selectedProfile.profiles_id === mainProfileId) && (
                        <PrimaryButton
                            buttonType={BUTTON_TYPE_ENUM.TERTIARY}
                            label={intl.formatMessage({id: "label_filter_channels", defaultMessage: "Filter Channels"})}
                            onClick={handleSelectAllowedChannels}
                        />
                    )}
                    {isEditImageDialogOpen && (
                        <Dialog handleCloseDialog={handleCloseImageEditor}>
                            <SettingsImagePickerContainer setProfilePictureChanged={setProfilePictureChanged}
                                                          profile={profileToEdit} setProfileToEdit={setProfileToEdit}
                                                          setIsEditImageDialogOpen={setIsEditImageDialogOpen}/>

                        </Dialog>
                    )}
                </EditProfileContainer>
            )}
            {step === 3 && (
                <AllowedChannels isParentalControlEnabled={isParentalControlEnabled}
                                 setIsParentalControlEnabled={setIsParentalControlEnabled}
                                 allowedChannels={allowedChannels} setAllowedChannels={setAllowedChannels}
                                 profile={profileToEdit} setWhiteListedChannelsChanged={setWhiteListedChannelsChanged}/>
            )}
        </>
    )
}

export default ProfilesSettings;
