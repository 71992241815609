import React from 'react';
import styled from "styled-components";
import {COLOR_WHITE} from "@motv-webapp/lib";
import {TYPEFACE_BODY_EXTRABOLD, TYPEFACE_CAPTION} from "@motv-webapp/lib";
import {useAppSelector} from "@motv-webapp/redux";
import {universal_selectedItem} from "@motv-webapp/redux";
import {RecommendationCardEntity} from "@motv-webapp/lib";

const NowPlaying = styled.div(props => ({
    display: "flex",
}));

const NowPlayingChannelImage = styled.div((props: { channelImage: string }) => ({
    backgroundImage: `url(${props.channelImage})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    borderRadius: 6,
    height: 42,
    marginRight: 16,
    width: 42,
}));

const NowPlayingEpgInfo = styled.div(props => ({
    color: COLOR_WHITE,
    display: "flex",
    flexDirection: "column",
    gap: 8,
    justifyContent: "center",
}));

const NowPlayingEpgInfoTitle = styled.p(props => ({
    ...TYPEFACE_CAPTION,
    margin: 0,
}));

const NowPlayingEpgInfoChannel = styled.p(props => ({
    ...TYPEFACE_BODY_EXTRABOLD,
    margin: 0,
}));

type Props = {

};

const SideControllersNowPlaying = ({}: Props) => {
    const currentlyPlaying = useAppSelector(universal_selectedItem) as RecommendationCardEntity

    return (
     <NowPlaying>
         {currentlyPlaying.channels_logo &&
           <NowPlayingChannelImage channelImage={currentlyPlaying.channels_logo}/>}
         <NowPlayingEpgInfo>
             {currentlyPlaying.title &&
               <NowPlayingEpgInfoTitle>{currentlyPlaying.title}</NowPlayingEpgInfoTitle>}
             {currentlyPlaying.channels_name &&
               <NowPlayingEpgInfoChannel>{currentlyPlaying.channels_name}</NowPlayingEpgInfoChannel>}
         </NowPlayingEpgInfo>
     </NowPlaying>
 );
};

export default SideControllersNowPlaying;
