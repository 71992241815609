import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import styled, {createGlobalStyle} from "styled-components";
import {COLOR_NEUTRAL, getSessionStorage, STORAGE_CONSTANT_PROFILE_ID} from "@motv-webapp/lib";
import ProfileSelector from "../components/ProfileSelector/ProfileSelector";
import {
    selectProfile,
    useAppDispatch,
    useAppSelector,
    user_profiles,
    user_selectedProfile,
} from "@motv-webapp/redux";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${COLOR_NEUTRAL};
  },
`

const SelectProfileWrapper = styled.div(props =>({
    alignItems: "center",
    backgroundColor: COLOR_NEUTRAL,
    display: "flex",
    minHeight: "calc(100vh - 160px)",
    justifyContent: "center",
    overflowY: "scroll",
    paddingBottom: 80,
    paddingTop: 80,
    width: "100vw",
    "@media (max-width: 630px)": {
        justifyContent: "flex-start",
    }
}));

const SelectProfilePage = () => {
    const profiles = useAppSelector(user_profiles);
    const selectedProfile = useAppSelector(user_selectedProfile);
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (!profiles) return
        const profileIdFromLocalStorage = getSessionStorage(STORAGE_CONSTANT_PROFILE_ID)
        if (!profileIdFromLocalStorage) return
        const profileFromLocalStorage = profiles.find(profile => profile.profiles_id === parseInt(profileIdFromLocalStorage))
        if (profileFromLocalStorage) dispatch(selectProfile(profileFromLocalStorage));
    }, [dispatch]) // eslint-disable-line

    useEffect(() => {
        if (selectedProfile) navigate("/", { replace: true });
    }, [selectedProfile]) // eslint-disable-line

    return (
        <SelectProfileWrapper>
            <GlobalStyle/>
            {profiles && <ProfileSelector {...{profiles}} />}
        </SelectProfileWrapper>
    );
};

export default SelectProfilePage;
