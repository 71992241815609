"use strict";
exports.__esModule = true;
exports.INPUT_FIELD_TYPE_ENUM = void 0;
var INPUT_FIELD_TYPE_ENUM;
(function (INPUT_FIELD_TYPE_ENUM) {
    INPUT_FIELD_TYPE_ENUM["TEXT"] = "text";
    INPUT_FIELD_TYPE_ENUM["PASSWORD"] = "password";
    INPUT_FIELD_TYPE_ENUM["NUMBER"] = "number";
    INPUT_FIELD_TYPE_ENUM["EMAIL"] = "email";
    INPUT_FIELD_TYPE_ENUM["CHECKBOX"] = "checkbox";
    INPUT_FIELD_TYPE_ENUM["DATE"] = "date";
})(INPUT_FIELD_TYPE_ENUM = exports.INPUT_FIELD_TYPE_ENUM || (exports.INPUT_FIELD_TYPE_ENUM = {}));
