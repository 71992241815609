import React, {MouseEvent, useEffect, useRef, useState} from 'react';
import {isResponseSuccessful} from "@motv-webapp/lib";
import {useNavigate, useParams} from "react-router-dom";
import {ChannelVodAudioSubtitleEntity, StreamUrlV2Entity} from "@motv-webapp/lib";
import {useAppDispatch, useAppSelector} from "@motv-webapp/redux";
import {user_customers_token, user_selectedProfile} from "@motv-webapp/redux";
import {getClientConfig} from "@motv-webapp/config";
import {portal_deviceHash, portal_deviceInfo} from "@motv-webapp/redux";
import {fetch_mw_vodGetStreamUrlV3} from "@motv-webapp/redux";
import {fetch_mw_channelGetStreamUrlV3} from "@motv-webapp/redux";
import {fetch_mw_recordingGetStreamUrlV3} from "@motv-webapp/redux";
import {isSafari} from 'react-device-detect';
import styled, {createGlobalStyle} from "styled-components";
import {COLOR_BLACK, COLOR_WHITE} from "@motv-webapp/lib";
import 'rc-slider/assets/index.css';
import {ShakaError, variantTrackType} from "@motv-webapp/lib";
import {
    universal_onVideoMessage,
    universal_selectedItem,
    universalGetFavouriteChannels,
    universalGetMyList,
    universalGetMyRecordings,
    universalSelectedItemGetUpdatedEventsV2,
    universalSelectedItemRecordingGetDataV2,
    universalSelectedItemVodGetDataV2
} from "@motv-webapp/redux";
import {addDays, endOfDay, formatISO, getUnixTime, startOfDay} from "date-fns";
import {useInterval, useKey, useTimeoutFn} from "react-use";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import {vendor_vendors_outage_image} from "@motv-webapp/redux";
import {
    fetch_mw_vendorGetLockedChannelTextImage,
    fetch_mw_vendorGetLockedVodTextImage
} from "@motv-webapp/redux";
import {VendorLockedItemTypeEnum} from "@motv-webapp/lib";
import PinDialog from "../PinDialog/PinDialog";
import {isRecommendationCardType} from "@motv-webapp/lib";
import {customHistory} from "@motv-webapp/lib";
import {addMwError, error_error_mw, removeAllErrors, universal_edgesBlacklisted, universal_edgesReachable, universal_edgesUnreachable} from "@motv-webapp/redux";
import PlayerError from "./PlayerError";
import {getLocalStorage, setLocalStorage} from "@motv-webapp/lib";
import PlayerControllers from "./PlayerControllers";
import {PLAYER_SIDE_CONTROLLER_PANEL_ENUM} from "@motv-webapp/lib";
import {
    channel_channels,
    channelGetChannelCategories,
    channelGetSubscribedChannels,
    epgGetUpdatedEventsV2
} from "@motv-webapp/redux";
import {RecommendationCardEntity} from "@motv-webapp/lib";
import {CHANNEL_TYPE_ENUM} from "@motv-webapp/lib";
import CreatePinDialog from "../PinDialog/CreatePinDialog";
import {TIME_CONST_ONE_MINUTE, TIME_CONST_ONE_SECOND, STORAGE_BLACKLISTED_EDGES} from "@motv-webapp/lib";
import {
    fetch_mw_loggerLogUnicastPlayer,
} from "@motv-webapp/redux";
import {CONTENT_TYPE_ENUM} from "@motv-webapp/lib";
import PlayerEndOfPlaybackOverlay from "./PlayerEndOfPlaybackOverlay";

const shaka = require('shaka-player/dist/shaka-player.ui');

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${COLOR_BLACK};
  }

  div {
    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 24px;
      background: rgba(255, 255, 255, 0.25);
    }
  }

  ,
`

const PlayerContainer = styled.div(props => ({
    alignItems: "center",
    background: COLOR_BLACK,
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    overflow: "hidden",
    position: "relative",
    width: "100vw",
}));

const AdContainer = styled.div(props => ({
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    minWidth: "100%",
    position: "absolute",

    "& > div": {
        zIndex: 950,
    }
}));

const StyledVideo = styled.video(props => ({
    flexGrow: 1,
    maxHeight: "100vh",
    maxWidth: "100vw",
    zIndex: 0,
}));

const ActionIconContainer = styled.div(props => ({
    alignItems: "center",
    display: "flex",
    height: "auto",
    justifyContent: "center",
    position: "absolute",
    width: "100%",
}));

const ActionIcon = styled.div((props: { visible: boolean }) => ({
    background: COLOR_BLACK,
    border: 0,
    borderRadius: "50%",
    height: 80,
    opacity: props.visible ? "70%" : "0%",
    padding: "16px",
    transition: props.visible ? "opacity 0s" : "opacity 0.5s",
    width: 80,
    "& img": {
        borderRadius: "50%",
        height: "100%",
        width: "100%",
    },
}))


const StyledHeadline = styled.h1(props => ({
    color: COLOR_WHITE,
    textAlign: "center",
}));

const OnVideoMessage = styled.div(props => ({
    background: COLOR_WHITE,
    borderRadius: 8,
    bottom: "20%",
    fontWeight: 500,
    left: "50%",
    padding: 20,
    position: "absolute",
    transform: "translate(-50%, 0)",
}));

const RadioLogo = styled.img(props => ({
    height: "40vh",
    left: "50%",
    position: "absolute",
    transform: "translate(-50%, 0)",
}));

type Props = {
    playerType: "vod" | "tv" | "recording"
};

const Player = ({playerType}: Props) => {
    const [actionIconSrc, setActionIconSrc] = useState("")
    const [adsInProgress, setAdsInProgress] = useState(false)
    const [confirmPinDialogOpen, setConfirmPinDialogOpen] = useState(false)
    const [currentTime, setCurrentTime] = useState(1)
    const [endOfPlaybackOverlay, setEndOfPlaybackOverlay] = useState(false)
    const [isAbrEnabled, setIsAbrEnabled] = useState(true) // auto bit rate
    const [isBuffering, setIsBuffering] = useState(true);
    const [liveEdge, setLiveEdge] = useState(0)
    const [lockedImage, setLockedImage] = useState<string | undefined>(undefined)
    const [lockedText, setLockedText] = useState<string | undefined>(undefined)
    const [settingsOpen, setSettingsOpen] = useState(false)
    const [showController, setShowController] = useState(true)
    const [sideControllersPanel, setSideControllersPanel] = useState<PLAYER_SIDE_CONTROLLER_PANEL_ENUM | undefined>(undefined)
    const [streamDetails, setStreamDetails] = useState<StreamUrlV2Entity | undefined>(undefined)
    const [streamDetailsFetchFailed, setStreamDetailsFetchFailed] = useState(false)
    const [subtitleTrack, setSubtitleTrack] = useState<variantTrackType | undefined>(undefined)
    const [subtitlesOpen, setSubtitlesOpen] = useState(false)
    const [uptimeThumbnails, setUptimeThumbnails] = useState(0)
    const [variantTrack, setVariantTrack] = useState<variantTrackType | undefined>(undefined)
    const [actionIconVisible, setActionIconVisible] = useState(false)
    const [volume, setVolume] = useState(1)
    const adsRef = useRef<HTMLDivElement | null>(null);
    const adVideoElmRef = useRef(null)
    const containerRef = useRef<HTMLDivElement | null>(null);
    const playerRef = useRef(null);
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const {mediaId, timestamp, preferredOffset} = useParams()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const channels = useAppSelector(channel_channels)
    const currentlyPlaying = useAppSelector(universal_selectedItem) as RecommendationCardEntity
    const customerToken = useAppSelector(user_customers_token)
    const deviceHash = useAppSelector(portal_deviceHash)
    const deviceInfo = useAppSelector(portal_deviceInfo)
    const edgesReachable = useAppSelector(universal_edgesReachable)
    const edgesUnreachable = useAppSelector(universal_edgesUnreachable)
    const edgesBlacklisted = useAppSelector(universal_edgesBlacklisted)
    const mwError = useAppSelector(error_error_mw)
    const onVideoMessage = useAppSelector(universal_onVideoMessage)
    const outageImage = useAppSelector(vendor_vendors_outage_image)
    const selectedProfile = useAppSelector(user_selectedProfile)

    // THUMBNAIL UPTIME HANDLERS START
    useEffect(() => {
        if (!streamDetails) return
        setUptimeThumbnails(streamDetails?.thumbnails?.uptime ? streamDetails.thumbnails.uptime : 0)
    }, [streamDetails])

    useInterval(() => {
        if (!streamDetails) return
        setUptimeThumbnails(prev => prev + 1)
    }, TIME_CONST_ONE_SECOND)
    // THUMBNAIL UPTIME HANDLERS END

    // HEARTBEATER HANDLERS START
    useEffect(() => {
        if (!deviceInfo
            || !deviceHash
            || !mediaId
            || !streamDetails) return
        fetch_mw_loggerLogUnicastPlayer({
            devicesIdentification: deviceInfo,
            devicesHash: deviceHash,
            isRenewal: false,
            type: playerType === "vod"
                ? CONTENT_TYPE_ENUM.VOD
                : playerType === "recording"
                    ? CONTENT_TYPE_ENUM.RECORDING
                    : streamDetails.type === CONTENT_TYPE_ENUM.LIVE
                        ? streamDetails.offset! < 40
                            ? CONTENT_TYPE_ENUM.LIVE
                            : CONTENT_TYPE_ENUM.TIMESHIFT
                        : CONTENT_TYPE_ENUM.CATCHUP,
            id: streamDetails.id,
            edgesId: streamDetails.edgesId ? streamDetails.edgesId : undefined,
            audioLanguage: variantTrack?.language,
            subtitleLanguage: subtitleTrack?.language,
            offset: streamDetails.type === CONTENT_TYPE_ENUM.LIVE ? 0 : Math.floor(currentTime),
            timestamp: streamDetails.type === CONTENT_TYPE_ENUM.LIVE ? Math.floor(Date.now() / 1000 - (liveEdge - currentTime)) : 0,
            bitrate: variantTrack?.videoBandwidth
        }).then((res) => {
            if (!isResponseSuccessful(res)) {
                dispatch(addMwError(res))
            }
        })
    }, [mediaId, timestamp, streamDetails])

    useInterval(() => {
        if (!deviceInfo
            || !deviceHash
            || !mediaId
            || !streamDetails) return
        fetch_mw_loggerLogUnicastPlayer({
            devicesIdentification: deviceInfo,
            devicesHash: deviceHash,
            isRenewal: true,
            type: playerType === "vod"
                ? CONTENT_TYPE_ENUM.VOD
                : playerType === "recording"
                    ? CONTENT_TYPE_ENUM.RECORDING
                    : streamDetails.type === CONTENT_TYPE_ENUM.LIVE
                        ? streamDetails.offset! < 40
                            ? CONTENT_TYPE_ENUM.LIVE
                            : CONTENT_TYPE_ENUM.TIMESHIFT
                        : CONTENT_TYPE_ENUM.CATCHUP,
            id: streamDetails.id,
            edgesId: streamDetails.edgesId ? streamDetails.edgesId : undefined,
            audioLanguage: variantTrack?.language,
            subtitleLanguage: subtitleTrack?.language,
            offset: streamDetails.type === CONTENT_TYPE_ENUM.LIVE ? 0 : Math.floor(currentTime),
            timestamp: streamDetails.type === CONTENT_TYPE_ENUM.LIVE ? Math.floor(Date.now() / 1000 - (liveEdge - currentTime)) : 0,
            bitrate: variantTrack?.videoBandwidth
        }).then((res) => {
            if (!isResponseSuccessful(res)) {
                dispatch(addMwError(res))
            }
        })
    }, TIME_CONST_ONE_MINUTE)
    // HEARTBEATER HANDLERS END

    useEffect(() => {
        dispatch(universalGetMyList())
        dispatch(channelGetSubscribedChannels({body: {}}))
        dispatch(universalGetMyRecordings())
        dispatch(universalGetFavouriteChannels())
        dispatch(channelGetChannelCategories({}))
    }, []) // eslint-disable-line

    useEffect(() => {
        if (!currentlyPlaying?.channels_id) return
        dispatch(epgGetUpdatedEventsV2({
            timestamp: 0,
            from: formatISO(startOfDay(Date.now())),
            to: formatISO(endOfDay(Date.now())),
        }))
    }, [currentlyPlaying?.channels_id])

    const [isReady, cancelTimeout, resetTimeout] = useTimeoutFn(() => {
        setShowController(false);
    }, 4000);

    useEffect(() => {
        (sideControllersPanel) ? cancelTimeout() : resetTimeout()
    }, [sideControllersPanel])

    // This useInterval is here only because of Safari, which resolves player.load() promise earlier than it is really loaded
    useInterval(() => {
        // @ts-ignore
        setVariantTrack(playerRef.current?.getVariantTracks().find((track: variantTrackType) => track.active))
        // @ts-ignore
        setSubtitleTrack(playerRef.current?.getTextTracks()?.find((trackItem: variantTrackType) => trackItem.active))
    }, variantTrack ? null : 2000)

    useEffect(() => {
        dispatch(removeAllErrors())
    }, [mediaId, timestamp])

    useInterval(() => {
        if (mwError) return
        switch (playerType) {
            case "vod":
                if (currentTime === videoRef.current?.duration) {
                    // REMOVE IF NO BUG OCCURS, changed 2023-01-02
                    // customHistory.action === "REPLACE" ? navigate("/") : navigate(-1)
                    setCurrentTime(0)
                    if (videoRef.current) videoRef.current.currentTime = 0
                    setEndOfPlaybackOverlay(true)
                }
                videoRef.current && setCurrentTime(videoRef.current.currentTime)
                break
            case "recording":
                if (currentTime === videoRef.current?.duration) {
                    // REMOVE IF NO BUG OCCURS, changed 2023-01-02
                    // customHistory.action === "REPLACE" ? navigate("/") : navigate(-1)
                    setCurrentTime(0)
                    if (videoRef.current) videoRef.current.currentTime = 0
                    setEndOfPlaybackOverlay(true)
                }
                videoRef.current && setCurrentTime(videoRef.current.currentTime)
                break
            case "tv":
                if (currentlyPlaying && isRecommendationCardType(currentlyPlaying) && videoRef.current && currentTime >= (streamDetails?.type === CONTENT_TYPE_ENUM.LIVE ? currentlyPlaying.duration! : videoRef.current.duration) && streamDetails?.offset! < 40) {
                    if (streamDetails?.type === CONTENT_TYPE_ENUM.LIVE && mediaId) {
                        // @ts-ignore
                        dispatch(universalSelectedItemGetUpdatedEventsV2({
                            body: {
                                channels: [Number(mediaId)],
                                timestamp: 0,
                                from: formatISO(Date.now()),
                                to: formatISO(Date.now())
                            }
                        }))
                        setCurrentTime(0)
                    } else {
                        // REMOVE IF NO BUG OCCURS, changed 2023-01-02
                        // // @ts-ignore
                        // playerRef.current?.destroy()
                        // setCurrentTime(0)
                        // navigate(`/tv/player/${mediaId}/${Number(timestamp!) + currentlyPlaying.duration!}`, {replace: true})
                        setCurrentTime(preferredOffset ? Number(preferredOffset) : 0)
                        if (videoRef.current) videoRef.current.currentTime = preferredOffset ? Number(preferredOffset) : 0
                        setEndOfPlaybackOverlay(true)
                    }
                } else {
                    // @ts-ignore
                    if (streamDetails?.type === CONTENT_TYPE_ENUM.LIVE && streamDetails.offset <= 40) {
                        // @ts-ignore
                        currentlyPlaying && isRecommendationCardType(currentlyPlaying) && setLiveEdge(getUnixTime(new Date()) - getUnixTime(new Date(currentlyPlaying.start!)))
                        // @ts-ignore
                        currentlyPlaying && isRecommendationCardType(currentlyPlaying) && playerRef.current && videoRef.current && setCurrentTime((Date.now() - (playerRef.current.seekRange()!.end - videoRef.current.currentTime) * 1000 - getUnixTime(new Date(currentlyPlaying.start!)) * 1000) / 1000)
                    } else {
                        // @ts-ignore
                        if (streamDetails?.type === CONTENT_TYPE_ENUM.LIVE && streamDetails.offset > 40) {
                            // @ts-ignore
                            currentlyPlaying && isRecommendationCardType(currentlyPlaying) && setLiveEdge(getUnixTime(new Date()) - getUnixTime(new Date(currentlyPlaying.start!)))
                            // @ts-ignore
                            currentlyPlaying && isRecommendationCardType(currentlyPlaying) && playerRef.current && videoRef.current && setCurrentTime((Date.now() - ((playerRef.current.seekRange()!.end - videoRef.current.currentTime) * 1000) - (getUnixTime(new Date(currentlyPlaying.start!)) * 1000)) / 1000)
                        } else {
                            videoRef.current && setCurrentTime(videoRef.current.currentTime)
                            videoRef.current && setLiveEdge(videoRef.current.duration)
                        }
                    }
                }
                break
            default:
                break
        }
    }, endOfPlaybackOverlay ? null : TIME_CONST_ONE_SECOND)

    useEffect(() => {
        switch (playerType) {
            case "recording":
                mediaId && fetch_mw_recordingGetStreamUrlV3({
                    epgEventsId: parseInt(mediaId),
                    preferredEdgesIds: edgesReachable ? edgesBlacklisted ? edgesReachable.filter(edge => !edgesBlacklisted.includes(edge)) : edgesReachable : undefined,
                    nonpreferredEdgesIds: edgesUnreachable ? edgesBlacklisted ? edgesUnreachable.concat(edgesBlacklisted) : edgesUnreachable : undefined
                })
                    .then((res) => {
                        if (isResponseSuccessful(res)) {
                            setStreamDetails(res.response)
                        } else {
                            setStreamDetailsFetchFailed(true)
                        }
                    })
                dispatch(universalSelectedItemRecordingGetDataV2({body: {epgEventsId: Number(mediaId!)}}))
                break
            case "tv":
                mediaId && fetch_mw_channelGetStreamUrlV3({
                    channelsId: parseInt(mediaId),
                    timestamp: timestamp ? Number(timestamp) : undefined,
                    preferredEdgesIds: edgesReachable ? edgesBlacklisted ? edgesReachable.filter(edge => !edgesBlacklisted.includes(edge)) : edgesReachable : undefined,
                    nonpreferredEdgesIds: edgesUnreachable ? edgesBlacklisted ? edgesUnreachable.concat(edgesBlacklisted) : edgesUnreachable : undefined
                })
                    .then((res) => {
                        if (isResponseSuccessful(res)) {
                            setStreamDetails(res.response)
                        } else {
                            if (res.status === 903) {
                                fetch_mw_vendorGetLockedChannelTextImage({channelsId: Number(mediaId)})
                                    .then(resLocked => {
                                        if (isResponseSuccessful(resLocked)) {
                                            if (resLocked.response.vendors_locked_item_type === VendorLockedItemTypeEnum.TEXT) {
                                                setLockedText(resLocked.response.vendors_locked_item_text)
                                            } else if (resLocked.response.vendors_locked_item_type === VendorLockedItemTypeEnum.IMAGE) {
                                                setLockedImage(resLocked.response.vendors_locked_item_image)
                                            }
                                        } else {
                                            dispatch(addMwError(res))
                                        }
                                    })
                            } else {
                                setStreamDetailsFetchFailed(true)
                                dispatch(addMwError(res))
                            }
                        }
                    })
                dispatch(universalSelectedItemGetUpdatedEventsV2({
                    body: {
                        channels: [parseInt(mediaId!)],
                        timestamp: 0,
                        from: timestamp ? formatISO(parseInt(timestamp) * 1000) : formatISO(Date.now()),
                        to: timestamp ? formatISO(parseInt(timestamp) * 1000) : formatISO(Date.now())
                    }
                }))
                break
            case "vod":
                mediaId && fetch_mw_vodGetStreamUrlV3({
                    vodsId: parseInt(mediaId),
                    preferredEdgesIds: edgesReachable ? edgesBlacklisted ? edgesReachable.filter(edge => !edgesBlacklisted.includes(edge)) : edgesReachable : undefined,
                    nonpreferredEdgesIds: edgesUnreachable ? edgesBlacklisted ? edgesUnreachable.concat(edgesBlacklisted) : edgesUnreachable : undefined
                })
                    .then((res) => {
                        if (isResponseSuccessful(res)) {
                            setStreamDetails(res.response)
                        } else {
                            if (res.status === 903) {
                                fetch_mw_vendorGetLockedVodTextImage({vodsId: parseInt(mediaId)})
                                    .then(res => {
                                        if (isResponseSuccessful(res)) {
                                            if (res.response.vendors_locked_item_type === VendorLockedItemTypeEnum.TEXT) {
                                                setLockedText(res.response.vendors_locked_item_text)
                                            } else if (res.response.vendors_locked_item_type === VendorLockedItemTypeEnum.IMAGE) {
                                                setLockedImage(res.response.vendors_locked_item_image)
                                            }
                                        }
                                    })
                            } else {
                                setStreamDetailsFetchFailed(true)
                            }
                        }
                    })
                dispatch(universalSelectedItemVodGetDataV2({body: {vodsId: parseInt(mediaId!)}}))
                break
            default:
                return
        }
    }, [mediaId, timestamp])

    async function initApp() {
        function uInt8ArrayToString(array: Uint8Array) {
            return String.fromCharCode.apply(null, Array.from(array));
        }

        function base64DecodeUint8Array(input: string) {
            return Uint8Array.from(atob(input), c => c.charCodeAt(0));
        }

        function base64EncodeUint8Array(input: Uint8Array) {
            return btoa(uInt8ArrayToString(input));
        }

        shaka.polyfill.installAll();

        const config = {
            // streaming: { jumpLargeGaps: true, stallEnabled: false },
            streaming: { stallEnabled: false },
            drm: {servers: streamDetails?.drms},
            ...(streamDetails?.audio?.find((audio: ChannelVodAudioSubtitleEntity) => audio.default === 1) ? {preferredAudioLanguage: streamDetails.audio.find((audio: ChannelVodAudioSubtitleEntity) => audio.default === 1)!.role} : {}),
            ...(streamDetails?.subtitle?.find((subtitle: ChannelVodAudioSubtitleEntity) => subtitle.default === 1) ? {preferredTextLanguage: streamDetails.subtitle.find((subtitle: ChannelVodAudioSubtitleEntity) => subtitle.default === 1)!.role} : {}),
            // ...(selectedAudio ? { preferredAudioLanguage: selectedAudio.role } : {}),
            // ...(selectedSubtitle?.role
            //     ? { preferredTextLanguage: selectedSubtitle.role }
            //     : {}),
            ...(streamDetails?.offset ? {playRangeStart: streamDetails.offset} : {}),
            // ...(preferedVideoVariantItem?.thisIsAbr
            //     ? { abr: { enabled: true } }
            //     : {}),
        };

        // @ts-ignore
        await playerRef.current?.configure(config)
        // @ts-ignore
        playerRef.current?.configure('drm.advanced.com\\.apple\\.fps.serverCertificateUri', streamDetails?.fairPlayCertificateUrl);

        // @ts-ignore
        await playerRef.current.getNetworkingEngine().registerRequestFilter((type: any, request: { headers: { [x: string]: string; }; body: Iterable<number>; }) => {
            if (type == shaka.net.NetworkingEngine.RequestType.LICENSE) {
                request.headers["Authorization"] = `Bearer ${customerToken}`;
                request.headers["profilesId"] = btoa(selectedProfile!.profiles_id!.toString());
                request.headers["devicesType"] = btoa(getClientConfig().devicesType);

                const bitrate = [
                    // @ts-ignore
                    playerRef.current?.getVariantTracks()
                        .find((trackItem: { active: boolean; }) => trackItem.active)?.bandwidth,
                ].map((bitrateItem) => bitrateItem - 128000)[0];

                const wrapped = {
                    devices_hash: deviceHash,
                    devices_identification: deviceInfo,
                    edges_id: streamDetails?.edgesId,
                    offset: streamDetails?.offset,
                    // offset: 0,
                    // offset: (player.isLive() || isTvView // TODO: make sure you have offset for all purposes
                    //     ? getCatchUpOffset(shakaPlayerRef.current)
                    //     : parseInt(videoElRef.current.currentTime, 10)) ? (shakaPlayerRef.current.isLive() || isTvView
                    //     ? getCatchUpOffset(shakaPlayerRef.current)
                    //     : parseInt(videoElRef.current.currentTime, 10)) : 0,
                    rawLicense: isSafari ? base64EncodeUint8Array(new Uint8Array(request.body)) : shaka.util.Uint8ArrayUtils.toBase64(
                        new Uint8Array(request.body),
                        false
                    ),
                    timestamp: new Date().getTime() / 1000,
                    // timestamp: isTvView // TODO: DODĚLAT SPRÁVNĚ
                    //     ? getPlaybackTimestampForTvView(shakaPlayerRef.current)
                    //     : getUnixTime(new Date()),
                    version: getClientConfig().portalVersion,
                    audioLanguage: playerRef.current
                        // @ts-ignore
                        ?.getVariantTracks()
                        ?.find((trackItem: { active: boolean; }) => trackItem.active)?.language,
                    // @ts-ignore
                    ...(playerRef.current?.isTextTrackVisible()
                        ? {
                            subtitleLanguage: playerRef.current
                                // @ts-ignore
                                ?.getTextTracks()
                                ?.find((trackItem: { active: boolean; }) => trackItem.active)?.role,
                        }
                        : {}),
                    ...(bitrate ? {bitrate} : {}),
                    remoteChannelsUnicastId: streamDetails?.remoteChannelsUnicastId,
                };
                // console.log(wrapped, "wrapped")
                const wrappedJson = JSON.stringify(wrapped);
                request.body = shaka.util.StringUtils.toUTF8(wrappedJson);

            }
        });
        // @ts-ignore
        await playerRef.current?.getNetworkingEngine().registerResponseFilter(function (type: any, response: { data: any; }) {
            if (type == shaka.net.NetworkingEngine.RequestType.LICENSE) {
                const wrappedString = shaka.util.StringUtils.fromUTF8(response.data);
                const wrapped = JSON.parse(wrappedString);
                const rawLicense = wrapped.rawLicense;
                // console.error(shaka.util.Uint8ArrayUtils.fromBase64(rawLicense) == base64DecodeUint8Array(rawLicense));
                response.data = shaka.util.Uint8ArrayUtils.fromBase64(rawLicense);
            }
        });

        function onErrorEvent(err: CustomEvent) {
            onError(err.detail)
        }

        function onError(error: ShakaError) {
            console.error('Error code', error.code, 'object', error);
            if (error.code === 6007) { //LICENSE_REQUEST_FAILED
                // @ts-ignore
                error.data[0] && dispatch(addMwError(JSON.parse(error.data[0].data[2])))
            } else {
                dispatch(addMwError({status: 0, response: [`Shaka error: ${error.code}`]}))
            }
            if (error.severity === 2) {
                let currentBlacklistedEdges = getLocalStorage(STORAGE_BLACKLISTED_EDGES)
                if (currentBlacklistedEdges) {
                    let newBlacklistedEdges = JSON.parse(currentBlacklistedEdges).push({edgeId: streamDetails?.edgesId, time: Date.now()})
                    setLocalStorage(STORAGE_BLACKLISTED_EDGES, newBlacklistedEdges)
                } else {
                    setLocalStorage(STORAGE_BLACKLISTED_EDGES, JSON.stringify([{edgeId: streamDetails?.edgesId, time: Date.now()}]))
                }
            }
        }

        function loggerEvent(error: string) {
            console.log(error)
        }

        // @ts-ignore
        playerRef.current?.addEventListener("error", onErrorEvent);

        await streamDetails?.follow
            // @ts-ignore
            ? await playerRef.current?.load(streamDetails?.url, streamDetails.follow)
            // @ts-ignore
            : await playerRef.current?.load(streamDetails?.url);
    }

    async function loadPlayer() {
        initApp().then(() => {
            // @ts-ignore
            setVariantTrack(playerRef.current?.getVariantTracks().find((track: variantTrackType) => track.active))
            // @ts-ignore
            playerRef.current?.setTextTrackVisibility(streamDetails?.subtitle?.find((subtitle: ChannelVodAudioSubtitleEntity) => subtitle.default === 1))
            // @ts-ignore
            setSubtitleTrack(playerRef.current?.getTextTracks()?.find((trackItem: variantTrackType) => trackItem.active))
            if (videoRef.current && preferredOffset) videoRef.current.currentTime = Number(preferredOffset)
            videoRef.current?.play();
            if (getLocalStorage("playerMute")) {
                if (JSON.parse(getLocalStorage("playerMute")!).playerMute && JSON.parse(getLocalStorage("playerMute")!).expiration > Date.now()) {
                    if (videoRef.current) videoRef.current.volume = 0
                }
            }
        })

        const eventManager = new shaka.util.EventManager();
        eventManager.listen(playerRef.current, `buffering`, (event: Event) => {
            // @ts-ignore
            if (event.buffering == true) {
                setIsBuffering(true)
                // eventManager.unlisten(playerRef.current, 'buffering');
            }
            // @ts-ignore
            if (event.buffering == false) {
                setIsBuffering(false)
                // eventManager.unlisten(playerRef.current, 'buffering');
            }
        });

        if (streamDetails?.adVmap) {
            // @ts-ignore
            const adManager = playerRef.current.getAdManager();
            adManager.initClientSide(adsRef.current, videoRef.current);

            // @ts-ignore
            const adsRequest = new google.ima.AdsRequest();

            adsRequest.adTagUrl = streamDetails.adVmap
            adManager.requestClientSideAds(adsRequest);

            adManager.addEventListener(shaka.ads.AdManager.AD_STARTED, (e: any) => {
                adVideoElmRef.current = e['ad']
                setAdsInProgress(true)
            });
            adManager.addEventListener(shaka.ads.AdManager.AD_STOPPED, () => {
                setAdsInProgress(false)
            });
            adManager.addEventListener(shaka.ads.AdManager.AD_CLICKED, () => {
                // @ts-ignore
                adVideoElmRef.current?.play()
            })
        }

        videoRef.current?.addEventListener("loadeddata", () => {
            if (streamDetails?.offset && videoRef.current?.currentTime && streamDetails?.type === CONTENT_TYPE_ENUM.LIVE) videoRef.current.currentTime = videoRef.current.currentTime - streamDetails.offset;
        })
    }

    useEffect(() => {
        if (!streamDetails) return

        const initStream = () => {
            playerRef.current = new shaka.Player(videoRef.current);

            if (streamDetails.isPinProtected) {
                setConfirmPinDialogOpen(true)
            }
            loadPlayer()
        }

        if (playerRef.current) {
            // @ts-ignore
            playerRef.current?.destroy().then(() => {
                initStream()
            })
        } else {
            initStream()
        }
    }, [streamDetails]);

    useEffect(() => {
        // @ts-ignore
        confirmPinDialogOpen ? adVideoElmRef.current?.pause() : adVideoElmRef.current?.play()
    }, [confirmPinDialogOpen, adVideoElmRef.current])

    const setVariantTrackQuality = (e: MouseEvent<HTMLDivElement>, newBitrate: number) => {
        e.stopPropagation()
        if (newBitrate === 0) {
            // @ts-ignore
            playerRef.current?.configure({abr: {enabled: true}})
            setIsAbrEnabled(true)
        } else {
            // @ts-ignore
            playerRef.current?.configure({abr: {enabled: false}})
            setIsAbrEnabled(false)
            // @ts-ignore
            const currentVariantTrack = playerRef.current?.getVariantTracks().find((track: variantTrackType) => track.active)
            // @ts-ignore
            const newVariantTrack = playerRef.current?.getVariantTracks().find((track: variantTrackType) => track.videoBandwidth === newBitrate * 1000 && track.language === currentVariantTrack.language)
            // @ts-ignore
            playerRef.current?.selectVariantTrack(newVariantTrack, true)
            setVariantTrack(newVariantTrack)
        }
        setSettingsOpen(false)
    }

    const handleMouseMove = () => {
        setShowController(true)
        !sideControllersPanel && resetTimeout()
    }

    const handleShowActionIcon = (url: string) => {
        setActionIconSrc(url)
        setActionIconVisible(true)
        setTimeout(() => {
            setActionIconVisible(false)
        }, 500);
    }

    const handlePlayButton = (e?: MouseEvent<HTMLButtonElement>) => {
        e?.stopPropagation()
        resetTimeout()
        videoRef.current?.paused ? videoRef.current?.play() : videoRef.current?.pause()
        handleShowActionIcon(videoRef.current?.paused ? "/images/icons/icon_pause_player.svg" : "/images/icons/icon_play_player.svg");
    }

    const handleClickOnPlayer = (e: MouseEvent<HTMLDivElement>) => {
        if (mwError) return
        e.stopPropagation()
        if (adsInProgress) return
        // close Side panel on outside click
        if (sideControllersPanel !== undefined) {
            setSideControllersPanel(undefined)
            return
        }
        if (!subtitlesOpen && !settingsOpen) {
            videoRef.current?.paused ? videoRef.current?.play() : videoRef.current?.pause()
            handleShowActionIcon(videoRef.current?.paused ? "/images/icons/icon_pause_player.svg" : "/images/icons/icon_play_player.svg");
        } else {
            setSubtitlesOpen(false)
            setSettingsOpen(false)
        }
    }

    const handleRewind = (e?: MouseEvent<HTMLButtonElement>) => {
        e?.stopPropagation()
        resetTimeout()
        videoRef.current!.currentTime = videoRef.current!.currentTime - 10
        handleShowActionIcon("/images/icons/icon_rewind10.svg");
    }

    const handleForward = (e?: MouseEvent<HTMLButtonElement>) => {
        e?.stopPropagation()
        resetTimeout()
        videoRef.current!.currentTime = videoRef.current!.currentTime + 10
        handleShowActionIcon("/images/icons/icon_forward10.svg");
    }

    const handleVolumeChange = (newValue: number | number[]) => {
        resetTimeout()
        if (newValue >= 100) {
            if (videoRef.current!.volume === 1) return
            videoRef.current!.volume = 1
            setVolume(1)
            handleShowActionIcon("/images/icons/icon_sound.svg")
            setLocalStorage("playerMute", JSON.stringify({
                playerMute: false,
                expiration: getUnixTime(addDays(Date.now(), 1)) * 1000
            }))
        } else if (newValue <= 0) {
            if (videoRef.current!.volume === 0) return
            videoRef.current!.volume = 0
            setVolume(0)
            handleShowActionIcon("/images/icons/icon_sound_mute.svg")
            setLocalStorage("playerMute", JSON.stringify({
                playerMute: true,
                expiration: getUnixTime(addDays(Date.now(), 1)) * 1000
            }))
        } else {
            if ((newValue as number / 100) > videoRef.current!.volume) {
                handleShowActionIcon("/images/icons/icon_sound.svg")
            } else {
                handleShowActionIcon("/images/icons/icon_sound_down.svg")
            }
            videoRef.current!.volume = newValue as number / 100
            setVolume(newValue as number / 100)
            setLocalStorage("playerMute", JSON.stringify({
                playerMute: false,
                expiration: getUnixTime(addDays(Date.now(), 1)) * 1000
            }))
        }
    }

    const goBackFromPinDialog = () => {
        customHistory.action === "REPLACE" ? navigate("/") : navigate(-1)
    }

    const onPinConfirmed = () => {
        setConfirmPinDialogOpen(false)
        videoRef.current?.play()
    }

    const handleReplay = () => {
        setEndOfPlaybackOverlay(false)
        videoRef.current?.play();
    }

    useKey('ArrowUp', () => handleVolumeChange((videoRef.current!.volume * 100) + 10));
    useKey('ArrowDown', () => handleVolumeChange((videoRef.current!.volume * 100) - 10));
    useKey("ArrowLeft", () => handleRewind());
    useKey("ArrowRight", () => handleForward())
    useKey(" ", () => handlePlayButton())

    return (
        <PlayerContainer ref={containerRef} onMouseMove={handleMouseMove} onClick={handleClickOnPlayer}>
            <GlobalStyle/>
            {<AdContainer ref={adsRef}/>}
            {channels?.find(channel => channel.channels_id === currentlyPlaying?.channels_id)?.channels_type === CHANNEL_TYPE_ENUM.RADIO &&
              <RadioLogo src={currentlyPlaying.channels_logo} alt={""}/>
            }
            {lockedText
                ? <StyledHeadline>{lockedText}</StyledHeadline>
                : mwError
                    ? <PlayerError/>
                    : <StyledVideo
                        id="video"
                        ref={videoRef}
                        onPlaying={confirmPinDialogOpen ? () => videoRef.current?.pause() : undefined}
                        poster={(streamDetailsFetchFailed && outageImage)
                            ? outageImage
                            : lockedImage
                                ? lockedImage
                                : ""}
                        autoPlay/>}
            <ActionIconContainer>
                <ActionIcon visible={actionIconVisible}>
                    <img src={actionIconSrc}
                         alt={""}
                    />
                </ActionIcon>
            </ActionIconContainer>
            {endOfPlaybackOverlay &&
                <PlayerEndOfPlaybackOverlay {...{handleReplay, playerType, setEndOfPlaybackOverlay, streamDetails}} />
            }
            {showController && !confirmPinDialogOpen && <PlayerControllers {...{
                adsInProgress,
                containerRef,
                currentTime,
                endOfPlaybackOverlay,
                handleForward,
                handlePlayButton,
                handleRewind,
                handleShowActionIcon,
                handleVolumeChange,
                isAbrEnabled,
                liveEdge,
                mediaId,
                playerType,
                playerRef,
                setCurrentTime,
                setSubtitleTrack,
                setVariantTrack,
                setVariantTrackQuality,
                sideControllersPanel,
                setSideControllersPanel,
                streamDetails,
                subtitleTrack,
                uptimeThumbnails,
                variantTrack,
                volume,
                videoRef
            }}/>}
            {isBuffering && !streamDetailsFetchFailed && !lockedImage && !lockedText && !confirmPinDialogOpen && !adsInProgress &&
              <LoadingSpinner gray={true}/>}
            {confirmPinDialogOpen && selectedProfile &&
                (selectedProfile.profiles_pin
                    ? <PinDialog
                        solidBackground={true}
                        onClose={goBackFromPinDialog}
                        onConfirm={onPinConfirmed}
                        profileToConfirmWithPin={selectedProfile}
                    />
                    : <CreatePinDialog onClose={goBackFromPinDialog}/>)
            }
            {onVideoMessage && onVideoMessage.showUntil >= Date.now() &&
              <OnVideoMessage>{onVideoMessage.message}</OnVideoMessage>}
        </PlayerContainer>
    )
};

export default Player;
