import React from 'react';
import styled from "styled-components";
import {useAppSelector} from "@motv-webapp/redux";
import {COLOR_NEUTRAL_90} from "@motv-webapp/lib";
import {channel_epg} from "@motv-webapp/redux";
import SideControllersEpgItem from "./SideControllersEpgItem";
import SideControllersNowPlaying from "./SideControllersNowPlaying";
import {universal_selectedItem} from "@motv-webapp/redux";
import {RecommendationCardEntity} from "@motv-webapp/lib";
import {getUnixTime} from "date-fns";

const SideControllersEpgContainer = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    height: "calc(100% - 32px)",
    margin: "32px 16px 0 16px"
}));

const Divider = styled.div(props => ({
    background: COLOR_NEUTRAL_90,
    height: 2,
    marginBottom: 24,
    marginTop: 14,
    width: "100%",
}));

const EpgList = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    gap: 8,
    overflowY: "auto",
    paddingBottom: 16,
}));

type Props = {};

const SideControllersEpg = ({}: Props) => {
    const epgItems = useAppSelector(channel_epg)
    const currentlyPlaying = useAppSelector(universal_selectedItem) as RecommendationCardEntity

    return (
        <SideControllersEpgContainer>
            <SideControllersNowPlaying />
            <Divider/>
            <EpgList>
                {epgItems && epgItems.filter(item => item.channels_id === currentlyPlaying.channels_id).sort((a, b) => getUnixTime(Date.parse(a.start!)) - getUnixTime(Date.parse(b.start!))).map((epgItem) => {
                    return (
                        <SideControllersEpgItem key={epgItem.id} {...{epgItem}} />
                    )
                })}
            </EpgList>
        </SideControllersEpgContainer>
    );
};

export default SideControllersEpg;
