import React from "react";
import {motion} from "framer-motion";
import styled from "styled-components";
import {COLOR_OFFWHITE_90, TYPEFACE_WEB_CAPTION_2_BOLD} from "@motv-webapp/lib";

const ProgressCircleContainer = styled.div((props: { size: number }) => ({
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    width: props.size
}));

const Counter = styled.div((props: { typeface: object, color:string }) => ({
    ...props.typeface && props.typeface,
    color: props.color,
    position: "absolute" as "absolute",
}));

const SvgContainer = styled.div((props: { size: number }) => ({
    height: props.size
}));

const SvgProgress = styled.svg((props: { size: number }) => ({
    height: props.size,
    marginLeft: -props.size,
    overflow: "visible",
    position: "absolute" as "absolute",
    transform: "rotate(-90deg)",
    width: props.size,
}));

type Props = {
    percents: number
    counter?: boolean,
    strokeColor?: string,
    emptyStrokeColor?: string,
    emptyStrokeOpacity?: number,
    duration?: number,
    delay?: number,
    size?: number,
    strokeWidth?: number,
    typeface?: object
    color?:string
}
const ProgressCircle = ({
                            percents,
                            counter = true,
                            strokeColor = "#0F0FFF",
                            emptyStrokeColor = strokeColor,
                            emptyStrokeOpacity = 0.25,
                            duration = 1,
                            delay = 0,
                            size = 50,
                            strokeWidth = 8,
                            typeface = TYPEFACE_WEB_CAPTION_2_BOLD,
                            color = COLOR_OFFWHITE_90,
                        }: Props) => {
    const radius = 45;
    const circumference = Math.ceil(2 * Math.PI * radius);
    const fillPercents = Math.abs(
        Math.ceil((circumference / 100) * (percents - 100))
    );

    const transition = {
        duration: duration,
        delay: delay,
        ease: "easeIn"
    };

    const variants = {
        hidden: {
            strokeDashoffset: circumference,
            transition
        },
        show: {
            strokeDashoffset: fillPercents,
            transition
        }
    };

    return (
        <ProgressCircleContainer size={size}>
            {counter && (
                <Counter typeface={typeface} color={color}>
                    {percents}%
                </Counter>
            )}
            <SvgContainer size={size}>
                <svg
                    viewBox="0 0 100 100"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width={size}
                    height={size}
                >
                    <circle
                        cx="50"
                        cy="50"
                        r={radius}
                        className="circle"
                        strokeWidth={strokeWidth}
                        stroke={emptyStrokeColor}
                        strokeOpacity={emptyStrokeOpacity}
                        fill="transparent"
                    />
                </svg>
                <SvgProgress
                    viewBox="0 0 100 100"
                    size={size}
                >
                    <motion.circle
                        cx="50"
                        cy="50"
                        r={radius}
                        strokeWidth={strokeWidth}
                        stroke={strokeColor}
                        fill="transparent"
                        strokeDashoffset={fillPercents}
                        strokeDasharray={circumference}
                        strokeLinecap={"round"}
                        variants={variants}
                        initial="hidden"
                        animate="show"
                    />
                </SvgProgress>
            </SvgContainer>
        </ProgressCircleContainer>
    );
};

export default ProgressCircle
