import React, {ChangeEvent, Dispatch, KeyboardEvent, SetStateAction} from 'react';
import styled from "styled-components";
import {
    COLOR_DANGER_100,
    COLOR_DANGER_15,
    COLOR_INPUT_FIELD_GREY,
    COLOR_OFFWHITE,
    COLOR_OFFWHITE_50,
} from "@motv-webapp/lib";
import {FormattedMessage} from "react-intl";
import {AnimatePresence, motion} from "framer-motion";
import {INPUT_FIELD_TYPE_ENUM} from "@motv-webapp/lib";
import {useUniqueId} from "@dnd-kit/utilities";

const InputContainer = styled.div(props => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
}));

const CheckboxContainer = styled.div(props => ({
    alignSelf: "flex-end",
    display: "flex",
    flexDirection: "row",
}));

const StyledLabel = styled.label(props => ({}));

const StyledInput = styled.input((props: { errorMessageId: string | undefined, disabled: boolean, marginBottom: string | number, type: INPUT_FIELD_TYPE_ENUM }) => ({
    background: (props.errorMessageId && props.errorMessageId !== "") ? COLOR_DANGER_15 : COLOR_INPUT_FIELD_GREY,
    border: 0,
    borderRadius: "10px",
    boxShadow: props.type === INPUT_FIELD_TYPE_ENUM.CHECKBOX ? "0" : "3px 3px 10px 1px rgba(0, 0, 0, 0.2)",
    color: props.disabled ? COLOR_OFFWHITE_50 : COLOR_OFFWHITE,
    fontFamily: "Roboto",
    fontWeight: 500,
    height: props.type === INPUT_FIELD_TYPE_ENUM.CHECKBOX ? "auto" : "3rem",
    marginBottom: props.marginBottom,
    padding: "0 0.75rem",
    zIndex: 1,
}));

const ErrorMessage = styled(motion.p)(props => ({
    color: COLOR_DANGER_100,
    fontSize: "0.8rem",
    fontWeight: 700,
    marginTop: "-0.7rem",
}));

const ErrorImage = styled(motion.img)((props: { inputWithController: boolean }) => ({
    height: "2rem",
    width: "2rem",
    position: "absolute" as "absolute",
    right: props.inputWithController ? 30 : 10,
    top: "0.5rem",

}));

type Props = {
    autocomplete?: string
    autofocus?: boolean
    dataTestid?: string
    defaultValue?: string
    defaultChecked?: boolean
    disabled?: boolean
    errorMessageId: string | undefined
    id?: string
    marginBottom?: string | number
    maxLength?: number
    onChange?: Dispatch<SetStateAction<string>> | ((value: string) => void)
    onChangeChecked?: Dispatch<SetStateAction<boolean>> | ((value: boolean) => void)
    onEnter?: () => void
    placeholder: string
    type: INPUT_FIELD_TYPE_ENUM
    value?: string
    checked?: boolean
};

const Input = ({
                   autocomplete = "off",
                   autofocus = false,
                   dataTestid,
                   defaultChecked,
                   defaultValue,
                   disabled = false,
                   errorMessageId,
                   id,
                   marginBottom = "1rem",
                   maxLength,
                   onChange,
                   onChangeChecked,
                   onEnter,
                   placeholder,
                   type,
                   value,
                   checked,
               }: Props) => {
    const uniqueId = useUniqueId("checkbox")

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        type === INPUT_FIELD_TYPE_ENUM.CHECKBOX
            ? onChangeChecked && onChangeChecked(e.target.checked)
            : onChange && onChange(e.target.value)
    }

    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && onEnter) {
            onEnter()
        }
    }
    return (
        <InputContainer>
            {type === INPUT_FIELD_TYPE_ENUM.CHECKBOX
                && (<CheckboxContainer>
                    <StyledLabel htmlFor={uniqueId}>{placeholder}</StyledLabel>
                    <StyledInput
                        data-testid={dataTestid}
                        disabled={disabled}
                        defaultChecked={defaultChecked}
                        errorMessageId={errorMessageId}
                        id={uniqueId}
                        marginBottom={marginBottom}
                        onChange={handleChange}
                        placeholder={placeholder}
                        type={type}
                        checked={checked}
                    />
                </CheckboxContainer>)
            }
            {type === INPUT_FIELD_TYPE_ENUM.DATE
                && <StyledInput
                data-testid={dataTestid}
                disabled={disabled}
                defaultValue={defaultValue}
                errorMessageId={errorMessageId}
                marginBottom={marginBottom}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => e.target.type = INPUT_FIELD_TYPE_ENUM.TEXT}
                onChange={handleChange}
                onFocus={(e: React.FocusEvent<HTMLInputElement>) => e.target.type = INPUT_FIELD_TYPE_ENUM.DATE}
                onKeyPress={handleKeyPress}
                placeholder={placeholder}
                type={defaultValue ? INPUT_FIELD_TYPE_ENUM.DATE : INPUT_FIELD_TYPE_ENUM.TEXT}
              />
            }
            {(type !== INPUT_FIELD_TYPE_ENUM.CHECKBOX && type !== INPUT_FIELD_TYPE_ENUM.DATE)
                && <StyledInput autoComplete={autocomplete}
                                autoFocus={autofocus}
                                data-testid={dataTestid}
                                defaultValue={defaultValue}
                                disabled={disabled}
                                errorMessageId={errorMessageId}
                                id={id}
                                marginBottom={marginBottom}
                                maxLength={maxLength}
                                onChange={handleChange}
                                onKeyPress={handleKeyPress}
                                placeholder={placeholder}
                                type={type}
                                value={value}
              />
            }
            <AnimatePresence>
                {errorMessageId && (
                    <>
                        <ErrorMessage
                            data-testid={`${dataTestid}ErrorMessage`}
                            initial={{y: -20, height: 0}}
                            animate={{y: 0, height: "auto"}}
                            exit={{y: -20, height: 0}}
                            transition={{type: "tween", duration: 0.4}}
                        >
                            <FormattedMessage id={errorMessageId} defaultMessage={errorMessageId}/>
                        </ErrorMessage>
                        {type !== INPUT_FIELD_TYPE_ENUM.CHECKBOX && <ErrorImage
                          initial={{opacity: 0}}
                          animate={{opacity: 1}}
                          exit={{opacity: 0}}
                          transition={{duration: 0.4}}
                          src={"/images/icons/icon_error.png"} alt={""}
                          inputWithController={type === INPUT_FIELD_TYPE_ENUM.NUMBER || type === INPUT_FIELD_TYPE_ENUM.DATE}
                        />}
                    </>
                )}
            </AnimatePresence>
        </InputContainer>
    );
};

export default Input;
