import React from 'react';
import {RecommendationRowEntity} from "@motv-webapp/lib";
import SingleRow from "./SingleRow";
import {RECOMMENDATION_ENGINE_ROW_STYLE_FULL_WIDTH} from "@motv-webapp/lib";
import MediaCardFullWidth from "../MediaCard/MediaCardFullWidth";
import {RECOMMENDATION_ENGINE_ROW_TYPE_ENUM} from "@motv-webapp/lib";
import {FormattedMessage} from "react-intl";
import styled from "styled-components";
import {COLOR_WHITE} from "@motv-webapp/lib";

const MainRowsContainer = styled.div(props =>({
    marginBottom: 72
}));

const HomeHeadline = styled.h1(props => ({
    color: COLOR_WHITE,
    marginBottom: 32,
    marginLeft: "4rem",
    marginTop: 100,
    "@media (max-width: 800px)": {
        marginLeft: 32,
    }
}));

type Props = {
    homeHeadline?: boolean
    disableHeaderSpacing?: boolean
    rows: Array<RecommendationRowEntity>
    vodLazyRows?: boolean
    handleClickGenreTitle?: (genresId:number,title:string) => void
    showGenreDetail?: boolean
};

const MainRows = ({homeHeadline = false, disableHeaderSpacing = false, rows, vodLazyRows = false, handleClickGenreTitle, showGenreDetail = false}: Props) => {
    return (
        <MainRowsContainer>
            {homeHeadline && rows[0] && rows[0].style !== RECOMMENDATION_ENGINE_ROW_STYLE_FULL_WIDTH && (
                <HomeHeadline>
                    <FormattedMessage id={"section_home"} defaultMessage={"Home"}/>
                </HomeHeadline>
            )}
            {rows.map((row, index) => {
                if (row.style === RECOMMENDATION_ENGINE_ROW_STYLE_FULL_WIDTH && row.data) {
                    return (<MediaCardFullWidth key={row.id} mediaCard={row.data[0]}/>)
                } else if (row.type === RECOMMENDATION_ENGINE_ROW_TYPE_ENUM.OFFSET_ROW) {
                    return
                } else {
                    return (
                        <SingleRow key={row.id}
                                   headerSpacing={row.style !== RECOMMENDATION_ENGINE_ROW_STYLE_FULL_WIDTH && index === 0 && !disableHeaderSpacing}
                                   handleClickGenreTitle={handleClickGenreTitle}
                                   showGenreDetail={showGenreDetail}
                                   {...{row, vodLazyRows}}
                        />
                    )
                }
            })}
        </MainRowsContainer>
    );
};

export default MainRows;
