import React from "react";
import styled from "styled-components";
import {
    COLOR_OFFWHITE_90,
    COLOR_WHITE,
    TYPEFACE_BODY_MEDIUM,
    TYPEFACE_HEADLINE,
    TYPEFACE_HEADLINE_2, TYPEFACE_TITLE
} from "@motv-webapp/lib";

const ErrorContainer = styled.div(props => ({
    alignItems:"center",
    display: "flex",
    flexDirection: "column",
    justifyContent:"center",
    "& img": {
        height: "320px",
        margin:"120px 0 32px 0",
    },
    "@media (max-height: 800px)": {
        "& img": {
            height: "220px",
        },
    },
    "@media (max-width: 870px)": {
        marginBottom: 80,
        "& img": {
            height: "160px",
        },
    }
}));

const LabelHeadline = styled.div(props => ({
    ...TYPEFACE_TITLE,
    color:COLOR_WHITE,
    textAlign:"center",
    margin:"0 64px 0 64px",
    "@media (max-width: 870px)": {
        ...TYPEFACE_HEADLINE,
    }
}));

const LabelMessage = styled.div(props => ({
    ...TYPEFACE_HEADLINE_2,
    color:COLOR_OFFWHITE_90,
    margin:"16px 64px 0 64px",
    textAlign:"center",
    "@media (max-width: 870px)": {
        ...TYPEFACE_BODY_MEDIUM,
    }
}));

type Props = {
    src: string
    labelHeadline: string
    labelMessage?:string
}

const Error = ({src, labelHeadline, labelMessage}: Props) => {

    return (
        <ErrorContainer>
            <img src={src} alt={""}/>
            <LabelHeadline>{labelHeadline}</LabelHeadline>
            {labelMessage && <LabelMessage>{labelMessage}</LabelMessage>}
        </ErrorContainer>
    )

}

export default Error;
