import React from 'react';
import styled from "styled-components";
import {useAppSelector} from "@motv-webapp/redux";
import {vendor_vendors_accent_color} from "@motv-webapp/redux";
import {
    COLOR_NEUTRAL,
    COLOR_NEUTRAL_90,
    COLOR_OFFWHITE,
    COLOR_OFFWHITE_50,
    COLOR_OFFWHITE_70,
    COLOR_OFFWHITE_80,
    COLOR_OFFWHITE_90,
    COLOR_PRIMARY_100,
    COLOR_SECONDARY,
    COLOR_TERTIARY,
    COLOR_TERTIARY_90,
    SVG_FILTER_NEUTRAL,
    SVG_FILTER_OFFWHITE_50,
    SVG_FILTER_OFFWHITE_70,
    SVG_FILTER_TERTIARY_90,
} from "@motv-webapp/lib";
import Color from "color";
import {motion} from "framer-motion";
import {BUTTON_SIZE_ENUM, BUTTON_TYPE_ENUM} from "@motv-webapp/lib";
import {TYPEFACE_BODY_MEDIUM, TYPEFACE_CAPTION_BOLD, TYPEFACE_WEB_BODY_2_MEDIUM} from "@motv-webapp/lib";

const StyledButton = styled(motion.button)((props: { $accentColor: string | null | undefined, $buttonSize: BUTTON_SIZE_ENUM, $buttonType: BUTTON_TYPE_ENUM, disabled: boolean }) => ({
    ...(props.$buttonSize === BUTTON_SIZE_ENUM.SMALL
        ? TYPEFACE_CAPTION_BOLD
        : props.$buttonSize === BUTTON_SIZE_ENUM.MEDIUM
            ? TYPEFACE_WEB_BODY_2_MEDIUM
            : TYPEFACE_BODY_MEDIUM),
    alignItems: "center",
    backgroundColor:
        props.$buttonType === BUTTON_TYPE_ENUM.OFFWHITE
            ? props.disabled ? COLOR_OFFWHITE_70 : COLOR_OFFWHITE
            : props.$buttonType === BUTTON_TYPE_ENUM.SECONDARY
                ? props.disabled ? COLOR_TERTIARY_90 : COLOR_SECONDARY
                : props.$buttonType === BUTTON_TYPE_ENUM.TERTIARY
                    ? "transparent"
                    : props.disabled
                        ? props.$accentColor
                            ? Color(props.$accentColor).darken(0.5).string()
                            : Color(COLOR_PRIMARY_100).darken(0.5).string()
                        : props.$accentColor
                            ? props.$accentColor
                            : COLOR_PRIMARY_100,
    border: props.$buttonType === BUTTON_TYPE_ENUM.SECONDARY
        ? `1px solid ${COLOR_TERTIARY_90}`
        : 0,
    borderRadius: 24,
    color:
        props.$buttonType === BUTTON_TYPE_ENUM.OFFWHITE
            ? props.disabled ? COLOR_TERTIARY_90 : COLOR_NEUTRAL
            : props.$buttonType === BUTTON_TYPE_ENUM.SECONDARY
                ? props.disabled ? COLOR_OFFWHITE_70 : COLOR_OFFWHITE
                : props.disabled ? COLOR_OFFWHITE_50 : COLOR_OFFWHITE, // VENDOR and TERTIARY have dame colors
    cursor: props.disabled ? "default" : "pointer",
    display: "flex",
    gap: 4,
    height: props.$buttonSize === BUTTON_SIZE_ENUM.SMALL
        ? 32
        : props.$buttonSize === BUTTON_SIZE_ENUM.MEDIUM
            ? 40
            : 48,
    justifyContent: "center",
    padding: props.$buttonSize === BUTTON_SIZE_ENUM.SMALL
        ? "8px 16px"
        : props.$buttonSize === BUTTON_SIZE_ENUM.MEDIUM
            ? "12px 24px"
            : "12px 32px",
    transition: "background-color 0.2s",
    "& img": {
        filter:
            props.$buttonType === BUTTON_TYPE_ENUM.OFFWHITE
                ? props.disabled ? SVG_FILTER_TERTIARY_90 : SVG_FILTER_NEUTRAL
                : props.$buttonType === BUTTON_TYPE_ENUM.SECONDARY
                    ? props.disabled ? SVG_FILTER_OFFWHITE_70 : "none"
                    : props.disabled ? SVG_FILTER_OFFWHITE_50 : "none", // VENDOR and TERTIARY have dame colors
        height: props.$buttonSize === BUTTON_SIZE_ENUM.LARGE ? 24 : 16,
    }
}));

type Props = {
    afterImage?: string
    beforeImage?: string
    buttonSize?: BUTTON_SIZE_ENUM
    buttonType?: BUTTON_TYPE_ENUM
    dataTestId?: string
    disabled?: boolean
    label: string
    onClick: () => void
    style?: object
};

const PrimaryButton = ({
                           afterImage,
                           beforeImage,
                           buttonSize = BUTTON_SIZE_ENUM.LARGE,
                           buttonType = BUTTON_TYPE_ENUM.VENDOR,
                           dataTestId,
                           disabled = false,
                           label,
                           onClick,
                           style
                       }: Props) => {
    const accentColor = useAppSelector(vendor_vendors_accent_color)

    return (
        <StyledButton
            data-testid={dataTestId}
            whileHover={{
                backgroundColor:
                    buttonType === BUTTON_TYPE_ENUM.OFFWHITE
                        ? disabled ? COLOR_OFFWHITE_70 : COLOR_OFFWHITE_90
                        : buttonType === BUTTON_TYPE_ENUM.SECONDARY
                            ? disabled ? COLOR_TERTIARY_90 : COLOR_NEUTRAL_90
                            : buttonType === BUTTON_TYPE_ENUM.TERTIARY
                                ? disabled ? "transparent" : COLOR_NEUTRAL_90
                                : disabled
                                    ? accentColor
                                        ? Color(accentColor).darken(0.5).string()
                                        : Color(COLOR_PRIMARY_100).darken(0.5).string()
                                    : accentColor
                                        ? Color(accentColor).darken(0.1).string()
                                        : Color(COLOR_PRIMARY_100).darken(0.1).string(),
            }}
            whileTap={{
                backgroundColor:
                    buttonType === BUTTON_TYPE_ENUM.OFFWHITE
                        ? disabled ? COLOR_OFFWHITE_70 : COLOR_OFFWHITE_80
                        : buttonType === BUTTON_TYPE_ENUM.SECONDARY
                            ? disabled ? COLOR_TERTIARY_90 : COLOR_TERTIARY
                            : buttonType === BUTTON_TYPE_ENUM.TERTIARY
                                ? disabled ? "transparent" : COLOR_TERTIARY
                                : disabled
                                    ? accentColor
                                        ? Color(accentColor).darken(0.5).string()
                                        : Color(COLOR_PRIMARY_100).darken(0.5).string()
                                    : accentColor
                                        ? Color(accentColor).darken(0.2).string()
                                        : Color(COLOR_PRIMARY_100).darken(0.2).string(),
            }}
            $accentColor={accentColor}
            $buttonSize={buttonSize}
            $buttonType={buttonType}
            {...{disabled, onClick, style}}
        >
            {beforeImage &&
              <img src={beforeImage} alt={""}/>
            }
            {label}
            {afterImage &&
              <img src={afterImage} alt={""}/>
            }
        </StyledButton>
    );
};

export default PrimaryButton;
