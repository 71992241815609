"use strict";
exports.__esModule = true;
exports.removeSessionStorage = exports.getSessionStorage = exports.setSessionStorage = void 0;
var setSessionStorage = function (name, value) {
    window.sessionStorage.setItem(name, value);
};
exports.setSessionStorage = setSessionStorage;
var getSessionStorage = function (name) {
    return window.sessionStorage.getItem(name) ? window.sessionStorage.getItem(name) : undefined;
};
exports.getSessionStorage = getSessionStorage;
var removeSessionStorage = function (name) {
    window.sessionStorage.setItem(name, "");
};
exports.removeSessionStorage = removeSessionStorage;
