"use strict";
exports.__esModule = true;
exports.ERROR_CODE_SMS_INVALID_PASSWORD_FORMAT = exports.ERROR_CODE_SMS_UNKNOWN_LOST_PASSWORD_TOKEN = exports.ERROR_CODE_SMS_UNKNOWN_LOGIN = exports.ERROR_CODE_SMS_UNKNOWN_REGISTRATION_TOKEN = exports.ERROR_CODE_SMS_INCORRECT_USERNAME_PASSWORD = exports.ERROR_CODE_SMS_DUPLICATE_ACCOUNT = exports.ERROR_CODE_SMS_MUST_BE_EMAIL = exports.ERROR_CODE_SMS_SOMETHING = void 0;
exports.ERROR_CODE_SMS_SOMETHING = 0;
exports.ERROR_CODE_SMS_MUST_BE_EMAIL = 104;
exports.ERROR_CODE_SMS_DUPLICATE_ACCOUNT = 14000;
exports.ERROR_CODE_SMS_INCORRECT_USERNAME_PASSWORD = 14001;
exports.ERROR_CODE_SMS_UNKNOWN_REGISTRATION_TOKEN = 14003;
exports.ERROR_CODE_SMS_UNKNOWN_LOGIN = 14004;
exports.ERROR_CODE_SMS_UNKNOWN_LOST_PASSWORD_TOKEN = 14005;
exports.ERROR_CODE_SMS_INVALID_PASSWORD_FORMAT = 14008;
