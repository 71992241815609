import React, {MouseEvent, useState} from 'react';
import styled from "styled-components";
import {COLOR_WHITE} from "@motv-webapp/lib";
import {FormattedMessage} from "react-intl";
import {useWindowSize} from "react-use";

const StyledButton = styled.button((props: { size: number | string, imageFilter: string | undefined }) => ({
    alignItems: "center" as "center",
    background: "transparent" as "transparent",
    border: 0,
    borderRadius: 10,
    cursor: "pointer" as "pointer",
    display: "flex" as "flex",
    height: props.size,
    justifyContent: "center" as "center",
    padding: 0,
    position: "relative" as "relative",
    width: props.size,
    "& img": {
        height: props.size,
        filter: props.imageFilter ? props.imageFilter : "none"
    }
}));

const HoverLabel = styled.div(props => ({
    bottom: -22,
    color: COLOR_WHITE,
    fontSize: "0.8rem",
    fontWeight: 500,
    position: "absolute",
    whiteSpace: "nowrap",
}));

type Props = {
    disabled?: boolean
    hoverLabelId?: string
    hoverLabelDefaultMessage?: string
    imageFilter?: string
    img: string
    onClick?: (() => void) | ((e: MouseEvent<HTMLButtonElement | HTMLImageElement>) => void)
    size?: number | string
    style?: object
};

const IconButtonTransparent = ({
                                   disabled = false,
                                   hoverLabelId,
                                   hoverLabelDefaultMessage,
                                   imageFilter,
                                   img,
                                   onClick,
                                   size = 40,
                                   style
                               }: Props) => {
    const [hover, setHover] = useState(false)
    const {width} = useWindowSize()

    const handleMouseEnter = () => {
        setHover(true)
    }

    const handleMouseLeave = () => {
        setHover(false)
    }

    const handleImageClick = (e: MouseEvent<HTMLImageElement>) => {
        if (!onClick) return
        e.stopPropagation()
        onClick(e)
    }

    return (
        <StyledButton onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} {...{
            disabled,
            imageFilter,
            onClick,
            size,
            style
        }}>
            <img alt={""} src={img} onClick={handleImageClick}/>
            {/*
            The "width < 800" condition only applies to DetailHero component.
            Make sure the it does not affect other behaviour when reusing.
            */}
            {hoverLabelId && hoverLabelDefaultMessage && (hover || width < 800) && <HoverLabel>
              <FormattedMessage id={hoverLabelId} defaultMessage={hoverLabelDefaultMessage}/>
            </HoverLabel>
            }
        </StyledButton>
    );
};

export default IconButtonTransparent;
