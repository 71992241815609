import React, {ChangeEvent, FocusEvent, KeyboardEvent, useEffect, useState} from 'react';
import Dialog from "../Dialog/Dialog";
import styled, {createGlobalStyle} from "styled-components";
import {COLOR_WHITE} from "@motv-webapp/lib";
import PrimaryButton from "../Button/PrimaryButton";
import {useIntl} from "react-intl";
import {motion, useAnimation} from "framer-motion";
import {useKey} from "react-use";
import {useNavigate} from "react-router-dom";
import {ProfileEntity} from "@motv-webapp/lib";
import {customHistory} from "@motv-webapp/lib";
import {BUTTON_TYPE_ENUM} from "@motv-webapp/lib";

const GlobalStyle = createGlobalStyle`
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  ,
`

const CloseButton = styled.button(props => ({
    background: "transparent",
    border: 0,
    height: "2.6rem",
    padding: 0,
    position: "absolute",
    right: "0.9rem",
    top: "0.9rem",
    width: "2.6rem",
    "& img": {
        height: "2.6rem",
        width: "2.6rem",
    }
}));

const ProfileImage = styled.div((props: { avatar: string | undefined | null }) => ({
    alignSelf: "center",
    backgroundColor: "#000",
    backgroundImage: `url(${props.avatar})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    border: 0,
    borderRadius: "50%",
    height: "11.25rem",
    marginBottom: "5rem",
    padding: 0,
    position: "relative" as "relative",
    width: "11.25rem",
}));

const ProfileLabel = styled.div(props => ({
    bottom: "-2rem",
    display: "flex",
    justifyContent: "center",
    position: "absolute" as "absolute",
    width: "100%",
    "& > p": {
        color: "white",
        fontFamily: "Roboto",
        fontSize: "1rem",
        fontWeight: 500,
        margin: 0,
        padding: 0,
        whiteSpace: "nowrap",
    },
    "& > img": {
        height: "1rem",
    }
}));

const InputContainer = styled(motion.div)(props => ({
    alignSelf: "center",
    display: "flex",
    gap: "1.5rem",
    marginBottom: "2rem",
    "@media (max-width: 500px)": {
        gap: "1rem",
    }
}));

const PinInput = styled.input.attrs({"autoComplete": "off", "maxLength": 1, "type": "password", "pattern": "^[0-9]$"})(props => ({
    background: "transparent",
    border: `solid 3px ${COLOR_WHITE}`,
    borderRadius: "12px",
    color: COLOR_WHITE,
    boxSizing: "border-box",
    fontSize: "4rem",
    height: "5.3rem",
    padding: 0,
    textAlign: "center",
    width: "4rem",
    "@media (max-width: 500px)": {
        fontSize: "3rem",
        height: "4rem",
        width: "3rem",
    }
}));

type Props = {
    profileToConfirmWithPin: ProfileEntity | {}
    handleSelectProfile: (profile: ProfileEntity) => void
};

const EnterPinDialog = ({profileToConfirmWithPin, handleSelectProfile}: Props) => {
    const [pinOne, setPinOne] = useState<string>()
    const [pinTwo, setPinTwo] = useState<string>()
    const [pinThree, setPinThree] = useState<string>()
    const [pinFour, setPinFour] = useState<string>()
    const controls = useAnimation()
    const intl = useIntl()
    const navigate = useNavigate()

    const handleCloseDialog = () => {
        customHistory.action === "REPLACE" ? navigate("/") : navigate(-1)
    }

    const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
        switch (e.target.id) {
            case "PinOneInput":
                setPinOne(e.target.value)
                e.target.value.length > 0 && document.getElementById("PinTwoInput")?.focus()
                break
            case "PinTwoInput":
                setPinTwo(e.target.value)
                e.target.value.length > 0 ? document.getElementById("PinThreeInput")?.focus() : document.getElementById("PinOneInput")?.focus()
                break
            case "PinThreeInput":
                setPinThree(e.target.value)
                e.target.value.length > 0 ? document.getElementById("PinFourInput")?.focus() : document.getElementById("PinTwoInput")?.focus()
                break
            case "PinFourInput":
                setPinFour(e.target.value)
                e.target.value.length === 0 && document.getElementById("PinThreeInput")?.focus()
                break
            default:
                return
        }
    }

    useEffect(() => {
        if (
            (!pinOne || pinOne === "")
            || (!pinTwo || pinTwo === "")
            || (!pinThree || pinThree === "")
            || (!pinFour || pinFour === "")
        ) return
        handleSubmit()
    }, [pinOne, pinTwo, pinThree, pinFour]) // eslint-disable-line

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key !== "1"
            && e.key !== "2"
            && e.key !== "3"
            && e.key !== "4"
            && e.key !== "5"
            && e.key !== "6"
            && e.key !== "7"
            && e.key !== "8"
            && e.key !== "9"
            && e.key !== "0"
        ) e.preventDefault()
        if (e.key === "Backspace") {
            switch ((e.target as HTMLInputElement).id) {
                case "PinOneInput":
                    (document.getElementById("PinOneInput") as HTMLInputElement).value = "";
                    break
                case "PinTwoInput":
                    if ((e.target as HTMLInputElement).value.length === 0) {
                        (document.getElementById("PinOneInput") as HTMLInputElement).value = "";
                        document.getElementById("PinOneInput")?.focus()
                    } else {
                        (document.getElementById("PinTwoInput") as HTMLInputElement).value = "";
                    }
                    break
                case "PinThreeInput":
                    if ((e.target as HTMLInputElement).value.length === 0) {
                        (document.getElementById("PinTwoInput") as HTMLInputElement).value = "";
                        document.getElementById("PinTwoInput")?.focus()
                    } else {
                        (document.getElementById("PinThreeInput") as HTMLInputElement).value = "";
                    }
                    break
                case "PinFourInput":
                    if ((e.target as HTMLInputElement).value.length === 0) {
                        (document.getElementById("PinThreeInput") as HTMLInputElement).value = "";
                        document.getElementById("PinThreeInput")?.focus()
                    } else {
                        (document.getElementById("PinFourInput") as HTMLInputElement).value = "";
                    }
                    break
                default:
                    return
            }
        }
        if (e.key === "Enter") handleSubmit()
    }

    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
        e.target.select()
    }

    const handleSubmit = () => {
        if (
            (!pinOne || pinOne === "")
            || (!pinTwo || pinTwo === "")
            || (!pinThree || pinThree === "")
            || (!pinFour || pinFour === "")
            || ("profiles_pin" in profileToConfirmWithPin && (pinOne + pinTwo + pinThree + pinFour) !== profileToConfirmWithPin.profiles_pin)
        ) {
            controls.start({
                x: ["0%", "1%", "-1%", "0%", "1%", "-1%", "0%", "1%", "-1%", "0%", "1%", "-2%", "1%"],
                transition: {duration: 0.5},
            });
            (document.getElementById("PinOneInput") as HTMLInputElement).value = "";
            (document.getElementById("PinTwoInput") as HTMLInputElement).value = "";
            (document.getElementById("PinThreeInput") as HTMLInputElement).value = "";
            (document.getElementById("PinFourInput") as HTMLInputElement).value = "";
            document.getElementById("PinOneInput")?.focus();
            setPinOne("")
            setPinTwo("")
            setPinThree("")
            setPinFour("")
        } else {
            // @ts-ignore // this ts-ignore has to be because TS is not recognizing equality between {} and !!Object.keys(profileToConfirmWithPin).length)
            (profileToConfirmWithPin && !!Object.keys(profileToConfirmWithPin).length) && handleSelectProfile(profileToConfirmWithPin)
        }
    }

    useKey('Escape', handleCloseDialog);

    return (
        <Dialog {...{handleCloseDialog}}>
            <GlobalStyle/>
            <CloseButton onClick={handleCloseDialog}>
                <img src={"/images/icons/icon_close.svg"} alt={""}/>
            </CloseButton>
            {"image" in profileToConfirmWithPin && <ProfileImage avatar={profileToConfirmWithPin.image}>
              <ProfileLabel>
                <p>{profileToConfirmWithPin.profiles_name}</p>
                  {profileToConfirmWithPin.profiles_pin && profileToConfirmWithPin.profiles_login_requires_pin &&
                    <img src={"/images/icons/icon_lock.svg"} alt={"lock icon"}/>}
              </ProfileLabel>
            </ProfileImage>}
            <InputContainer animate={controls}>
                <PinInput id={"PinOneInput"} onChange={handleChange} onFocus={handleFocus} onKeyDown={handleKeyDown}
                          autoFocus/>
                <PinInput id={"PinTwoInput"} onChange={handleChange} onFocus={handleFocus} onKeyDown={handleKeyDown}/>
                <PinInput id={"PinThreeInput"} onChange={handleChange} onFocus={handleFocus} onKeyDown={handleKeyDown}/>
                <PinInput id={"PinFourInput"} onChange={handleChange} onFocus={handleFocus} onKeyDown={handleKeyDown}/>
            </InputContainer>
            <PrimaryButton
                buttonType={BUTTON_TYPE_ENUM.OFFWHITE}
                label={intl.formatMessage({id: "label_continue", defaultMessage: "Continue"})}
                onClick={handleSubmit}
            />
        </Dialog>
    );
};

export default EnterPinDialog;
