"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
exports.__esModule = true;
exports.recording_rows = exports.recordingReducer = exports.recordingGetRecordingsByProfileV2 = exports.recordingRemoveRecording = exports.recordingAddRecording = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var lib_1 = require("@motv-webapp/lib");
var recordingAPI_1 = require("./recordingAPI");
var universal_1 = require("../universal/universal");
var user_1 = require("../user/user");
var lib_2 = require("@motv-webapp/lib");
var initialState = {
    status: "idle",
    rows: undefined,
    lastUpdated: undefined,
    ignoreCache: false
};
exports.recordingAddRecording = (0, toolkit_1.createAsyncThunk)('recording/addRecording', function (body, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, recordingAPI_1.fetch_mw_recordingAddRecordingV2)(body)];
            case 1:
                response = _a.sent();
                if ((0, lib_1.isResponseSuccessful)(response)) {
                    thunkAPI.dispatch((0, universal_1.universalGetMyRecordings)());
                    thunkAPI.dispatch((0, user_1.customerGetData)({}));
                }
                return [2 /*return*/];
        }
    });
}); });
exports.recordingRemoveRecording = (0, toolkit_1.createAsyncThunk)('recording/removeRecording', function (body, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, recordingAPI_1.fetch_mw_recordingRemoveRecording)(body)];
            case 1:
                response = _a.sent();
                if ((0, lib_1.isResponseSuccessful)(response)) {
                    thunkAPI.dispatch((0, universal_1.universalGetMyRecordings)());
                    thunkAPI.dispatch((0, user_1.customerGetData)({}));
                }
                return [2 /*return*/];
        }
    });
}); });
exports.recordingGetRecordingsByProfileV2 = (0, toolkit_1.createAsyncThunk)('recording/getRecordingsByProfileV2', function (arg, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, recording, universal, response;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = thunkAPI.getState(), recording = _a.recording, universal = _a.universal;
                if (!(recording.lastUpdated
                    && (recording.lastUpdated + (lib_2.TIME_CONST_ONE_MINUTE * lib_2.TIME_CONST_NUMBER_OF_MINUTES_TO_CACHE)) > Date.now()
                    && (universal.invalidateCache ? recording.lastUpdated > universal.invalidateCache : true)
                    && !recording.ignoreCache
                    && !arg.ignoreCache)) return [3 /*break*/, 1];
                return [2 /*return*/, recording.rows];
            case 1: return [4 /*yield*/, (0, recordingAPI_1.fetch_mw_recordingGetRecordingsByProfileV2)()];
            case 2:
                response = _b.sent();
                return [4 /*yield*/, response.response];
            case 3: return [2 /*return*/, _b.sent()];
        }
    });
}); });
exports.recordingReducer = (0, toolkit_1.createSlice)({
    name: 'recording',
    initialState: initialState,
    reducers: {
    // removeAllErrors: () => initialState,
    },
    extraReducers: function (builder) {
        builder
            .addCase(exports.recordingAddRecording.fulfilled, function (state) {
            state.ignoreCache = true;
        })
            .addCase(exports.recordingRemoveRecording.fulfilled, function (state) {
            state.ignoreCache = true;
        })
            .addCase(exports.recordingGetRecordingsByProfileV2.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.recordingGetRecordingsByProfileV2.fulfilled, function (state, action) {
            state.status = 'idle';
            state.rows = action.payload;
            state.lastUpdated = Date.now();
            state.ignoreCache = false;
        });
    }
});
exports._b = _a = exports.recordingReducer.actions;
// Selectors
var recording_rows = function (state) { return state.recording.rows; };
exports.recording_rows = recording_rows;
exports["default"] = exports.recordingReducer.reducer;
