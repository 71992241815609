"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
exports.__esModule = true;
exports.fetch_mw_customerGetData = exports.fetch_mw_profileResetChannelOrder = exports.fetch_mw_profileUpdateChannelOrder = exports.fetch_mw_profileEventSubtitleChange = exports.fetch_mw_profileEventAudioChange = exports.fetch_mw_profileDeleteProfile = exports.fetch_mw_profileGetProfiles = exports.fetch_mw_profileUpdate = exports.fetch_mw_profileGetPredefinedProfileImages = exports.fetch_sms_devicesFacebookRegister = exports.fetch_sms_devicesAppleLogin = exports.fetch_sms_devicesAppleRegister = exports.fetch_sms_devicesGoogleRegister = exports.fetch_mw_customerGetQRCodeData = exports.fetch_mw_customerRequestLoginQRCode = exports.fetch_sms_devicesMotvApiChangePassword = exports.fetch_sms_devicesMotvApiRequestNewPassword = exports.fetch_sms_devicesMotvApiLoginWithToken = exports.fetch_sms_devicesMotvApiLoginV2 = exports.fetch_sms_devicesMotvApiLoginWithDevice = void 0;
var lib_1 = require("@motv-webapp/lib");
var config_1 = require("@motv-webapp/config");
var fetch_sms_devicesMotvApiLoginWithDevice = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, (0, lib_1.post)({
                url: "".concat((0, config_1.getClientConfig)().smsUrl, "/api/Devices/Motv/apiLoginWithDevice"),
                smsPost: true,
                body: body
            })];
    });
}); };
exports.fetch_sms_devicesMotvApiLoginWithDevice = fetch_sms_devicesMotvApiLoginWithDevice;
var fetch_sms_devicesMotvApiLoginV2 = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, (0, lib_1.post)({
                url: "".concat((0, config_1.getClientConfig)().smsUrl, "/api/Devices/Motv/apiLoginV2"),
                smsPost: true,
                body: body
            })];
    });
}); };
exports.fetch_sms_devicesMotvApiLoginV2 = fetch_sms_devicesMotvApiLoginV2;
var fetch_sms_devicesMotvApiLoginWithToken = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, (0, lib_1.post)({
                url: "".concat((0, config_1.getClientConfig)().smsUrl, "/api/Devices/Motv/apiLoginWithToken"),
                smsPost: true,
                body: body
            })];
    });
}); };
exports.fetch_sms_devicesMotvApiLoginWithToken = fetch_sms_devicesMotvApiLoginWithToken;
var fetch_sms_devicesMotvApiRequestNewPassword = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, (0, lib_1.post)({
                url: "".concat((0, config_1.getClientConfig)().smsUrl, "/api/Devices/Motv/apiRequestNewPassword"),
                smsPost: true,
                body: body
            })];
    });
}); };
exports.fetch_sms_devicesMotvApiRequestNewPassword = fetch_sms_devicesMotvApiRequestNewPassword;
var fetch_sms_devicesMotvApiChangePassword = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, (0, lib_1.post)({
                url: "".concat((0, config_1.getClientConfig)().smsUrl, "/api/Devices/Motv/apiChangePassword"),
                smsPost: true,
                body: body
            })
            //QR CODE START
        ];
    });
}); };
exports.fetch_sms_devicesMotvApiChangePassword = fetch_sms_devicesMotvApiChangePassword;
//QR CODE START
var fetch_mw_customerRequestLoginQRCode = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, (0, lib_1.post)({
                url: "".concat((0, config_1.getClientConfig)().mwUrl, "/public/customer/requestLoginQRCode"),
                body: body
            })];
    });
}); };
exports.fetch_mw_customerRequestLoginQRCode = fetch_mw_customerRequestLoginQRCode;
var fetch_mw_customerGetQRCodeData = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, (0, lib_1.post)({
                url: "".concat((0, config_1.getClientConfig)().mwUrl, "/public/customer/getQRCodeData"),
                body: body
            })
            //QR CODE END
            //Socials START
        ];
    });
}); };
exports.fetch_mw_customerGetQRCodeData = fetch_mw_customerGetQRCodeData;
//QR CODE END
//Socials START
var fetch_sms_devicesGoogleRegister = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, (0, lib_1.post)({
                url: "".concat((0, config_1.getClientConfig)().smsUrl, "/api/Devices/Google/register"),
                smsPost: true,
                body: body
            })];
    });
}); };
exports.fetch_sms_devicesGoogleRegister = fetch_sms_devicesGoogleRegister;
var fetch_sms_devicesAppleRegister = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, (0, lib_1.post)({
                url: "".concat((0, config_1.getClientConfig)().smsUrl, "/api/Devices/Apple/register"),
                smsPost: true,
                body: body
            })];
    });
}); };
exports.fetch_sms_devicesAppleRegister = fetch_sms_devicesAppleRegister;
var fetch_sms_devicesAppleLogin = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, (0, lib_1.post)({
                url: "".concat((0, config_1.getClientConfig)().smsUrl, "/api/Devices/Apple/login"),
                smsPost: true,
                body: body
            })];
    });
}); };
exports.fetch_sms_devicesAppleLogin = fetch_sms_devicesAppleLogin;
var fetch_sms_devicesFacebookRegister = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, (0, lib_1.post)({
                url: "".concat((0, config_1.getClientConfig)().smsUrl, "/api/Devices/Facebook/register"),
                smsPost: true,
                body: body
            })
            //Socials END
            // Profile START
        ];
    });
}); };
exports.fetch_sms_devicesFacebookRegister = fetch_sms_devicesFacebookRegister;
//Socials END
// Profile START
var fetch_mw_profileGetPredefinedProfileImages = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, (0, lib_1.post)({
                url: "".concat((0, config_1.getClientConfig)().mwUrl, "/public/profile/getPredefinedProfileImages"),
                body: body,
                noAuth: true
            })];
    });
}); };
exports.fetch_mw_profileGetPredefinedProfileImages = fetch_mw_profileGetPredefinedProfileImages;
var fetch_mw_profileUpdate = function (body, fakeProfileHeader) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, (0, lib_1.post)({
                url: "".concat((0, config_1.getClientConfig)().mwUrl, "/public/profile/update"),
                body: body,
                fakeProfileHeader: fakeProfileHeader
            })];
    });
}); };
exports.fetch_mw_profileUpdate = fetch_mw_profileUpdate;
var fetch_mw_profileGetProfiles = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, (0, lib_1.post)({
                url: "".concat((0, config_1.getClientConfig)().mwUrl, "/public/profile/getProfiles")
            })];
    });
}); };
exports.fetch_mw_profileGetProfiles = fetch_mw_profileGetProfiles;
var fetch_mw_profileDeleteProfile = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, (0, lib_1.post)({
                url: "".concat((0, config_1.getClientConfig)().mwUrl, "/public/profile/deleteProfile"),
                body: body
            })];
    });
}); };
exports.fetch_mw_profileDeleteProfile = fetch_mw_profileDeleteProfile;
var fetch_mw_profileEventAudioChange = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, (0, lib_1.post)({
                url: "".concat((0, config_1.getClientConfig)().mwUrl, "/public/profile/eventAudioChange"),
                body: body
            })];
    });
}); };
exports.fetch_mw_profileEventAudioChange = fetch_mw_profileEventAudioChange;
var fetch_mw_profileEventSubtitleChange = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, (0, lib_1.post)({
                url: "".concat((0, config_1.getClientConfig)().mwUrl, "/public/profile/eventSubtitleChange"),
                body: body
            })];
    });
}); };
exports.fetch_mw_profileEventSubtitleChange = fetch_mw_profileEventSubtitleChange;
var fetch_mw_profileUpdateChannelOrder = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, (0, lib_1.post)({
                url: "".concat((0, config_1.getClientConfig)().mwUrl, "/public/profile/updateChannelOrder"),
                body: body
            })];
    });
}); };
exports.fetch_mw_profileUpdateChannelOrder = fetch_mw_profileUpdateChannelOrder;
var fetch_mw_profileResetChannelOrder = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, (0, lib_1.post)({
                url: "".concat((0, config_1.getClientConfig)().mwUrl, "/public/profile/resetChannelOrder"),
                body: body
            })
            // Profile END
        ];
    });
}); };
exports.fetch_mw_profileResetChannelOrder = fetch_mw_profileResetChannelOrder;
// Profile END
var fetch_mw_customerGetData = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, (0, lib_1.post)({
                url: "".concat((0, config_1.getClientConfig)().mwUrl, "/public/customer/getData")
            })];
    });
}); };
exports.fetch_mw_customerGetData = fetch_mw_customerGetData;
