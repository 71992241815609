import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {
    BUTTON_TYPE_ENUM,
    COLOR_WHITE_80,
    RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM,
    RecommendationCardEntity,
    SubscribedChannelEntity,
    TIME_CONST_ONE_MINUTE,
    imageResizeUrl,
    isResponseSuccessful,
} from "@motv-webapp/lib";
import {useWindowSize} from "react-use";
import {
    channel_channels,
    fetch_mw_vendorGetLockedChannelTextImage,
    fetch_mw_vendorGetLockedVodTextImage,
    myListAddToMyList,
    myListRemoveFromMyList,
    recordingAddRecording,
    recordingRemoveRecording,
    selectYoutubeItem,
    universal_myList,
    universal_myRecordingIds,
    useAppDispatch,
    useAppSelector,
    user_customers_recording_length,
    user_customers_recording_used,
    user_selectedLanguage,
} from "@motv-webapp/redux";
import Separator from "../Separator/Separator";
import {FormattedMessage, useIntl} from "react-intl";
import PrimaryButton from "../Button/PrimaryButton";
import IconButtonTransparent from "../Button/IconButtonTransparent";
import {debounce} from "debounce";
import {useNavigate} from "react-router-dom";
import {getUnixTime, isAfter, isBefore} from "date-fns";

const DetailHeroContainer = styled.div((props: { windowWidth: number }) => ({
    display: "flex",
    flexDirection: "column" as "column",
    height: props.windowWidth < 800 ? "65vh" : "56.25vw",
    justifyContent: "flex-end" as "flex-end",
    maxHeight: "90vh",
    overflow: "hidden",
    position: "relative" as "relative",
    "@media (max-width: 800px)": {
        overflow: "visible",
    }
}));

const DetailBigImage = styled.div((props: { mediaItem: RecommendationCardEntity, windowWidth: number }) => ({
    background: `url(${imageResizeUrl(props.mediaItem?.image_widescreen ? props.mediaItem?.image_widescreen : props.mediaItem?.image, props.windowWidth)})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    filter: props.windowWidth < 800 ? "" : props.mediaItem?.image_widescreen && (props.mediaItem?.image_widescreen_width! * 2 > props.windowWidth) ? ""
        : props.mediaItem?.image && (props.mediaItem?.image_width! * 2 > props?.windowWidth) ? "" : "blur(1000px)",
    height: "100%",
    overflow: "hidden",
    position: "absolute" as "absolute",
    width: "100%",
}));

const DetailBigImageOverlay = styled.div(props => ({
    backgroundImage: "linear-gradient(to bottom, rgba(17, 18, 19, 0) 0%, rgba(17, 18, 19, 0.68) 68%, #111213 100%)",
    bottom: 0,
    height: "50%",
    position: "absolute" as "absolute",
    width: "100%",
}));

const DetailSmallImage = styled.img(props => ({
    alignSelf: "flex-end",
    borderRadius: 12,
    bottom: "30%",
    flexShrink: 1,
    marginRight: "4rem",
    maxHeight: "50%",
    maxWidth: "40vw",
    position: "absolute",
    zIndex: 100,
}));

const DetailHeroInfoBox = styled.div(props => ({
    flexShrink: 1,
    marginLeft: "4rem",
    zIndex: 100,
    "& h1": {
        marginTop: 0,
        marginBottom: "1rem",
        maxWidth: "50vw",
    },
    "@media (max-width: 800px)": {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        marginLeft: 0,
        position: "relative",
        bottom: -120,
        "& h1": {
            fontFamily: `"Roboto Condensed", "Roboto", Arial, sans-serif`,
            fontSize: "1.953rem",
            fontWeight: "bold",
        },
    }
}));

const EpisodeName = styled.p(props => ({
    fontWeight: 500,
    marginTop: 0,
    marginBottom: "0.75rem",
}));

const EpgInfo = styled.div(props => ({
    alignItems: "center",
    display: "flex",
    marginBottom: "2rem",
    "& > div": {
        color: COLOR_WHITE_80,
        fontSize: "0.875rem",
        fontWeight: 500,
    },
    "& > img": {
        height: "1.5rem",
        marginRight: "1rem",
    },
    "@media (max-width: 800px)": {
        marginBottom: "1.5rem",
    }
}));

const ActionButtons = styled.div(props => ({
    alignItems: "center",
    display: "flex",
    marginBottom: "4.5rem",
    "& > div": {
        display: "flex", gap: "1.5rem"
    },
    "@media (max-width: 800px)": {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        gap: 16,
        "& > div": {
            gap: "initial",
            justifyContent: "space-around",
            width: "100vw",
        },
    }
}));

type Props = {
    mediaItem: RecommendationCardEntity
};

const
    DetailHero = ({mediaItem}: Props) => {
        const [playable, setPlayable] = useState(false)
        const [recordable, setRecordable] = useState(false)
        const [isInMyList, setIsInMyList] = useState(false)
        const [isInMyRecordings, setIsInMyRecordings] = useState(false)
        const [lockedUrl, setLockedUrl] = useState("")
        const channels = useAppSelector(channel_channels) as Array<SubscribedChannelEntity>
        const customersRecordingLength = useAppSelector(user_customers_recording_length)
        const customersRecordingUsed = useAppSelector(user_customers_recording_used)
        const language = useAppSelector(user_selectedLanguage)
        const myList = useAppSelector(universal_myList)
        const myRecordingIds = useAppSelector(universal_myRecordingIds)
        const dispatch = useAppDispatch()
        const intl = useIntl()
        const navigate = useNavigate()
        const {width: windowWidth} = useWindowSize()

        useEffect(() => {
            if (!mediaItem) return
            if (mediaItem.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV) {
                if (!mediaItem.start
                    || !channels?.find(channel => channel.channels_id === mediaItem.channels_id)?.customers_recording_length) return
                const now = Date.now();
                const start = Date.parse(mediaItem.start);
                setPlayable(
                    start < now &&
                    now - channels.find(channel => channel.channels_id === mediaItem.channels_id)!.customers_recording_length! * TIME_CONST_ONE_MINUTE < start
                );
            } else if (mediaItem.type !== RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.CATEGORY
                && mediaItem.type !== RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.CHANNEL
                && mediaItem.type !== RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.PERSON
                && mediaItem.type !== RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.GENRE
                && mediaItem.type !== RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.IMAGE
                && mediaItem.type !== RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.VIDEO
                && mediaItem.type !== RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.ADMOB
                && mediaItem.type
            ) {
                setPlayable(true)
            }
        }, [mediaItem?.id, channels])

        useEffect(() => {
            if (!mediaItem) return
            if (!mediaItem.start
                || !channels?.find(channel => channel.channels_id === mediaItem.channels_id)?.channels_recording_length
                || !channels?.find(channel => channel.channels_id === mediaItem.channels_id)?.customers_recording_length) return
            const now = Date.now();
            const start = Date.parse(mediaItem.start);
            setRecordable(
                (mediaItem.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV || mediaItem.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.RECORDING) && (
                    !!channels.find(channel => channel.channels_id === mediaItem.channels_id) &&
                    channels.find(channel => channel.channels_id === mediaItem.channels_id)?.channels_recording_length! > 0 &&
                    now - channels.find(channel => channel.channels_id === mediaItem.channels_id)?.customers_recording_length! * TIME_CONST_ONE_MINUTE < start &&
                    now - channels.find(channel => channel.channels_id === mediaItem.channels_id)?.channels_recording_length! * TIME_CONST_ONE_MINUTE < start &&
                    !!mediaItem?.duration &&
                    customersRecordingLength - customersRecordingUsed > mediaItem.duration)
            )
        }, [mediaItem?.id, channels])

        useEffect(() => {
            if (mediaItem?.locked) {
                if (mediaItem.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.VOD) {
                    fetch_mw_vendorGetLockedVodTextImage({vodsId: mediaItem.id!})
                        .then(res => {
                                if (isResponseSuccessful(res)) {
                                    setLockedUrl(res.response.vendors_locked_item_url)
                                }
                            }
                        )
                } else if (mediaItem.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV) {
                    fetch_mw_vendorGetLockedChannelTextImage({channelsId: mediaItem.channels_id!})
                        .then(res => {
                                if (isResponseSuccessful(res)) {
                                    setLockedUrl(res.response.vendors_locked_item_url)
                                }
                            }
                        )
                }
            }
        }, [mediaItem])

        useEffect(() => {
            myList?.some(item => item.id === mediaItem?.id) ? setIsInMyList(true) : setIsInMyList(false)
        }, [myList, mediaItem])

        useEffect(() => {
            mediaItem?.id && myRecordingIds?.includes(mediaItem?.id) ? setIsInMyRecordings(true) : setIsInMyRecordings(false)
        }, [myRecordingIds, mediaItem])

        const handleClickPlay = () => {
            switch (mediaItem.type) {
                case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV:
                    if (!mediaItem.start || !mediaItem.end) return
                    let now = new Date()
                    let start = new Date(mediaItem.start)
                    let end = new Date(mediaItem.end)
                    isAfter(now, start) && isBefore(now, end)
                        ? navigate(`/tv/player/${mediaItem.channels_id}`)
                        : navigate(`/tv/player/${mediaItem.channels_id}/${getUnixTime(start)}`)
                    break
                case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.RECORDING:
                    navigate(`/recording/player/${mediaItem.id}`)
                    break
                case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.VOD:
                    navigate(`/vod/player/${mediaItem.id}`)
                    break
                case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.YOUTUBE:
                    dispatch(selectYoutubeItem(mediaItem))
                    break
                default:
                    return
            }
        }

        const handleClickBuy = () => {
            window.location.href = lockedUrl
        }

        const handleMyListButton = () => {
            if (mediaItem.type && mediaItem.id) {
                if (isInMyList) {
                    dispatch(myListRemoveFromMyList({type: mediaItem.type, id: mediaItem.id}))
                } else {
                    dispatch(myListAddToMyList({type: mediaItem.type, id: mediaItem.id}))
                }
            }
        }

        const handleRecordButton = () => {
            if (mediaItem.id) {
                if (isInMyRecordings) {
                    dispatch(recordingRemoveRecording({epgEventsId: mediaItem.id}))
                } else {
                    dispatch(recordingAddRecording({epgEventsId: mediaItem.id}))
                }
            }
        }

        const handleCategoryButton = () => {
            if (mediaItem.categories_id) {
                navigate(`/category/${mediaItem.categories_id}`)
            }
        }

        return (
            <DetailHeroContainer {...{windowWidth}} >
                <DetailBigImage {...{mediaItem, windowWidth}} ></DetailBigImage>
                <DetailBigImageOverlay/>
                {mediaItem && (!mediaItem?.image_widescreen || !mediaItem?.image_widescreen_width || !(mediaItem?.image_widescreen_width * 2 > windowWidth)) && !(mediaItem.image_width! * 2 > windowWidth) && windowWidth > 800 &&
                  <DetailSmallImage src={mediaItem.image_widescreen ? mediaItem.image_widescreen : mediaItem.image}
                                    alt={""}/>
                }
                <DetailHeroInfoBox>
                    <h1>{mediaItem?.title}</h1>
                    {mediaItem?.episode && <EpisodeName>{mediaItem.episode}</EpisodeName>}
                    <EpgInfo>
                        {mediaItem?.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.YOUTUBE ?
                            <img src={"/images/icons/icon_youtube_logo.png"} alt={""}/> : (mediaItem?.channels_logo &&
                            <img src={mediaItem.channels_logo} alt={""}/>)}
                        {mediaItem?.start && <div>{new Intl.DateTimeFormat(language, {
                            month: 'long', day: 'numeric',
                            hour: 'numeric', minute: 'numeric',
                            hour12: false,
                        }).format(Date.parse(mediaItem.start))}</div>}
                        {mediaItem?.duration && <div>
                            {mediaItem?.start && <Separator/>}
                            {Math.round(mediaItem.duration / 60)}
                            {" "}
                          <FormattedMessage id={"label_minutes_abbreviated"} defaultMessage={"min"}/>
                        </div>}
                    </EpgInfo>
                    <ActionButtons>
                        {!!mediaItem?.locked &&
                          <PrimaryButton
                            beforeImage={"/images/icons/icon_dollar.svg"}
                            buttonType={BUTTON_TYPE_ENUM.OFFWHITE}
                            label={intl.formatMessage({id: "label_buy", defaultMessage: "Buy"})}
                            onClick={handleClickBuy}
                            style={{
                                marginRight: windowWidth < 800 ? 0 : "2rem",
                                minWidth: 220,
                            }}
                          />
                        }
                        {/*Don't simplify 'mediaItem.playable !== false' to 'mediaItem.playable. It has to be true when mediaItem.playable = null.*/}
                        {(playable || (isInMyRecordings && mediaItem?.start && isAfter(Date.now(), Date.parse(mediaItem.start)))) && !mediaItem.locked && mediaItem.playable !== false &&
                          <PrimaryButton
                            beforeImage={"/images/icons/icon_play.svg"}
                            buttonType={BUTTON_TYPE_ENUM.OFFWHITE}
                            label={intl.formatMessage({id: "label_play", defaultMessage: "Play"})}
                            onClick={handleClickPlay}
                            style={{
                                marginRight: windowWidth < 800 ? 0 : "2rem",
                                minWidth: 220,
                            }}
                          />
                        }
                        <div>
                            <IconButtonTransparent
                                hoverLabelId={isInMyList ? "label_event_remove_from_my_list" : "label_event_add_my_list"}
                                hoverLabelDefaultMessage={isInMyList ? "Remove from my list" : "Add to my list"}
                                img={isInMyList ? "/images/icons/icon_list_remove.svg" : "/images/icons/icon_list_add.svg"}
                                onClick={debounce(handleMyListButton, 200)}
                            />
                            {(mediaItem?.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV || mediaItem?.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.RECORDING) && (recordable || isInMyRecordings) &&
                              <IconButtonTransparent
                                hoverLabelId={isInMyRecordings ? "label_event_remove_recording" : "label_record"}
                                hoverLabelDefaultMessage={isInMyRecordings ? "Remove from recordings" : "Record"}
                                img={isInMyRecordings ? "/images/icons/icon_record_remove.svg" : "/images/icons/icon_record.svg"}
                                onClick={debounce(handleRecordButton, 200)}
                              />}
                            {mediaItem?.categories_id && mediaItem?.categories_name && !window.location.pathname.includes("category") &&
                              <IconButtonTransparent
                                hoverLabelId={"_"}
                                hoverLabelDefaultMessage={mediaItem.categories_name}
                                img={"/images/icons/icon_category.svg"}
                                onClick={debounce(handleCategoryButton, 200)}
                              />}
                        </div>
                    </ActionButtons>
                </DetailHeroInfoBox>
            </DetailHeroContainer>
        );
    };

export default DetailHero;
