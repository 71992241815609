import React, {useEffect, useRef, useState} from 'react';
import {RecommendationRowEntity} from "@motv-webapp/lib";
import MediaCard from "../MediaCard/MediaCard";
import styled from "styled-components";
import {RECOMMENDATION_ENGINE_ROW_TYPE_ENUM,} from "@motv-webapp/lib";
import ChannelCard from "../MediaCard/ChannelCard";
import ImageCard from "../MediaCard/ImageCard";
import {
    RECOMMENDATION_ENGINE_ROW_STYLE_LARGE,
    RECOMMENDATION_ENGINE_ROW_STYLE_LARGE_WIDE,
    RECOMMENDATION_ENGINE_ROW_STYLE_NORMAL_WIDE,
    RECOMMENDATION_ENGINE_ROW_STYLE_SMALL_WIDE
} from "@motv-webapp/lib";
import {
    CHANNEL_CARD_HEIGHT,
    LARGE_HEIGHT,
    LARGE_WIDE_HEIGHT,
    NORMAL_HEIGHT,
    NORMAL_WIDE_HEIGHT,
    SMALL_WIDE_HEIGHT
} from "@motv-webapp/lib";
import {useWindowSize} from "react-use";
import {useSwipeable} from 'react-swipeable';
import {RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM} from "@motv-webapp/lib";
import {useAppDispatch} from "@motv-webapp/redux";
import {vodGetVodHomepageRow} from "@motv-webapp/redux";

const SingleRowContainer = styled.div(props => ({
    marginBottom: 55,
    marginLeft: "4rem",
    "@media (max-width: 870px)": {
        marginLeft: "2rem",
    }
}));

const HeaderSpacer = styled.div(props => ({
    height: 135,
}));

const RowTitleContainer = styled.div((props: {vodLazyRows:boolean}) => ({
    display:"flex",
    alignItems:"center",
    cursor:props.vodLazyRows ? "pointer" : "default",
    margin: props.vodLazyRows ? "0 0 20px 0" : 0,
    "& > img": {
        height: "20px",
        marginLeft: "4px",
        width: "20px"
    }
}));

const RowTitle = styled.h2((props: {marginBottom: boolean})  => ({
    margin: props.marginBottom ? "0 0 20px 0" : 0,
}));

const RowContainer = styled.div((props: {offsetLeft: number}) => ({
    display: "flex",
    gap: "1rem",
    left: -1 * props.offsetLeft,
    position: "relative" as "relative",
    transition: "left 0.5s",
}));

const ChevronForward = styled.div((props: {offsetLeft: number}) => ({
    alignItems: "center",
    background: "linear-gradient(-90deg, #101113 0%, rgba(16, 17, 19, 0) 100%)",
    cursor: "pointer",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    position: "absolute" as "absolute",
    right: -1 * (props.offsetLeft + 50),
    transition: "right 0.5s",
    width: 150,
    zIndex: 200,
    "& > img": {
        height: 32
    }
}));

const ChevronBack = styled.div((props: {offsetLeft: number}) => ({
    alignItems: "center",
    background: "linear-gradient(90deg, #101113 0%, rgba(16, 17, 19, 0) 100%)",
    cursor: "pointer",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    left: props.offsetLeft - 100,
    position: "absolute" as "absolute",
    transition: "left 0.5s",
    width: 150,
    zIndex: 200,
    "& > img": {
        height: 32
    }
}));

type Props = {
    headerSpacing: boolean
    row: RecommendationRowEntity
    vodLazyRows: boolean
    handleClickGenreTitle?: (genresId:number,title:string) => void
    showGenreDetail?:boolean
};

const SingleRow = ({headerSpacing, row, vodLazyRows, handleClickGenreTitle, showGenreDetail = false}: Props) => {
    const [hoverChevrons, setHoverChevrons] = useState(false)
    const [offsetLeft, setOffsetLeft] = useState(0)
    const {width} = useWindowSize();
    const dispatch = useAppDispatch()
    const rowRef = useRef<HTMLDivElement>(null)
    const [showChevronForward, setShowChevronForward] = useState(false)

    useEffect(() => {
        vodLazyRows && setOffsetLeft(0)
    }, [showGenreDetail])

    useEffect(() => {
        setShowChevronForward(((rowRef.current?.lastChild as HTMLDivElement)?.offsetLeft + (rowRef.current?.lastChild as HTMLDivElement)?.offsetWidth -
                offsetLeft) >
            (width - ((rowRef.current?.lastChild as HTMLDivElement)?.offsetWidth) * 0.5))
    }, [rowRef.current?.lastChild, rowRef.current?.children, offsetLeft, width])

    useEffect(() => {
        if (!row.id || row.data || !vodLazyRows) return
        if (showGenreDetail) {
            dispatch(vodGetVodHomepageRow({genresId: row.id, subGenresId:row.id}))
        } else {
            dispatch(vodGetVodHomepageRow({genresId: row.id}))
        }
    }, [row.data])

    const handleClickChevronForward = () => {
        if (((rowRef.current?.lastChild as HTMLDivElement)?.offsetLeft + (rowRef.current?.lastChild as HTMLDivElement).offsetWidth -
                offsetLeft) >
            (width - ((rowRef.current?.lastChild as HTMLDivElement).offsetWidth) * 0.5))
        setOffsetLeft(offsetLeft + (width * 0.7))
    }

    const handleShowChevrons = () => {
        setHoverChevrons(true)
    }

    const handleHideChevrons = () => {
        setHoverChevrons(false)
    }

    const handleClickChevronBack = () => {
        if (offsetLeft > width * 0.7) {
            setOffsetLeft(offsetLeft - width * 0.7);
        } else {
            setOffsetLeft(0);
        }
    }

    const handlers = useSwipeable({
        onSwipedLeft: () => handleClickChevronForward(),
        onSwipedRight: () => handleClickChevronBack(),
        swipeDuration: 1000,
        preventScrollOnSwipe: true,
        trackTouch: true
    });

    const refPassThrough = (el:HTMLDivElement) => {
        // call useSwipeable ref prop with el
        handlers.ref(el);

        // set myRef el so you can access it yourself
        // @ts-ignore
        rowRef.current = el;
    }

    return (
        <SingleRowContainer>
            {headerSpacing && <HeaderSpacer/>}
            {row.title ? (
                <>
                    {(row.id && handleClickGenreTitle) ? (
                        <RowTitleContainer vodLazyRows={vodLazyRows} onClick={() => handleClickGenreTitle(row.id!,row.title!)}>
                            <RowTitle marginBottom={false}>
                                {row.title}
                            </RowTitle>
                            <img src={"/images/icons/icon_chevron_forward.svg"} alt={""}/>
                        </RowTitleContainer>
                    ) : (
                        <RowTitle marginBottom={true}>{row.title}</RowTitle>
                    )}
                </>
            ) : (<div style={{height: 30}} />)}
            <RowContainer {...{offsetLeft}} {...handlers} ref={refPassThrough} onMouseEnter={handleShowChevrons}
                          onMouseLeave={handleHideChevrons}>
                {!!offsetLeft && width > 870 && <ChevronBack onClick={handleClickChevronBack}
                                              style={{
                                                  height: (row.type === RECOMMENDATION_ENGINE_ROW_TYPE_ENUM.FAVOURITE_CHANNELS || row.type === RECOMMENDATION_ENGINE_ROW_TYPE_ENUM.CONTINUE_WATCHING_CHANNELS) ? CHANNEL_CARD_HEIGHT * 1.2
                                                      : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_NORMAL_WIDE ? NORMAL_WIDE_HEIGHT * 1.2
                                                          : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_SMALL_WIDE ? SMALL_WIDE_HEIGHT * 1.2
                                                              : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_LARGE ? LARGE_HEIGHT * 1.2
                                                                  : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_LARGE_WIDE ? LARGE_WIDE_HEIGHT * 1.2 : NORMAL_HEIGHT * 1.2,
                                                  top: -((row.type === RECOMMENDATION_ENGINE_ROW_TYPE_ENUM.FAVOURITE_CHANNELS || row.type === RECOMMENDATION_ENGINE_ROW_TYPE_ENUM.CONTINUE_WATCHING_CHANNELS) ? CHANNEL_CARD_HEIGHT * 0.1
                                                          : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_NORMAL_WIDE ? NORMAL_WIDE_HEIGHT * 0.1
                                                              : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_SMALL_WIDE ? SMALL_WIDE_HEIGHT * 0.1
                                                                  : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_LARGE ? LARGE_HEIGHT * 0.1
                                                                      : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_LARGE_WIDE ? LARGE_WIDE_HEIGHT * 0.1 : NORMAL_HEIGHT * 0.1),
                                              }}
                                              {...{offsetLeft}}
                >
                    {hoverChevrons && <img src={"/images/icons/icon_chevron_back.svg"} alt={""}/>}
                </ChevronBack>}
                {showChevronForward && width > 870 &&
                  <ChevronForward onClick={handleClickChevronForward}
                               style={{
                                   height: (row.type === RECOMMENDATION_ENGINE_ROW_TYPE_ENUM.FAVOURITE_CHANNELS || row.type === RECOMMENDATION_ENGINE_ROW_TYPE_ENUM.CONTINUE_WATCHING_CHANNELS) ? CHANNEL_CARD_HEIGHT * 1.2
                                       : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_NORMAL_WIDE ? NORMAL_WIDE_HEIGHT * 1.2
                                           : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_SMALL_WIDE ? SMALL_WIDE_HEIGHT * 1.2
                                               : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_LARGE ? LARGE_HEIGHT * 1.2
                                                   : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_LARGE_WIDE ? LARGE_WIDE_HEIGHT * 1.2 : NORMAL_HEIGHT * 1.2,
                                   top: -((row.type === RECOMMENDATION_ENGINE_ROW_TYPE_ENUM.FAVOURITE_CHANNELS || row.type === RECOMMENDATION_ENGINE_ROW_TYPE_ENUM.CONTINUE_WATCHING_CHANNELS) ? CHANNEL_CARD_HEIGHT * 0.1
                                           : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_NORMAL_WIDE ? NORMAL_WIDE_HEIGHT * 0.1
                                               : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_SMALL_WIDE ? SMALL_WIDE_HEIGHT * 0.1
                                                   : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_LARGE ? LARGE_HEIGHT * 0.1
                                                       : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_LARGE_WIDE ? LARGE_WIDE_HEIGHT * 0.1 : NORMAL_HEIGHT * 0.1),
                               }}
                                  {...{offsetLeft}}
                  >
                      {hoverChevrons && <img src={"/images/icons/icon_chevron_forward.svg"} alt={""}/>}
                  </ChevronForward>}
                {row.data && row.data.map((mediaCard) => {
                    switch (row.type) {
                        case RECOMMENDATION_ENGINE_ROW_TYPE_ENUM.FAVOURITE_CHANNELS:
                            if (mediaCard.type !== RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.IMAGE) {
                                return (
                                    <ChannelCard key={row.id + "-" + mediaCard.id} {...{mediaCard}} />
                                )
                            }
                            break
                        case RECOMMENDATION_ENGINE_ROW_TYPE_ENUM.CONTINUE_WATCHING_CHANNELS:
                            if (mediaCard.type !== RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.IMAGE) {
                                return (
                                    <ChannelCard key={row.id + "-" + mediaCard.id} {...{mediaCard}} />
                                )
                            }
                            break
                        case RECOMMENDATION_ENGINE_ROW_TYPE_ENUM.IMAGES:
                            return (
                                <ImageCard key={row.id + "-" + mediaCard.id} {...{mediaCard, row}} />
                            )
                        case RECOMMENDATION_ENGINE_ROW_TYPE_ENUM.SEARCH:
                            if (mediaCard.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.CHANNEL) {
                                return (
                                    <ChannelCard key={row.id + "-" + mediaCard.id} {...{mediaCard}} />
                                )
                            } else if (mediaCard.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.IMAGE) {
                                return (
                                    <ImageCard key={row.id + "-" + mediaCard.id} {...{mediaCard, row}} />
                                )
                            } else {
                                return (
                                    <MediaCard key={row.id + "-" + mediaCard.id} {...{mediaCard, row}} />
                                )
                            }
                        default:
                            return (
                                <MediaCard key={row.id + "-" + mediaCard.id} {...{mediaCard, row}} />
                            )
                    }
                })}
            </RowContainer>
        </SingleRowContainer>
    );
};

export default SingleRow;
