import React, {ChangeEvent, Dispatch, SetStateAction, useEffect, useState} from 'react';
import styled from "styled-components";
import OutsideClickHandler from "react-outside-click-handler";
import {useIntl} from "react-intl";
import {COLOR_NEUTRAL, COLOR_WHITE} from "@motv-webapp/lib";
import {useTimeoutFn} from "react-use";
import {useAppDispatch, useAppSelector} from "@motv-webapp/redux";
import {
    searchOpenFalse,
    searchOpenTrue,
    universal_searchOpen,
    universalGetSearchRows
} from "@motv-webapp/redux";
import SearchResults from "../Header/SearchResults";
import {AnimatePresence, motion} from "framer-motion";

const SearchButtonContainer = styled.div(props => ({}));

const StyledButton = styled.button((props: {}) => ({
    background: "transparent",
    border: 0,
    cursor: "pointer",
    display: "flex",
    height: "1.9rem",
    padding: 0,
    width: "1.9rem",
    "& img": {
        height: "1.9rem",
        width: "1.9rem",
    },
}));

const SearchBar = styled(motion.div)(props => ({
    position: "relative",
    "& img": {
        height: 24,
        left: "1rem",
        marginRight: "0.5rem",
        position: "absolute",
        top: "0.4rem",
        width: 24,
    },
}));

const StyledInput = styled(motion.input)(props => ({
    background: COLOR_NEUTRAL,
    border: 0,
    borderRadius: 16,
    color: COLOR_WHITE,
    height: "2rem",
    paddingLeft: "3rem",
    width: 162,
}));

type Props = {
    setBlackBackground: Dispatch<SetStateAction<boolean>>
};

    const SearchButton = ({setBlackBackground}: Props) => {
    const [searchValue, setSearchValue] = useState("")
    const searchOpen = useAppSelector(universal_searchOpen)
    const dispatch = useAppDispatch()
    const intl = useIntl()

    useEffect(() => {
        if (searchValue && searchValue !== "" && searchOpen) {
            setBlackBackground(true)
        } else {
            setBlackBackground(false)
        }
    }, [searchOpen, searchValue])

    const [isReady, cancel, resetTimeout] = useTimeoutFn(() => {
        searchValue && searchValue !== "" && dispatch(universalGetSearchRows({search: searchValue}))
    }, 200);

    const handleOpenSearchBar = () => {
        dispatch(searchOpenTrue())
    }

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value)
        resetTimeout()
    }

    return (
        <SearchButtonContainer data-testid={"searchButtonContainer"}>
            <AnimatePresence>
                {searchOpen
                    ? (
                        <OutsideClickHandler
                            onOutsideClick={() => {
                                dispatch(searchOpenFalse())
                            }}
                        >
                            <SearchBar>
                                <StyledInput
                                    key={"searchInput"}
                                    autoFocus
                                    onChange={handleSearchChange}
                                    placeholder={intl.formatMessage({id: "label_search", defaultMessage: "Search"})}
                                    value={searchValue}
                                    initial={{width: 0}}
                                    animate={{width: 162}}
                                    exit={{width: 0}}
                                    transition={{type: "tween", duration: 0.2}}
                                />
                                <img src={"/images/icons/icon_search.svg"} alt={""}/>
                            </SearchBar>
                            {searchValue && searchValue !== "" && searchOpen && <SearchResults />}
                        </OutsideClickHandler>
                    )
                    : (<StyledButton onClick={handleOpenSearchBar}>
                            <img src={"/images/icons/icon_search.svg"} alt={""}/>
                        </StyledButton>
                    )
                }
            </AnimatePresence>

        </SearchButtonContainer>
    );
};

export default SearchButton;
