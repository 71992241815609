import React, {ChangeEvent, useState} from 'react';
import styled from "styled-components";
import {
    COLOR_NEUTRAL_90,
    COLOR_TERTIARY,
    COLOR_WHITE
} from "@motv-webapp/lib";
import {TYPEFACE_CAPTION_BOLD} from "@motv-webapp/lib";
import {useAppSelector} from "@motv-webapp/redux";
import SideControllersNowPlaying from "./SideControllersNowPlaying";
import {channel_channels} from "@motv-webapp/redux";
import ChannelListFilter from "../Channels/ChannelListFilter";
import {motion} from "framer-motion";
import {useIntl} from "react-intl";
import SideControllersChannelsItemLazyWrapper from "./SideControllersChannelsItemLazyWrapper";

const SideControllersChannelsContainer = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    height: "calc(100% - 32px)",
    margin: "32px 16px 0 16px"
}));

const Divider = styled.div(props => ({
    background: COLOR_NEUTRAL_90,
    height: 2,
    marginBottom: 24,
    marginTop: 14,
    width: "100%",
}));

const ChannelList = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    gap: 8,
    overflowY: "auto",
    paddingBottom: 16,
}));

const SearchBar = styled(motion.div)(props => ({
    marginBottom: 24,
    position: "relative",
    "& img": {
        height: 24,
        left: "1rem",
        marginRight: "0.5rem",
        position: "absolute",
        top: "0.4rem",
        width: 24,
    },
}));

const StyledInput = styled.input(props => ({
    ...TYPEFACE_CAPTION_BOLD,
    background: COLOR_TERTIARY,
    border: 0,
    borderRadius: 16,
    color: COLOR_WHITE,
    height: "2rem",
    paddingLeft: "3rem",
    width: "calc(100% - 64px)",
}));

type Props = {};

const SideControllersChannels = ({}: Props) => {
    const [typeFilter, setTypeFilter] = useState<string | undefined>(undefined)
    const [categoryFilter, setCategoryFilter] = useState<number | undefined>(undefined)
    const [search, setSearch] = useState("")
    const channelItems = useAppSelector(channel_channels)
    const intl = useIntl()

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value)
    }

    return (
        <SideControllersChannelsContainer>
            <SideControllersNowPlaying/>
            <Divider/>
            <ChannelListFilter filterButtonBackgroundColor={COLOR_NEUTRAL_90} {...{
                setTypeFilter,
                setCategoryFilter
            }} />
            <SearchBar>
                <StyledInput
                    key={"searchInput"}
                    onChange={handleSearchChange}
                    placeholder={intl.formatMessage({id: "label_search", defaultMessage: "Search"})}
                    value={search}
                />
                <img src={"/images/icons/icon_search.svg"} alt={""}/>
            </SearchBar>
            <ChannelList>
                {channelItems && !!channelItems.length && channelItems.filter(item => item.channels_name?.toLowerCase().includes(search.toLowerCase())).map((channelItem) => {
                    if (!!typeFilter) {
                        if (channelItem.channels_type === typeFilter) {
                            return (
                                <SideControllersChannelsItemLazyWrapper key={channelItem.channels_id} {...{channelItem}} />
                            )
                        }
                    } else if (!!categoryFilter) {
                        if (channelItem.channels_categories?.includes(categoryFilter)) {
                            return (
                                <SideControllersChannelsItemLazyWrapper key={channelItem.channels_id} {...{channelItem}} />
                            )
                        }
                    } else {
                        return (
                            <SideControllersChannelsItemLazyWrapper key={channelItem.channels_id} {...{channelItem}} />
                        )
                    }
                })}
            </ChannelList>
        </SideControllersChannelsContainer>
    );
};

export default SideControllersChannels;
