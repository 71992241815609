import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useAppSelector} from "@motv-webapp/redux";
import {vendor_vendors_header_image, vendor_vendors_name} from "@motv-webapp/redux";
import MainMenu from "./MainMenu";
import UtilityMenu from "./UtilityMenu";
import {useWindowScroll} from "react-use";
import {COLOR_NEUTRAL, COLOR_WHITE, imageResizeUrl} from "@motv-webapp/lib";
import {useNavigate} from "react-router-dom";
import {portal_motv_portals_sections_v2} from "@motv-webapp/redux";
import {PORTAL_SECTION_ENUM} from "@motv-webapp/lib";

const HeaderContainer = styled.nav((props: { blackBackground: boolean }) => ({
    alignItems: "center",
    background: props.blackBackground ? COLOR_NEUTRAL : "transparent",
    backgroundImage: "linear-gradient(180deg, rgba(16,17,19,0.85) 0%, rgba(16,17,19,0) 100%)",
    boxSizing: "border-box" as "border-box",
    display: "flex",
    height: "70px",
    padding: "0.875rem 4rem 0.875rem 3.938rem",
    position: "fixed" as "fixed",
    transition: "background 1s",
    width: "100%",
    zIndex: 800,
    "@media (max-width: 870px)": {
        padding: "0.875rem 1.5rem 0.875rem 1rem",
    }
}));

const HeaderLogo = styled.button(props => ({
    background: "transparent",
    border: 0,
    color: COLOR_WHITE,
    "& > img": {
        marginRight: "2rem",
        maxHeight: 42,
    },
    "& > p": {
        fontSize: "1.563rem",
        fontWeight: 600,
        margin: "0 2rem 0 0",
    }
}));

type Props = {};

const Header = ({}: Props) => {
    const [blackBackground, setBlackBackground] = useState(false)
    const logoImg = useAppSelector(vendor_vendors_header_image)
    const sectionsAllowedV2 = useAppSelector(portal_motv_portals_sections_v2)
    const vendorName = useAppSelector(vendor_vendors_name)
    const {y} = useWindowScroll();
    const navigate = useNavigate()

    useEffect(() => {
        y > 50 ? setBlackBackground(true) : setBlackBackground(false)
    }, [y])

    const handleClickLogo = () => {
        // Navigate to first item in allowed section array if available
        sectionsAllowedV2
            ? sectionsAllowedV2[0] === PORTAL_SECTION_ENUM.LIVE
                ? navigate("/channels")
                : (sectionsAllowedV2[0] === PORTAL_SECTION_ENUM.RECORDINGS || sectionsAllowedV2[0] === PORTAL_SECTION_ENUM.MY_LIST)
                    ? navigate("/saved")
                    : sectionsAllowedV2[0] === PORTAL_SECTION_ENUM.VOD
                        ? navigate("/vod")
                        : navigate("/")
            : navigate("/")
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
    return (
        <HeaderContainer {...{blackBackground}} >
            <HeaderLogo onClick={handleClickLogo}>
                {logoImg
                    ? <img src={imageResizeUrl(logoImg, 255)} alt={vendorName}/>
                    : <p>{vendorName}</p>
                }
            </HeaderLogo>
            <MainMenu/>
            <UtilityMenu {...{setBlackBackground}} />
        </HeaderContainer>
    );
};

export default Header;
