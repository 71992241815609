import React, {useState} from "react";
import styled from "styled-components";
import {FormattedMessage, useIntl} from "react-intl";
import Input from "../../InputFields/Input";
import {INPUT_FIELD_TYPE_ENUM} from "@motv-webapp/lib";
import PrimaryButton from "../../Button/PrimaryButton";
import {fetch_mw_customerLoginWithQrCode,} from "@motv-webapp/redux";
import {isResponseSuccessful} from "@motv-webapp/lib";
import {BUTTON_TYPE_ENUM} from "@motv-webapp/lib";

const QrMainContainer = styled.div(props => ({
    display: "flex",
    width: "100%",
    gap: "64px",
    "@media (max-width: 1100px)": {
        flexDirection: "column",
        gap: "32px",
    },
    "@media (max-width: 870px)": {
        width: "auto"
    },
}));

const QrTextContainer = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    maxWidth: 400,
    width: "100%",
    "& > h3": {
        padding: 0,
        margin: "0 0 12px 0",
        lineHeight: 1,
    },
    "& > p": {
        padding: 0,
        margin: "12px 0 0 0",
        fontSize: "1rem",
        fontWeight: 500,
        lineHeight: 1,
    },
}));

const QrFormContainer = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    maxWidth: 320,
    width: "100%",
}));

const QrLoginSettings = () => {
    const intl = useIntl();
    const [qrCode, setQrCode] = useState("")
    const [errorMessageId, setErrorMessageId] = useState("")

    const handleChange = (value: string) => {
        setQrCode(value)
    }

    const handleSubmitQrCode = () => {
        if (qrCode === "") {
            setErrorMessageId("message_required_field")
        } else {
            fetch_mw_customerLoginWithQrCode({code: parseInt(qrCode)})
                .then((r) => {
                    if (isResponseSuccessful(r)) {
                        setErrorMessageId("")
                    } else if (r.status === 106) {
                        setErrorMessageId("mw_exception_106")
                    } else {
                        setErrorMessageId("message_something_went_wrong")
                    }
                })
        }
    }

    return (
        <QrMainContainer>
            <QrTextContainer>
                <h3>
                    <FormattedMessage id={"label_qr_info_heading"}
                                      defaultMessage={"In the TV application or the web portal"}/>
                </h3>
                <p>
                    1. <FormattedMessage id={"label_qr_code_step_one"}
                                         defaultMessage={"Go to “Log in with QR code”"}/>
                </p>
                <p>
                    2. <FormattedMessage id={"label_qr_code_step_two"}
                                         defaultMessage={"Re-type number from under the QR code (In the web portal, hover over the QR code to show the number)"}/>

                </p>
            </QrTextContainer>
            <QrFormContainer>
                <Input errorMessageId={errorMessageId}
                       marginBottom={"1.5rem"}
                       onChange={handleChange}
                       onEnter={handleSubmitQrCode}
                       placeholder={intl.formatMessage({
                           id: "label_qr_login",
                           defaultMessage: "QR Login"
                       })}
                       type={INPUT_FIELD_TYPE_ENUM.TEXT}
                       value={qrCode}/>
                <PrimaryButton
                    buttonType={BUTTON_TYPE_ENUM.OFFWHITE}
                    label={intl.formatMessage({id: "label_submit", defaultMessage: "Submit"})}
                    onClick={handleSubmitQrCode}
                />
            </QrFormContainer>
        </QrMainContainer>
    )
}

export default QrLoginSettings;
