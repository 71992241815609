import {AnimatePresence, motion} from 'framer-motion';
import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {COLOR_BLACK, COLOR_WHITE} from "@motv-webapp/lib";
import {FormattedMessage} from "react-intl";
import {QRCodeSVG} from 'qrcode.react';
import {useAppDispatch, useAppSelector} from "@motv-webapp/redux";
import {customerRequestLoginQRCode, LoginWithQRCode, user_qrCodeForLogin} from "@motv-webapp/redux";
import {vendor_vendors_id} from "@motv-webapp/redux";
import {useInterval, useWindowSize} from 'react-use';
import Div100vh from "react-div-100vh";
import LoginHeader from "./LoginHeader";

const QrPanelContainer = styled(motion(Div100vh))(props => ({
    alignItems: "center",
    backgroundColor: "transparent",
    color: COLOR_WHITE,
    display: "flex",
    justifyContent: "space-around",
    position: "absolute",
    right: 0,
    width: "40vw",
    "@media (max-width: 800px)": {
        alignItems: "flex-start",
        width: "100vw",
    }
}));

const QrPanelContent = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    width: "22rem",
    "& h1": {
        marginTop: 0,
    },
    "& h2": {
        marginTop: 0,
    },
    "& p": {
        fontWeight: 500,
        marginTop: 0,
        marginBottom: "1.125rem",
        "& img": {
            height: "1.5rem",
            marginRight: "1rem",
            verticalAlign: "middle",
            width: "1.5rem",
        }
    },
    "@media (max-width: 800px)": {
        margin: "8rem 0 6rem 0",
    }

}));

const QRCodeContainer = styled.div(props => ({
    alignSelf: "flex-start",
    backgroundColor: COLOR_WHITE,
    borderRadius: 10,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    marginBottom: "4rem",
    padding: "1rem",
    position: "relative",
    "@media (max-width: 800px)": {
        alignSelf: "center"
    }
}));

const VisibleQRNumber = styled.p(props =>({
    bottom: -48,
    left: "50%",
    position: "absolute",
    transform: "translate(-50%,0)",
    whiteSpace: "nowrap",
}));

// type Props = {};

// const QrPanel = ({}: Props) => {
const QrPanel = () => {
    const [qrVisible, setQrVisible] = useState(false)
    const dispatch = useAppDispatch()
    const vendorsId = useAppSelector(vendor_vendors_id)
    const qrCode = useAppSelector(user_qrCodeForLogin)
    const {width:windowWidth} = useWindowSize()

    useEffect(() => {
        vendorsId && dispatch(customerRequestLoginQRCode({vendorsId}))
    }, []) //eslint-disable-line

    useInterval(
        () => {
            qrCode && dispatch(LoginWithQRCode({code: qrCode}));
        },
        3000
    );

    const handleMouseEnter = () => {
        setQrVisible(true)
    }

    const handleMouseLeave = () => {
        setQrVisible(false)
    }

    return (
        <QrPanelContainer
            initial={{x: "100%"}}
            animate={{x: 0}}
            exit={{x: "100%"}}
            transition={{type: "spring", duration: 1}}
        >
            <LoginHeader/>
            <QrPanelContent>
                <h1><FormattedMessage id={"label_qr_login"} defaultMessage={"QR Login"}/></h1>
                <QRCodeContainer>
                    <AnimatePresence>
                        {qrCode
                            ? <motion.div initial={{opacity: 0}}
                                          data-testid={"qrCodeDiv"}
                                          animate={{opacity: 1}}
                                          exit={{opacity: 0}}
                                          transition={{duration: 0.8}}
                                          style={{display: "flex"}}
                                          onMouseEnter={handleMouseEnter}
                                          onMouseLeave={handleMouseLeave}
                            >
                                <QRCodeSVG
                                    data-testid={"qrCodeSvg"}
                                    value={qrCode.toString()}
                                    size={160}
                                    fgColor={COLOR_BLACK}
                                    bgColor={COLOR_WHITE}
                                    includeMargin={false}
                                    level={"L"}
                                />
                                {(qrVisible || windowWidth < 800) && <VisibleQRNumber data-testid={"visibleQrNumber"}>
                                    {qrCode?.toString().replace(/\B(?=(\d{4})+(?!\d))/g, " ")}
                                </VisibleQRNumber>}
                            </motion.div>
                            : <div style={{backgroundColor: COLOR_WHITE, width: 160, height: 160}}/>}
                    </AnimatePresence>
                </QRCodeContainer>
                <h3>
                    <FormattedMessage id={"message_in_mobile_application_go_to"}
                                      defaultMessage={"In the mobile application, go to:"}/>
                </h3>
                <p>
                    <img src={"/images/icons/icon_avatar.svg"} alt={"account"}/>
                    <FormattedMessage id={"section_account"} defaultMessage={"Account"}/>
                </p>
                <p>
                    <img src={"/images/icons/icon_qr_code.svg"} alt={"qr code"}/>
                    <FormattedMessage id={"label_log_in_with_qr_code"} defaultMessage={"Log in with QR code"}/>
                </p>
            </QrPanelContent>
        </QrPanelContainer>
    );
};

export default QrPanel;
