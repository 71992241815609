import CreateAccountPanel from "../components/Login/CreateAccountPanel";
import Div100vh from 'react-div-100vh';
import ForgottenPasswordPanel from "../components/Login/ForgottenPasswordPanel";
import LoginPanel from "../components/Login/LoginPanel";
import QrPanel from "../components/Login/QrPanel";
import React, {useEffect, useState} from 'react';
import styled, {createGlobalStyle} from "styled-components";
import {AnimatePresence} from "framer-motion";
import {useNavigate, useParams} from "react-router-dom";
import {
    error_error_mw, error_error_sms,
    motvApiLoginWithToken,
    useAppDispatch,
    useAppSelector,
    user_customers_token,
    user_device_motv_social_registration_completed,
    vendor_vendors_accent_color,
    vendor_vendors_background_image_portal,
    vendor_vendors_background_image_scaling,
} from "@motv-webapp/redux";
import {
    COLOR_NEUTRAL,
    COLOR_SECONDARY,
    STORAGE_CONSTANT_AUTHORIZATION,
    VENDOR_CUSTOMIZATION_LOGIN_BACKGROUND_SCALING_FILL,
    getLocalStorage,
} from "@motv-webapp/lib";
import {getClientConfig} from "@motv-webapp/config";
import ErrorPage from "./ErrorPage";

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
    background-color: ${COLOR_SECONDARY};
  }
  ,
`

const LoginContainer = styled(Div100vh)(() => ({
    backgroundColor: COLOR_SECONDARY,
    display: "flex",
    justifyContent: "space-between",
    overflow: "hidden",
}));

const LoginBackground = styled.div((props: { accentColor: string | null | undefined, loginBackground: string | null | undefined, loginBackgroundScaling: string | null | undefined }) => ({
    backgroundColor: COLOR_NEUTRAL,
    backgroundImage: `url(${props.loginBackground})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: props.loginBackgroundScaling === VENDOR_CUSTOMIZATION_LOGIN_BACKGROUND_SCALING_FILL ? "cover" : "60%",
    width: "60vw",
    "& > div": {
        backgroundColor: `${props.accentColor}`,
        filter: "blur(300px)",
        opacity: 0.6,
        transform: "rotate(-40deg)",
        width: 212,
        height: 899,
    },
    "@media (max-width: 800px)": {
    display: "none",
    }
}));

const PANEL_LOGIN = "login"
const PANEL_QR = "qr"
const PANEL_FORGOTTEN_PASSWORD = "forgottenPassword"
const PANEL_CREATE_ACCOUNT = "createAccount"

const LoginPage = () => {
    const [activePanel, setActivePanel] = useState(PANEL_LOGIN)
    const dispatch = useAppDispatch();
    const token = useAppSelector(user_customers_token);
    const loginBackground = useAppSelector(vendor_vendors_background_image_portal);
    const loginBackgroundScaling = useAppSelector(vendor_vendors_background_image_scaling);
    const mwError = useAppSelector(error_error_mw)
    const smsError = useAppSelector(error_error_sms)
    const socialRegistrationComplete = useAppSelector(user_device_motv_social_registration_completed);
    const accentColor = useAppSelector(vendor_vendors_accent_color)
    const deviceLoginEnabled = getClientConfig().deviceLoginEnabled
    const {panel} = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        const tokenFromLocalStorage = getLocalStorage(STORAGE_CONSTANT_AUTHORIZATION)
        if (tokenFromLocalStorage) dispatch(motvApiLoginWithToken({token: tokenFromLocalStorage}));
    }, []) // eslint-disable-line

    useEffect(() => {
        if (token && socialRegistrationComplete) navigate("/", {replace: true});
        if (token && !socialRegistrationComplete) setActivePanel(PANEL_CREATE_ACCOUNT);
    }, [token, navigate, socialRegistrationComplete])

    useEffect(() => {
        if (!panel) {
            setActivePanel(PANEL_LOGIN)
            return
        }
        setActivePanel(panel)
    }, [panel])

    return (
        (deviceLoginEnabled && (mwError?.status === -1 || smsError?.status === -1)) ? <ErrorPage/>
            : <LoginContainer>
            <GlobalStyle/>
            <LoginBackground {...{accentColor, loginBackground, loginBackgroundScaling}} >
                <div/>
            </LoginBackground>
            <AnimatePresence>
                {activePanel === PANEL_LOGIN &&
                  <LoginPanel key={"login"} {...{
                      PANEL_QR,
                      PANEL_FORGOTTEN_PASSWORD,
                      PANEL_CREATE_ACCOUNT
                  }} />}
                {activePanel === PANEL_FORGOTTEN_PASSWORD &&
                  <ForgottenPasswordPanel {...{setActivePanel, PANEL_LOGIN, PANEL_FORGOTTEN_PASSWORD}} />}
                {activePanel === PANEL_CREATE_ACCOUNT &&
                  <CreateAccountPanel />}
                {activePanel === PANEL_QR && <QrPanel key={"qr"} {...{setActivePanel, PANEL_LOGIN}} />}
            </AnimatePresence>
        </LoginContainer>
    );
};

export default LoginPage;
