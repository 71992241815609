import React, {useState} from 'react';
import styled from "styled-components";
import {useAppSelector} from "@motv-webapp/redux";
import {vendor_vendors_accent_color} from "@motv-webapp/redux";
import {COLOR_OFFWHITE_50, COLOR_OFFWHITE_90, COLOR_PRIMARY_100} from "@motv-webapp/lib";

const StyledButton = styled.button((props: {accentColor: string | undefined | null, active: boolean, hover: boolean}) =>({
    alignItems: "center",
    background: "transparent",
    border: 0,
    display: "flex",
    gap: 8,
    padding: "20px 0",
    "& > p": {
        color: props.active ? (props.accentColor ? props.accentColor : COLOR_PRIMARY_100) : props.hover ? COLOR_OFFWHITE_90 : COLOR_OFFWHITE_50,
        fontWeight: 500,
        margin: 0,
        textAlign: "center" as "center",
    }
}));

type Props = {
    active: boolean
    dateNumber: string
    onClick: () => void
    weekday: string
};

const GuideDatePickerButton = ({active, dateNumber, onClick, weekday}: Props) => {
    const [hover, setHover] = useState(false)
    const accentColor = useAppSelector(vendor_vendors_accent_color)

    const handleMouseEnter = () => {
        setHover(true)
    }

    const handleMouseLeave = () => {
        setHover(false)
    }

 return (
  <StyledButton onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} {...{accentColor, active, hover, onClick}} >
      <p>{weekday}</p>
      <p>{dateNumber}</p>
  </StyledButton>
 );
};

export default GuideDatePickerButton;
