import React, {useState, MouseEvent} from "react";
import {
    deleteProfile,
    user_customers_profiles_id,
    user_selectedProfile
} from "@motv-webapp/redux";
import styled from "styled-components";
import {
    COLOR_SECONDARY,
    COLOR_BLACK, COLOR_INPUT_FIELD_GREY,
} from "@motv-webapp/lib";
import {FormattedMessage} from "react-intl";
import {useWindowSize} from "react-use";
import {useAppDispatch, useAppSelector} from "@motv-webapp/redux";
import ConfirmDialog from "../../../Dialog/ConfirmDialog";
import {ProfileEntity} from "@motv-webapp/lib";

const ProfileCardContainer = styled.div((props: {}) => ({
    padding: "16px",
    width: "248px",
    height: "62px",
    background: COLOR_SECONDARY,
    borderRadius: "12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative" as "relative",
    cursor: "pointer",
    "@media (max-width: 870px)": {
        overflow: "hidden",
        width: "100%",
    }
}));
const ProfileInfoContainer = styled.div(props => ({
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    "& > p": {
        marginLeft: "16px",
        fontSize: "1rem",
        fontWeight: 500,
        lineHeight: 1,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
}));
const ProfileImageContainer = styled.div(props => ({
    height: "62px",
    width: "62px",
    borderRadius: "50%",
    background: COLOR_BLACK,
    "& img": {
        borderRadius: "50%",
        height: "62px",
        width: "62px",
        objectFit: "cover"
    },
}));
const StyledButton = styled.button((props: {}) => ({
    background: "transparent",
    border: 0,
    padding: 0,
    cursor: "pointer",
    display: "flex",
    "& img": {
        width: "11px",
        height: "11px",
    },
    position: "absolute" as "absolute",
    top: "16px",
    right: "16px",
}));

const Tooltip = styled.div((props: { visible: boolean }) => ({
    background: COLOR_INPUT_FIELD_GREY,
    padding: "16px",
    position: "absolute" as "absolute",
    top: "-44px",
    visibility: props.visible ? "visible" as "visible" : "hidden" as "hidden",
    whiteSpace: "nowrap" as "nowrap",
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: 1,
    borderRadius: "12px",
    ":after": {
        content: '""',
        position: "absolute" as "absolute",
        top: "100%",
        left: "50%",
        marginLeft: "-10px",
        borderWidth: "10px",
        borderStyle: "solid",
        borderColor: `${COLOR_INPUT_FIELD_GREY} transparent transparent transparent`,
    }
}));

const DialogContainer = styled.div(props => ({
    display: "flex",
    flexDirection: "column"
}));

const DialogHeader = styled.div(props => ({
    fontSize: "1.563rem",
    fontWeight: 500,
    lineHeight: 1,
    "@media (max-width: 600px)": {
        textAlign: "center",
    }
}))

const DialogText = styled.div(props => ({
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: 1,
    margin: "44px 0",
    "@media (max-width: 600px)": {
        textAlign: "center",
        margin: "32px 0",

    }
}))

type Props = {
    profile?: ProfileEntity;
    onClick: () => void
};

const ProfileCard = ({profile, onClick}: Props) => {
    const [hover, setHover] = useState(false)
    const [isDeleteProfileDialogOpen, setIsDeleteProfileDialogOpen] = useState(false)
    const {width} = useWindowSize();
    const dispatch = useAppDispatch();
    const selectedProfile = useAppSelector(user_selectedProfile);
    const mainProfileId = useAppSelector(user_customers_profiles_id);

    const handleMouseEnter = () => {
        setHover(true)
    }

    const handleMouseLeave = () => {
        setHover(false)
    }

    const handleClickRemoveButton = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        setIsDeleteProfileDialogOpen(true);
    }

    const handleCloseDialog = () => {
        setIsDeleteProfileDialogOpen(false)
    }

    const handleConfirmDeleteProfile = () => {
        if (profile?.profiles_id) {
            dispatch(deleteProfile({profilesId: profile.profiles_id}))
        }
        setIsDeleteProfileDialogOpen(false);
    }

    return (
        <>
            <ProfileCardContainer
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
            >
                <ProfileInfoContainer>
                    <ProfileImageContainer>
                        {profile?.image ? (
                            <img src={profile?.image && profile.image} alt={""}/>
                        ) : (
                            <img src={"/images/icons/icon_add_circle.svg"} alt={""}/>
                        )}
                    </ProfileImageContainer>
                    <p>{profile ? profile.profiles_name : (
                        <FormattedMessage id={"label_settings_new_profile"} defaultMessage={"New profile"}/>
                    )
                    }</p>
                </ProfileInfoContainer>
                <StyledButton onClick={handleClickRemoveButton}>
                    {((profile && selectedProfile && (selectedProfile.profiles_id === mainProfileId)) && (width < 871 || hover) && profile.profiles_id !== mainProfileId) &&
                        <img src={"/images/icons/icon_close.svg"} alt={""}/>}
                </StyledButton>
                {(profile?.profiles_name && width > 870) &&
                    <Tooltip visible={hover && profile.profiles_name.length > 20}>{profile.profiles_name}</Tooltip>}
            </ProfileCardContainer>
            {isDeleteProfileDialogOpen && (
                <ConfirmDialog handleCloseDialog={handleCloseDialog}
                               onConfirmButtonClick={handleConfirmDeleteProfile}>
                    <DialogContainer>
                        <DialogHeader>
                            <FormattedMessage id={"label_delete_profile"}
                                              defaultMessage={"Delete profile"}/>
                        </DialogHeader>
                        <DialogText>
                            <FormattedMessage id={"label_settings_profile_remove_confirmation"}
                                              defaultMessage={"Do you really want to remove this profile?"}/>
                        </DialogText>
                    </DialogContainer>
                </ConfirmDialog>
            )}
        </>
    )
}

export default ProfileCard;
