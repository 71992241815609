import React from 'react';
import Input from "../../InputFields/Input";
import {useIntl} from "react-intl";
import {INPUT_FIELD_TYPE_ENUM} from "@motv-webapp/lib";
import {CustomerTemplateSectionField} from "@motv-webapp/lib";

type Props = {
    defaultChecked?: boolean
    disabled?: boolean
    errorMessageId: string | undefined
    fieldItem: CustomerTemplateSectionField
    onChange: (key: string, value: boolean) => void
};

const CustomerEditFieldText = ({defaultChecked, disabled, errorMessageId, fieldItem, onChange}: Props) => {
    const intl = useIntl()

    const handleChange = (value: boolean) => {
        onChange(fieldItem.key, value)
    }

    return (
        <Input disabled={disabled}
               defaultChecked={defaultChecked}
               errorMessageId={errorMessageId}
               onChangeChecked={handleChange}
               placeholder={fieldItem.optional ? `${fieldItem.label} (${intl.formatMessage({id: 'label_optional', defaultMessage: 'Optional'})})` : `${fieldItem.label}`}
               type={INPUT_FIELD_TYPE_ENUM.CHECKBOX}
        />
    );
};

export default CustomerEditFieldText;
