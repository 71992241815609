"use strict";
exports.__esModule = true;
exports.isRecommendationCardType = exports.isCategoryEntity = exports.isRecommendationCategoryType = void 0;
var enums_1 = require("../enums");
function isRecommendationCategoryType(test) {
    return test.category !== undefined;
}
exports.isRecommendationCategoryType = isRecommendationCategoryType;
function isCategoryEntity(test) {
    return test.categories_id !== undefined;
}
exports.isCategoryEntity = isCategoryEntity;
function isRecommendationCardType(test) {
    return "type" in test && Object.values(enums_1.RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM).includes(test.type);
}
exports.isRecommendationCardType = isRecommendationCardType;
