import React from 'react';
import {CustomerTemplateSectionField} from "@motv-webapp/lib";
import SelectBox from "../../InputFields/SelectBox";
import {useIntl} from "react-intl";

type Props = {
    defaultValue?: string
    disabled?: boolean
    errorMessageId: string | undefined
    fieldItem: CustomerTemplateSectionField
    onChange: (key: string, value: string) => void
};

const CustomerEditFieldSelect = ({defaultValue, disabled, errorMessageId, fieldItem, onChange}: Props) => {
    const intl = useIntl()

    const handleChange = (value: string) => {
        onChange(fieldItem.key, value)
    }

    return (
        <SelectBox disabled={disabled}
                   defaultValue={defaultValue}
                   errorMessageId={errorMessageId}
                   onChange={handleChange}
                   placeholder={fieldItem.optional ? `${fieldItem.label} (${intl.formatMessage({id: 'label_optional', defaultMessage: 'Optional'})})` : `${fieldItem.label}`}
                   options={fieldItem.options && fieldItem.options}
        />
    );
};

export default CustomerEditFieldSelect;
