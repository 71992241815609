"use strict";
exports.__esModule = true;
exports.SHAKA_ERROR_CATEGORY = void 0;
var SHAKA_ERROR_CATEGORY;
(function (SHAKA_ERROR_CATEGORY) {
    SHAKA_ERROR_CATEGORY[SHAKA_ERROR_CATEGORY["NETWORK"] = 1] = "NETWORK";
    SHAKA_ERROR_CATEGORY[SHAKA_ERROR_CATEGORY["TEXT"] = 2] = "TEXT";
    SHAKA_ERROR_CATEGORY[SHAKA_ERROR_CATEGORY["MEDIA"] = 3] = "MEDIA";
    SHAKA_ERROR_CATEGORY[SHAKA_ERROR_CATEGORY["MANIFEST"] = 4] = "MANIFEST";
    SHAKA_ERROR_CATEGORY[SHAKA_ERROR_CATEGORY["STREAMING"] = 5] = "STREAMING";
    SHAKA_ERROR_CATEGORY[SHAKA_ERROR_CATEGORY["DRM"] = 6] = "DRM";
    SHAKA_ERROR_CATEGORY[SHAKA_ERROR_CATEGORY["PLAYER"] = 7] = "PLAYER";
    SHAKA_ERROR_CATEGORY[SHAKA_ERROR_CATEGORY["CAST"] = 8] = "CAST";
    SHAKA_ERROR_CATEGORY[SHAKA_ERROR_CATEGORY["STORAGE"] = 9] = "STORAGE";
    SHAKA_ERROR_CATEGORY[SHAKA_ERROR_CATEGORY["ADS"] = 10] = "ADS";
})(SHAKA_ERROR_CATEGORY = exports.SHAKA_ERROR_CATEGORY || (exports.SHAKA_ERROR_CATEGORY = {}));
