import React, {ReactNode} from 'react';
import styled from "styled-components";
import {COLOR_NEUTRAL, COLOR_SECONDARY, COLOR_WHITE} from "@motv-webapp/lib";
import OutsideClickHandler from "react-outside-click-handler";
import {FormattedMessage} from "react-intl";

const DialogContainer = styled.div(props => ({
    alignItems: "center",
    backdropFilter: "blur(10px)",
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    left: 0,
    position: "fixed",
    top: 0,
    width: "100vw",
    zIndex: 900,
}));

const DialogContent = styled.div(props => ({
    backgroundColor: COLOR_SECONDARY,
    borderRadius: "12px",
    width: "451px",
    maxHeight: "95vh",
    position: "relative",
    padding: "40px",
    margin: "0 8px",
    "@media (max-width: 600px)": {
        maxWidth: "70vw",
        width: "auto",
    },
}));

const DialogButtonContainer = styled.div(props => ({
    display: "flex",
    justifyContent: "space-between",
    gap: "16px"
}));

const ConfirmButton = styled.button(props => ({
    display: "flex",
    justifyContent: "center",
    padding: "16px 48px",
    borderRadius: "6px",
    background: COLOR_WHITE,
    color: COLOR_NEUTRAL,
    border: `solid 1px ${COLOR_WHITE}`,
    flex: 1,
    whiteSpace: "nowrap",
    fontSize: "1rem",
    fontWeight: 800,

}));

const CancelButton = styled.button(props => ({
    display: "flex",
    justifyContent: "center",
    padding: "16px 48px",
    borderRadius: "6px",
    flex: 1,
    backgroundColor: COLOR_SECONDARY,
    color: COLOR_WHITE,
    border: `solid 1px ${COLOR_WHITE}`,
    fontSize: "1rem",
    fontWeight: 800,
}));

type Props = {
    children: ReactNode
    handleCloseDialog: () => void
    onConfirmButtonClick: () => void
};

const ConfirmDialog = ({children, handleCloseDialog, onConfirmButtonClick}: Props) => {
    return (
        <DialogContainer>
            <OutsideClickHandler
                onOutsideClick={() => {
                    handleCloseDialog()
                }}
            >
                <DialogContent>
                    {children}
                    <DialogButtonContainer>
                        <CancelButton onClick={handleCloseDialog}>
                            <FormattedMessage id={"label_no"}
                                              defaultMessage={"No"}/>
                        </CancelButton>
                        <ConfirmButton onClick={onConfirmButtonClick}>
                            <FormattedMessage id={"label_yes"}
                                              defaultMessage={"Yes"}/>

                        </ConfirmButton>
                    </DialogButtonContainer>
                </DialogContent>
            </OutsideClickHandler>
        </DialogContainer>
    );
};

export default ConfirmDialog;
