import React, {useEffect} from "react";
import styled from "styled-components";
import DevicesCard from "./DevicesCard/DevicesCard";
import {useAppDispatch, useAppSelector} from "@motv-webapp/redux";
import {device_devices, getDevices} from "@motv-webapp/redux";

const DevicesContainer = styled.div(props => ({
    display: "flex",
    flexWrap: "wrap",
    gap: "16px",
    width: "100%",
}));

const DevicesSettings = () => {
    const devices = useAppSelector(device_devices);
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getDevices())
    }, []) //eslint-disable-line

    return (
        <DevicesContainer>
            {devices && devices.map((device) => (
                <DevicesCard key={device.devices_id} device={device}/>
            ))}
        </DevicesContainer>
    )
}

export default DevicesSettings;
