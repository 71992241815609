import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, MessagePayload} from 'firebase/messaging';
import {getClientConfig} from "@motv-webapp/config";
import firebase from "firebase/compat";  // eslint-disable-line
import {
    NotificationEntity,
    STORAGE_CONSTANT_AUTHORIZATION,
    STORAGE_CONSTANT_PROFILE_ID,
    removeLocalStorage,
    removeSessionStorage,
} from "@motv-webapp/lib";
import {
    forbidFirebase,
    invalidateCache,
    logOutUser,
    setClearCacheTrue,
    setNotifications,
    setOnVideoMessage,
    store as reduxStore,
    universalGetMyList,
    universalGetMyRecordings,
} from "@motv-webapp/redux";
import {addSeconds, getTime, subDays} from "date-fns";

export const initFirebase = () => {
    if (!("firebase" in getClientConfig())) return
    // @ts-ignore
    initializeApp(getClientConfig()?.firebase);
}

export const requestForToken = () => {
    if (reduxStore.getState().portal.firebaseForbidden) return
    if (!("firebase" in getClientConfig())) return
    let messaging
    try {
        messaging = getMessaging();
    } catch (err) {
        console.error('Failed to initialize Firebase Messaging', err);
        reduxStore.dispatch(forbidFirebase())
        return
    }
    // @ts-ignore
    return getToken(messaging, { vapidKey: getClientConfig().firebase.vapidKey })
        .then((currentToken) => {
            if (currentToken) {
                return currentToken
            } else {
                // Show permission request UI
                console.log('No registration token available. Request permission to generate one.');
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            reduxStore.dispatch(forbidFirebase())
        });
};

export const receiveFirebaseNotification = (message: MessageEvent) => {
    if (reduxStore.getState().portal.firebaseForbidden) return
    if(!message?.data) return;

    let db;
    let dbReq = indexedDB.open('motv', 1);
    dbReq.onupgradeneeded = function(event: IDBVersionChangeEvent) {
        // Set the db variable to our database so we can use it!
        // @ts-ignore
        db = event.target.result;

        // Create an object store named notes. Object stores
        // in databases are where data are stored.
        let notes = db.createObjectStore('notifications', {autoIncrement: true}); // eslint-disable-line
    }
    dbReq.onsuccess = function(event) {
        // @ts-ignore
        db = event.target.result;
        // @ts-ignore
        addNotification(db, message.data)
    }
    dbReq.onerror = function(event) {
        // @ts-ignore
        alert('error opening database ' + event.target.errorCode);
    }

    function addNotification(db: IDBDatabase, notificationObject: MessagePayload) {
        // Start a database transaction and get the notes object store
        let tx = db.transaction(['notifications'], 'readwrite');
        let store = tx.objectStore('notifications');
        // Put the sticky note into the object store
        if (notificationObject.data?.type && notificationObject.data.type === "2") {
            reduxStore.dispatch(setOnVideoMessage({showUntil: getTime(addSeconds(Date.now(), parseInt(notificationObject.data.duration))), message: notificationObject.data.message}))
        } else if (notificationObject.data?.type && notificationObject.data.type === "3") {
            console.log("Received ATV restart notification on background. This type of notification is not processed on web portal.")
        } else if (notificationObject.data?.type && notificationObject.data.type === "4") {
            reduxStore.dispatch(logOutUser())
            removeSessionStorage(STORAGE_CONSTANT_PROFILE_ID)
            removeLocalStorage(STORAGE_CONSTANT_AUTHORIZATION)
        } else if (notificationObject.data?.type && notificationObject.data.type === "5") {
            window.location.pathname = `/tv/player/${notificationObject.data.channelsId}`
        } else if (notificationObject.data?.type && notificationObject.data.type === "6") {
            console.log("Received firmware update notification on background. This type of notification is not processed on web portal.")
        } else if (notificationObject.data?.type && notificationObject.data.type === "7") {
            reduxStore.dispatch(setClearCacheTrue())
        } else if (notificationObject.data?.type && notificationObject.data.type === "8") {
            reduxStore.dispatch(setOnVideoMessage({showUntil: getTime(addSeconds(Date.now(), parseInt(notificationObject.data.duration))), message: reduxStore.getState().user.customers_login!}))
        } else if (notificationObject.data?.type && notificationObject.data.type === "9") {
            reduxStore.dispatch(universalGetMyList())
        } else if (notificationObject.data?.type && notificationObject.data.type === "10") {
            reduxStore.dispatch(universalGetMyRecordings())
        } else {
            let notification = {timestamp: Date.now(), type: "notification", read: false, notificationObject: notificationObject.notification, link: notificationObject.data?.link, customersId: notificationObject.data?.customersId};
            store.add(notification);
        }
        // Wait for the database transaction to complete
        tx.oncomplete = function() { console.log('notification processed!') }
        tx.onerror = function(event) {
            // @ts-ignore
            alert('error storing notification ' + event.target.errorCode);
        }
    }
}

export const retrieveCurrentNotifications = () => {
    if (reduxStore.getState().portal.firebaseForbidden) return
    let db;
    let dbReq = indexedDB.open('motv', 1);
    dbReq.onupgradeneeded = function(event: IDBVersionChangeEvent) {
        // Set the db variable to our database so we can use it!
        // @ts-ignore
        db = event.target.result;

        // Create an object store named notes. Object stores
        // in databases are where data are stored.
        let notes = db.createObjectStore('notifications', {autoIncrement: true}); // eslint-disable-line
    }
    dbReq.onsuccess = function(event) {
        // @ts-ignore
        db = event.target.result;
        getCurrentNotifications(db)
    }
    dbReq.onerror = function(event) {
        // @ts-ignore
        alert('error opening database ' + event.target.errorCode);
    }

    function getCurrentNotifications (db: IDBDatabase) {
        let tx = db.transaction(['notifications'], 'readwrite');
        let store = tx.objectStore('notifications');
        // Create a cursor request to get all items in the store, which
        // we collect in the allNotes array
        let req = store.openCursor();
        let allNotifications: Array<NotificationEntity> = [];

        req.onsuccess = function(event) {
            // The result of req.onsuccess is an IDBCursor
            // @ts-ignore
            let cursor = event.target.result;
            if (cursor != null) {
                // If the cursor isn't null, we got an IndexedDB item.
                // Add it to the note array and have the cursor continue!
                if (parseInt(cursor.value.customersId) === reduxStore.getState().user.customers_id) {
                    if (cursor.value.type === "logout" && cursor.value.read === false) {
                        reduxStore.dispatch(logOutUser())
                        reduxStore.dispatch(invalidateCache())
                        removeSessionStorage(STORAGE_CONSTANT_PROFILE_ID)
                        removeLocalStorage(STORAGE_CONSTANT_AUTHORIZATION)
                        cursor.update({...cursor.value, read: true})
                    } else if (cursor.value.type === "clearCache" && cursor.value.read === false) {
                        reduxStore.dispatch(setClearCacheTrue())
                        cursor.update({...cursor.value, read: true})
                    }
                    else if (cursor.value.type === "listChanged" && cursor.value.read === false) {
                        reduxStore.dispatch(universalGetMyList())
                        cursor.update({...cursor.value, read: true})
                    }
                    else if (cursor.value.type === "recordingsChanged" && cursor.value.read === false) {
                        reduxStore.dispatch(universalGetMyRecordings())
                        cursor.update({...cursor.value, read: true})
                    }
                    else if (cursor.value.type === "notification" && cursor.value.timestamp > subDays(Date.now(), 10)) allNotifications.push(cursor.value)
                }
                if (cursor.value.timestamp < subDays(Date.now(), 28)) cursor.delete()
                cursor.continue();
            } else {
                // If we have a null cursor, it means we've gotten
                // all the items in the store, so display the notes we got
                reduxStore.dispatch(setNotifications(allNotifications))
            }
        }
        req.onerror = function(event) {
            // @ts-ignore
            alert('error in cursor request ' + event.target.errorCode);
        }
    }
}

export const markAllNotificationsRead = () => {
    if (reduxStore.getState().portal.firebaseForbidden) return
    let db;
    let dbReq = indexedDB.open('motv', 1);
    dbReq.onupgradeneeded = function(event: IDBVersionChangeEvent) {
        // Set the db variable to our database so we can use it!
        // @ts-ignore
        db = event.target.result;

        // Create an object store named notes. Object stores
        // in databases are where data are stored.
        let notes = db.createObjectStore('notifications', {autoIncrement: true}); // eslint-disable-line
    }
    dbReq.onsuccess = function(event) {
        // @ts-ignore
        db = event.target.result;
        updateNotificationsRead(db)
    }
    dbReq.onerror = function(event) {
        // @ts-ignore
        alert('error opening database ' + event.target.errorCode);
    }

    function updateNotificationsRead (db: IDBDatabase) {
        let tx = db.transaction(['notifications'], 'readwrite');
        let store = tx.objectStore('notifications');
        // Create a cursor request to get all items in the store, which
        // we collect in the allNotes array
        let req = store.openCursor();
        let allNotifications: Array<NotificationEntity> = [];

        req.onsuccess = function(event) {
            // The result of req.onsuccess is an IDBCursor
            // @ts-ignore
            let cursor = event.target.result;
            if (cursor != null) {
                // If the cursor isn't null, we got an IndexedDB item.
                // Add it to the note array and have the cursor continue!
                cursor.update({...cursor.value, read: true})
                cursor.continue();
            } else {
                // If we have a null cursor, it means we've gotten
                // all the items in the store, so display the notes we got
                reduxStore.dispatch(setNotifications(allNotifications))
            }
        }
        req.onerror = function(event) {
            // @ts-ignore
            alert('error in cursor request ' + event.target.errorCode);
        }
    }
}
