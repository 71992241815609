import React from 'react';
import {useInView} from "react-intersection-observer";
import {SubscribedChannelEntity} from "@motv-webapp/lib";
import SideControllersChannelsItem from "./SideControllersChannelsItem";

type Props = {
    channelItem: SubscribedChannelEntity
};

const SideControllersChannelsItemLazyWrapper = ({channelItem}: Props) => {
    const {ref, inView} = useInView({
        threshold: 0
    });
    return (
        <div style={{height: 96, minHeight: 96}} ref={ref}>
            {inView && <SideControllersChannelsItem key={channelItem.channels_id} {...{channelItem}} />}
        </div>
    );
};

export default SideControllersChannelsItemLazyWrapper;
