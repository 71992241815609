import React from 'react';
import Dialog from "../Dialog/Dialog";
import styled from "styled-components";
import {COLOR_WHITE} from "@motv-webapp/lib";
import {FormattedMessage, useIntl} from "react-intl";
import {BUTTON_TYPE_ENUM} from "@motv-webapp/lib";
import PrimaryButton from "../Button/PrimaryButton";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "@motv-webapp/redux";
import {user_selectedProfile} from "@motv-webapp/redux";

const CloseButton = styled.button(props => ({
    background: "transparent",
    border: 0,
    height: "2.6rem",
    padding: 0,
    position: "absolute",
    right: "0.9rem",
    top: "0.9rem",
    width: "2.6rem",
    "& img": {
        height: "2.6rem",
        width: "2.6rem",
    }
}));

const Message = styled.h3(props => ({
    color: COLOR_WHITE,
    display: "flex",
    justifyContent: "center",
    marginBottom: 48,
    marginTop: 0,
    maxWidth: 500,
    textAlign: "center",
    width: "100%",
}));

type Props = {
    onClose: () => void
};

const CreatePinDialog = ({onClose}: Props) => {
    const selectedProfile = useAppSelector(user_selectedProfile)
    const intl = useIntl()
    const navigate = useNavigate()

    const handleGoToEditProfile = () => {
        navigate(`/settings/profiles/${selectedProfile?.profiles_id}`)
    }

    return (
        <Dialog handleCloseDialog={() => {
        }} solidBackground={true}>
            <CloseButton onClick={onClose}>
                <img src={"/images/icons/icon_close.svg"} alt={""}/>
            </CloseButton>
            <Message>
                <FormattedMessage
                    id={"message_create_pin_to_watch_channel"}
                    defaultMessage={"This channel is protected by PIN, but your profile does not have a PIN set. Please set a PIN for this profile if you would like to watch."}/>
            </Message>
            <PrimaryButton
                buttonType={BUTTON_TYPE_ENUM.OFFWHITE}
                label={intl.formatMessage({id: "label_edit_profile", defaultMessage: "Edit profile"})}
                onClick={handleGoToEditProfile}
                style={{marginBottom: 0}}
            />
        </Dialog>
    );
};

export default CreatePinDialog;
