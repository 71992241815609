import React, {ChangeEvent, Dispatch, SetStateAction} from 'react';
import styled from "styled-components";
import {
    COLOR_INPUT_FIELD_GREY,
    COLOR_INPUT_PLACEHOLDER_GREY,
    COLOR_DANGER_100,
    COLOR_WHITE, COLOR_DANGER_15
} from "@motv-webapp/lib";
import {FormattedMessage} from "react-intl";
import {AnimatePresence, motion} from "framer-motion";

const SelectBoxContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  ::after {
    content: "⌄";
    color: ${COLOR_INPUT_PLACEHOLDER_GREY};
    top: 10px;
    right: 12px;
    position: absolute;
  }
`

interface StyledSelectProps {
    readonly errorMessageId: string | undefined;
}

const StyledSelect = styled.select<StyledSelectProps>`
  background: ${props => (props.errorMessageId && props.errorMessageId !== "") ? COLOR_DANGER_15 : COLOR_INPUT_FIELD_GREY};
  border: 0;
  border-radius: 10px;
  box-shadow: 3px 3px 10px 1px rgba(0, 0, 0, 0.2);
  color: ${COLOR_WHITE};
  font-family: "Roboto", serif;
  font-weight: 500;
  height: 3rem;
  margin-bottom: 1rem;
  padding: 0 0.75rem; //0.5rem is just due to select box having some sort of padding by itself.
  width: 100%;

  -webkit-appearance: none;
  appearance: none;

  :invalid {
    color: ${COLOR_INPUT_PLACEHOLDER_GREY}
  }
`

const PlaceholderOption = styled.option(props => ({
    // color: COLOR_INPUT_PLACEHOLDER_GREY
}));

const ErrorMessage = styled(motion.p)(props => ({
    color: COLOR_DANGER_100,
    fontSize: "0.8rem",
    fontWeight: 700,
    marginTop: "-0.7rem",
}));

const ErrorImage = styled(motion.img)(props => ({
    height: "2rem",
    width: "2rem",
    position: "absolute",
    right: 30,
    top: "0.5rem",

}));

type Props = {
    disabled?: boolean
    errorMessageId: string | undefined
    onChange: Dispatch<SetStateAction<string>> | ((value: string) => void)
    options: Array<{ key: string, value: string }> | undefined
    placeholder: string
    defaultValue?: string
};

const SelectBox = ({disabled = false, errorMessageId, onChange, options, placeholder, defaultValue}: Props) => {
    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        onChange(e.target.value)
    }
    return (
        <SelectBoxContainer>
            <StyledSelect disabled={disabled}
                          onChange={handleChange}
                          errorMessageId={errorMessageId}
                          required
                          defaultValue={defaultValue ? defaultValue : ""}
            >
                <PlaceholderOption value={""} disabled>{placeholder}</PlaceholderOption>
                {options && options.map((optionsItem) => {
                    return (
                        <option key={optionsItem.key} value={optionsItem.value}>{optionsItem.key}</option>
                    )
                })}
            </StyledSelect>
            <AnimatePresence>
                {errorMessageId && (
                    <>
                        <ErrorMessage
                            initial={{y: -20, height: 0}}
                            animate={{y: 0, height: "auto"}}
                            exit={{y: -20, height: 0}}
                            transition={{type: "tween", duration: 0.4}}
                        >
                            <FormattedMessage id={errorMessageId} defaultMessage={"Unknown/undefined error."}/>
                        </ErrorMessage>
                        <ErrorImage
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                            transition={{duration: 0.4}}
                            src={"/images/icons/icon_error.png"} alt={""}
                        />
                    </>
                )}
            </AnimatePresence>
        </SelectBoxContainer>
    );
};

export default SelectBox;
