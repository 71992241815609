import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import styled from "styled-components";
import {addHours, startOfDay} from "date-fns";
import {useAppSelector} from "@motv-webapp/redux";
import {channel_epg} from "@motv-webapp/redux";
import {
    COLOR_NEUTRAL,
    COLOR_NEUTRAL_90, COLOR_OFFWHITE_50, imageResizeUrl,
} from "@motv-webapp/lib";
import {user_selectedLanguage} from "@motv-webapp/redux";
import TVGuideColumnStyleItem from "./TVGuideColumnStyleItem";
import {SubscribedChannelEntity} from "@motv-webapp/lib";
import {isSafari} from "react-device-detect";

const TvGuideColumnStyleContainer = styled.div(props => ({
    position: "relative",
    zIndex: 0,
}));

const NavLeft = styled.div((props: {showFilters: boolean}) => ({
    background: `linear-gradient(90deg, ${COLOR_NEUTRAL} 0%, rgba(16, 17, 19, 0) 100%)`,
    cursor: "pointer",
    height: "100%",
    position: "absolute" as "absolute",
    left: 64,
    width: 140,
    zIndex: 100,
    "& > img": {
        left: 84,
        position: "fixed" as "fixed",
        top: `calc(50vh + ${props.showFilters ? "200px": "0px"})`
    }
}));

const NavRight = styled.div((props: {showFilters: boolean}) => ({
    background: `linear-gradient(90deg, rgba(16, 17, 19, 0) 0%, ${COLOR_NEUTRAL} 100%)`,
    cursor: "pointer",
    height: "100%",
    position: "absolute" as "absolute",
    right: 0,
    width: 140,
    zIndex: 100,
    "& > img": {
        right: 20,
        position: "fixed" as "fixed",
        top: `calc(50vh + ${props.showFilters ? "200px": "0px"})`
    }
}));

const GuideTable = styled.table(props => ({
    borderCollapse: "collapse",
}));

const RowHeader = styled.tr(props => ({
    opacity: 0,
}));

const Row = styled.tr(props => ({
    verticalAlign: "top",
}));

const BlockHeader = styled.td((props: { borderBottom: boolean }) => ({
    borderBottom: props.borderBottom ? `4px solid ${COLOR_NEUTRAL_90}` : 0,
    color: COLOR_OFFWHITE_50,
    fontWeight: 800,
    paddingLeft: 24,
    paddingRight: 24,
    textAlign: "center" as "center",
    transform: isSafari ? "none" : "rotate(-180deg)",
    verticalAlign: "middle",
    writingMode: "vertical-rl" as "vertical-rl",
    zIndex: 200,
}));

const Block = styled.td(props => ({
    borderBottom: `4px solid ${COLOR_NEUTRAL_90}`,
    padding: "12px 3px 3px 3px",
    maxWidth: 250,
}));

const ColumnHeader = styled.th.attrs({nowrap: true})(props => ({
    fontSize: "0.8rem",
    fontWeight: "bold",
    minWidth: 250,
    width: 250,
    verticalAlign: "middle",
    "& > img": {
        height: 24,
        marginRight: 8,
        verticalAlign: "middle",
    },
}));

const FakeColumnHeader = styled.th.attrs({nowrap: true})(props => ({
    minWidth: 67,
    width: 67
}));

type Props = {
    channelOffset: number
    setChannelOffset: Dispatch<SetStateAction<number>>
    filteredChannels: Array<SubscribedChannelEntity>
    selectedDate: Date
    showFilters: boolean
};

const TvGuideColumnStyle = ({channelOffset, setChannelOffset, filteredChannels, selectedDate, showFilters}: Props) => {
    const [startOfBlocksArray, setStartOfBlocksArray] = useState<Date[]>([])
    const epgItems = useAppSelector(channel_epg)
    const language = useAppSelector(user_selectedLanguage)

    useEffect(() => {
        setStartOfBlocksArray([addHours(startOfDay(selectedDate), 8), addHours(startOfDay(selectedDate), 12), addHours(startOfDay(selectedDate), 16), addHours(startOfDay(selectedDate), 20), addHours(startOfDay(selectedDate), 24), addHours(startOfDay(selectedDate), 28)])
    }, [selectedDate])

    const handleNavLeft = () => {
        if ((channelOffset - (Math.round(window.innerWidth / 250) - 1)) <= 0) {
            setChannelOffset(0)
        } else {
            setChannelOffset(prevState => prevState - (Math.round(window.innerWidth / 250) - 2))
        }
    }

    const handleNavRight = () => {
        setChannelOffset(prevState => prevState + (Math.round(window.innerWidth / 250) - 2))
    }

    return (
        <TvGuideColumnStyleContainer>
            {!!channelOffset && <NavLeft onClick={handleNavLeft} {...{showFilters}} >
              <img src={"/images/icons/icon_chevron_back.svg"} alt={""}/>
            </NavLeft>}
            {filteredChannels.length && (channelOffset < (filteredChannels.length - (window.innerWidth / 250 * 0.9))) &&
              <NavRight onClick={handleNavRight} {...{showFilters}} >
                <img src={"/images/icons/icon_chevron_forward.svg"} alt={""}/>
              </NavRight>}
            <GuideTable>
                <thead>
                <RowHeader>
                    <FakeColumnHeader/>
                    {filteredChannels.length && filteredChannels.slice(channelOffset, channelOffset + (window.innerWidth / 250 * 1.5)).map((channel) => {
                        return (
                            <ColumnHeader key={channel.channels_id}>
                                <img src={imageResizeUrl(channel.channels_logo, 50)} alt={""}/>
                                {channel.channels_name}
                            </ColumnHeader>
                        )
                    })}
                </RowHeader>
                </thead>
                <tbody>
                {startOfBlocksArray.map((blockStart) => {
                    return (
                        <Row key={blockStart.toISOString()}>
                            <BlockHeader borderBottom={channelOffset === 0}>
                                {new Intl.DateTimeFormat(language, {
                                        hour: 'numeric',
                                        minute: 'numeric'
                                    }).format(blockStart)
                                    + " - " +
                                    new Intl.DateTimeFormat(language, {
                                        hour: 'numeric',
                                        minute: 'numeric'
                                    }).format(addHours(blockStart, 4))
                                }
                            </BlockHeader>
                            {filteredChannels.length && filteredChannels.slice(channelOffset, channelOffset + (window.innerWidth / 250 * 1.5)).map((channel) => {
                                return (
                                    <Block key={blockStart.toISOString() + channel.channels_id}>
                                        {epgItems && epgItems.filter(item => item.channels_id === channel.channels_id && Date.parse(item.start!) > blockStart.getTime() && Date.parse(item.start!) < addHours(blockStart, 4).getTime()).map((item, index) => {
                                            if (index === 0) {
                                                return (
                                                    <TVGuideColumnStyleItem key={item.id} mediaItem={item}
                                                                            withImage={true}/>
                                                )
                                            } else {
                                                return (
                                                    <TVGuideColumnStyleItem key={item.id} mediaItem={item}/>
                                                )
                                            }
                                        })}
                                    </Block>
                                )
                            })}
                        </Row>
                    )
                })}
                </tbody>
            </GuideTable>
        </TvGuideColumnStyleContainer>
    );
};

export default TvGuideColumnStyle;
