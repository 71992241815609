import React, {useState} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import styled from "styled-components";
import {COLOR_NEUTRAL_90, COLOR_NEUTRAL} from "@motv-webapp/lib";
import {
    LANGUAGE_CZECH_ABBR,
    LANGUAGE_CZECH_LABEL,
    LANGUAGE_ENGLISH_ABBR,
    LANGUAGE_ENGLISH_LABEL,
    LANGUAGE_GERMAN_ABBR,
    LANGUAGE_GERMAN_LABEL,
    LANGUAGE_MONGOLIAN_ABBR,
    LANGUAGE_MONGOLIAN_LABEL,
    LANGUAGE_PORTUGAL_ABBR,
    LANGUAGE_PORTUGAL_LABEL,
    LANGUAGE_SPANISH_ABBR,
    LANGUAGE_SPANISH_LABEL,
    LANGUAGE_FRENCH_ABBR,
    LANGUAGE_FRENCH_LABEL
} from "@motv-webapp/lib";
import {TYPEFACE_WEB_BODY_2_MEDIUM} from "@motv-webapp/lib";
import {getClientConfig} from "@motv-webapp/config";
import {selectLanguage} from "@motv-webapp/redux";
import {setLocalStorage} from "@motv-webapp/lib";
import {useAppDispatch, useAppLanguage} from "@motv-webapp/redux";

const LanguageButtonContainer = styled.div(props => ({
    position: "relative"
}));

const StyledButton = styled.button((props: { headerMode: boolean }) => ({
    background: "transparent",
    border: 0,
    borderRadius: "50%",
    boxShadow: "3px 3px 10px 1px rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    display: "flex",
    height: props.headerMode ? 22 : 16,
    padding: 0,
    width: props.headerMode ? 22 : 16,
    "& img": {
        height: props.headerMode ? 22 : 16,
        width: props.headerMode ? 22 : 16,
    },
}));

const LanguagePicker = styled.div(props => ({
    backgroundColor: COLOR_NEUTRAL,
    borderRadius: 6,
    padding: "1rem",
    position: "absolute",
    left: "-210%",
    top: "2rem",
    zIndex: 800,
    "& hr": {
        border: 0,
        backgroundColor: COLOR_NEUTRAL_90,
        height: 1,
    },
}));

const LanguagePickerRow = styled.div.attrs({"data-testid": "languagePickerRow"})(props => ({
    cursor: "pointer",
    display: "flex",
    "& img": {
        height: "1rem",
        marginRight: "0.6rem",
        width: "1rem",
    },
    "& p": {
        ...TYPEFACE_WEB_BODY_2_MEDIUM,
        margin: 0
    }
}));

type Props = {
    headerMode?: boolean
    style?: object
};

const LanguageButton = ({headerMode = false, style}: Props) => {
    const [languagePickerOpen, setLanguagePickerOpen] = useState(false)
    const availableLanguages = getClientConfig().languages
    const currentLanguage = useAppLanguage()
    const dispatch = useAppDispatch()

    const handleOpenOrClosePicker = () => {
        if (languagePickerOpen) {
            setLanguagePickerOpen(false)
        } else {
            setLanguagePickerOpen(true)
        }
    }

    const currentLanguageButton = () => {
        switch (currentLanguage) {
            case LANGUAGE_ENGLISH_ABBR:
                return (<StyledButton style={style} onClick={handleOpenOrClosePicker} {...{headerMode}} ><img
                    src={"/images/flags/flag_england.png"} alt={""}/></StyledButton>)
            case LANGUAGE_CZECH_ABBR:
                return (<StyledButton style={style} onClick={handleOpenOrClosePicker} {...{headerMode}} ><img
                    src={"/images/flags/flag_czech.png"} alt={""}/></StyledButton>)
            case LANGUAGE_GERMAN_ABBR:
                return (<StyledButton style={style} onClick={handleOpenOrClosePicker} {...{headerMode}} ><img
                    src={"/images/flags/flag_germany.png"} alt={""}/></StyledButton>)
            case LANGUAGE_SPANISH_ABBR:
                return (<StyledButton style={style} onClick={handleOpenOrClosePicker} {...{headerMode}} ><img
                    src={"/images/flags/flag_spain.png"} alt={""}/></StyledButton>)
            case LANGUAGE_MONGOLIAN_ABBR:
                return (<StyledButton style={style} onClick={handleOpenOrClosePicker} {...{headerMode}} ><img
                    src={"/images/flags/flag_mongolia.png"} alt={""}/></StyledButton>)
            case LANGUAGE_PORTUGAL_ABBR:
                return (<StyledButton style={style} onClick={handleOpenOrClosePicker} {...{headerMode}} ><img
                    src={"/images/flags/flag_portugal.png"} alt={""}/></StyledButton>)
            case LANGUAGE_FRENCH_ABBR:
                return (<StyledButton style={style} onClick={handleOpenOrClosePicker} {...{headerMode}} ><img
                    src={"/images/flags/flag_french.png"} alt={""}/></StyledButton>)
            default:
                return (<StyledButton style={style} onClick={handleOpenOrClosePicker} {...{headerMode}} ><img
                    src={"/images/flags/flag_england.png"} alt={""}/></StyledButton>)
        }
    }

    const handleSelectLanguage = (newLanguage: string) => () => {
        setLocalStorage(
            "language",
            newLanguage,
        );
        dispatch(selectLanguage(newLanguage))
        setLanguagePickerOpen(false);
    }

    return (
        <>
            {availableLanguages.length > 1 && (
                <LanguageButtonContainer data-testid={"languageButtonContainer"}>
                    <OutsideClickHandler
                        onOutsideClick={() => {
                            setLanguagePickerOpen(false)
                        }}
                    >
                        {currentLanguageButton()}
                        {languagePickerOpen
                            && (
                                <LanguagePicker data-testid={"languageButtonPicker"}>
                                    {availableLanguages.map((language, index) => {
                                        switch (language) {
                                            case LANGUAGE_ENGLISH_ABBR:
                                                return (
                                                    <div key={LANGUAGE_ENGLISH_ABBR}>
                                                        <LanguagePickerRow
                                                            onClick={handleSelectLanguage(LANGUAGE_ENGLISH_ABBR)}>
                                                            <img src={"/images/flags/flag_england.png"} alt={""}/>
                                                            <p>{LANGUAGE_ENGLISH_LABEL}</p>
                                                        </LanguagePickerRow>
                                                        {
                                                            availableLanguages.length - 1 > index && <hr/>
                                                        }
                                                    </div>
                                                )
                                            case LANGUAGE_CZECH_ABBR:
                                                return (
                                                    <div key={LANGUAGE_CZECH_ABBR}>
                                                        <LanguagePickerRow
                                                            onClick={handleSelectLanguage(LANGUAGE_CZECH_ABBR)}>
                                                            <img src={"/images/flags/flag_czech.png"} alt={""}/>
                                                            <p>{LANGUAGE_CZECH_LABEL}</p>
                                                        </LanguagePickerRow>
                                                        {
                                                            availableLanguages.length - 1 > index && <hr/>
                                                        }
                                                    </div>
                                                )
                                            case LANGUAGE_GERMAN_ABBR:
                                                return (
                                                    <div key={LANGUAGE_GERMAN_ABBR}>
                                                        <LanguagePickerRow
                                                            onClick={handleSelectLanguage(LANGUAGE_GERMAN_ABBR)}>
                                                            <img src={"/images/flags/flag_germany.png"} alt={""}/>
                                                            <p>{LANGUAGE_GERMAN_LABEL}</p>
                                                        </LanguagePickerRow>
                                                        {
                                                            availableLanguages.length - 1 > index && <hr/>
                                                        }
                                                    </div>
                                                )
                                            case LANGUAGE_SPANISH_ABBR:
                                                return (
                                                    <div key={LANGUAGE_SPANISH_ABBR}>
                                                        <LanguagePickerRow
                                                            onClick={handleSelectLanguage(LANGUAGE_SPANISH_ABBR)}>
                                                            <img src={"/images/flags/flag_spain.png"} alt={""}/>
                                                            <p>{LANGUAGE_SPANISH_LABEL}</p>
                                                        </LanguagePickerRow>
                                                        {
                                                            availableLanguages.length - 1 > index && <hr/>
                                                        }
                                                    </div>
                                                )
                                            case LANGUAGE_MONGOLIAN_ABBR:
                                                return (
                                                    <div key={LANGUAGE_MONGOLIAN_ABBR}>
                                                        <LanguagePickerRow
                                                            onClick={handleSelectLanguage(LANGUAGE_MONGOLIAN_ABBR)}>
                                                            <img src={"/images/flags/flag_mongolia.png"} alt={""}/>
                                                            <p>{LANGUAGE_MONGOLIAN_LABEL}</p>
                                                        </LanguagePickerRow>
                                                        {
                                                            availableLanguages.length - 1 > index && <hr/>
                                                        }
                                                    </div>
                                                )
                                            case LANGUAGE_PORTUGAL_ABBR:
                                                return (
                                                    <div key={LANGUAGE_PORTUGAL_ABBR}>
                                                        <LanguagePickerRow
                                                            onClick={handleSelectLanguage(LANGUAGE_PORTUGAL_ABBR)}>
                                                            <img src={"/images/flags/flag_portugal.png"} alt={""}/>
                                                            <p>{LANGUAGE_PORTUGAL_LABEL}</p>
                                                        </LanguagePickerRow>
                                                        {
                                                            availableLanguages.length - 1 > index && <hr/>
                                                        }
                                                    </div>
                                                )
                                            case LANGUAGE_FRENCH_ABBR:
                                                return (
                                                    <div key={LANGUAGE_FRENCH_ABBR}>
                                                        <LanguagePickerRow
                                                            onClick={handleSelectLanguage(LANGUAGE_FRENCH_ABBR)}>
                                                            <img src={"/images/flags/flag_french.png"} alt={""}/>
                                                            <p>{LANGUAGE_FRENCH_LABEL}</p>
                                                        </LanguagePickerRow>
                                                        {
                                                            availableLanguages.length - 1 > index && <hr/>
                                                        }
                                                    </div>
                                                )
                                            default:
                                                return false
                                        }
                                    })}
                                </LanguagePicker>
                            )
                        }
                    </OutsideClickHandler>
                </LanguageButtonContainer>
            )
            }
        </>);
};

export default LanguageButton;
