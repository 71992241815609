"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
exports.__esModule = true;
exports.homepage_rows = exports.homepageReducer = exports.homepageGetHomepageV2IncludeData = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var homepageAPI_1 = require("./homepageAPI");
var lib_1 = require("@motv-webapp/lib");
var initialState = {
    status: "idle",
    rows: undefined,
    lastUpdated: undefined
};
exports.homepageGetHomepageV2IncludeData = (0, toolkit_1.createAsyncThunk)('homepage/getHomepageV2IncludeData', function (arg, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, homepage, universal, response;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = thunkAPI.getState(), homepage = _a.homepage, universal = _a.universal;
                if (!(homepage.lastUpdated
                    && (homepage.lastUpdated + (lib_1.TIME_CONST_ONE_MINUTE * lib_1.TIME_CONST_NUMBER_OF_MINUTES_TO_CACHE)) > Date.now()
                    && (universal.invalidateCache ? homepage.lastUpdated > universal.invalidateCache : true)
                    && !arg.ignoreCache)) return [3 /*break*/, 1];
                return [2 /*return*/, homepage.rows];
            case 1: return [4 /*yield*/, (0, homepageAPI_1.fetch_mw_recommendationEngineGetHomepageV2)({ includeData: true })];
            case 2:
                response = _b.sent();
                return [4 /*yield*/, response.response];
            case 3: return [2 /*return*/, _b.sent()];
        }
    });
}); });
exports.homepageReducer = (0, toolkit_1.createSlice)({
    name: 'error',
    initialState: initialState,
    reducers: {
    //     removeAllErrors: () => initialState,
    //     addSmsError: (state, action: PayloadAction<ErrorType>) => {
    //         state.error_sms = action.payload
    //     }
    },
    extraReducers: function (builder) {
        builder
            .addCase(exports.homepageGetHomepageV2IncludeData.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.homepageGetHomepageV2IncludeData.fulfilled, function (state, action) {
            state.status = 'idle';
            state.rows = action.payload;
            state.lastUpdated = Date.now();
        });
    }
});
exports._b = _a = exports.homepageReducer.actions;
// Selectors
var homepage_rows = function (state) { return state.homepage.rows; };
exports.homepage_rows = homepage_rows;
exports["default"] = exports.homepageReducer.reducer;
