"use strict";
exports.__esModule = true;
exports.SVG_FILTER_NEUTRAL = exports.SVG_FILTER_TERTIARY_90 = exports.SVG_FILTER_OFFWHITE_50 = exports.SVG_FILTER_OFFWHITE_70 = exports.COLOR_RECORDING_COLORS_ARRAY = exports.COLOR_BADGE_RED = exports.COLOR_INPUT_FIELD_GREY = exports.COLOR_INPUT_PLACEHOLDER_GREY = exports.COLOR_OFFWHITE_50 = exports.COLOR_OFFWHITE_70 = exports.COLOR_OFFWHITE_80 = exports.COLOR_OFFWHITE_90 = exports.COLOR_OFFWHITE = exports.COLOR_NEUTRAL_90 = exports.COLOR_NEUTRAL = exports.COLOR_TERTIARY_90 = exports.COLOR_TERTIARY = exports.COLOR_SECONDARY = exports.COLOR_PRIMARY_100 = exports.COLOR_DANGER_15 = exports.COLOR_DANGER_100 = exports.COLOR_SYSTEM_BLUE = exports.COLOR_BLACK_30 = exports.COLOR_BLACK = exports.COLOR_WHITE_20 = exports.COLOR_WHITE_60 = exports.COLOR_WHITE_80 = exports.COLOR_WHITE = void 0;
// export const COLOR_WHITE = "#ffffff"
exports.COLOR_WHITE = "#E3E2E6";
exports.COLOR_WHITE_80 = "rgba(255, 255, 255, 0.8)";
exports.COLOR_WHITE_60 = "rgba(255, 255, 255, 0.6)";
exports.COLOR_WHITE_20 = "rgba(255, 255, 255, 0.2)";
exports.COLOR_BLACK = "#000000";
exports.COLOR_BLACK_30 = "rgba(0, 0, 0, 0.3)";
exports.COLOR_SYSTEM_BLUE = "#2877EF";
exports.COLOR_DANGER_100 = "#f88a8a";
exports.COLOR_DANGER_15 = "#524043";
exports.COLOR_PRIMARY_100 = "#0089ff";
exports.COLOR_SECONDARY = "#1c1e21";
exports.COLOR_TERTIARY = "#313437";
exports.COLOR_TERTIARY_90 = "#46484B";
exports.COLOR_NEUTRAL = "#101113";
exports.COLOR_NEUTRAL_90 = "#28292B";
exports.COLOR_OFFWHITE = "#E3E2E6";
exports.COLOR_OFFWHITE_90 = "#CCCCCC";
exports.COLOR_OFFWHITE_80 = "#B8B8B8";
exports.COLOR_OFFWHITE_70 = "#a3a3a3";
exports.COLOR_OFFWHITE_50 = "#828282";
exports.COLOR_INPUT_PLACEHOLDER_GREY = "#ADAEAF";
exports.COLOR_INPUT_FIELD_GREY = "#313437";
exports.COLOR_BADGE_RED = "#cb0811";
exports.COLOR_RECORDING_COLORS_ARRAY = ["#FFB800", "#FF005C", "#6580FF", "#854BFF", "#E92B47", "#3CFFD0", "#ED6B86", "#FF785A"];
exports.SVG_FILTER_OFFWHITE_70 = "brightness(0) saturate(100%) invert(74%) sepia(0%) saturate(0%) hue-rotate(187deg) brightness(90%) contrast(83%)";
exports.SVG_FILTER_OFFWHITE_50 = "brightness(0) saturate(100%) invert(55%) sepia(0%) saturate(65%) hue-rotate(146deg) brightness(94%) contrast(83%)";
exports.SVG_FILTER_TERTIARY_90 = "brightness(0) saturate(100%) invert(26%) sepia(3%) saturate(606%) hue-rotate(177deg) brightness(99%) contrast(89%)";
exports.SVG_FILTER_NEUTRAL = "brightness(0) saturate(100%) invert(3%) sepia(3%) saturate(4135%) hue-rotate(182deg) brightness(99%) contrast(92%)";
