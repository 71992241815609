"use strict";
exports.__esModule = true;
exports.PORTAL_SECTION_ENUM = exports.CUSTOMER_TEMPLATE_SECTION_FIELD_TYPE_ENUM = void 0;
var CUSTOMER_TEMPLATE_SECTION_FIELD_TYPE_ENUM;
(function (CUSTOMER_TEMPLATE_SECTION_FIELD_TYPE_ENUM) {
    CUSTOMER_TEMPLATE_SECTION_FIELD_TYPE_ENUM["TEXT"] = "text";
    CUSTOMER_TEMPLATE_SECTION_FIELD_TYPE_ENUM["PASSWORD"] = "password";
    CUSTOMER_TEMPLATE_SECTION_FIELD_TYPE_ENUM["NUMBER"] = "number";
    CUSTOMER_TEMPLATE_SECTION_FIELD_TYPE_ENUM["EMAIL"] = "email";
    CUSTOMER_TEMPLATE_SECTION_FIELD_TYPE_ENUM["SELECT"] = "select";
    CUSTOMER_TEMPLATE_SECTION_FIELD_TYPE_ENUM["CHECKBOX"] = "checkbox";
    CUSTOMER_TEMPLATE_SECTION_FIELD_TYPE_ENUM["DATE"] = "date";
    CUSTOMER_TEMPLATE_SECTION_FIELD_TYPE_ENUM["STATIC_TEXT"] = "static_text";
})(CUSTOMER_TEMPLATE_SECTION_FIELD_TYPE_ENUM = exports.CUSTOMER_TEMPLATE_SECTION_FIELD_TYPE_ENUM || (exports.CUSTOMER_TEMPLATE_SECTION_FIELD_TYPE_ENUM = {}));
var PORTAL_SECTION_ENUM;
(function (PORTAL_SECTION_ENUM) {
    PORTAL_SECTION_ENUM["HOMEPAGE"] = "homepage";
    PORTAL_SECTION_ENUM["LIVE"] = "live";
    PORTAL_SECTION_ENUM["RADIO"] = "radio";
    PORTAL_SECTION_ENUM["VOD"] = "vod";
    PORTAL_SECTION_ENUM["RECORDINGS"] = "recordings";
    PORTAL_SECTION_ENUM["MY_LIST"] = "my_list";
    PORTAL_SECTION_ENUM["APPS"] = "apps";
})(PORTAL_SECTION_ENUM = exports.PORTAL_SECTION_ENUM || (exports.PORTAL_SECTION_ENUM = {}));
