import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {INPUT_FIELD_TYPE_ENUM} from "@motv-webapp/lib";
import styled from "styled-components";
import Input from "../../../InputFields/Input";
import {useIntl} from "react-intl";
import {
    user_customers_profiles_id,
    user_selectedProfile
} from "@motv-webapp/redux";
import {useAppSelector} from "@motv-webapp/redux";
import SelectBox from "../../../InputFields/SelectBox";
import {fetch_mw_epgRatingGetOptions} from "@motv-webapp/redux";
import {ProfileEntity} from "@motv-webapp/lib";

const ProfileCreateEditContainer = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: 0,
}));

type Props = {
    profile: ProfileEntity
    onChange: (key: string, value: string) => void
    errors: { [key: string]: string | undefined }
    disableAllFields: boolean
    isPinControlEnabled: boolean
    setIsPinControlEnabled: Dispatch<SetStateAction<boolean>>
}

const ProfileCreateEdit = ({
                               profile,
                               onChange,
                               errors,
                               disableAllFields,
                               isPinControlEnabled,
                               setIsPinControlEnabled
                           }: Props) => {
    const intl = useIntl();
    const selectedProfile = useAppSelector(user_selectedProfile);
    const mainProfileId = useAppSelector(user_customers_profiles_id);
    const [epgRating, setEpgRating] = useState([] as { key: string, value: string }[])

    const handleNameChange = (value: string) => {
        onChange("profiles_name", value)
    }
    const handlePinChange = (value: string) => {
        onChange("profiles_pin", value)
    }
    const handleAgeChange = (value: string) => {
        onChange("profiles_age", value)
    }
    const handlePinControlChange = () => {
        setIsPinControlEnabled(!isPinControlEnabled)
    }

    useEffect(() => {
        let array = [] as { key: string, value: string }[];
        fetch_mw_epgRatingGetOptions()
            .then((r) => {
                r.response.forEach((age: { name: string, year: number }) => (
                    array.push({key: age.name, value: age.year.toString()})
                ))
                setEpgRating(array)
            })
    }, [])

    return (
        <ProfileCreateEditContainer>
            <Input disabled={disableAllFields} errorMessageId={errors.profiles_name} placeholder={intl.formatMessage({
                id: "label_settings_profile_name",
                defaultMessage: "Profile name"
            })} type={INPUT_FIELD_TYPE_ENUM.TEXT} onChange={handleNameChange}
                   value={profile.profiles_name ? profile.profiles_name : ""}/>
            <Input disabled={disableAllFields} errorMessageId={errors.profiles_pin} placeholder={"PIN"}
                   type={INPUT_FIELD_TYPE_ENUM.NUMBER} onChange={handlePinChange}
                   value={profile.profiles_pin ? profile.profiles_pin : ""}/>
            {selectedProfile && (selectedProfile.profiles_id === mainProfileId) && (
                <>
                    { epgRating.length !== 0 &&
                      <SelectBox errorMessageId={errors.profiles_age} onChange={handleAgeChange} options={epgRating}
                                 placeholder={intl.formatMessage({
                                     id: "label_age_restriction_of_content",
                                     defaultMessage: "Age restriction of content"
                                 })}
                                 defaultValue={profile.profiles_age ? profile.profiles_age.toString() : ""}
                      />}
                    <Input errorMessageId={errors.profiles_pin_enabled} placeholder={intl.formatMessage({
                        id: "label_require_pin_for_adult_content",
                        defaultMessage: "Require PIN for adult content"
                    })}
                           checked={isPinControlEnabled}
                           type={INPUT_FIELD_TYPE_ENUM.CHECKBOX} onChangeChecked={handlePinControlChange}/>
                </>
            )}
        </ProfileCreateEditContainer>
    )
}

export default ProfileCreateEdit
