import React, {Dispatch, SetStateAction} from 'react';
import styled from "styled-components";
import Dialog from "../Dialog/Dialog";
import {RecommendationCardEntity} from "@motv-webapp/lib";
import {useKey} from "react-use";

const CloseButton = styled.button(props => ({
    background: "transparent",
    border: 0,
    height: "2.6rem",
    padding: 0,
    position: "absolute",
    right: "0.9rem",
    top: "0.9rem",
    width: "2.6rem",
    "& img": {
        height: "2.6rem",
        width: "2.6rem",
    }
}));

type Props = {
    mediaCard: RecommendationCardEntity
    setEnlargeOpen: Dispatch<SetStateAction<boolean>>
};

const EnlargeImageDialog = ({mediaCard, setEnlargeOpen}: Props) => {

    const handleCloseDialog = () => {
        setEnlargeOpen(false)
    }

    useKey('Escape', handleCloseDialog);

    return (
        <Dialog {...{handleCloseDialog}} >
            <CloseButton onClick={handleCloseDialog}>
                <img src={"/images/icons/icon_close.svg"} alt={""}/>
            </CloseButton>
            <img src={mediaCard.image_widescreen ? mediaCard.image_widescreen : mediaCard.image} alt={""}/>
        </Dialog>
    );
};

export default EnlargeImageDialog;
