import React, {useEffect, useState} from "react";
import {SubscribedChannelDragAndDropEntity, SubscribedChannelEntity} from "@motv-webapp/lib";
import ChannelListItem from "./ChannelListItem";
import {COLOR_NEUTRAL_90} from "@motv-webapp/lib";
import {useAppDispatch} from "@motv-webapp/redux";
import {updateChannelOrder} from "@motv-webapp/redux";
import {CHANNEL_TYPE_ENUM} from "@motv-webapp/lib";
import styled from "styled-components";
import {
    closestCenter,
    DndContext,
    DragOverlay,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import {arrayMove, rectSortingStrategy, SortableContext, sortableKeyboardCoordinates} from "@dnd-kit/sortable";
import SortableItem from "../Sortable/SortableItem";

const Divider = styled.div(props => ({
    background: COLOR_NEUTRAL_90,
    height:"2px",
    margin:"32px 64px 32px 0",
}));

const SortableContextContainer = styled.div(props => ({
    columnGap:"16px",
    display:"flex",
    flexDirection:"row",
    flexWrap:"wrap",
    rowGap:"24px",
}));

type Props = {
    isChannelList?: boolean
    channels: Array<SubscribedChannelEntity>
    isChannelReorderEnabled: boolean
}

const ChannelReorderList = ({channels, isChannelReorderEnabled, isChannelList = false}: Props) => {
    const [activeChannel, setActiveChannel] = useState({} as SubscribedChannelEntity)
    const [dragAndDropChannels, setDragAndDropChannels] = useState([] as SubscribedChannelDragAndDropEntity[])
    const [channelOrderChanged, setChannelOrderChanged] = useState(false)
    const dispatch = useAppDispatch()

    useEffect(() => {
        const array = channels.map((channel) => {
            return {...channel, id: channel.channels_id} as SubscribedChannelDragAndDropEntity
        })
        setDragAndDropChannels(array)
    },[])

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    const handleDragStart = (event:any) => {
        const channel = channels.find(x => x.channels_id === event.active.id);
        channel && setActiveChannel(channel)
    };

    const handleDragEnd = (event:any) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            setChannelOrderChanged(true)
            setDragAndDropChannels((dragAndDropChannels) => {
                const oldIndex = dragAndDropChannels.findIndex((i) => i.id === active.id);
                const newIndex = dragAndDropChannels.findIndex((i) => i.id === over.id);
                return arrayMove(dragAndDropChannels, oldIndex, newIndex);
            });
        }
        setActiveChannel({} as SubscribedChannelEntity)
    };

    const mapChannelsForMW = () => {
        let mappedChannels = {} as Array<number>
        let order = 0
        dragAndDropChannels.forEach((channel) => {
            let mappedChannels2 = mappedChannels
            mappedChannels = {...mappedChannels2, [`${channel.channels_id}`]: order}
            order = order + 1
        })
        return mappedChannels
    }

    useEffect(() => {
        if (channelOrderChanged) {
            dispatch(updateChannelOrder({
                type: isChannelList ? CHANNEL_TYPE_ENUM.CHANNEL : CHANNEL_TYPE_ENUM.RADIO,
                channels: mapChannelsForMW()
            }))
        }
    }, [dragAndDropChannels])

    return (
        <>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                onDragStart={handleDragStart}
            >
                {!isChannelList && <Divider/>}
                <SortableContextContainer>
                    <SortableContext
                        items={dragAndDropChannels}
                        strategy={rectSortingStrategy}>
                        {dragAndDropChannels.map((channel) => (
                            <SortableItem key={channel.id} id={channel.id}>
                                <ChannelListItem channel={channel} isChannelReorderEnabled={isChannelReorderEnabled}/>
                            </SortableItem>
                        ))}
                        <DragOverlay>
                            {activeChannel ? (
                                <ChannelListItem channel={activeChannel} isChannelReorderEnabled={isChannelReorderEnabled}/>
                            ) : null}
                        </DragOverlay>
                    </SortableContext>
                </SortableContextContainer>
            </DndContext>
        </>
    );
}


export default ChannelReorderList;
