import React, {ChangeEvent, useEffect, useState} from 'react';
import Dialog from "../Dialog/Dialog";
import styled from "styled-components";
import {fetch_mw_profileGetPredefinedProfileImages} from "@motv-webapp/redux";
import {isResponseSuccessful} from "@motv-webapp/lib";
import Input from "../InputFields/Input";
import {FormattedMessage, useIntl} from "react-intl";
import PrimaryButton from "../Button/PrimaryButton";
import {COLOR_WHITE} from "@motv-webapp/lib";
import {useAppDispatch, useAppSelector} from "@motv-webapp/redux";
import {vendor_vendors_id} from "@motv-webapp/redux";
import {CreateProfileWithFakeProfileHeader, getProfiles, user_profiles} from "@motv-webapp/redux";
import {useKey} from "react-use";
import {useNavigate, useParams} from "react-router-dom";
import {INPUT_FIELD_TYPE_ENUM} from "@motv-webapp/lib";
import {createFileFromUri} from "@motv-webapp/lib";
import ImagePicker from "./ImagePicker";
import {BUTTON_TYPE_ENUM} from "@motv-webapp/lib";

const CloseButton = styled.button(props => ({
    background: "transparent",
    border: 0,
    height: "2.6rem",
    padding: 0,
    position: "absolute",
    right: "0.9rem",
    top: "0.9rem",
    width: "2.6rem",
    "& img": {
        height: "2.6rem",
        width: "2.6rem",
    }
}));

const BackButton = styled.button(props => ({
    background: "transparent",
    border: 0,
    height: "2.6rem",
    padding: 0,
    position: "absolute",
    left: "0.9rem",
    top: "0.9rem",
    width: "2.6rem",
    "& img": {
        height: "2.6rem",
        width: "2.6rem",
    }
}));

interface ProfileButtonProps {
    readonly selectedAvatar: string | undefined;
}

const ProfileButton = styled.button<ProfileButtonProps>`
  align-self: center;
  background-color: #000;
  background-image: ${props => `url(${props.selectedAvatar})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  height: 11.25rem;
  margin-bottom: 3rem;
  padding: 0;
  width: 11.25rem;

  & > p {
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    color: ${COLOR_WHITE};
    display: none;
    height: 100%;
    font-weight: 800;
    justify-content: center;
    margin: 0;
    width: 100%;
  }

  :hover {
    & > p {
      display: flex;
    }
  }
`

type Props = {
    CONST_NEW_PROFILE_DIALOG_STEP_ONE: string
    CONST_NEW_PROFILE_DIALOG_STEP_TWO: string
};

const NewProfileDialog = ({CONST_NEW_PROFILE_DIALOG_STEP_ONE, CONST_NEW_PROFILE_DIALOG_STEP_TWO}: Props) => {
    const [selectedAvatar, setSelectedAvatar] = useState("")
    const [predefinedProfileImages, setPredefinedProfileImages] = useState([])
    const [profileName, setProfileName] = useState("")
    const [profileNameError, setProfileNameError] = useState("")
    const [imagePickerOpened, setImagePickerOpened] = useState(false)
    const vendorsId = useAppSelector(vendor_vendors_id)
    const intl = useIntl()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const userProfiles = useAppSelector(user_profiles)
    const {dialog} = useParams()

    //Fetch getPredefinedProfileImages ...
    useEffect(() => {
        vendorsId && fetch_mw_profileGetPredefinedProfileImages({vendorsId:vendorsId})
            .then(response => {
                if (isResponseSuccessful(response)) {
                    setPredefinedProfileImages(response.response)
                    handleSelectNewPredefinedImage(response.response[0], true)
                }
            })
    }, []) //eslint-disable-line

    // ...and setSelectedAvatar to first in array of predefined images
    useEffect(() => {
        predefinedProfileImages[0] && handleSelectNewPredefinedImage(predefinedProfileImages[0], true)
    }, [predefinedProfileImages]) // eslint-disable-line

    const handleCloseDialog = () => {
        navigate(`/select-profile`)
        setProfileName("")
        setProfileNameError("")
        setImagePickerOpened(false)
    }

    const handleOpenImagePicker = () => {
        navigate(`/select-profile/${CONST_NEW_PROFILE_DIALOG_STEP_TWO}`)
    }

    const handleCloseImagePicker = () => {
        navigate(`/select-profile/${CONST_NEW_PROFILE_DIALOG_STEP_ONE}`)
    }

    useEffect(() => {
        if (dialog === CONST_NEW_PROFILE_DIALOG_STEP_TWO) {
            if (!imagePickerOpened) setImagePickerOpened(true)
        } else {
            if (imagePickerOpened) setImagePickerOpened(false)
        }
    }, [dialog]) // eslint-disable-line

    const handleUploadNewImage = (e: ChangeEvent<HTMLInputElement>) => {
        if (e?.target?.files?.length && e.target.files[0]) {
            const reader = new FileReader();

            reader.addEventListener("load", function () {
                setSelectedAvatar(reader.result ? reader.result as string : "")
            });

            reader.readAsDataURL(e.target.files[0]);
        }
        navigate(`/select-profile/${CONST_NEW_PROFILE_DIALOG_STEP_ONE}`)
    }


    const handleSelectNewPredefinedImage = (image: string, doNotNavigate: boolean = false) => {
        createFileFromUri(image).then((file: File) => {
            const reader = new FileReader();
            reader.addEventListener("load", function () {
                setSelectedAvatar(reader.result ? reader.result as string : "")
            });
            if (file) {
                reader.readAsDataURL(file);
            }
        });
        if (!doNotNavigate) navigate(`/select-profile/${CONST_NEW_PROFILE_DIALOG_STEP_ONE}`)
    }

    const handleSaveNewProfile = async () => {
        setProfileNameError("")
        if (!profileName || profileName === "") {
            setProfileNameError("message_required_field")
            return
        }
        if (userProfiles) {
            for (let i = 0; i < userProfiles.length; i++) {
                if (userProfiles[i].profiles_name === profileName) {
                    setProfileNameError("message_duplicate_profile_name")
                    return
                }
            }
        }
        if (userProfiles && userProfiles[0].profiles_id) await dispatch(CreateProfileWithFakeProfileHeader({
            body: {
                profilesId: null,
                data: {
                    ...((selectedAvatar && selectedAvatar !== "") ? {image: selectedAvatar.substring(selectedAvatar.indexOf(';base64,') + ';base64,'.length, selectedAvatar.length)} : {image:undefined}),
                    profiles_name: profileName,
                    profiles_pin_enabled: 1,
                    profiles_birthday:undefined,
                    profiles_pin:undefined,
                    profiles_protect:undefined,
                    profiles_age:undefined,
                    channels_whitelisting:undefined,
                }
            }, fakeProfileHeader: btoa(userProfiles[0].profiles_id.toString())
        }))
        await dispatch(getProfiles())
        navigate(`/select-profile`)
    }
    useKey('Escape', handleCloseDialog);

    return (
        <Dialog {...{handleCloseDialog}}>
            {imagePickerOpened
                ? (
                    <>
                        <BackButton onClick={handleCloseImagePicker}>
                            <img src={"/images/icons/icon_arrow_back.svg"} alt={""}/>
                        </BackButton>
                        <CloseButton onClick={handleCloseDialog}>
                            <img src={"/images/icons/icon_close.svg"} alt={""}/>
                        </CloseButton>
                        <ImagePicker predefinedProfileImages={predefinedProfileImages}
                                     handleSelectNewPredefinedImage={handleSelectNewPredefinedImage}
                                     handleUploadNewImage={handleUploadNewImage}/>
                    </>
                )
                : (
                    <>
                        <CloseButton onClick={handleCloseDialog}>
                            <img src={"/images/icons/icon_close.svg"} alt={""}/>
                        </CloseButton>
                        <ProfileButton onClick={handleOpenImagePicker} {...{selectedAvatar}}><p><FormattedMessage
                            id={"label_edit"} defaultMessage={"Edit"}/></p></ProfileButton>
                        <Input autofocus={true}
                               errorMessageId={profileNameError}
                               marginBottom={"1.5rem"}
                               maxLength={40}
                               onChange={setProfileName}
                               onEnter={handleSaveNewProfile}
                               placeholder={intl.formatMessage({
                                   id: "label_settings_profile_name",
                                   defaultMessage: "Profile Name"
                               })}
                               type={INPUT_FIELD_TYPE_ENUM.TEXT}
                               value={profileName}
                        />
                        <PrimaryButton
                            buttonType={BUTTON_TYPE_ENUM.OFFWHITE}
                            label={intl.formatMessage({id: "label_submit", defaultMessage: "Submit"})}
                            onClick={handleSaveNewProfile}
                        />
                    </>
                )}

        </Dialog>
    );
};

export default NewProfileDialog;
