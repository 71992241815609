import {AnimatePresence, motion} from 'framer-motion';
import React, {useState} from 'react';
import styled from "styled-components";
import {COLOR_WHITE} from "@motv-webapp/lib";
import {FormattedMessage} from "react-intl";
import LoginHeader from "./LoginHeader";
import CustomerEdit from "../Customer/CustomerEdit";
import Div100vh from 'react-div-100vh';
import CreateAccountGsmStep from "./CreateAccountGsmStep";

const CreateAccountPanelContainer = styled(motion(Div100vh))(props => ({
    alignItems: "flex-start",
    backgroundColor: "transparent",
    color: COLOR_WHITE,
    display: "flex",
    justifyContent: "space-around",
    overflowY: "scroll",
    position: "absolute",
    right: 0,
    width: "40vw",
    "& h1": {
        marginBottom: "2rem",
    },
    "@media (max-width: 800px)": {
        width: "100vw",
    }
}));

const CreateAccountPanelContent = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    margin: "8rem 0 6rem 0",
    width: "20rem",
    "& h1": {
        marginTop: 0,
    }
}));

type Props = {
};

const CreateAccountPanel = ({}: Props) => {
    const [gsmStep, setGsmStep] = useState(false)

    return (
        <CreateAccountPanelContainer
            initial={{x: "100%"}}
            animate={{x: 0}}
            exit={{x: "100%"}}
            transition={{type: "spring", duration: 1}}
        >
            <LoginHeader logoutOnBack={true} />
            <CreateAccountPanelContent>
                <h1>
                    <FormattedMessage id={"label_create_account"} defaultMessage={"Create an Account"}/>
                </h1>
                <AnimatePresence>
                    {gsmStep
                        ? <CreateAccountGsmStep />
                        : <CustomerEdit isRegistration={true} {...{setGsmStep}} />
                    }
                </AnimatePresence>
            </CreateAccountPanelContent>
        </CreateAccountPanelContainer>
    );
};

export default CreateAccountPanel;
