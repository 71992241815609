import React, {Dispatch, SetStateAction, useState} from 'react';
import styled from "styled-components";
import {motion} from "framer-motion";
import Input from "../InputFields/Input";
import {useIntl} from "react-intl";
import PrimaryButton from "../Button/PrimaryButton";
import {fetch_sms_devicesMotvApiRequestNewPassword} from "@motv-webapp/redux";
import {isResponseSuccessful} from "@motv-webapp/lib";
import {ERROR_CODE_SMS_UNKNOWN_LOGIN} from "@motv-webapp/lib";
import {useNavigate} from "react-router-dom";
import {INPUT_FIELD_TYPE_ENUM} from "@motv-webapp/lib";
import {useAppSelector} from "@motv-webapp/redux";
import {vendor_vendors_id} from "@motv-webapp/redux";
import {getClientConfig} from "@motv-webapp/config";

const StepOneContainer = styled(motion.div)(props => ({
    display: "flex",
    flexDirection: "column",
}));

type Props = {
    setStepsFinished: Dispatch<SetStateAction<number>>
    PANEL_FORGOTTEN_PASSWORD: string
};

const ForgottenPasswordFormStepOne = ({setStepsFinished, PANEL_FORGOTTEN_PASSWORD}: Props) => {
    const [loginToReset, setLoginToReset] = useState("")
    const [loginToResetError, setLoginToResetError] = useState("")
    const [disableAllFields, setDisableAllFields] = useState(false)
    const vendorsId = useAppSelector(vendor_vendors_id)

    const intl = useIntl()
    const navigate = useNavigate()

    const handleSubmitForgottenPassword = async () => {
        setDisableAllFields(true)
        setLoginToResetError("")
        if (!loginToReset || loginToReset === "") {
            setLoginToResetError("message_required_field")
            setDisableAllFields(false)
            return
        }
        const response = await fetch_sms_devicesMotvApiRequestNewPassword({login: loginToReset, vendors_id: vendorsId ? vendorsId : getClientConfig().vendorId})
        if (!isResponseSuccessful(response)) {
            if (response.status === ERROR_CODE_SMS_UNKNOWN_LOGIN) {
                setLoginToResetError("message_email_does_not_exist")
            } else {
                setLoginToResetError("message_something_went_wrong")
            }
            setDisableAllFields(false)
            return
        }
        setStepsFinished(1)
        navigate(`/login/${PANEL_FORGOTTEN_PASSWORD}/1`)
        setLoginToReset("")
        setLoginToResetError("")
        setDisableAllFields(false)
    }
    return (
        <StepOneContainer
            initial={{x: "100%"}}
            animate={{x: 0}}
            exit={{x: "100%"}}
            transition={{type: "spring", duration: 1}}
        >
            <Input disabled={disableAllFields}
                   dataTestid={"forgottenPasswordUsername"}
                   errorMessageId={loginToResetError}
                   onEnter={handleSubmitForgottenPassword}
                   onChange={setLoginToReset}
                   placeholder={intl.formatMessage({id: 'label_login', defaultMessage: 'Login'})}
                   type={INPUT_FIELD_TYPE_ENUM.TEXT}
            />
            <PrimaryButton
                disabled={disableAllFields}
                label={intl.formatMessage({id: "reset_password", defaultMessage: "Reset password"})}
                onClick={handleSubmitForgottenPassword}
                style={{marginTop: 16}}
            />
        </StepOneContainer>
    );
};

export default ForgottenPasswordFormStepOne;
