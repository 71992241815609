import React from 'react';
import './index.css';
import App from './App';
import {store} from '@motv-webapp/redux';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import {createRoot} from "react-dom/client";

Sentry.init({
    dsn: "https://b955fab225964b21a0e8c203c0a82270@o221143.ingest.sentry.io/6697824",
    beforeSend(event, hint) {
        const error = hint.originalException;
        if (
            error &&
            typeof error === "object" && "message" in error && error.message &&
            (error.message.match(/removeChild/i)
            || error.message.match(/getItem/i) // filters error from webViews and browsers that do not allow localStorage
            || error.message.match(/requestFullscreen/i) // filters error from iOS browsers that don't handle shakaPlayer correctly
            || error.message.match(/scrollTo/i) // filters error from very old browser that don't have full support for window.scrollTo
            || error.message.match(/navigator.serviceWorker/i) // filters error from Safari which probably blocks serviceWorkers
            )
        ) {
            return null
        }
        return event;
    },
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

const container = document.getElementById('root');
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
