import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {
    channel_channels,
    myListAddToMyList,
    myListRemoveFromMyList,
    recordingAddRecording,
    recordingRemoveRecording,
    universal_myList,
    universal_myRecordingIds,
    universal_selectedItem,
    useAppDispatch,
    useAppSelector,
    user_customers_recording_length,
    user_customers_recording_used,
    user_selectedLanguage,
} from "@motv-webapp/redux";
import {
    COLOR_WHITE,
    RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM,
    RecommendationCardEntity,
    SubscribedChannelEntity,
    TIME_CONST_ONE_MINUTE,
    TYPEFACE_BODY_MEDIUM,
    TYPEFACE_CAPTION_BOLD,
    isRecommendationCardType,
} from "@motv-webapp/lib";
import {FormattedMessage} from "react-intl";
import IconButtonTransparent from "../Button/IconButtonTransparent";
import {debounce} from "debounce";
import {useNavigate} from "react-router-dom";

const SideControllerDescContainer = styled.div(props => ({
    color: COLOR_WHITE,
    margin: "32px 32px 16px 16px",
    "& > h2": {
        marginBottom: 8,
    }
}));

const EpisodeTitle = styled.div(props => ({
    ...TYPEFACE_BODY_MEDIUM,
    marginBottom: 8,
    marginTop: 0,
}));

const EpgDetail = styled.div(props => ({
    ...TYPEFACE_BODY_MEDIUM,
    alignItems: "center",
    display: "flex",
}));

const EpgDetailChannel = styled.div((props: { channelImage: string }) => ({
    backgroundImage: `url(${props.channelImage})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    borderRadius: 6,
    height: 42,
    marginRight: 8,
    width: 42,
}));

const EpgDetailDate = styled.div(props => ({}));

const ActionButtons = styled.div(props => ({
    display: "flex",
    gap: 20,
    marginBottom: 24,
    marginTop: 24,
}));

const Description = styled.div(props => ({
    marginBottom: 24,
}));

const ActorsAndDirectors = styled.div(props => ({
    ...TYPEFACE_CAPTION_BOLD,
    marginBottom: 24,
}));

const SideControllersDesc = () => {
    const [recordable, setRecordable] = useState(false)
    const [isInMyList, setIsInMyList] = useState(false)
    const [isInMyRecordings, setIsInMyRecordings] = useState(false)
    const channels = useAppSelector(channel_channels) as Array<SubscribedChannelEntity>
    const customersRecordingLength = useAppSelector(user_customers_recording_length)
    const customersRecordingUsed = useAppSelector(user_customers_recording_used)
    const currentlyPlaying = useAppSelector(universal_selectedItem) as RecommendationCardEntity
    const language = useAppSelector(user_selectedLanguage)
    const myList = useAppSelector(universal_myList)
    const myRecordingIds = useAppSelector(universal_myRecordingIds)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (!currentlyPlaying || !isRecommendationCardType(currentlyPlaying)) return
        if (!currentlyPlaying.start
            || !channels?.find(channel => channel.channels_id === currentlyPlaying.channels_id)?.channels_recording_length
            || !channels?.find(channel => channel.channels_id === currentlyPlaying.channels_id)?.customers_recording_length
            || !channels?.find(channel => channel.channels_id === currentlyPlaying.channels_id)?.channels_recording_length) return
        const now = Date.now();
        const start = Date.parse(currentlyPlaying.start);
        setRecordable(
            (currentlyPlaying.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV || currentlyPlaying.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.RECORDING) && (
                !!channels.find(channel => channel.channels_id === currentlyPlaying.channels_id) &&
                channels.find(channel => channel.channels_id === currentlyPlaying.channels_id)?.channels_recording_length! > 0 &&
                now - channels.find(channel => channel.channels_id === currentlyPlaying.channels_id)?.customers_recording_length! * TIME_CONST_ONE_MINUTE < start &&
                now - channels.find(channel => channel.channels_id === currentlyPlaying.channels_id)?.channels_recording_length! * TIME_CONST_ONE_MINUTE < start &&
                !!currentlyPlaying?.duration &&
                customersRecordingLength - customersRecordingUsed > currentlyPlaying.duration)
        )
    }, [currentlyPlaying?.id, channels])

    useEffect(() => {
        myList?.some(item => item.id === currentlyPlaying?.id) ? setIsInMyList(true) : setIsInMyList(false)
    }, [myList, currentlyPlaying])

    useEffect(() => {
        currentlyPlaying?.id && myRecordingIds?.includes(currentlyPlaying?.id) ? setIsInMyRecordings(true) : setIsInMyRecordings(false)
    }, [myRecordingIds, currentlyPlaying])

    const handleMyListButton = () => {
        if (currentlyPlaying.type && currentlyPlaying.id) {
            if (isInMyList) {
                dispatch(myListRemoveFromMyList({type: currentlyPlaying.type, id: currentlyPlaying.id}))
            } else {
                dispatch(myListAddToMyList({type: currentlyPlaying.type, id: currentlyPlaying.id}))
            }
        }
    }

    const handleRecordButton = () => {
        if (currentlyPlaying.id) {
            if (isInMyRecordings) {
                dispatch(recordingRemoveRecording({epgEventsId: currentlyPlaying.id}))
            } else {
                dispatch(recordingAddRecording({epgEventsId: currentlyPlaying.id}))
            }
        }
    }

    const handleCategoryButton = () => {
        if (currentlyPlaying.categories_id) {
            navigate(`/category/${currentlyPlaying.categories_id}`)
        }
    }

    return (
        <>
            {currentlyPlaying && isRecommendationCardType(currentlyPlaying) &&
                (
                    <SideControllerDescContainer>
                        <h2>{currentlyPlaying.title}</h2>
                        {currentlyPlaying.episode && <EpisodeTitle>{currentlyPlaying.episode}</EpisodeTitle>}
                        {(currentlyPlaying.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV
                                || currentlyPlaying.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.RECORDING) &&
                          <EpgDetail>
                              {currentlyPlaying.channels_logo &&
                                <EpgDetailChannel channelImage={currentlyPlaying.channels_logo}/>}
                              {currentlyPlaying.start && <EpgDetailDate>
                                  {new Intl.DateTimeFormat(language, {
                                      month: 'long', day: 'numeric',
                                      hour: 'numeric', minute: 'numeric',
                                      hour12: false,
                                  }).format(Date.parse(currentlyPlaying.start))}
                              </EpgDetailDate>}
                          </EpgDetail>
                        }
                        <ActionButtons>
                            <IconButtonTransparent
                                hoverLabelId={isInMyList ? "label_event_remove_from_my_list" : "label_event_add_my_list"}
                                hoverLabelDefaultMessage={isInMyList ? "Remove from my list" : "Add to my list"}
                                img={isInMyList ? "/images/icons/icon_list_remove.svg" : "/images/icons/icon_list_add.svg"}
                                onClick={debounce(handleMyListButton, 200)}
                                size={24}
                            />
                            {(currentlyPlaying?.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV || currentlyPlaying?.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.RECORDING) && (recordable || isInMyRecordings) &&
                              <IconButtonTransparent
                                hoverLabelId={isInMyRecordings ? "label_event_remove_recording" : "label_record"}
                                hoverLabelDefaultMessage={isInMyRecordings ? "Remove from recordings" : "Record"}
                                img={isInMyRecordings ? "/images/icons/icon_record_remove.svg" : "/images/icons/icon_record.svg"}
                                onClick={debounce(handleRecordButton, 200)}
                                size={24}
                              />}
                            {currentlyPlaying?.categories_id && currentlyPlaying?.categories_name && !window.location.pathname.includes("category") &&
                              <IconButtonTransparent
                                hoverLabelId={"_"}
                                hoverLabelDefaultMessage={currentlyPlaying.categories_name}
                                img={"/images/icons/icon_category.svg"}
                                onClick={debounce(handleCategoryButton, 200)}
                                size={24}
                              />}
                        </ActionButtons>
                        {currentlyPlaying.description && <Description>{currentlyPlaying.description}</Description>}
                        {currentlyPlaying.actors &&
                          <ActorsAndDirectors>
                            <FormattedMessage id={"label_actors"} defaultMessage={"Actors"}/>
                              {`: ${currentlyPlaying.actors}`}
                          </ActorsAndDirectors>
                        }
                        {currentlyPlaying.directors &&
                          <ActorsAndDirectors>
                            <FormattedMessage id={"label_directors"} defaultMessage={"Directors"}/>
                              {`: ${currentlyPlaying.directors}`}
                          </ActorsAndDirectors>
                        }
                    </SideControllerDescContainer>
                )
            }
        </>
    );
};

export default SideControllersDesc;
