"use strict";
exports.__esModule = true;
exports.TYPEFACE_WEB_CAPTION_2 = exports.TYPEFACE_WEB_CAPTION_2_BOLD = exports.TYPEFACE_CAPTION = exports.TYPEFACE_CAPTION_BOLD = exports.TYPEFACE_WEB_BODY_2 = exports.TYPEFACE_WEB_BODY_2_MEDIUM = exports.TYPEFACE_BODY = exports.TYPEFACE_BODY_MEDIUM = exports.TYPEFACE_BODY_EXTRABOLD = exports.TYPEFACE_HEADLINE_3 = exports.TYPEFACE_HEADLINE_2 = exports.TYPEFACE_HEADLINE_2_SEMIBOLD = exports.TYPEFACE_HEADLINE = exports.TYPEFACE_TITLE = void 0;
exports.TYPEFACE_TITLE = {
    fontFamily: "\"Roboto Condensed\", \"Roboto\", Arial, sans-serif",
    fontSize: "3.815rem",
    fontWeight: "bold",
    fontStretch: "condensed",
    fontStyle: "normal",
    lineHeight: "1",
    letterSpacing: "normal"
};
exports.TYPEFACE_HEADLINE = {
    fontFamily: "\"Roboto\", Arial, sans-serif",
    fontSize: "1.953rem",
    fontWeight: "bold",
    fontStretch: "condensed",
    fontStyle: "normal",
    lineHeight: "1",
    letterSpacing: "normal"
};
exports.TYPEFACE_HEADLINE_2_SEMIBOLD = {
    fontFamily: "\"Roboto\", Arial, sans-serif",
    fontSize: "1.563rem",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1",
    letterSpacing: "normal"
};
exports.TYPEFACE_HEADLINE_2 = {
    fontFamily: "\"Roboto\", Arial, sans-serif",
    fontSize: "1.563rem",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1",
    letterSpacing: "normal"
};
exports.TYPEFACE_HEADLINE_3 = {
    fontFamily: "\"Roboto\", Arial, sans-serif",
    fontSize: "1.25rem",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1",
    letterSpacing: "normal",
    textAlign: "left"
};
exports.TYPEFACE_BODY_EXTRABOLD = {
    fontFamily: "\"Roboto\", Arial, sans-serif",
    fontSize: "1rem",
    fontWeight: "800",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1",
    letterSpacing: "normal"
};
exports.TYPEFACE_BODY_MEDIUM = {
    fontFamily: "\"Roboto\", Arial, sans-serif",
    fontSize: "1rem",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1",
    letterSpacing: "normal"
};
exports.TYPEFACE_BODY = {
    fontFamily: "\"Roboto\", Arial, sans-serif",
    fontSize: "1rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1",
    letterSpacing: "normal"
};
exports.TYPEFACE_WEB_BODY_2_MEDIUM = {
    fontFamily: "\"Roboto\", Arial, sans-serif",
    fontSize: "0.875rem",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1",
    letterSpacing: "normal"
};
exports.TYPEFACE_WEB_BODY_2 = {
    fontFamily: "\"Roboto\", Arial, sans-serif",
    fontSize: "0.875rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1",
    letterSpacing: "normal"
};
exports.TYPEFACE_CAPTION_BOLD = {
    fontFamily: "\"Roboto\", Arial, sans-serif",
    fontSize: "0.8rem",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1",
    letterSpacing: "normal"
};
exports.TYPEFACE_CAPTION = {
    fontFamily: "\"Roboto\", Arial, sans-serif",
    fontSize: "0.8rem",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1",
    letterSpacing: "normal"
};
exports.TYPEFACE_WEB_CAPTION_2_BOLD = {
    fontFamily: "\"Roboto\", Arial, sans-serif",
    fontSize: "0.64rem",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1",
    letterSpacing: "normal"
};
exports.TYPEFACE_WEB_CAPTION_2 = {
    fontFamily: "Roboto",
    fontSize: "0.64rem",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1",
    letterSpacing: "normal"
};
