import React, {useState} from "react";
import Dialog from "../Dialog/Dialog";
import styled from "styled-components";
import ProgressCircle from "./ProgressCircle";
import RecordingProfileCard from "./RecordingProfileCard";
import {FormattedMessage} from "react-intl";
import {
    useAppSelector,
    user_customers_recording_by_profile,
    user_customers_recording_length, user_customers_recording_used, user_profiles,
    vendor_vendors_accent_color
} from "@motv-webapp/redux";
import {
    COLOR_NEUTRAL_90,
    COLOR_OFFWHITE_90, COLOR_PRIMARY_100,
    COLOR_WHITE,
    TYPEFACE_BODY_MEDIUM,
    TYPEFACE_HEADLINE,
    TYPEFACE_HEADLINE_2, TYPEFACE_WEB_BODY_2_MEDIUM
} from "@motv-webapp/lib";

const CloseButton = styled.button(props => ({
    background: "transparent",
    border: 0,
    height: 24,
    padding: 0,
    position: "absolute",
    right: 20,
    top: 20,
    width: 24,
    "& img": {
        height: 24,
        width: 24,
    }
}));

const RecordingsDetailContainer = styled.div(props => ({
    alignItems:"center",
    display: "flex",
    gap:24,
    position:"relative",
    "@media (max-width: 800px)": {
        flexDirection:"column",
    }
}));
const RecordingsUsedSpaceContainer = styled.div(props => ({
    alignItems:"center",
    display: "flex",
    flexDirection:"column",
}));

const RecordingsUsedSpaceInfoContainer = styled.div(props => ({
    display: "flex",
    gap:64,
    justifyContent:"space-between",
    marginTop:48,
    whiteSpace:"nowrap"
}));

const RecordingsUsedSpaceMinutesContainer = styled.div(props => ({
    display: "flex",
    flexDirection:"column",
    gap:12,
    textAlign:"center",
    "& p":{
        ...TYPEFACE_WEB_BODY_2_MEDIUM,
        color:COLOR_OFFWHITE_90,
        margin:0,
        padding:0,
    },
    "& div":{
        ...TYPEFACE_BODY_MEDIUM,
        color: COLOR_WHITE
    },
}));

const RecordingsUsersTitle = styled.div(props => ({
    ...TYPEFACE_HEADLINE_2,
    color:COLOR_WHITE,
    "@media (max-width: 800px)": {
        textAlign:"center",
    }
}));

const RecordingsProfilesContainer = styled.div(props => ({
    display: "flex",
    flexWrap:"wrap",
    gap:16,
    height:400,
    marginTop:12,
    overflowY:"scroll",
    "::-webkit-scrollbar": {
        background: "transparent",
        width:6,
    },
    "::-webkit-scrollbar-thumb": {
        background: COLOR_NEUTRAL_90,
        borderRadius:8,
    },
    "@media (max-width: 800px)": {
        justifyContent:"center",
        overflowY:"visible",
    }
}));

type Props = {
    handleToggleDetails: () => void
}
const RecordingDetailDialog = ({handleToggleDetails}: Props) => {
    const accentColor = useAppSelector(vendor_vendors_accent_color)
    const profiles = useAppSelector(user_profiles)
    const recordingByProfile = useAppSelector(user_customers_recording_by_profile)
    const recordingLength = useAppSelector(user_customers_recording_length)
    const recordingUsed = useAppSelector(user_customers_recording_used)
    const [hover, setHover] = useState(false)

    const handleHover = () => {
        setHover(true)
    }
    const handleExitHover = () => {
        setHover(false)
    }

    return (
        <Dialog handleCloseDialog={handleToggleDetails} overridePadding={"64px 12px 64px 48px"}>
            <CloseButton onClick={handleToggleDetails}
                         onMouseEnter={handleHover}
                         onMouseLeave={handleExitHover}
            >
                <img src={hover ? "/images/icons/icon_close.svg" : "/images/icons/icon_close_tertiary.svg"} alt={""}/>
            </CloseButton>
            <RecordingsDetailContainer>
                <RecordingsUsedSpaceContainer>
                    <ProgressCircle
                        percents={(!!recordingUsed && recordingLength) ? Math.round(recordingUsed / recordingLength * 100) : 0}
                        strokeColor={accentColor ? accentColor : COLOR_PRIMARY_100} strokeWidth={8} size={200} color={COLOR_WHITE} typeface={TYPEFACE_HEADLINE}
                    />
                    <RecordingsUsedSpaceInfoContainer>
                        <RecordingsUsedSpaceMinutesContainer>
                            <p>
                                <FormattedMessage id={"label_used_space"} defaultMessage={"Used space"}/>
                            </p>
                            <div>{recordingUsed}{" "}
                                <FormattedMessage id={"label_minutes_abbreviated"} defaultMessage={"minutes"}/>
                            </div>
                        </RecordingsUsedSpaceMinutesContainer>
                        <RecordingsUsedSpaceMinutesContainer>
                            <p>
                                <FormattedMessage id={"label_free_space"} defaultMessage={"Free space"}/>
                            </p>
                            <div>{recordingLength}{" "}
                                <FormattedMessage id={"label_minutes_abbreviated"} defaultMessage={"minutes"}/>
                            </div>
                        </RecordingsUsedSpaceMinutesContainer>
                    </RecordingsUsedSpaceInfoContainer>
                </RecordingsUsedSpaceContainer>
                <div>
                    <RecordingsUsersTitle>
                        <FormattedMessage id={"label_settings_profiles"} defaultMessage={"Profiles"}/>
                    </RecordingsUsersTitle>
                    <RecordingsProfilesContainer>
                        {profiles && profiles.map((profile) => (
                            <RecordingProfileCard key={profile.profiles_id} {...{profile, recordingByProfile, recordingUsed}}/>
                        ))}
                    </RecordingsProfilesContainer>
                </div>
            </RecordingsDetailContainer>
        </Dialog>
    )
}

export default RecordingDetailDialog;