import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {fetch_sms_getPortalPageByName} from "@motv-webapp/redux";
import {getClientConfig} from "@motv-webapp/config";
import {Helmet} from "react-helmet";
import {FormattedMessage} from "react-intl";
import styled, {createGlobalStyle} from "styled-components";
import {
    COLOR_SECONDARY,
    COLOR_WHITE,
    SmsMotvPagesModelEntity,
    isResponseSuccessful,
} from "@motv-webapp/lib";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${COLOR_SECONDARY};
  }
  ,
`

const PortalPageContainer = styled.div(props => ({
    background: COLOR_SECONDARY,
    color: COLOR_WHITE,
    padding: "0 32px",
}));

const PortalPage = () => {
    const [pageHtml, setPageHtml] = useState<SmsMotvPagesModelEntity | undefined>(undefined)
    const {name} = useParams()

    useEffect(() => {
        name && fetch_sms_getPortalPageByName({motv_portals_pages_motv_portal_id: getClientConfig().portalId, name})
            .then(response => {
                if (isResponseSuccessful(response) && response.response !== null) {
                    setPageHtml(response.response)
                }
            })
    }, [name])

    function createMarkup(text: string) {
        return {__html: text};
    }

    return (
        <PortalPageContainer>
            <GlobalStyle />
            {pageHtml ?
                <div>
                    <Helmet>
                        <title>{pageHtml.motv_portals_pages_title}</title>
                    </Helmet>
                    <div dangerouslySetInnerHTML={createMarkup(pageHtml.motv_portals_pages_content)}/>
                </div> :
                <h1><FormattedMessage id={"label_page_not_found"} defaultMessage={"Page not found"}/></h1>}
        </PortalPageContainer>
    );
};

export default PortalPage;
