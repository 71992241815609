"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
exports.__esModule = true;
exports.vendor_vendors_qr_code = exports.vendor_vendors_outage_image = exports.vendor_vendors_name = exports.vendor_vendors_header_image = exports.vendor_customer_password_validation_regex = exports.vendor_vendors_background_image_scaling = exports.vendor_vendors_background_image_portal = exports.vendor_vendors_accent_color = exports.vendor_vendors_id = exports.vendorReducer = exports.vendorGetData = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var vendorAPI_1 = require("./vendorAPI");
var lib_1 = require("@motv-webapp/lib");
var error_1 = require("../error/error");
var lib_2 = require("@motv-webapp/lib");
var initialState = {
    status: "idle",
    retryCounter: 0,
    vendors_accent_color: undefined,
    vendors_background_gradient_from_color: undefined,
    vendors_background_gradient_to_color: undefined,
    vendors_background_image_phone_landscape: undefined,
    vendors_background_image_phone_portrait: undefined,
    vendors_background_image_portal: undefined,
    vendors_background_image_scaling: undefined,
    vendors_background_image_tablet_landscape: undefined,
    vendors_background_image_tablet_portrait: undefined,
    vendors_background_image_tv: undefined,
    vendors_background_solid_color: undefined,
    vendors_background_type: undefined,
    vendors_card_background_color: undefined,
    vendors_header_image: undefined,
    vendors_deeplink_scheme: undefined,
    vendors_dvb_networks: undefined,
    vendors_id: undefined,
    vendors_login_force_change_password: undefined,
    vendors_mac_login: undefined,
    vendors_multicast_unicast_fallback: undefined,
    vendors_name: undefined,
    vendors_outage_image: undefined,
    vendors_playback_inactivity_timeout: undefined,
    vendors_portal_url: undefined,
    vendors_push_reminders: undefined,
    vendors_qr_code: undefined,
    vendors_recommendation_engine_enabled: undefined,
    customer_password_validation_regex: undefined
};
exports.vendorGetData = (0, toolkit_1.createAsyncThunk)('vendor/fetchGetData', function (body, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response, vendor;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, vendorAPI_1.fetch_vendorGetData)(body)];
            case 1:
                response = _a.sent();
                vendor = thunkAPI.getState().vendor;
                if (!!(0, lib_1.isResponseSuccessful)(response)) return [3 /*break*/, 2];
                if (vendor.retryCounter > 3) {
                    thunkAPI.dispatch((0, error_1.addMwError)({ response: [{ errorAt: "vendorGetData" }], status: -1 }));
                }
                else {
                    setTimeout(function () { return thunkAPI.dispatch((0, exports.vendorGetData)(body)); }, lib_2.TIME_CONST_ONE_SECOND / 2);
                    return [2 /*return*/, thunkAPI.rejectWithValue({ errorAt: "vendorGetData" })];
                }
                return [3 /*break*/, 4];
            case 2: return [4 /*yield*/, response.response];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/];
        }
    });
}); });
exports.vendorReducer = (0, toolkit_1.createSlice)({
    name: 'vendor',
    initialState: initialState,
    reducers: {
    // setDeviceInfo: (state) => {
    //   const uaParser = new UAParser();
    //   const browser = uaParser.getBrowser();
    //   const os = uaParser.getOS();
    //
    //   state.deviceHash = getBrowserFingerprint({enableWebgl: true})
    //   state.deviceInfo = `${os['name']} ${os['version']} ${browser['name']} ${browser['major']}`
    // }
    },
    extraReducers: function (builder) {
        builder
            .addCase(exports.vendorGetData.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.vendorGetData.rejected, function (state) {
            state.status = 'failed';
            state.retryCounter += 1;
        })
            .addCase(exports.vendorGetData.fulfilled, updateVendorFromPayload);
    }
});
var updateVendorFromPayload = function (state, action) {
    if (action.payload) {
        state.status = 'idle';
        state.retryCounter = 0;
        state.vendors_accent_color = action.payload.vendors_accent_color;
        state.vendors_background_gradient_from_color = action.payload.vendors_background_gradient_from_color;
        state.vendors_background_gradient_to_color = action.payload.vendors_background_gradient_to_color;
        state.vendors_background_image_phone_landscape = action.payload.vendors_background_image_phone_landscape;
        state.vendors_background_image_phone_portrait = action.payload.vendors_background_image_phone_portrait;
        state.vendors_background_image_portal = action.payload.vendors_background_image_portal;
        state.vendors_background_image_scaling = action.payload.vendors_background_image_scaling;
        state.vendors_background_image_tablet_landscape = action.payload.vendors_background_image_tablet_landscape;
        state.vendors_background_image_tablet_portrait = action.payload.vendors_background_image_tablet_portrait;
        state.vendors_background_image_tv = action.payload.vendors_background_image_tv;
        state.vendors_background_solid_color = action.payload.vendors_background_solid_color;
        state.vendors_background_type = action.payload.vendors_background_type;
        state.vendors_card_background_color = action.payload.vendors_card_background_color;
        state.vendors_header_image = action.payload.vendors_header_image;
        state.vendors_deeplink_scheme = action.payload.vendors_deeplink_scheme;
        state.vendors_dvb_networks = action.payload.vendors_dvb_networks;
        state.vendors_id = action.payload.vendors_id;
        state.vendors_login_force_change_password = action.payload.vendors_login_force_change_password;
        state.vendors_mac_login = action.payload.vendors_mac_login;
        state.vendors_multicast_unicast_fallback = action.payload.vendors_multicast_unicast_fallback;
        state.vendors_name = action.payload.vendors_name;
        state.vendors_outage_image = action.payload.vendors_outage_image;
        state.vendors_playback_inactivity_timeout = action.payload.vendors_playback_inactivity_timeout;
        state.vendors_portal_url = action.payload.vendors_portal_url;
        state.vendors_push_reminders = action.payload.vendors_push_reminders;
        state.vendors_qr_code = action.payload.vendors_qr_code;
        state.vendors_recommendation_engine_enabled = action.payload.vendors_recommendation_engine_enabled;
        state.customer_password_validation_regex = action.payload.customer_password_validation_regex;
    }
};
// export const {} = vendorReducer.actions;
// Selectors
var vendor_vendors_id = function (state) { return state.vendor.vendors_id; };
exports.vendor_vendors_id = vendor_vendors_id;
var vendor_vendors_accent_color = function (state) { return state.vendor.vendors_accent_color; };
exports.vendor_vendors_accent_color = vendor_vendors_accent_color;
var vendor_vendors_background_image_portal = function (state) { return state.vendor.vendors_background_image_portal; };
exports.vendor_vendors_background_image_portal = vendor_vendors_background_image_portal;
var vendor_vendors_background_image_scaling = function (state) { return state.vendor.vendors_background_image_scaling; };
exports.vendor_vendors_background_image_scaling = vendor_vendors_background_image_scaling;
var vendor_customer_password_validation_regex = function (state) { return state.vendor.customer_password_validation_regex; };
exports.vendor_customer_password_validation_regex = vendor_customer_password_validation_regex;
var vendor_vendors_header_image = function (state) { return state.vendor.vendors_header_image; };
exports.vendor_vendors_header_image = vendor_vendors_header_image;
var vendor_vendors_name = function (state) { return state.vendor.vendors_name; };
exports.vendor_vendors_name = vendor_vendors_name;
var vendor_vendors_outage_image = function (state) { return state.vendor.vendors_outage_image; };
exports.vendor_vendors_outage_image = vendor_vendors_outage_image;
var vendor_vendors_qr_code = function (state) { return state.vendor.vendors_qr_code; };
exports.vendor_vendors_qr_code = vendor_vendors_qr_code;
exports["default"] = exports.vendorReducer.reducer;
