import React from 'react';
import {CustomerTemplateSectionField} from "@motv-webapp/lib";
import Input from "../../InputFields/Input";
import {useIntl} from "react-intl";
import {INPUT_FIELD_TYPE_ENUM} from "@motv-webapp/lib";

type Props = {
    disabled?: boolean
    errorMessageId: string | undefined
    fieldItem: CustomerTemplateSectionField
    onChange: (key: string, value: string) => void
    onEnter: () => void
};

const CustomerEditFieldNumber = ({disabled, errorMessageId, fieldItem, onChange, onEnter}: Props) => {
    const intl = useIntl()

    const handleChange = (value: string) => {
        onChange(fieldItem.key, value)
    }

    return (
        <Input disabled={disabled}
               errorMessageId={errorMessageId}
               onChange={handleChange}
               onEnter={onEnter}
               placeholder={fieldItem.optional ? `${fieldItem.label} (${intl.formatMessage({id: 'label_optional', defaultMessage: 'Optional'})})` : `${fieldItem.label}`}
               type={INPUT_FIELD_TYPE_ENUM.NUMBER}
        />
    );
};

export default CustomerEditFieldNumber;
