import React, {useEffect, useRef, useState} from 'react';
import GuideDatePicker from "./GuideDatePicker";
import TvGuideColumnStyle from "./TVGuideColumnStyle";
import styled from "styled-components";
import {
    channel_channels,
    channelGetChannelCategories,
    channelGetSubscribedAndLockedChannels, epgGetUpdatedEventsV2forCache,
    epgGetUpdatedEventsV2,setCachedChannelsEpg, setCachedEpgs,
} from "@motv-webapp/redux";
import {
    setClearCacheFalse,
    universal_clearCache,
    universalGetFavouriteChannels,
    universalGetMyList,
    universalGetMyRecordings
} from "@motv-webapp/redux";
import {useAppDispatch, useAppSelector} from "@motv-webapp/redux";
import ChannelListFilter from "../Channels/ChannelListFilter";
import {FormattedMessage, useIntl} from "react-intl";
import {COLOR_NEUTRAL, COLOR_NEUTRAL_90, COLOR_WHITE, imageResizeUrl} from "@motv-webapp/lib";
import {AnimatePresence, motion} from "framer-motion";
import {useWindowScroll} from "react-use";
import {SubscribedChannelEntity} from "@motv-webapp/lib";
import {addDays, addHours, endOfDay, formatISO, startOfDay, subHours} from "date-fns";
import TVGuideRowStyle from "./TVGuideRowStyle";
import {GUIDE_TYPE_ENUM} from "@motv-webapp/lib";
import {getLocalStorage, setLocalStorage} from "@motv-webapp/lib";
import Error from "../ErrorHandle/Error";

const TVGuideContainer = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
}));

const ControlsContainer = styled.div(props => ({
    padding: "0 4rem",
    display: "flex",
    justifyContent:"space-between",
}));

const GuideTypeButtonsContainer = styled.div(props => ({
    minWidth:"max-content",
    marginTop:5,
    marginLeft:8
}));

const GuideTypeButton = styled.button((props: { buttonGuideType: GUIDE_TYPE_ENUM, selectedGuideType: string }) => ({
    background:props.selectedGuideType === props.buttonGuideType ? COLOR_WHITE : COLOR_NEUTRAL_90,
    border:0,
    borderRadius:props.buttonGuideType === GUIDE_TYPE_ENUM.COLUMN ? "6px 0 0 6px" : "0 6px 6px 0",
    color:props.selectedGuideType === props.buttonGuideType ? COLOR_NEUTRAL_90 : COLOR_WHITE,
    padding: "4px 20px",
}));

const GuideHeadline = styled.h1(props => ({
    color: COLOR_WHITE,
    marginBottom: 32,
    marginLeft: "4rem",
    marginTop: 20,
    "@media (max-width: 800px)": {
        marginLeft: 32,
    }
}));

const FixedHolder = styled.div(props => ({
    height: 246,
    minHeight: 246,
}))

const FixedElement = styled.div(props => ({
    background: COLOR_NEUTRAL,
    position: "fixed",
    top: 0,
    width: window.innerWidth,
    zIndex: 400
}));

const ChannelHeaderContainer = styled.div(props =>({
    borderBottom: `2px solid ${COLOR_NEUTRAL_90}`,
    display: "flex",
    gap: 6,
    paddingLeft: 72,
    paddingBottom: 16,
    paddingTop: 16,
}));

const ChannelHeader = styled.div(props => ({
    alignItems: "center",
    display: "flex",
    fontSize: "0.8rem",
    fontWeight: "bold",
    justifyContent: "center",
    minWidth: 250,
    width: 250,
    verticalAlign: "middle",
    "& > img": {
        height: 24,
        marginRight: 8,
        verticalAlign: "middle",
    },
}));

const ErrorContainer = styled.div(props => ({
    marginTop:80,
}))

type Props = {};

const TvGuide = ({}: Props) => {
    const localStorageDate = getLocalStorage("tvGuideSelectedDate");
    const [selectedDate, setSelectedDate] = useState<Date>(localStorageDate && localStorageDate !== "" ? new Date(localStorageDate) : new Date())
    const [typeFilter, setTypeFilter] = useState<string | undefined>(undefined)
    const [categoryFilter, setCategoryFilter] = useState<number | undefined>(undefined)
    const [showFilters, setShowFilters] = useState(true)
    const [filteredChannels, setFilteredChannels] = useState<SubscribedChannelEntity[]>([])
    const [fetchMoreChannelsOffset, setFetchMoreChannelsOffset] = useState<number>(0)
    const [channelOffset, setChannelOffset] = useState(0)
    const [selectedGuideType, setSelectedGuideType] = useState<GUIDE_TYPE_ENUM>(getLocalStorage("preferredGuideType") ? getLocalStorage("preferredGuideType") as GUIDE_TYPE_ENUM : GUIDE_TYPE_ENUM.ROW);
    const dispatch = useAppDispatch()
    const channels = useAppSelector(channel_channels)
    const clearCache = useAppSelector(universal_clearCache)
    const {y} = useWindowScroll();
    const CHANNEL_HEIGHT = 76;
    const wrapRef = useRef<HTMLInputElement | null>(null);
    const intl = useIntl()

    useEffect(() => {
        dispatch(channelGetSubscribedAndLockedChannels({body: {}}))
        dispatch(universalGetMyList())
        dispatch(universalGetMyRecordings())
        dispatch(universalGetFavouriteChannels())
        dispatch(channelGetChannelCategories({}))
    }, []) //eslint-disable-line

    useEffect(() => {
        if (clearCache) {
            dispatch(channelGetSubscribedAndLockedChannels({body: {}, ignoreCache: true}))
            dispatch(universalGetMyList())
            dispatch(universalGetMyRecordings())
            dispatch(universalGetFavouriteChannels())
            dispatch(channelGetChannelCategories({ignoreCache: true}))
            dispatch(setClearCacheFalse())
        }
    }, [clearCache])

    const handleChangeGuideType = (guideType: GUIDE_TYPE_ENUM) => () => {
        setSelectedGuideType(guideType);
        setLocalStorage("preferredGuideType",guideType)
    }

    useEffect(() => {
        y > 300 ? setShowFilters(false) : setShowFilters(true)
    }, [y])

    useEffect(() => {
        if (!channels) return
        setChannelOffset(0)
        setFilteredChannels(channels.filter(channel => {
            if (!!typeFilter) {
                return channel.channels_type === typeFilter
            } else if (!!categoryFilter) {
                return channel.channels_categories?.includes(categoryFilter)
            } else {
                return channel
            }
        }))
    }, [channels, typeFilter, categoryFilter])

    useEffect(() => {
        if (!channels) return
        if (selectedGuideType === GUIDE_TYPE_ENUM.COLUMN) {
            dispatch(epgGetUpdatedEventsV2({
                timestamp: 0,
                from: formatISO(startOfDay(selectedDate)),
                to: formatISO(endOfDay(addDays(selectedDate, 1))),
                channels: Array.from(filteredChannels.slice(channelOffset, channelOffset + (window.innerWidth / 250 * 1.5)), (channel) => channel.channels_id!)
            }))
        } else if (selectedGuideType === GUIDE_TYPE_ENUM.ROW) {
            if (!wrapRef.current) return;
            const {scrollTop} = wrapRef.current;
            setFetchMoreChannelsOffset(Math.floor(scrollTop / CHANNEL_HEIGHT))
            dispatch(setCachedChannelsEpg([]))
            dispatch(setCachedEpgs([]))
            let channelsVisibleOnScreen = Array.from(filteredChannels.slice(Math.floor(scrollTop / CHANNEL_HEIGHT) > 5 ? Math.floor(scrollTop / CHANNEL_HEIGHT) - 5 : 0, Math.floor(scrollTop / CHANNEL_HEIGHT) + Math.ceil(wrapRef.current.clientHeight / CHANNEL_HEIGHT) + 5), (channel) => channel.channels_id!)
            dispatch(setCachedChannelsEpg(channelsVisibleOnScreen))
            dispatch(epgGetUpdatedEventsV2forCache({
                timestamp: 0,
                from: formatISO(subHours(startOfDay(selectedDate), 1)),
                to: formatISO(addHours(endOfDay(selectedDate), 1)),
                channels: channelsVisibleOnScreen
            }))
        }
    }, [filteredChannels, channelOffset, selectedDate, selectedGuideType])

    return (
        <TVGuideContainer>
            {!!filteredChannels.length ? (
                <>
                    <FixedHolder>
                        <FixedElement>
                            <div style={{marginTop: showFilters ? 94 : 70}}>
                                <AnimatePresence>
                                    {showFilters && <motion.div
                                        style={{overflow: "hidden"}}
                                        initial={{height: 0}}
                                        animate={{height: "auto"}}
                                        exit={{height: 0}}
                                        transition={{type: "tween", duration: 0.2}}
                                    >
                                        <ControlsContainer>
                                            <ChannelListFilter {...{setTypeFilter, setCategoryFilter}} />
                                            <GuideTypeButtonsContainer>
                                                <GuideTypeButton buttonGuideType={GUIDE_TYPE_ENUM.COLUMN} selectedGuideType={selectedGuideType} onClick={handleChangeGuideType(GUIDE_TYPE_ENUM.COLUMN)}>
                                                    <FormattedMessage id={"label_guide_columns"} defaultMessage={"Columns"}/>
                                                </GuideTypeButton>
                                                <GuideTypeButton buttonGuideType={GUIDE_TYPE_ENUM.ROW} selectedGuideType={selectedGuideType} onClick={handleChangeGuideType(GUIDE_TYPE_ENUM.ROW)}>
                                                    <FormattedMessage id={"label_guide_rows"} defaultMessage={"Rows"}/>
                                                </GuideTypeButton>
                                            </GuideTypeButtonsContainer>
                                        </ControlsContainer>
                                    </motion.div>}
                                </AnimatePresence>
                                {filteredChannels.length !== 0 && (
                                    <>
                                        <GuideDatePicker {...{selectedDate, setSelectedDate}} />
                                        {selectedGuideType === GUIDE_TYPE_ENUM.COLUMN && (
                                            <ChannelHeaderContainer>
                                                {filteredChannels.slice(channelOffset, channelOffset + (window.innerWidth / 250 * 1.5)).map((channel) => {
                                                    return (
                                                        <ChannelHeader key={channel.channels_id}>
                                                            <img src={imageResizeUrl(channel.channels_logo, 50)} alt={""}/>
                                                            {channel.channels_name}
                                                        </ChannelHeader>
                                                    )
                                                })}
                                            </ChannelHeaderContainer>
                                        )}
                                    </>
                                )}
                            </div>
                        </FixedElement>
                    </FixedHolder>
                    {selectedGuideType === GUIDE_TYPE_ENUM.COLUMN && (
                        <TvGuideColumnStyle {...{selectedDate, filteredChannels, channelOffset, setChannelOffset, showFilters}} />
                    )}
                    {selectedGuideType === GUIDE_TYPE_ENUM.ROW && (
                        <TVGuideRowStyle wrapRef={wrapRef}  {...{selectedDate, filteredChannels,CHANNEL_HEIGHT, setShowFilters, fetchMoreChannelsOffset, setFetchMoreChannelsOffset}}/>
                    )}
                </>
                ) : (
                    <ErrorContainer>
                        <GuideHeadline>
                            <FormattedMessage id={"section_tvguide"} defaultMessage={"TV Guide"}/>
                        </GuideHeadline>
                        <Error src={"/images/icons/icon_ghost_filled.svg"} labelHeadline={intl.formatMessage({id: "label_no_channels_purchased", defaultMessage: "You do not have any channels purchased"})}/>
                    </ErrorContainer>
                )}
        </TVGuideContainer>
    );
};

export default TvGuide;
