import React from 'react';
import styled from "styled-components";
import {COLOR_BADGE_RED} from "@motv-webapp/lib";

const MediaCardBadgeContainer = styled.div(props =>({
 alignItems: "center",
 backgroundColor: COLOR_BADGE_RED,
 borderRadius: "0 6px 0 6px",
 display: "flex",
 height: 32,
 justifyContent: "center",
 position: "absolute",
 right: 0,
 top: 0,
 width: 26,
 "& > img": {
  maxHeight: "1.25rem",
  maxWidth: "1.25rem",
 }
}));

type Props = {
 type: "youtube" | "locked"
};

const MediaCardBadge = ({type}: Props) => {
 return (
  <MediaCardBadgeContainer>
   {type === "youtube" && <img src={"/images/icons/icon_youtube.svg"} alt={""}/>}
   {type === "locked" && <img src={"/images/icons/icon_dollar.svg"} alt={""}/>}
  </MediaCardBadgeContainer>
 );
};

export default MediaCardBadge;
