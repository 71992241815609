import React, {Dispatch, SetStateAction, useState, MouseEvent, RefObject} from 'react';
import styled from "styled-components";
import {COLOR_WHITE} from "@motv-webapp/lib";
import PlayerDialogLine from "./PlayerDialogLine";
import {FormattedMessage, useIntl} from "react-intl";
import {StreamUrlV2Entity, TemplateProfileEntity} from "@motv-webapp/lib";
import {variantTrackType} from "@motv-webapp/lib";
import {isSafari} from "react-device-detect";

const SubviewContainer = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    marginTop: 24,
    position: "relative",
}));

const SubviewBackButton = styled.div(props => ({
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    left: 16,
    position: "absolute",
    top: -32,
    "& > img": {
        height: 12,
        marginRight: 6,
    },
    "& > p": {
        color: COLOR_WHITE,
        fontSize: "0.8rem",
        fontWeight: 500,
        margin: 0,
    },
}));

const SUBVIEW_SPEED = "speed"
const SUBVIEW_QUALITY = "quality"

type Props = {
    isAbrEnabled: boolean
    playerRef: RefObject<any>
    playbackRate: number
    setPlaybackRate: Dispatch<SetStateAction<number>>
    setSettingsOpen: Dispatch<SetStateAction<boolean>>
    setVariantTrackQuality: (e: MouseEvent<HTMLDivElement>, newBitrate: number) => void
    streamDetails: StreamUrlV2Entity
    variantTrack: variantTrackType
};

const SettingsDialog = ({
                            isAbrEnabled,
                            playerRef,
                            playbackRate,
                            setPlaybackRate,
                            setSettingsOpen,
                            setVariantTrackQuality,
                            streamDetails,
                            variantTrack
                        }: Props) => {
    const [subview, setSubview] = useState<string | undefined>(undefined)
    const intl = useIntl()

    const handleResetSubview = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        setSubview(undefined)
    }

    const handleClickSpeed = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        setSubview(SUBVIEW_SPEED)
    }

    const handleClickQuality = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        setSubview(SUBVIEW_QUALITY)
    }

    const _setPlaybackRate = (e: MouseEvent<HTMLDivElement>, value: number) => {
        e.stopPropagation()
        setPlaybackRate(value)
        setSettingsOpen(false)
    }

    return (
        <>
            {subview === SUBVIEW_SPEED
                ? (
                    <SubviewContainer>
                        <SubviewBackButton onClick={handleResetSubview}>
                            <img src={"/images/icons/icon_chevron_back.svg"} alt={""}/>
                            <p><FormattedMessage id={"label_speed"} defaultMessage={"Speed"}/></p>
                        </SubviewBackButton>
                        <PlayerDialogLine icon={playbackRate === 2 ? "/images/icons/icon_tick.svg" : ""} label={"2.0"}
                                          onClick={(e: MouseEvent<HTMLDivElement>) => _setPlaybackRate(e, 2)}/>
                        <PlayerDialogLine icon={playbackRate === 1.5 ? "/images/icons/icon_tick.svg" : ""} label={"1.5"}
                                          onClick={(e: MouseEvent<HTMLDivElement>) => _setPlaybackRate(e, 1.5)}/>
                        <PlayerDialogLine icon={playbackRate === 1 ? "/images/icons/icon_tick.svg" : ""} label={"1.0"}
                                          onClick={(e: MouseEvent<HTMLDivElement>) => _setPlaybackRate(e, 1)}/>
                        <PlayerDialogLine icon={playbackRate === 0.75 ? "/images/icons/icon_tick.svg" : ""}
                                          label={"0.75"} onClick={(e: MouseEvent<HTMLDivElement>) => _setPlaybackRate(e, 0.75)}/>
                        <PlayerDialogLine icon={playbackRate === 0.5 ? "/images/icons/icon_tick.svg" : ""} label={"0.5"}
                                          onClick={(e: MouseEvent<HTMLDivElement>) => _setPlaybackRate(e, 0.5)}/>
                    </SubviewContainer>
                )
                : subview === SUBVIEW_QUALITY
                    ? (
                        <SubviewContainer>
                            <SubviewBackButton onClick={handleResetSubview}>
                                <img src={"/images/icons/icon_chevron_back.svg"} alt={""}/>
                                <p><FormattedMessage id={"label_video_quality"} defaultMessage={"Quality"}/></p>
                            </SubviewBackButton>
                            <PlayerDialogLine
                                icon={isAbrEnabled ? "/images/icons/icon_tick.svg" : ""}
                                label={intl.formatMessage({id: "label_auto", defaultMessage: "Auto"})} onClick={(e: MouseEvent<HTMLDivElement>) => setVariantTrackQuality(e, 0)}/>
                            {streamDetails.videoProfiles!.map((profile: TemplateProfileEntity) => {
                                return (
                                    playerRef.current?.getVariantTracks().find((track: variantTrackType) => track.videoBandwidth! / 1000 === profile.templates_profile_bitrate) &&
                                    <PlayerDialogLine
                                        key={profile.templates_profile_bitrate}
                                        icon={(profile.templates_profile_bitrate! * 1000 === variantTrack.videoBandwidth && !isAbrEnabled) ? "/images/icons/icon_tick.svg" : ""}
                                        label={profile.templates_profile_label!} onClick={(e: MouseEvent<HTMLDivElement>) => setVariantTrackQuality(e, profile.templates_profile_bitrate!)}/>
                                )
                            })}
                        </SubviewContainer>
                    )
                    : (
                        <>
                            <PlayerDialogLine onClick={handleClickSpeed} icon={"/images/icons/icon_playback_rate.svg"}
                                              label={intl.formatMessage({id: "label_speed", defaultMessage: "Speed"})}
                                              value={playbackRate.toString() + "x"}/>
                            {/*Bitrate option is not available in Safari due to Shaka player limitation https://github.com/shaka-project/shaka-player/issues/2802*/}
                            {!isSafari && <PlayerDialogLine onClick={handleClickQuality} icon={"/images/icons/icon_tune.svg"}
                                               label={intl.formatMessage({
                                                   id: "label_video_quality",
                                                   defaultMessage: "Quality"
                                               })}
                                               value={isAbrEnabled ? intl.formatMessage({
                                                   id: "label_auto",
                                                   defaultMessage: "Auto"
                                               }) : streamDetails.videoProfiles?.find(profile => profile.templates_profile_bitrate! * 1000 === variantTrack.videoBandwidth)?.templates_profile_label!}/>}
                        </>
                    )}
        </>
    );
};

export default SettingsDialog;
