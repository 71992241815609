import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import OutsideClickHandler from "react-outside-click-handler";
import {COLOR_SECONDARY} from "@motv-webapp/lib";
import {useInterval} from "react-use";
import {TIME_CONST_ONE_SECOND} from "@motv-webapp/lib";
import {markAllNotificationsRead, retrieveCurrentNotifications} from "../../firebase/firebase";
import {useAppSelector} from "@motv-webapp/redux";
import {universal_notifications} from "@motv-webapp/redux";
import {NotificationEntity} from "@motv-webapp/lib";
import Notifications from "../Notification/Notifications";
import {FormattedMessage} from "react-intl";

const NotificationButtonContainer = styled.div(props => ({

}));

const StyledButton = styled.button((props: {}) => ({
    background: "transparent",
    border: 0,
    cursor: "pointer",
    display: "flex",
    height: "1.6rem",
    padding: 0,
    width: "1.6rem",
    "& img": {
        height: "1.6rem",
        width: "1.6rem",
    },
}));

const NotificationBox = styled.div(props => ({
    background: COLOR_SECONDARY,
    borderRadius: 6,
    height: 440,
    overflowY: "auto",
    paddingTop: 8,
    position: "absolute",
    right: 0,
    top: 32,
    width: 330,
}));

type Props = {};

const NotificationButton = ({}: Props) => {
    const [notificationsOpen, setNotificationsOpen] = useState(false)
    const [sortedNotifications, setSortedNotifications] = useState<Array<NotificationEntity>>([])
    const notifications = useAppSelector(universal_notifications)

    useEffect(() => {
        retrieveCurrentNotifications()
    }, [])

    useInterval(() => {
        retrieveCurrentNotifications()
    }, TIME_CONST_ONE_SECOND * 10)

    useEffect(() => {
        if (!notifications) return
        let arrayToSort = [...notifications]
        setSortedNotifications(arrayToSort.sort((a, b) =>  b.timestamp - a.timestamp))
    }, [notifications])

    const handleClick = () => {
        if (notificationsOpen) {
            setNotificationsOpen(false)
            markAllNotificationsRead()
            retrieveCurrentNotifications()
        } else {
            setNotificationsOpen(true)
        }
    }

    return (
        <NotificationButtonContainer data-testid={"notificationButtonContainer"}>

                    <OutsideClickHandler
                        onOutsideClick={() => {
                            if (notificationsOpen) handleClick()
                        }}
                    >
            <StyledButton onClick={handleClick}>
                {notifications?.some(item => item.read === false)
                ? <img src={"/images/icons/icon_notifications_unread.svg"} alt={""}/>
                : <img src={"/images/icons/icon_notifications.svg"} alt={""}/>}
            </StyledButton>
            {notificationsOpen
                && (
                        <NotificationBox data-testid={"notificationBox"}>
                            {!!sortedNotifications.length ? sortedNotifications.map((notificationItem)=> {
                                return(
                                    <Notifications key={notificationItem.timestamp} {...{notificationItem}} />
                                )
                            })
                                : <h4 style={{textAlign: "center", marginTop : "50%", transform: "translate(0, 50%)"}}><FormattedMessage id={"message_you_have_no_notifications"} defaultMessage={"You have no notifications"} /></h4>
                            }
                        </NotificationBox>
                )
            }
                    </OutsideClickHandler>
        </NotificationButtonContainer>
    );
};

export default NotificationButton;
