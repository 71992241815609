import React, {ChangeEvent, MouseEvent, useRef, useState} from 'react';
import styled from "styled-components";
import {StreamUrlV2Entity} from "@motv-webapp/lib";
import {COLOR_NEUTRAL, COLOR_OFFWHITE} from "@motv-webapp/lib";
import {CONTENT_TYPE_ENUM} from "@motv-webapp/lib";

const THUMBNAIL_HEIGHT = 100

const CustomSliderContainer = styled.div(props => ({
    position: "relative" as "relative",
    width: "100%",
}));

const Seeker = styled.div(props => ({
    height: 12,
    pointerEvents: "none" as "none",
    position: "absolute" as "absolute",
    top: 6,
    width: "100%",
    "& :first-child": {
        background: COLOR_OFFWHITE,
        borderRadius: 2,
        height: 12,
        position: "absolute" as "absolute",
        top: 0,
        width: 4,
    }
}));

const Thumbnail = styled.div((props: { hoverPosition: number, thumbnailSlide: string | undefined, thumbnailIndexInSlide: number | undefined, thumbnailFormula: { frequency: number, w: number, h: number, pw: number, ph: number } | undefined, value: number, max: number }) => ({
    aspectRatio: `${(props.thumbnailFormula!.pw / props.thumbnailFormula!.w) / (props.thumbnailFormula!.ph / props.thumbnailFormula!.h)}`,
    background: COLOR_NEUTRAL,
    backgroundImage: `url(${props.thumbnailSlide})`,
    backgroundSize: `${(THUMBNAIL_HEIGHT * (props.thumbnailFormula!.pw / props.thumbnailFormula!.w) / (props.thumbnailFormula!.ph / props.thumbnailFormula!.h)) / (props.thumbnailFormula!.pw / props.thumbnailFormula!.w) * props.thumbnailFormula!.pw}px ${THUMBNAIL_HEIGHT / (props.thumbnailFormula!.ph / props.thumbnailFormula!.h) * props.thumbnailFormula!.ph}px`,
    backgroundPosition: `top -${Math.floor(props.thumbnailIndexInSlide! / props.thumbnailFormula!.h) * THUMBNAIL_HEIGHT}px left ${-((props.thumbnailIndexInSlide! % props.thumbnailFormula!.w) - 1) * THUMBNAIL_HEIGHT * (props.thumbnailFormula!.pw / props.thumbnailFormula!.w) / (props.thumbnailFormula!.ph / props.thumbnailFormula!.h)}px`,
    height: THUMBNAIL_HEIGHT,
    left: props.hoverPosition < (props.thumbnailFormula!.pw / props.thumbnailFormula!.w) / (props.thumbnailFormula!.ph / props.thumbnailFormula!.h) * THUMBNAIL_HEIGHT / 2 ? (props.thumbnailFormula!.pw / props.thumbnailFormula!.w) / (props.thumbnailFormula!.ph / props.thumbnailFormula!.h) * THUMBNAIL_HEIGHT / 2 : props.hoverPosition,
    position: "absolute" as "absolute",
    top: -130,
    transform: "translateX(-50%)",
}));

const StyledInput = styled.input.attrs({type: "range"})`
  -webkit-appearance: none;
  width: 100%;
  height: 6px;
  border-radius: 3px;
  background: #101113 linear-gradient(#E3E2E6, #E3E2E6) no-repeat;
  background-size: ${(props: { liveEdge?: number, max: number, tooltip: string, value: number }) => props.value / props.max * 100}% 100%;
  position: relative;


  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: #E3E2E6;
    cursor: grab;
    box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.25);
    transition: background .3s ease-in-out;
  }

  ::-moz-range-thumb {
    -webkit-appearance: none;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: #E3E2E6;
    cursor: grab;
    box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.25);
    transition: background .3s ease-in-out;
  }

  ::-ms-thumb {
    -webkit-appearance: none;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: #E3E2E6;
    cursor: grab;
    box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.25);
    transition: background .3s ease-in-out;
  }

  ::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  ::-moz-range-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  ::before { // liveEdge
    background: #a3a3a3;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    content: " ";
    height: 6px;
    left: calc(${(props: { max: number, tooltip: string, value: number }) => (props.value / props.max) < 0 ? 0 : (props.value / props.max)} * (100% - 12px) + 12px);
    max-width: calc(100% - 12px);
    width: calc(${(props: { liveEdge?: number, max: number, offset: number, tooltip: string, value: number }) => ((props.liveEdge ? (props.liveEdge > props.max) ? (props.max - props.value) : (props.liveEdge - props.value) : 0) / props.max) * 100}% - 7px);
    position: absolute;
  }

  ::after { // tooltip
    color: #E3E2E6;
    content: '${(props: { max: number, tooltip: string, value: number }) => props.tooltip}';
    font-family: "Roboto", Arial, sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    top: -22px;
    left: ${(props: { max: number, tooltip: string, value: number }) => props.value / props.max * 100}%;
    position: absolute;
    transform: translateX(-50%);
  }
`

type Props = {
    enableSeeking: boolean
    liveEdge?: number
    min: number
    max: number
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void
    streamDetails?: StreamUrlV2Entity
    tooltip: string
    uptimeThumbnails: number
    value: number
};

const SeekbarSlider = ({enableSeeking = false, liveEdge, min, max, onChange, streamDetails, tooltip, uptimeThumbnails, value}: Props) => {
    const [hover, setHover] = useState(false)
    const [hoverPosition, setHoverPosition] = useState(0)
    const [thumbnailSlide, setThumbnailSlide] = useState<string | undefined>(undefined)
    const [thumbnailIndexInSlide, setThumbnailIndexInSlide] = useState<number | undefined>(undefined)
    const seekerRef = useRef<HTMLDivElement>(null)

    const handleMouseEnter = () => {
        setHover(true)
    }

    const handleMouseLeave = () => {
        setHover(false)
    }
    const mouseOver = (e: MouseEvent<HTMLDivElement>) => {
        setHoverPosition(e.clientX - 64)
        const target = e.target as HTMLDivElement
        const inputValue = (e.clientX - 64 - 6) / target.clientWidth * max // 64 is offset of the whole seekbar from left side, 6 is half of thumb width
        // THUMBNAILS START
        if (streamDetails?.thumbnails) {
            const thumbnailsPerSlide = streamDetails.thumbnails.formula.h * streamDetails.thumbnails.formula.w
            const closestSlideIndex = (streamDetails?.type === CONTENT_TYPE_ENUM.LIVE)
                ? Math.round((uptimeThumbnails - (liveEdge! - inputValue)) * 1000 / streamDetails.thumbnails.formula.frequency)
                : Math.ceil(inputValue * 1000 / streamDetails.thumbnails.formula.frequency / thumbnailsPerSlide)
            const thumbnailIndex = Math.ceil(inputValue * 1000 / streamDetails.thumbnails.formula.frequency) % thumbnailsPerSlide
            const thumbnailSlideUrl = `${streamDetails.thumbnails.url}t${thumbnailIndex === 0 ? closestSlideIndex + 1 : closestSlideIndex}.jpg`

            if (thumbnailSlideUrl !== thumbnailSlide) setThumbnailSlide(thumbnailSlideUrl)
            if (thumbnailIndex !== thumbnailIndexInSlide) setThumbnailIndexInSlide(thumbnailIndex)
        }
        // THUMBNAILS END
    }

    return (
        <CustomSliderContainer onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onMouseMove={mouseOver}>
            <StyledInput
                offset={streamDetails?.offset ? streamDetails.offset : 0}
                {...{liveEdge, min, max, onChange, tooltip, value}}
            />
            {enableSeeking && hover &&
              <Seeker
                ref={seekerRef}
                {...{hoverPosition}}
              >
                <div
                  style={{
                      left: hoverPosition < 3
                          ? 3
                          : (liveEdge && (hoverPosition / (seekerRef.current?.clientWidth ? seekerRef.current.clientWidth : 0) * max) > liveEdge)
                              ? liveEdge / max * (seekerRef.current?.clientWidth ? seekerRef.current.clientWidth : 0)
                              : hoverPosition,
                  }}
                />
                  {thumbnailSlide && <Thumbnail
                    id={"thumb"}
                    thumbnailFormula={streamDetails?.thumbnails && streamDetails.thumbnails.formula}
                    {...{hoverPosition, max, thumbnailSlide, thumbnailIndexInSlide, value}}
                  />}
              </Seeker>
            }
        </CustomSliderContainer>
    );
};

export default SeekbarSlider;
