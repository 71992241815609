import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {SubscribedChannelEntity} from "@motv-webapp/lib";
import {useAppDispatch, useAppSelector} from "@motv-webapp/redux";
import {user_selectedLanguage} from "@motv-webapp/redux";
import {
    channel_cachedChannelsEpg,
    channel_epg,
    epgGetUpdatedEventsV2forCache,setCachedChannelsEpg, setCachedEpgs
} from "@motv-webapp/redux";
import {
    addHours,
    endOfDay,
    formatISO,
    getUnixTime,
    isToday,
    startOfDay,
    subHours
} from "date-fns";
import {
    COLOR_NEUTRAL,
    COLOR_NEUTRAL_90, COLOR_PRIMARY_100,
} from "@motv-webapp/lib";
import TVGuideRowStyleRow from "./TVGuideRowStyleRow";
import {TYPEFACE_CAPTION} from "@motv-webapp/lib";
import {vendor_vendors_accent_color} from "@motv-webapp/redux";
import {useInterval} from "react-use";
import {TIME_CONST_ONE_MINUTE} from "@motv-webapp/lib";
import ScrollContainer from "react-indiana-drag-scroll";
import {getLocalStorage, setLocalStorage} from "@motv-webapp/lib";

const Wrap = styled(ScrollContainer).attrs({className: "scroll-container"})((props: { topOffset: number }) => ({
    bottom: 0,
    left: 0,
    overflowY: "scroll" as "scroll",
    position: "fixed" as "fixed",
    right: 0,
    top: props.topOffset,
    "@media (max-width: 870px)": {
        bottom: 64,
    }
}));


const Timeline = styled.div((props: { MINUTE_TO_PIXELS: number, CHANNEL_HEADER_OFFSET: number }) => ({
    background: COLOR_NEUTRAL,
    borderBottom: `2px solid ${COLOR_NEUTRAL_90}`,
    boxSizing: "border-box" as "border-box",
    display: "flex",
    height: "24px",
    marginLeft: -(30 * props.MINUTE_TO_PIXELS),
    paddingLeft: props.CHANNEL_HEADER_OFFSET,
    position: "sticky" as "sticky",
    top: 0,
    width: `${26 * (60 * props.MINUTE_TO_PIXELS) + props.CHANNEL_HEADER_OFFSET}px`,
    zIndex: 4,
}));

const TimeLineHour = styled.div((props: { MINUTE_TO_PIXELS: number }) => ({
    width: 60 * props.MINUTE_TO_PIXELS,
}));

const ActualTimeBar = styled.div((props: { actualTimeLeftOffset: number, accentColor: string | null | undefined }) => ({
    background: props.accentColor ? props.accentColor : COLOR_PRIMARY_100,
    height: "100vh",
    left: `${props.actualTimeLeftOffset}px`,
    position: "absolute" as "absolute",
    top: 20,
    width: 3,
    zIndex: 2,
}));

const ActualTimeTime = styled.div((props: { actualTimeLeftOffset: number, accentColor: string | null | undefined }) => ({
    ...TYPEFACE_CAPTION,
    background: props.accentColor ? props.accentColor : COLOR_PRIMARY_100,
    borderRadius: "11px",
    left: `${props.actualTimeLeftOffset}px`,
    padding: 4,
    position: "absolute" as "absolute",
    top: 0,
    zIndex: 5,
}));

const Grid = styled.div((props: { MINUTE_TO_PIXELS: number, CHANNEL_HEADER_OFFSET: number }) => ({
    display: "flex",
    boxSizing: "border-box" as "border-box",
    position: "relative" as "relative",
    width: `${25.5 * (60 * props.MINUTE_TO_PIXELS) + props.CHANNEL_HEADER_OFFSET}px`,
}));

const ChannelsHeaderContainer = styled.div((props: { CHANNEL_HEADER_OFFSET: number }) => ({
    display: "flex" as "flex",
    flexDirection: "column" as "column",
    gap: "4px",
    left: 0,
    padding: "4px 0",
    position: "sticky" as "sticky",
    width: props.CHANNEL_HEADER_OFFSET - 4,
    zIndex: 3,
}));

const ChannelHeader = styled.div((props: { CHANNEL_HEIGHT: number, CHANNEL_HEADER_OFFSET: number }) => ({
    alignItems: "center" as "center",
    background: COLOR_NEUTRAL_90,
    boxSizing: "border-box" as "border-box",
    display: "flex",
    height: props.CHANNEL_HEIGHT - 4,
    "& > img": {
        width: "69px",
    },
    justifyContent: "center",
    width: props.CHANNEL_HEADER_OFFSET - 4,
}));

const EpgContainer = styled.div((props) => ({
    boxSizing: "border-box" as "border-box",
    display: "flex" as "flex",
    flexDirection: "column" as "column",
    gap: "4px",
    padding: "4px 0 4px 4px",
}));

type Props = {
    filteredChannels: Array<SubscribedChannelEntity>
    selectedDate: Date
    setShowFilters: Dispatch<SetStateAction<boolean>>
    CHANNEL_HEIGHT: number
    wrapRef: any
    fetchMoreChannelsOffset: number
    setFetchMoreChannelsOffset: Dispatch<SetStateAction<number>>
};

const TVGuideRowStyle = ({filteredChannels, selectedDate, setShowFilters, CHANNEL_HEIGHT,
                             wrapRef,fetchMoreChannelsOffset, setFetchMoreChannelsOffset
}: Props) => {
    const [actualTimeLeftOffset, setActualTimeLeftOffset] = useState<number>(0)
    const [actualTimeTimeWidth, setActualTimeTimeWidth] = useState<number>(0)
    const [hoursArray, setHoursArray] = useState<Date[]>([])
    const [topOffset, setTopOffset] = useState<number>(226)
    const dispatch = useAppDispatch()
    const accentColor = useAppSelector(vendor_vendors_accent_color);
    const epgItems = useAppSelector(channel_epg)
    const cachedChannelsEpg = useAppSelector(channel_cachedChannelsEpg)
    const language = useAppSelector(user_selectedLanguage)
    const CHANNEL_HEADER_OFFSET = 121;
    const MINUTE_TO_PIXELS = 6;
    const actualTimeRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        setHoursArray(Array.from(Array(26).keys()).map(i => addHours(startOfDay(selectedDate), i - 1)))
    }, [])

    useEffect(() => {
        if (isToday(selectedDate)) {
            setActualTimeLeftOffset(((((getUnixTime((Date.now())) - getUnixTime(subHours(startOfDay(selectedDate), 1),)) / 60) * MINUTE_TO_PIXELS) + 120))
        }
        if (!actualTimeRef.current) return
        setActualTimeTimeWidth(actualTimeRef.current.offsetWidth)
    }, [epgItems])

    useEffect(() => {
        if (!wrapRef?.current) return;
        const {scrollTop} = wrapRef.current;
        setFetchMoreChannelsOffset(Math.floor(scrollTop / CHANNEL_HEIGHT))
        dispatch(setCachedChannelsEpg([]))
        dispatch(setCachedEpgs([]))
        if (getLocalStorage("tvGuideRowsScrollX") && getLocalStorage("tvGuideRowsScrollY")){
            wrapRef.current.scrollLeft = getLocalStorage("tvGuideRowsScrollX")
            wrapRef.current.scrollTop = getLocalStorage("tvGuideRowsScrollY")
        } else if (isToday(selectedDate)) {
            wrapRef.current.scrollLeft = ((((getUnixTime(Date.now()) - getUnixTime(subHours(startOfDay(selectedDate), 1))) / 60) * MINUTE_TO_PIXELS) + 120) - (wrapRef.current.clientWidth / 2)
        } else {
            wrapRef.current.scrollLeft = 0
        }
    }, [selectedDate])

    useInterval(() => {
        if (isToday(selectedDate)) {
            setActualTimeLeftOffset((((getUnixTime((Date.now())) - getUnixTime(subHours(startOfDay(selectedDate), 1),)) / 60) * MINUTE_TO_PIXELS) + 120)
            if (!actualTimeRef.current) return
            setActualTimeTimeWidth(actualTimeRef.current.offsetWidth)
        }
    }, TIME_CONST_ONE_MINUTE)

    const handleScroll = () => {
        if (!wrapRef.current) return;
        const {scrollTop} = wrapRef.current;
        if ((Math.floor(scrollTop / CHANNEL_HEIGHT) > (fetchMoreChannelsOffset + 5)) || (Math.ceil(scrollTop / CHANNEL_HEIGHT) < fetchMoreChannelsOffset - 5)) {
            setFetchMoreChannelsOffset(Math.floor(scrollTop / CHANNEL_HEIGHT))
            handleFetchAndCacheChannels(scrollTop)
        }
    }

    const handleFetchAndCacheChannels = (scrollTop:number) => {
        let channelsVisibleOnScreen = Array.from(filteredChannels.slice(Math.floor(scrollTop / CHANNEL_HEIGHT) > 5 ? Math.floor(scrollTop / CHANNEL_HEIGHT) - 5 : 0, Math.floor(scrollTop / CHANNEL_HEIGHT) + Math.ceil(wrapRef.current.clientHeight / CHANNEL_HEIGHT) + 5), (channel) => channel.channels_id!)
        channelsVisibleOnScreen = channelsVisibleOnScreen.filter( ( channel:any ) => !cachedChannelsEpg.includes( channel ) );
        if (!channelsVisibleOnScreen.length) return
        dispatch(setCachedChannelsEpg(channelsVisibleOnScreen.concat(cachedChannelsEpg)))
        dispatch(epgGetUpdatedEventsV2forCache({
            timestamp: 0,
            from: formatISO(subHours(startOfDay(selectedDate), 1)),
            to: formatISO(addHours(endOfDay(selectedDate), 1)),
            channels: channelsVisibleOnScreen
        }))
    }

    const onEndScroll = () => {
        if (!wrapRef.current) return;
        const {scrollTop} = wrapRef.current;
        const {scrollLeft} = wrapRef.current;

        if (scrollTop > 226) {
            setShowFilters(false)
            setTopOffset(156)
        } else if (scrollTop < 100) {
            setShowFilters(true)
            setTopOffset(226)
        }
        setLocalStorage("tvGuideRowsScrollX", scrollLeft)
        setLocalStorage("tvGuideRowsScrollY", scrollTop)
    }

    return (
        <Wrap hideScrollbars={false} innerRef={wrapRef} onScroll={handleScroll} onEndScroll={onEndScroll} topOffset={topOffset}>
            <Timeline MINUTE_TO_PIXELS={MINUTE_TO_PIXELS} CHANNEL_HEADER_OFFSET={CHANNEL_HEADER_OFFSET}>
                {hoursArray.map(hour => (
                    <TimeLineHour MINUTE_TO_PIXELS={MINUTE_TO_PIXELS} key={hour.toISOString()}>
                        {new Intl.DateTimeFormat(language, {
                            hour: 'numeric',
                            minute: 'numeric'
                        }).format(hour)}
                    </TimeLineHour>
                ))}
                {isToday(selectedDate) && (
                    <>
                        <ActualTimeTime id="actualTime" ref={actualTimeRef}
                                        actualTimeLeftOffset={actualTimeLeftOffset - (actualTimeTimeWidth / 2) + 1}
                                        {...{accentColor}}>
                            {new Intl.DateTimeFormat(language, {
                                hour: 'numeric',
                                minute: 'numeric'
                            }).format(Date.now())}
                        </ActualTimeTime>
                        <ActualTimeBar actualTimeLeftOffset={actualTimeLeftOffset} {...{accentColor}}/>
                    </>
                )}
            </Timeline>
            <Grid MINUTE_TO_PIXELS={MINUTE_TO_PIXELS} CHANNEL_HEADER_OFFSET={CHANNEL_HEADER_OFFSET}>
                <ChannelsHeaderContainer CHANNEL_HEADER_OFFSET={CHANNEL_HEADER_OFFSET}>
                    {filteredChannels.length && filteredChannels.map((channel) => (
                        <ChannelHeader CHANNEL_HEADER_OFFSET={CHANNEL_HEADER_OFFSET} CHANNEL_HEIGHT={CHANNEL_HEIGHT}
                                       key={channel.channels_id}>
                            <img src={channel.channels_logo} alt={""}/>
                        </ChannelHeader>
                    ))}
                </ChannelsHeaderContainer>
                <EpgContainer>
                    {filteredChannels.length && filteredChannels.map((channel) => (
                        <TVGuideRowStyleRow MINUTE_TO_PIXELS={MINUTE_TO_PIXELS} key={channel.channels_id}
                                            channel={channel} selectedDate={selectedDate}/>
                    ))}
                </EpgContainer>
            </Grid>
        </Wrap>
    )
}

export default TVGuideRowStyle;
