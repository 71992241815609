import React, {useEffect} from 'react';
import styled, {createGlobalStyle} from "styled-components";
import {COLOR_NEUTRAL, COLOR_WHITE} from "@motv-webapp/lib";
import Header from "../components/Header/Header";
import MainRows from "../components/Rows/MainRows";
import {
    channelGetSubscribedChannels,
    homepageGetHomepageV2IncludeData,
    homepage_rows,
    setClearCacheFalse,
    universalGetFavouriteChannels,
    universalGetMyList,
    universalGetMyRecordings,
    universal_clearCache,
    useAppDispatch,
    useAppSelector,
} from "@motv-webapp/redux";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${COLOR_NEUTRAL};
  }
  ,
`

const HomepageContainer = styled.div(props =>({
    background: COLOR_NEUTRAL,
    color: COLOR_WHITE,
    minHeight: "100vh",
    overflowX: "hidden",
}));

const HomepagePage = () => {
    const dispatch = useAppDispatch()
    const rows = useAppSelector(homepage_rows)
    const clearCache = useAppSelector(universal_clearCache)

    useEffect(() => {
        dispatch(homepageGetHomepageV2IncludeData({}))
        dispatch(channelGetSubscribedChannels({body: {}}))
        dispatch(universalGetMyList())
        dispatch(universalGetMyRecordings())
        dispatch(universalGetFavouriteChannels())
    }, []) //eslint-disable-line

    useEffect(() => {
        if (clearCache) {
            dispatch(homepageGetHomepageV2IncludeData({ignoreCache: true}))
            dispatch(channelGetSubscribedChannels({body: {}, ignoreCache: true}))
            dispatch(universalGetMyList())
            dispatch(universalGetMyRecordings())
            dispatch(universalGetFavouriteChannels())
            dispatch(setClearCacheFalse())
        }
    }, [clearCache]) // eslint-disable-line

    return (
        <HomepageContainer>
            <GlobalStyle/>
            <Header />
            {rows && <MainRows homeHeadline={true} disableHeaderSpacing={true} {...{rows}} />}
        </HomepageContainer>
    );
};

export default HomepagePage;
