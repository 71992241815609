"use strict";
exports.__esModule = true;
exports.GUIDE_TYPE_ENUM = exports.CHANNEL_MANIFEST_TYPE_ENUM = exports.CHANNEL_TYPE_ENUM = void 0;
var CHANNEL_TYPE_ENUM;
(function (CHANNEL_TYPE_ENUM) {
    CHANNEL_TYPE_ENUM["CHANNEL"] = "channel";
    CHANNEL_TYPE_ENUM["RADIO"] = "radio";
    CHANNEL_TYPE_ENUM["MOZAIC"] = "mozaic";
})(CHANNEL_TYPE_ENUM = exports.CHANNEL_TYPE_ENUM || (exports.CHANNEL_TYPE_ENUM = {}));
var CHANNEL_MANIFEST_TYPE_ENUM;
(function (CHANNEL_MANIFEST_TYPE_ENUM) {
    CHANNEL_MANIFEST_TYPE_ENUM["DASH"] = "dash";
    CHANNEL_MANIFEST_TYPE_ENUM["HLS"] = "hls";
})(CHANNEL_MANIFEST_TYPE_ENUM = exports.CHANNEL_MANIFEST_TYPE_ENUM || (exports.CHANNEL_MANIFEST_TYPE_ENUM = {}));
var GUIDE_TYPE_ENUM;
(function (GUIDE_TYPE_ENUM) {
    GUIDE_TYPE_ENUM["ROW"] = "ROW";
    GUIDE_TYPE_ENUM["COLUMN"] = "COLUMN";
})(GUIDE_TYPE_ENUM = exports.GUIDE_TYPE_ENUM || (exports.GUIDE_TYPE_ENUM = {}));
