"use strict";
exports.__esModule = true;
exports.RECOMMENDATION_ENGINE_ROW_TYPE_ENUM = void 0;
var RECOMMENDATION_ENGINE_ROW_TYPE_ENUM;
(function (RECOMMENDATION_ENGINE_ROW_TYPE_ENUM) {
    RECOMMENDATION_ENGINE_ROW_TYPE_ENUM["PRIMETIME"] = "primetime";
    RECOMMENDATION_ENGINE_ROW_TYPE_ENUM["MOST_WATCHED"] = "most watched";
    RECOMMENDATION_ENGINE_ROW_TYPE_ENUM["CONTINUE_WATCHING"] = "continue watching";
    RECOMMENDATION_ENGINE_ROW_TYPE_ENUM["CONTINUE_WATCHING_CHANNELS"] = "continue watching channels";
    RECOMMENDATION_ENGINE_ROW_TYPE_ENUM["FAVOURITE_CHANNELS"] = "favorite channels";
    RECOMMENDATION_ENGINE_ROW_TYPE_ENUM["CATEGORY_SELECTION"] = "category selection";
    RECOMMENDATION_ENGINE_ROW_TYPE_ENUM["PLAYLIST"] = "playlist";
    RECOMMENDATION_ENGINE_ROW_TYPE_ENUM["MY_RECORDINGS"] = "my recordings";
    RECOMMENDATION_ENGINE_ROW_TYPE_ENUM["MY_LIST"] = "my list";
    RECOMMENDATION_ENGINE_ROW_TYPE_ENUM["IMAGES"] = "images";
    RECOMMENDATION_ENGINE_ROW_TYPE_ENUM["SIMILAR_TO_PAST"] = "similar to past";
    RECOMMENDATION_ENGINE_ROW_TYPE_ENUM["FULLSIZE_BANNER"] = "fullsize banner";
    RECOMMENDATION_ENGINE_ROW_TYPE_ENUM["OFFSET_ROW"] = "offset row";
    RECOMMENDATION_ENGINE_ROW_TYPE_ENUM["RECORDING"] = "recording";
    RECOMMENDATION_ENGINE_ROW_TYPE_ENUM["SEARCH"] = "search";
    RECOMMENDATION_ENGINE_ROW_TYPE_ENUM["SIMILAR"] = "similar";
    RECOMMENDATION_ENGINE_ROW_TYPE_ENUM["VOD"] = "vod";
    RECOMMENDATION_ENGINE_ROW_TYPE_ENUM["CATEGORY"] = "category";
})(RECOMMENDATION_ENGINE_ROW_TYPE_ENUM = exports.RECOMMENDATION_ENGINE_ROW_TYPE_ENUM || (exports.RECOMMENDATION_ENGINE_ROW_TYPE_ENUM = {}));
