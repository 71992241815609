import React, {Dispatch, SetStateAction, MouseEvent, useEffect, useState} from 'react';
import styled from "styled-components";
import {COLOR_BLACK, COLOR_NEUTRAL_90, COLOR_OFFWHITE, COLOR_OFFWHITE_70} from "@motv-webapp/lib";
import {useAppDispatch, useAppSelector} from "@motv-webapp/redux";
import {
    selectYoutubeItem,
    universal_nextRecommendedItems, universal_selectedItem,
    universalGetNextPlaybackItems
} from "@motv-webapp/redux";
import {RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM} from "@motv-webapp/lib";
import {StreamUrlV2Entity} from "@motv-webapp/lib";
import {FormattedMessage, useIntl} from "react-intl";
import {useInterval} from "react-use";
import {TIME_CONST_ONE_SECOND} from "@motv-webapp/lib";
import PrimaryButton from "../Button/PrimaryButton";
import {BUTTON_SIZE_ENUM, BUTTON_TYPE_ENUM} from "@motv-webapp/lib";
import {useNavigate} from "react-router-dom";
import {getUnixTime, isAfter, isBefore} from "date-fns";
import {RecommendationCardEntity} from "@motv-webapp/lib";
import PlayerMediaCard from "../MediaCard/PlayerMediaCard";
import {TYPEFACE_WEB_BODY_2_MEDIUM} from "@motv-webapp/lib";

const PlayerEndOfPlaybackOverlayContainer = styled.div(props => ({
    alignItems: "center",
    background: COLOR_BLACK,
    color: COLOR_OFFWHITE,
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    position: "absolute",
    width: "100vw",
}));

const NextInQueue = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    "& > img": {
        borderRadius: 10,
        height: "30vh",
        marginBottom: 16,
        marginTop: 16,
    }
}));

const NextInCountdown = styled.div(props => ({
    ...TYPEFACE_WEB_BODY_2_MEDIUM,
    color: COLOR_OFFWHITE_70,
    marginBottom: 16,
}));

const ControlButtons = styled.div(props => ({
    display: "flex",
    gap: 8,
    marginTop: 24,
}));

const RecommendationDrawer = styled.div(props =>({
    display: "flex",
    gap: 16,
}));

const ReplayDivider = styled.div(props =>({
    background: COLOR_NEUTRAL_90,
    height: 140,
    marginLeft: 16,
    marginRight: 16,
    width: 4,
}));

type Props = {
    handleReplay: () => void
    playerType: "tv" | "vod" | "recording"
    setEndOfPlaybackOverlay: Dispatch<SetStateAction<boolean>>
    streamDetails: StreamUrlV2Entity | undefined
};

const PlayerEndOfPlaybackOverlay = ({handleReplay, playerType, setEndOfPlaybackOverlay, streamDetails}: Props) => {
    const [countDown, setCountDown] = useState(15)
    const [countDownCanceled, setCountDownCanceled] = useState(false)
    const dispatch = useAppDispatch()
    const nextRecommendedItems = useAppSelector(universal_nextRecommendedItems)
    const currentlyPlaying = useAppSelector(universal_selectedItem) as RecommendationCardEntity
    const intl = useIntl()
    const navigate = useNavigate()

    useEffect(() => {
        if (!streamDetails) return
        dispatch(universalGetNextPlaybackItems({
            type: playerType === "vod"
                ? RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.VOD
                : playerType === "recording"
                    ? RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.RECORDING
                    : RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV,
            id: streamDetails.id
        }))
    }, [])

    useInterval(() => {
        if (countDown > 0) {
            setCountDown(prev => prev - 1)
        } else {
            if (nextRecommendedItems && nextRecommendedItems[0]) {
                handlePlay(nextRecommendedItems[0])()
            }
        }
    }, TIME_CONST_ONE_SECOND)

    const handleCancel = () => {
        setCountDownCanceled(true)
    }

    const handlePlay = (mediaItem: RecommendationCardEntity) => () => {
        setEndOfPlaybackOverlay(false)
        switch (mediaItem.type) {
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV:
                if (!mediaItem.start || !mediaItem.end) return
                let now = new Date()
                let start = new Date(mediaItem.start)
                let end = new Date(mediaItem.end)
                isAfter(now, start) && isBefore(now, end)
                    ? navigate(`/tv/player/${mediaItem.channels_id}`, {replace: true})
                    : mediaItem.preferred_offset
                        ? navigate(`/tv/player/${mediaItem.channels_id}/${getUnixTime(start)}/${mediaItem.preferred_offset}`, {replace: true})
                        : navigate(`/tv/player/${mediaItem.channels_id}/${getUnixTime(start)}`, {replace: true})
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.RECORDING:
                navigate(`/recording/player/${mediaItem.id}`, {replace: true})
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.VOD:
                navigate(`/vod/player/${mediaItem.id}`, {replace: true})
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.YOUTUBE:
                dispatch(selectYoutubeItem(mediaItem))
                break
            default:
                return
        }
    }

    const stopPropagation = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
    }

    return (
        <PlayerEndOfPlaybackOverlayContainer onClick={stopPropagation}>
            {!countDownCanceled
                ? nextRecommendedItems?.length &&
                (
                    <NextInQueue>
                        <NextInCountdown>
                            <FormattedMessage id={"label_next_in"} defaultMessage={"Next in {d}"}
                                              values={{d: countDown}}/>
                        </NextInCountdown>
                        <PlayerMediaCard handlePlay={handlePlay(nextRecommendedItems[0])}
                                         mediaCard={nextRecommendedItems[0]}/>
                        <ControlButtons>
                            <PrimaryButton
                                label={intl.formatMessage({id: "label_cancel", defaultMessage: "Cancel"})}
                                onClick={handleCancel}
                                buttonSize={BUTTON_SIZE_ENUM.MEDIUM}
                                buttonType={BUTTON_TYPE_ENUM.SECONDARY}
                                style={{flex: 1}}
                            />
                            <PrimaryButton
                                label={intl.formatMessage({id: "label_play", defaultMessage: "Play"})}
                                onClick={handlePlay(nextRecommendedItems[0])}
                                buttonSize={BUTTON_SIZE_ENUM.MEDIUM}
                                buttonType={BUTTON_TYPE_ENUM.OFFWHITE}
                                style={{flex: 1}}
                            />
                        </ControlButtons>
                    </NextInQueue>
                )
                : (
                    <RecommendationDrawer>
                        <PlayerMediaCard mediaCard={currentlyPlaying} handlePlay={handleReplay} type={"replay"} />
                        <ReplayDivider />
                        {nextRecommendedItems?.map((item) => {
                            return(
                                <PlayerMediaCard handlePlay={handlePlay(item)} mediaCard={item} />
                            )
                        })}
                    </RecommendationDrawer>
                )
            }
        </PlayerEndOfPlaybackOverlayContainer>
    );
};

export default PlayerEndOfPlaybackOverlay;
