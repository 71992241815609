import React, {Dispatch, SetStateAction, useState} from 'react';
import styled from "styled-components";
import {AnimatePresence, motion} from "framer-motion";
import {FormattedMessage, useIntl} from "react-intl";
import Input from "../InputFields/Input";
import {COLOR_NEUTRAL_90, COLOR_DANGER_100, COLOR_OFFWHITE} from "@motv-webapp/lib";
import PrimaryButton from "../Button/PrimaryButton";
import {fetch_sms_devicesMotvApiChangePassword} from "@motv-webapp/redux";
import {isResponseSuccessful} from "@motv-webapp/lib";
import {ERROR_CODE_SMS_UNKNOWN_LOST_PASSWORD_TOKEN} from "@motv-webapp/lib";
import {useAppSelector} from "@motv-webapp/redux";
import {vendor_customer_password_validation_regex} from "@motv-webapp/redux";
import {INPUT_FIELD_TYPE_ENUM} from "@motv-webapp/lib";

const StepTwoContainer = styled(motion.div)(props => ({
    display: "flex",
    flexDirection: "column",
}));

const EmailInfo = styled.div(props => ({
    alignItems: "center",
    backgroundColor: COLOR_NEUTRAL_90,
    borderRadius: 6,
    color: COLOR_OFFWHITE,
    display: "flex",
    padding: "1rem",
    marginBottom: "2rem",
    "& img": {
        height: "2rem",
        marginRight: "1rem",
    },
    "& p": {
        fontWeight: 500,
        margin: 0,
    }
}));

const ErrorMessage = styled(motion.p)(props => ({
    color: COLOR_DANGER_100,
    fontSize: "0.8rem",
    fontWeight: 700,
    marginTop: "-0.7rem",
}));

type Props = {
    setStepsFinished: Dispatch<SetStateAction<number>>
    setActivePanel: Dispatch<SetStateAction<string>>
    PANEL_LOGIN: string
};

const ForgottenPasswordFormStepTwo = ({setActivePanel, setStepsFinished, PANEL_LOGIN}: Props) => {
    const [code, setCode] = useState("")
    const [codeError, setCodeError] = useState("")
    const [login, setLogin] = useState("")
    const [loginError, setLoginError] = useState("")
    const [password, setPassword] = useState("")
    const [passwordError, setPasswordError] = useState("")
    const [passwordAgain, setPasswordAgain] = useState("")
    const [passwordAgainError, setPasswordAgainError] = useState("")
    const [formError, setFormError] = useState("")
    const [disableAllFields, setDisableAllFields] = useState(false)
    const passwordRegExp = useAppSelector(vendor_customer_password_validation_regex)

    const intl = useIntl()

    const handleSubmitNewPassword = async () => {
        setDisableAllFields(true)
        setCodeError("")
        setLoginError("")
        setPasswordError("")
        setFormError("")
        if (!code || code === "") {
            setCodeError("message_required_field")
            setDisableAllFields(false)
            return
        }
        if (!login || login === "") {
            setLoginError("message_required_field")
            setDisableAllFields(false)
            return
        }
        if (!password || password === "") {
            setPasswordError("message_required_field")
            setDisableAllFields(false)
            return
        }
        if (passwordRegExp) {
            const reMeta = /(^|[^\\])\/(\w+$){0,1}/g; //this removes forward slash delimiters from beginning and end of regexp
            let patternRegExp = new RegExp(passwordRegExp.replace(reMeta,'$1'))
            if (!patternRegExp.test(password)) {
                setPasswordError("message_password_validation_error")
                setDisableAllFields(false)
                return
            }
        }
        if (!passwordAgain || passwordAgain === "") {
            setPasswordAgainError("message_required_field")
            setDisableAllFields(false)
            return
        } else if (password !== passwordAgain) {
            setPasswordAgainError("label_settings_passwords_are_not_same")
            setDisableAllFields(false)
            return
        }
        const response = await fetch_sms_devicesMotvApiChangePassword({code, login, password})
        if (!isResponseSuccessful(response)) {
            if (response.status === ERROR_CODE_SMS_UNKNOWN_LOST_PASSWORD_TOKEN) {
                setCodeError("message_incorrect_code")
            } else {
                setFormError("message_something_went_wrong")
            }
            setDisableAllFields(false)
            return
        }
        setStepsFinished(0)
        setActivePanel(PANEL_LOGIN)
        setCodeError("")
        setLoginError("")
        setPasswordError("")
        setFormError("")
        setDisableAllFields(false)
    }

    return (
        <StepTwoContainer
            initial={{x: "100%"}}
            animate={{x: 0}}
            exit={{x: "100%"}}
            transition={{type: "spring", duration: 1}}
        >
            <EmailInfo>
                <img src={"/images/icons/icon_envelope.svg"} alt={""}/>
                <p><FormattedMessage id={"label_lost_password_email_sent"}
                                     defaultMessage={"We've sent you an email with further instructions."}/></p>
            </EmailInfo>
            <Input disabled={disableAllFields}
                   errorMessageId={codeError}
                   onChange={setCode}
                   onEnter={handleSubmitNewPassword}
                   placeholder={intl.formatMessage({id: 'label_code', defaultMessage: 'Code'})}
                   type={INPUT_FIELD_TYPE_ENUM.TEXT}
            />
            <Input disabled={disableAllFields}
                   errorMessageId={loginError}
                   onChange={setLogin}
                   onEnter={handleSubmitNewPassword}
                   placeholder={intl.formatMessage({id: 'label_login', defaultMessage: 'Login'})}
                   type={INPUT_FIELD_TYPE_ENUM.TEXT}
            />
            <Input disabled={disableAllFields}
                   errorMessageId={passwordError}
                   onChange={setPassword}
                   onEnter={handleSubmitNewPassword}
                   placeholder={intl.formatMessage({id: 'label_password', defaultMessage: 'Password'})}
                   type={INPUT_FIELD_TYPE_ENUM.PASSWORD}
            />
            <Input disabled={disableAllFields}
                   errorMessageId={passwordAgainError}
                   onChange={setPasswordAgain}
                   onEnter={handleSubmitNewPassword}
                   placeholder={intl.formatMessage({
                       id: 'label_form_password_again',
                       defaultMessage: 'Password again'
                   })}
                   type={INPUT_FIELD_TYPE_ENUM.PASSWORD}/>
            <AnimatePresence>
                {(formError && formError !== "") && <ErrorMessage
                  initial={{y: -20, height: 0}}
                  animate={{y: 0, height: "auto"}}
                  exit={{y: -20, height: 0}}
                  transition={{type: "tween", duration: 0.4}}
                >
                  <FormattedMessage id={formError} defaultMessage={"Something went wrong."}/>
                </ErrorMessage>}
            </AnimatePresence>
            <PrimaryButton
                label={intl.formatMessage({id: "reset_password", defaultMessage: "Reset password"})}
                disabled={disableAllFields}
                onClick={handleSubmitNewPassword}
            />
        </StepTwoContainer>
    );
};

export default ForgottenPasswordFormStepTwo;
