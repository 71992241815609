import React, {MouseEvent, RefObject} from 'react';
import styled from "styled-components";
import {COLOR_WHITE} from "@motv-webapp/lib";
import PlayerDialogShortLine from "./PlayerDialogShortLine";
import {FormattedMessage, useIntl} from "react-intl";
import {ChannelVodAudioSubtitleEntity, StreamUrlV2Entity} from "@motv-webapp/lib";
import {variantTrackType} from "@motv-webapp/lib";

const SubtitlesDialogContainer = styled.div(props =>({
    display: "flex",
}));

const Column = styled.div(props =>({
    marginTop: 24,
    position: "relative",
}));

const ColumnHeadline = styled.p(props =>({
    color: COLOR_WHITE,
    fontWeight: 500,
    left: 24,
    position: "absolute",
    top: -42,
}));

type Props = {
    hideSubtitles: (e: MouseEvent<HTMLDivElement>) => void
    playerRef: RefObject<any>
    setVariantTrackAudio: (e: MouseEvent<HTMLDivElement>, newLanguage: string) => void
    setVariantTrackSubtitle: (e: MouseEvent<HTMLDivElement>, newLanguage: string) => void
    streamDetails: StreamUrlV2Entity
    subtitleTrack: variantTrackType | undefined
    variantTrack: variantTrackType
};

const SubtitlesDialog = ({hideSubtitles, playerRef, setVariantTrackAudio, setVariantTrackSubtitle, streamDetails, subtitleTrack, variantTrack}: Props) => {
    const intl = useIntl()

 return (
  <SubtitlesDialogContainer>
   <Column>
        <ColumnHeadline><FormattedMessage id={"label_audio"} defaultMessage={"Audio"} /></ColumnHeadline>
       {streamDetails.audio?.map((audioItem: ChannelVodAudioSubtitleEntity) => {
           return (
               <PlayerDialogShortLine key={audioItem.role}
                                      label={audioItem.label}
                                      onClick={(e: MouseEvent<HTMLDivElement>) => setVariantTrackAudio(e, audioItem.role)}
                                      tick={audioItem.role.toLowerCase() === variantTrack.language.toLowerCase()} />
           )
       })}
   </Column>
      <Column>
          <ColumnHeadline><FormattedMessage id={"label_captions"} defaultMessage={"Subtitles"}/></ColumnHeadline>
          <PlayerDialogShortLine label={intl.formatMessage({id: "label_none", defaultMessage: "None"})}
                                 onClick={hideSubtitles}
                                 tick={!subtitleTrack}
          />
          {streamDetails.subtitle?.map((subtitleItem: ChannelVodAudioSubtitleEntity) => {
              return (
              // @ts-ignore
                  playerRef.current?.getTextTracks().find(track => track.language === subtitleItem.role) &&
                  <PlayerDialogShortLine key={subtitleItem.role}
                                         label={subtitleItem.label}
                                         onClick={(e: MouseEvent<HTMLDivElement>) => setVariantTrackSubtitle(e, subtitleItem.role)}
                                         tick={subtitleItem.role === subtitleTrack?.language}
                  />
              )
          })}
      </Column>
  </SubtitlesDialogContainer>
 );
};

export default SubtitlesDialog;
