import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {imageResizeUrl} from "@motv-webapp/lib";
import styled from "styled-components";
import {COLOR_SECONDARY} from "@motv-webapp/lib";
import {SubscribedChannelEntity} from "@motv-webapp/lib";
import {ProfileEntity} from "@motv-webapp/lib";

const ChannelCardBox = styled.div((props: { isParentalControl: boolean }) => ({
    background: COLOR_SECONDARY,
    borderRadius: "10px",
    cursor: props.isParentalControl ? "pointer" : "default",
    filter: props.isParentalControl ? "none" : "grayscale(100%)",
    display: "flex",
    height: "146px",
    overflow: "hidden",
    position: "relative" as "relative",
    width: "146px",
    "& > img": {
        maxHeight: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1,
    },
}));

const ChannelCardOverlayDiv = styled.div((props: {}) => ({
    height: "100%",
    width: "100%",
    position: "absolute" as "absolute",
    zIndex: 2,
    background: "rgba(255, 255, 255, 0.25)",
}));

const BlurBox = styled.div(props => ({
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    filter: "blur(60px)",
    height: "100%",
    width: "100%",
    zIndex: 100,
}));

const ActionBarContainer = styled.div(props => ({
    backgroundImage: "linear-gradient(to bottom, rgba(16, 17, 19, 0) 0%, rgba(16, 17, 19, 0.5) 100%)",
    bottom: 0,
    position: "absolute",
    width: "100%",
}));

const ActionButtonsContainer = styled.div(props => ({
    display: "flex",
    justifyContent: "flex-end",
    margin: "8px 8px 8px 0",
}));

const ActionButton = styled.button((props: {}) => ({
    background: "transparent",
    border: 0,
    display: "flex",
    height: "24px",
    padding: 0,
    width: "24px",
    zIndex: 200,
    "& > img": {
        height: "24px",
    }
}));

type Props = {
    channel: SubscribedChannelEntity;
    isParentalControlEnabled: boolean;
    profile: ProfileEntity
    allowedChannels: number[]
    setAllowedChannels: Dispatch<SetStateAction<number[]>>
    setWhiteListedChannelsChanged: Dispatch<SetStateAction<boolean>>
}

export const AllowedChannelCardBox = ({
                                          channel,
                                          isParentalControlEnabled,
                                          profile,
                                          allowedChannels,
                                          setAllowedChannels, setWhiteListedChannelsChanged
                                      }: Props) => {

    const whitelistedChannels = profile.channels_whitelisting;
    const [allowed, setAllowed] = useState(false)

    useEffect(() => {
        if (!whitelistedChannels) return
        setAllowed(whitelistedChannels.includes(channel.channels_id!))
    }, [whitelistedChannels, isParentalControlEnabled])

    const handleClickFavouriteButton = () => {
        if (!channel.channels_id) return
        if (allowedChannels.includes(channel.channels_id)) {
            setAllowedChannels((prev: number[]) => [...prev.filter((x) => x !== channel.channels_id)])
        } else {
            setAllowedChannels((prev: number[]) => [...prev, ...[channel.channels_id!]])
        }
        setWhiteListedChannelsChanged(true)
    }

    useEffect(() => {
        if (!channel.channels_id) return
        setAllowed(allowedChannels.includes(channel.channels_id))
    }, [allowedChannels])

    return (
        <ChannelCardBox isParentalControl={isParentalControlEnabled}
                        key={channel.channels_id} onClick={handleClickFavouriteButton}>
            <BlurBox style={{
                backgroundImage: `url(${imageResizeUrl(channel.channels_logo, 146)})`,
            }}/>
            <img loading="lazy" src={imageResizeUrl(channel.channels_logo, 146)} alt={""}/>
            {isParentalControlEnabled && allowed && (
                <ChannelCardOverlayDiv/>
            )}
            {isParentalControlEnabled && (
                <ActionBarContainer>
                    <ActionButtonsContainer>
                        <ActionButton>
                            <img src={allowed ? "/images/icons/icon_check.svg" : "/images/icons/icon_check_not_yet.svg"}
                                 alt={""}/>
                        </ActionButton>
                    </ActionButtonsContainer>
                </ActionBarContainer>
            )}
        </ChannelCardBox>
    )
}

export default AllowedChannelCardBox;
