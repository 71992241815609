import React, {MouseEvent, useEffect, useState} from 'react';
import styled from "styled-components";
import {RecommendationCardEntity} from "@motv-webapp/lib";
import {useAppDispatch, useAppSelector} from "@motv-webapp/redux";
import {
    universal_favouriteChannels,
    universalAddFavouriteChannel,
    universalRemoveFavouriteChannel
} from "@motv-webapp/redux";
import {SubscribedChannelEntity} from "@motv-webapp/lib";

const ActionBarContainer = styled.div(props => ({
    backgroundImage: "linear-gradient(to bottom, rgba(16, 17, 19, 0) 0%, rgba(16, 17, 19, 0.5) 100%)",
    bottom: 0,
    position: "absolute",
    width: "100%",
}));

const ActionButtonsContainer = styled.div(props =>({
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "0.5rem",
    marginTop: "0.5rem",
    marginRight: "0.5rem",
}));

const ActionButton = styled.button((props: { smallFormat: boolean, isChannelReorderEnabled: boolean }) => ({
    background: "transparent",
    border: 0,
    cursor: props.isChannelReorderEnabled ? "grab" : "pointer",
    display: "flex",
    height: props.smallFormat ? "1rem" : "1.3rem",
    padding: 0,
    width: props.smallFormat ? "1rem" : "1.3rem",
    zIndex: 200,
    "& > img": {
        height: props.smallFormat ? "1rem" : "1.3rem",
    }
}));

type Props = {
    mediaCard: RecommendationCardEntity | SubscribedChannelEntity
    smallFormat?: boolean
    isChannelReorderEnabled?: boolean
};

const ChannelCardActionBar = ({mediaCard, smallFormat = false, isChannelReorderEnabled = false}: Props) => {
    const [liked, setLiked] = useState(false)
    const [hoverButton, setHoverButton] = useState(false)
    const favouriteChannels = useAppSelector(universal_favouriteChannels)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!favouriteChannels) return
        setLiked(favouriteChannels.includes(mediaCard.channels_id!))
    }, [favouriteChannels])

    const handleClickFavouriteButton = (e: MouseEvent<HTMLButtonElement>) => {
        if (isChannelReorderEnabled) return
        e.stopPropagation()
        if (liked) {
            dispatch(universalRemoveFavouriteChannel({channelsId: mediaCard.channels_id!}))
        } else {
            dispatch(universalAddFavouriteChannel({channelsId: mediaCard.channels_id!}))
        }
    }

    const handleMouseEnter = () => {
        setHoverButton(true)
    }

    const handleOnMouseLeave = () => {
        setHoverButton(false)
    }

    return (
        <ActionBarContainer>
            <ActionButtonsContainer>
                <ActionButton onClick={handleClickFavouriteButton}
                              onMouseEnter={handleMouseEnter} onMouseLeave={handleOnMouseLeave} isChannelReorderEnabled={isChannelReorderEnabled} {...{smallFormat}} >
                    {hoverButton && !liked && !isChannelReorderEnabled ? (
                        <img src={"images/icons/icon_favorite_50.svg"}
                             alt={""}/>
                    ) : (
                        <img src={liked ? "/images/icons/icon_favorite.svg" : "/images/icons/icon_favorite_not_yet.svg"}
                             alt={""}/>
                    )}
                </ActionButton>
            </ActionButtonsContainer>
        </ActionBarContainer>
    );
};

export default ChannelCardActionBar;
