import React, {useEffect, useState} from 'react';
import {RecommendationCardEntity, RecommendationRowEntity} from "@motv-webapp/lib";
import styled from "styled-components";
import {motion, useAnimation} from "framer-motion";
import {
    RECOMMENDATION_ENGINE_ROW_STYLE_LARGE,
    RECOMMENDATION_ENGINE_ROW_STYLE_LARGE_WIDE,
    RECOMMENDATION_ENGINE_ROW_STYLE_NORMAL_WIDE,
    RECOMMENDATION_ENGINE_ROW_STYLE_SMALL_WIDE
} from "@motv-webapp/lib";
import {
    LARGE_HEIGHT,
    LARGE_WIDE_HEIGHT,
    NORMAL_HEIGHT,
    NORMAL_WIDE_HEIGHT,
    SMALL_WIDE_HEIGHT,
} from "@motv-webapp/lib";
import {ADVERT_HOMEPAGE_ACTION_TYPE_ENUM} from "@motv-webapp/lib";
import EnlargeImageDialog from "./EnlargeImageDialog";
import {imageResizeUrl} from "@motv-webapp/lib";
import {useAppDispatch} from "@motv-webapp/redux";
import {searchOpenFalse} from "@motv-webapp/redux";

const ImageCardContainer = styled.div((props: {action_type: ADVERT_HOMEPAGE_ACTION_TYPE_ENUM | undefined}) => ({
    cursor: props.action_type === ADVERT_HOMEPAGE_ACTION_TYPE_ENUM.NONE ? "initial" : "pointer",
}));

const ImageCardBox = styled(motion.div)(props => ({
    backgroundPosition: "center",
    backgroundSize: "contain",
    borderRadius: 6,
    position: "relative" as "relative",
}));

type Props = {
    mediaCard: RecommendationCardEntity
    row: RecommendationRowEntity
};

const ImageCard = ({mediaCard, row}: Props) => {
    const [hover, setHover] = useState(false)
    const [imageWidth, setImageWidth] = useState(0)
    const [enlargeOpen, setEnlargeOpen] = useState(false)
    const controls = useAnimation()
    const controlsFiller = useAnimation()
    const dispatch = useAppDispatch()

    useEffect(() => {
        setImageWidth((row.style === RECOMMENDATION_ENGINE_ROW_STYLE_NORMAL_WIDE ? NORMAL_WIDE_HEIGHT
            : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_SMALL_WIDE ? SMALL_WIDE_HEIGHT
                : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_LARGE ? LARGE_HEIGHT
                    : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_LARGE_WIDE ? LARGE_WIDE_HEIGHT : NORMAL_HEIGHT) / mediaCard.image_height! * mediaCard.image_width!)
    }, [row.style])

    useEffect(() => {
        if (hover) {
            controls.start({
                scale: 1.1
            })
            controlsFiller.start({
                height: "calc(200px * 0.05)",
            })
        } else {
            controls.start({
                scale: 1.0
            })
            controlsFiller.start({
                height: 0,
            })
        }
    }, [hover])

    const handleOnMouseEnter = () => {
        setHover(true)
    }

    const handleOnMouseLeave = () => {
        setHover(false)
    }

    const handleClick = () => {
        if (mediaCard.action_type === ADVERT_HOMEPAGE_ACTION_TYPE_ENUM.URL) {
            window.open(mediaCard.action!)
        } else if (mediaCard.action_type === ADVERT_HOMEPAGE_ACTION_TYPE_ENUM.ENLARGE) {
            setEnlargeOpen(true)
        }
        dispatch(searchOpenFalse())
    }

 return (
  <ImageCardContainer
                      onMouseEnter={handleOnMouseEnter}
                      onMouseLeave={handleOnMouseLeave}
                      action_type={mediaCard.action_type} >
      <ImageCardBox animate={controls}
                    onClick={handleClick}
                    style={{
                        backgroundImage: `url(${((row.style === RECOMMENDATION_ENGINE_ROW_STYLE_NORMAL_WIDE
                            || row.style === RECOMMENDATION_ENGINE_ROW_STYLE_SMALL_WIDE
                            || row.style === RECOMMENDATION_ENGINE_ROW_STYLE_LARGE_WIDE) && mediaCard.image_widescreen)
                            ? imageResizeUrl(mediaCard.image_widescreen, imageWidth) : imageResizeUrl(mediaCard.image, imageWidth)})`,
                        height: row.style === RECOMMENDATION_ENGINE_ROW_STYLE_NORMAL_WIDE ? NORMAL_WIDE_HEIGHT
                            : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_SMALL_WIDE ? SMALL_WIDE_HEIGHT
                                : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_LARGE ? LARGE_HEIGHT
                                    : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_LARGE_WIDE ? LARGE_WIDE_HEIGHT : NORMAL_HEIGHT,
                        // width: row.style === RECOMMENDATION_ENGINE_ROW_STYLE_NORMAL_WIDE ? NORMAL_WIDE_WIDTH
                        //     : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_SMALL_WIDE ? SMALL_WIDE_WIDTH
                        //         : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_LARGE ? LARGE_WIDTH
                        //             : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_LARGE_WIDE ? LARGE_WIDE_WIDTH : NORMAL_WIDTH,
                        width: `${(row.style === RECOMMENDATION_ENGINE_ROW_STYLE_NORMAL_WIDE ? NORMAL_WIDE_HEIGHT
                            : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_SMALL_WIDE ? SMALL_WIDE_HEIGHT
                                : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_LARGE ? LARGE_HEIGHT
                                    : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_LARGE_WIDE ? LARGE_WIDE_HEIGHT : NORMAL_HEIGHT) / mediaCard.image_height! * mediaCard.image_width!}px`,
                        zIndex: hover ? 100 : 0,
                    }}>
      </ImageCardBox>
      {enlargeOpen && <EnlargeImageDialog {...{mediaCard, setEnlargeOpen}} />}
  </ImageCardContainer>
 );
};

export default ImageCard;
