import React, {useEffect, useState} from 'react';
import styled, {createGlobalStyle} from "styled-components";
import Header from "../components/Header/Header";
import MainRows from "../components/Rows/MainRows";
import {FormattedMessage} from "react-intl";
import {
    COLOR_NEUTRAL,
    COLOR_WHITE,
} from "@motv-webapp/lib";
import {
    setClearCacheFalse,
    universalGetMyList,
    universal_clearCache,
    useAppDispatch,
    useAppSelector,
    vodGetVodHomepageRows,
    vod_rows,
} from "@motv-webapp/redux";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${COLOR_NEUTRAL};
  }
  ,
`

const VodPageContainer = styled.div(props => ({
    background: COLOR_NEUTRAL,
    color: COLOR_WHITE,
    minHeight: "100vh",
    overflowX: "hidden",
}));

const GenreDetailHeaderContainer = styled.div((props: {}) => ({
    alignItems:"center",
    display: "flex",
    margin: "100px 0px 32px 0",
}));

const BackButton = styled.button((props: {}) => ({
    background: "transparent",
    border: 0,
    cursor: "pointer",
    display: "flex",
    padding: 0,
    "& img": {
        height: "40px",
        margin:"0 16px 0 64px",
        width: "40px",
        "@media (max-width: 870px)": {
            marginLeft: "32px",
        }
    },
}));

const GenreHeader = styled.h1(props => ({
    lineHeight: 1,
    margin: 0,
    padding: 0,
}));

const VodHeadline = styled.h1(props => ({
    color: COLOR_WHITE,
    marginBottom: 32,
    marginLeft: "4rem",
    marginTop: 100,
    "@media (max-width: 800px)": {
        marginLeft: 32,
    }
}));

const VodPage = () => {
    const rows = useAppSelector(vod_rows)
    const dispatch = useAppDispatch()
    const clearCache = useAppSelector(universal_clearCache)
    const [showGenreDetail, setShowGenreDetail] = useState(false);
    const [genreDetailHeader, setGenreDetailHeader] = useState("");

    useEffect(() => {
        dispatch(vodGetVodHomepageRows({body:{}}))
        dispatch(universalGetMyList())
    }, []) // eslint-disable-line

    useEffect(() => {
        if (clearCache) {
            dispatch(vodGetVodHomepageRows({body: {}, ignoreCache: true}))
            dispatch(universalGetMyList())
            dispatch(setClearCacheFalse())
        }
    }, [clearCache]) // eslint-disable-line

    const handleClickGenreTitle = (genresId: number,title:string) => {
        dispatch(vodGetVodHomepageRows({body: {genresId: genresId}, ignoreCache: true}))
        setGenreDetailHeader(title)
        setShowGenreDetail(true)
    }

    const handleBackButtonClick = () => {
        dispatch(vodGetVodHomepageRows({body: {}, ignoreCache: true}))
        setShowGenreDetail(false)
    }

    return (
        <VodPageContainer>
            <GlobalStyle/>
            <Header/>
            {!showGenreDetail && <VodHeadline>
                <FormattedMessage id={"section_vod"} defaultMessage={"VOD"}/>
            </VodHeadline>}
            {showGenreDetail && (
                <GenreDetailHeaderContainer>
                    <BackButton onClick={handleBackButtonClick}>
                        <img src={"/images/icons/icon_arrow_back.svg"} alt={""}/>
                    </BackButton>
                    <GenreHeader>
                        {genreDetailHeader}
                    </GenreHeader>
                </GenreDetailHeaderContainer>
            )}
            {rows && <MainRows disableHeaderSpacing={true}
                               handleClickGenreTitle={showGenreDetail ? undefined : handleClickGenreTitle}
                               showGenreDetail={showGenreDetail}
                               vodLazyRows={true}
                               {...{rows}}/>}
        </VodPageContainer>
    );
};

export default VodPage;
