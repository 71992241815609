import React, {MouseEvent, useEffect, useState} from 'react';
import styled from "styled-components";
import {RecommendationCardEntity, SubscribedChannelEntity} from "@motv-webapp/lib";
import {RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM,} from "@motv-webapp/lib";
import {TIME_CONST_ONE_MINUTE} from "@motv-webapp/lib";
import {
    channel_channels,
    myListAddToMyList,
    myListRemoveFromMyList,
    recordingAddRecording,
    recordingRemoveRecording,
    selectYoutubeItem,
    universal_myList,
    universal_myRecordingIds,
    useAppDispatch,
    useAppSelector,
    user_customers_recording_length,
    user_customers_recording_used,
} from "@motv-webapp/redux";
import {useNavigate} from "react-router-dom";
import {getUnixTime, isAfter, isBefore} from "date-fns";

const ActionBarContainer = styled.div(props => ({
    backgroundImage: "linear-gradient(to bottom, rgba(16, 17, 19, 0) 0%, rgba(16, 17, 19, 0.5) 100%)",
    bottom: 0,
    position: "absolute",
    width: "100%",
}));

const ActionButtonsContainer = styled.div(props => ({
    display: "flex",
    gap: "0.5rem",
    justifyContent: "flex-end",
    marginBottom: "0.5rem",
    marginTop: "0.5rem",
    marginRight: "0.5rem",
}));

const ActionButton = styled.button(props => ({
    background: "transparent",
    border: 0,
    display: "flex",
    height: "1.3rem",
    padding: 0,
    width: "1.3rem",
    "& > img": {
        height: "1.3rem",
    }
}));

type Props = {
    mediaCard: RecommendationCardEntity
};

const MediaCardActionBar = ({mediaCard}: Props) => {
    const [recordable, setRecordable] = useState(false)
    const [playable, setPlayable] = useState(false)
    const [isInMyList, setIsInMyList] = useState(false)
    const [isInMyRecordings, setIsInMyRecordings] = useState(false)
    const dispatch = useAppDispatch()
    const channels = useAppSelector(channel_channels) as Array<SubscribedChannelEntity>
    const myList = useAppSelector(universal_myList)
    const myRecordingIds = useAppSelector(universal_myRecordingIds)
    const customersRecordingLength = useAppSelector(user_customers_recording_length)
    const customersRecordingUsed = useAppSelector(user_customers_recording_used)
    const navigate = useNavigate()

    useEffect(() => {
        if (!mediaCard.start
            || !channels?.find(channel => channel.channels_id === mediaCard.channels_id)?.channels_recording_length
            || !channels?.find(channel => channel.channels_id === mediaCard.channels_id)?.customers_recording_length
            || !channels?.find(channel => channel.channels_id === mediaCard.channels_id)?.channels_recording_length) return
        const now = Date.now();
        const start = Date.parse(mediaCard.start);
        setRecordable(
            (mediaCard.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV || mediaCard.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.RECORDING) && (
                !!channels.find(channel => channel.channels_id === mediaCard.channels_id) &&
                channels.find(channel => channel.channels_id === mediaCard.channels_id)?.channels_recording_length! > 0 &&
                now - channels.find(channel => channel.channels_id === mediaCard.channels_id)?.customers_recording_length! * TIME_CONST_ONE_MINUTE < start &&
                now - channels.find(channel => channel.channels_id === mediaCard.channels_id)?.channels_recording_length! * TIME_CONST_ONE_MINUTE < start &&
                !!mediaCard?.duration &&
                customersRecordingLength - customersRecordingUsed > mediaCard.duration
            )
        )
    }, [])

    useEffect(() => {
        if (mediaCard.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV) {
            if (!mediaCard.start
                || !channels?.find(channel => channel.channels_id === mediaCard.channels_id)?.customers_recording_length) return
            const now = Date.now();
            const start = Date.parse(mediaCard.start);
            setPlayable(
                start < now &&
                now - channels.find(channel => channel.channels_id === mediaCard.channels_id)!.customers_recording_length! * TIME_CONST_ONE_MINUTE < start
            );
        } else if (mediaCard.type !== RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.CATEGORY
            && mediaCard.type !== RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.CHANNEL
            && mediaCard.type !== RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.PERSON
            && mediaCard.type !== RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.GENRE
            && mediaCard.type !== RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.IMAGE
            && mediaCard.type !== RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.VIDEO
            && mediaCard.type !== RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.ADMOB
        ) {
            setPlayable(true)
        }
    }, [])

    useEffect(() => {
        myList?.some(item => item.id === mediaCard.id) ? setIsInMyList(true) : setIsInMyList(false)
    }, [myList])

    useEffect(() => {
        mediaCard.id && myRecordingIds?.includes(mediaCard.id) ? setIsInMyRecordings(true) : setIsInMyRecordings(false)
    }, [myRecordingIds])

    const handlePlayButton = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        switch (mediaCard.type) {
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV:
                if (!mediaCard.start || !mediaCard.end) return
                let now = new Date()
                let start = new Date(mediaCard.start)
                let end = new Date(mediaCard.end)
                isAfter(now, start) && isBefore(now, end)
                    ? navigate(`/tv/player/${mediaCard.channels_id}`)
                    : navigate(`/tv/player/${mediaCard.channels_id}/${getUnixTime(start)}`)
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.RECORDING:
                navigate(`/recording/player/${mediaCard.id}`)
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.VOD:
                navigate(`/vod/player/${mediaCard.id}`)
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.YOUTUBE:
                dispatch(selectYoutubeItem(mediaCard))
                break
            default:
                return
        }
    }

    const handleMyListButton = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        if (mediaCard.type && mediaCard.id) {
            if (isInMyList) {
                dispatch(myListRemoveFromMyList({type: mediaCard.type, id: mediaCard.id}))
            } else {
                dispatch(myListAddToMyList({type: mediaCard.type, id: mediaCard.id}))
            }
        }
    }

    const handleRecordButton = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        if (mediaCard.id) {
            if (isInMyRecordings) {
                dispatch(recordingRemoveRecording({epgEventsId: mediaCard.id}))
            } else {
                dispatch(recordingAddRecording({epgEventsId: mediaCard.id}))
            }
        }
    }

    return (
        <ActionBarContainer>
            <ActionButtonsContainer>
                <ActionButton onClick={handleMyListButton}>
                    <img src={isInMyList ? "/images/icons/icon_list_remove.svg" : "/images/icons/icon_list_add.svg"}
                         alt={""}/>
                </ActionButton>
                {(mediaCard.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV || mediaCard.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.RECORDING)
                    && (recordable || isInMyRecordings) &&
                  <ActionButton onClick={handleRecordButton}>
                    <img
                      src={isInMyRecordings ? "/images/icons/icon_record_remove.svg" : "/images/icons/icon_record.svg"}
                      alt={""}/>
                  </ActionButton>
                }
                {/*Don't simplify 'mediaCard.playable !== false' to 'mediaCard.playable. It has to be true when mediaCard.playable = null.*/}
                {(playable || isInMyRecordings || mediaCard.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.VOD) && !mediaCard.locked && mediaCard.playable !== false &&
                  <ActionButton onClick={handlePlayButton}>
                    <img src={"/images/icons/icon_play.svg"} alt={""}/>
                  </ActionButton>
                }
            </ActionButtonsContainer>
        </ActionBarContainer>
    );
};

export default MediaCardActionBar;
