import DetailDescription from "../components/DetailView/DetailDescription";
import DetailHero from "../components/DetailView/DetailHero";
import Header from "../components/Header/Header";
import MainRows from "../components/Rows/MainRows";
import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useAppDispatch, useAppSelector} from "@motv-webapp/redux";
import {useParams} from "react-router-dom";
import {
    COLOR_NEUTRAL,
    COLOR_WHITE,
    RecommendationCardEntity,
    isCategoryEntity,
} from "@motv-webapp/lib";
import {
    channelGetSubscribedChannels,
    setClearCacheFalse,
    universalGetMyList,
    universalGetMyRecordings,
    universalSelectedItemGetCategory,
    universal_clearCache,
    universal_selectedItem,
    universal_selectedItemRows,
} from "@motv-webapp/redux";

const DetailContainer = styled.div(props => ({
    background: COLOR_NEUTRAL,
    color: COLOR_WHITE,
    minHeight: "100vh",
    overflowX: "hidden",
}));

const MobileSpacer = styled.div(props => ({
    height: 40,
}));

const DetailVodPage = () => {
    const [fakeMediaItem, setFakeMediaItem] = useState<RecommendationCardEntity | undefined>(undefined) // when category follow === null
    const {mediaId} = useParams()
    const dispatch = useAppDispatch()
    const mediaItem = useAppSelector(universal_selectedItem)
    const rows = useAppSelector(universal_selectedItemRows)
    const clearCache = useAppSelector(universal_clearCache)

    useEffect(() => {
        dispatch(universalGetMyList())
        dispatch(channelGetSubscribedChannels({body: {}}))
        dispatch(universalGetMyRecordings())
    }, []) // eslint-disable-line

    useEffect(() => {
        mediaId && dispatch(universalSelectedItemGetCategory({body:{categoriesId: parseInt(mediaId)}}))
    }, [mediaId]) // eslint-disable-line

    useEffect(() => {
        if (clearCache) {
            dispatch(universalGetMyList())
            dispatch(channelGetSubscribedChannels({body: {}, ignoreCache: true}))
            dispatch(universalGetMyRecordings())
            mediaId && dispatch(universalSelectedItemGetCategory({body: {categoriesId: parseInt(mediaId)}, ignoreCache: true}))
            dispatch(setClearCacheFalse())
        }
    }, [clearCache]) // eslint-disable-line

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
    }, [])

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, [mediaId])

    useEffect(() => {
        if (!mediaItem) return
        setFakeMediaItem({
            action: undefined,
            action_type: undefined,
            actors: undefined,
            admob_id: undefined,
            categories_id: undefined,
            categories_name: undefined,
            channels_forced_pin: undefined,
            channels_id: undefined,
            channels_logo: undefined,
            channels_logo_widescreen: undefined,
            channels_name: undefined,
            description: undefined,
            directors: undefined,
            duration: undefined,
            end: undefined,
            episode: undefined,
            episode_number: undefined,
            expiration: undefined,
            external_id: undefined,
            follow: undefined,
            genres: undefined,
            hot_news: undefined,
            id: undefined,
            // @ts-ignore
            image: mediaItem.categories_image!,
            // @ts-ignore
            image_height: mediaItem.categories_image_height!,
            image_widescreen: undefined,
            image_widescreen_height: undefined,
            image_widescreen_width: undefined,
            // @ts-ignore
            image_width: mediaItem.categories_image_width!,
            imdb_id: undefined,
            imdb_rating: undefined,
            locked: undefined,
            order: undefined,
            origin: undefined,
            playable: undefined,
            preferred_offset: undefined,
            rating: undefined,
            released: undefined,
            reminder: undefined,
            removable: undefined,
            score: undefined,
            season_number: undefined,
            start: undefined,
            subtitle: undefined,
            type: undefined,
            video: undefined,
            video_label: undefined,
            video_loop: undefined,
            video_sound_on: undefined,
            youtube_id: undefined,
            title: mediaItem.categories_name!})
    }, [mediaItem])

    return (
        <DetailContainer>
            <Header/>
            {mediaItem && fakeMediaItem && isCategoryEntity(mediaItem) &&
              <>
                <DetailHero mediaItem={mediaItem.follow ? mediaItem.follow : fakeMediaItem}/>
                <DetailDescription mediaItem={mediaItem.follow}/>
                  {rows && <MainRows disableHeaderSpacing={true} {...{rows}} />}
                <MobileSpacer/>
              </>
            }
        </DetailContainer>
    );
};

export default DetailVodPage;
