"use strict";
exports.__esModule = true;
exports.imageResizeUrl = void 0;
var imageResizeUrl = function (url, width) {
    if (!url || !width)
        return;
    var availableSizes = [64, 96, 160, 256, 320, 720, 1280];
    var suffix = "";
    for (var i = 0; i < availableSizes.length; i++) {
        if (availableSizes[i] > width) {
            suffix = availableSizes[i].toString();
            break;
        }
    }
    if (suffix !== "") {
        return url + "/" + suffix;
    }
    else {
        return url;
    }
};
exports.imageResizeUrl = imageResizeUrl;
