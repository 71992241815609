import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {
    error_error_sms,
    motvApiLoginV2,
    motvApiRegister,
    motvApiUpdateCustomerV2,
    motvGetMotvCustomerConfig,
    portal_customerTemplate,
    portal_motv_portals_account_update_enabled,
    portal_motv_portals_id,
    portal_motv_portals_privacy_url,
    portal_motv_portals_registration_method,
    portal_motv_portals_terms_url,
    removeAllErrors,
    useAppDispatch,
    useAppSelector,
    user_customers_token,
    user_selectedLanguage,
    vendor_vendors_id,
} from "@motv-webapp/redux";
import styled from "styled-components";
import CustomerEditFieldText from "./CustomerEditFields/CustomerEditFieldText";
import CustomerEditFieldPassword from "./CustomerEditFields/CustomerEditFieldPassword";
import CustomerEditFieldNumber from "./CustomerEditFields/CustomerEditFieldNumber";
import CustomerEditFieldEmail from "./CustomerEditFields/CustomerEditFieldEmail";
import CustomerEditFieldSelect from "./CustomerEditFields/CustomerEditFieldSelect";
import CustomerEditFieldCheckBox from "./CustomerEditFields/CustomerEditFieldCheckBox";
import {
    COLOR_DANGER_100,
    COLOR_NEUTRAL_90,
    COLOR_OFFWHITE,
    ERROR_CODE_SMS_DUPLICATE_ACCOUNT,
    ERROR_CODE_SMS_INVALID_PASSWORD_FORMAT,
    ERROR_CODE_SMS_SOMETHING,
    MOTV_PORTAL_REGISTRATION_METHOD_ENUM,
    getLanguage,
    validateEmail,
} from "@motv-webapp/lib";
import CustomerEditFieldDate from "./CustomerEditFields/CustomerEditFieldDate";
import PrimaryButton from "../Button/PrimaryButton";
import {FormattedMessage, useIntl} from "react-intl";
import {AnimatePresence, motion} from "framer-motion";
import {getClientConfig} from "@motv-webapp/config";
import {Navigate} from "react-router-dom";

const CustomerEditContainer = styled.div((props: { isWide: boolean }) => ({
    display: "flex",
    flexDirection: "column" as "column",
    width: props.isWide ? "100%" : "320px",
}));

const SectionName = styled.p(props => ({
    fontSize: "1.563rem",
    fontWeight: 500,
    marginTop: 0,
    marginBottom: "1rem",
}));

const StaticText = styled.div(props => ({
    backgroundColor: COLOR_NEUTRAL_90,
    borderRadius: 6,
    color: COLOR_OFFWHITE,
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
    marginBottom: "2rem",
    "& h1, h2, h3, h4, h5, h6": {
        margin: 0,
    },
    "& p": {
        fontWeight: 500,
        margin: 0,
    }
}));

const ErrorMessage = styled(motion.p)(props => ({
    color: COLOR_DANGER_100,
    fontSize: "0.8rem",
    fontWeight: 700,
    marginTop: "-0.7rem",
}));

const TermsAndPolicy = styled.p(props => ({
    fontSize: "0.8rem",
    fontWeight: 700,
    marginBottom: 0,
}));

type Props = {
    afterSubmit?: () => void
    isRegistration?: boolean
    isWide?: boolean
    setGsmStep?: Dispatch<SetStateAction<boolean>>
};

const CustomerEdit = ({afterSubmit, isRegistration = false, isWide = false, setGsmStep}: Props) => {
    const [disableAllFields, setDisableAllFields] = useState(false)
    const dispatch = useAppDispatch()
    const intl = useIntl()
    const customerEditEnabled = useAppSelector(portal_motv_portals_account_update_enabled)
    const registrationMethod = useAppSelector(portal_motv_portals_registration_method)
    const customerTemplate = useAppSelector(portal_customerTemplate)
    const customers_token = useAppSelector(user_customers_token)
    const language = useAppSelector(user_selectedLanguage)
    const portals_id = useAppSelector(portal_motv_portals_id)
    const privacyUrl = useAppSelector(portal_motv_portals_privacy_url)
    const termsUrl = useAppSelector(portal_motv_portals_terms_url)
    const vendors_id = useAppSelector(vendor_vendors_id)
    const smsError = useAppSelector(error_error_sms)
    const [values, setValues] = useState<{ [key: string]: string | boolean }>({})
    const [errors, setErrors] = useState<{ [key: string]: string | undefined }>({})

    useEffect(() => {
        portals_id && dispatch(motvGetMotvCustomerConfig({
            language: language || getLanguage() || getClientConfig().languages[0],
            portals_id: portals_id,
        }))
    }, []) // eslint-disable-line

    useEffect(() => {
        if (!customerTemplate) return
        customerTemplate.map((section) => {
            section.fields.map((field) => {
                if (field.value) setValues((prevValues) => ({...prevValues, [field.key]: field.value!}))
            })
        })
    }, [customerTemplate])

    const handleChange = (key: string, value: string | boolean) => {
        setValues({...values, [key]: value})
    }

    const handleValidation = () => {
        if (!customerTemplate) return // just so TS is calm

        let formValid = true
        customerTemplate.forEach((section) => {
            section.fields.forEach((field) => {
                //Check all required fields
                if (!field.optional && field.type !== "static_text") {
                    if (values[field.key] === undefined || values[field.key] === "") {
                        setErrors(prevState => ({...prevState, [field.key]: "message_required_field"}))
                        formValid = false
                    }
                }
                //Check must_equal
                if (field.must_equal) {
                    if (values[field.key] !== values[field.must_equal]) {
                        setErrors(prevState => ({...prevState, [field.key]: "label_settings_passwords_are_not_same"}))
                        formValid = false
                    }
                }
                //Check pattern - if field is not optional or already user entered some value
                if (field.pattern && (!field.optional || (values[field.key] !== "" && !!values[field.key]))) {
                    const patternRegExp = new RegExp(field.pattern)
                    if (!patternRegExp.test(values[field.key]?.toString())) {
                        setErrors(prevState => ({...prevState, [field.key]: field.patternLabel}))
                        formValid = false
                    }
                }
                //Check if password is not the same as login
                if (field.type === "password" && values[field.key] === values["login"]) {
                    setErrors(prevState => ({...prevState, [field.key]: "message_password_same_as_login"}))
                    formValid = false
                }
                //Check if email is email
                if (field.type === "email" && !validateEmail(String(values[field.key]))) {
                    setErrors(prevState => ({...prevState, [field.key]: "message_email_validation_error"}))
                    formValid = false
                }
            })
        })
        return formValid
    }

    const handleSaveCustomer = async () => {
        // Disable all fields
        setDisableAllFields(true)
        //Clean all errors
        setErrors({})
        await dispatch(removeAllErrors())
        //Value checks
        if (!handleValidation()) {
            setDisableAllFields(false)
            return
        }
        //Submit
        if (isRegistration && (!customers_token && portals_id)) {
            await dispatch(motvApiRegister({
                language: language || getLanguage() || getClientConfig().languages[0],
                portalId: portals_id,
                data: values
            }))
        } else if (customers_token) {
            await dispatch(motvApiUpdateCustomerV2({
                language: language || getLanguage() || getClientConfig().languages[0],
                customers_token,
                data: values
            }))
        }

        // Check if all went ok
        if (smsError?.status) {
            setDisableAllFields(false)
            return
        }
        //If GSM registration, confirm code
        if (registrationMethod === MOTV_PORTAL_REGISTRATION_METHOD_ENUM.GSM) {
            setGsmStep && setGsmStep(true)
            return
        }
        //Login new customer
        vendors_id
        && values.login
        && values.password
        && dispatch(motvApiLoginV2({login: values.login as string, password: values.password as string, vendors_id}))
        //Clean all errors and values
        setValues({})
        setErrors({})
        //Enable all fields
        setDisableAllFields(false)
        afterSubmit && afterSubmit()

    }

    const handleEnter = () => {
        handleSaveCustomer()
    }

    return (
        (customerEditEnabled || isRegistration) ?
            <CustomerEditContainer isWide={isWide}>
                {customerTemplate && customerTemplate.map((section, index) => {
                    return (
                        <div key={section.sectionName + index}>
                            {section.sectionName !== "" && <SectionName>{section.sectionName}</SectionName>}
                            {section.fields.map((fieldItem) => {
                                switch (fieldItem.type) {
                                    case "static_text":
                                        return (
                                            // @ts-ignore
                                            <StaticText key={fieldItem.key}
                                                        dangerouslySetInnerHTML={{__html: fieldItem.label}}/>
                                        )
                                    case "text":
                                        return (
                                            <CustomerEditFieldText disabled={(disableAllFields || fieldItem.readonly)}
                                                                   defaultValue={fieldItem.value ? fieldItem.value : undefined}
                                                                   errorMessageId={errors[fieldItem.key]}
                                                                   key={fieldItem.key}
                                                                   onChange={handleChange}
                                                                   onEnter={handleEnter}
                                                                   {...{fieldItem}} />
                                        )
                                    case "password":
                                        return (
                                            <CustomerEditFieldPassword
                                                disabled={(disableAllFields || fieldItem.readonly)}
                                                errorMessageId={errors[fieldItem.key]}
                                                key={fieldItem.key}
                                                onChange={handleChange}
                                                onEnter={handleEnter}
                                                {...{fieldItem}} />
                                        )
                                    case "number":
                                        return (
                                            <CustomerEditFieldNumber disabled={(disableAllFields || fieldItem.readonly)}
                                                                     errorMessageId={errors[fieldItem.key]}
                                                                     key={fieldItem.key}
                                                                     onChange={handleChange}
                                                                     onEnter={handleEnter}
                                                                     {...{fieldItem}} />
                                        )
                                    case "email":
                                        return (
                                            <CustomerEditFieldEmail disabled={(disableAllFields || fieldItem.readonly)}
                                                                    defaultValue={fieldItem.value ? fieldItem.value : undefined}
                                                                    errorMessageId={errors[fieldItem.key]}
                                                                    key={fieldItem.key}
                                                                    onChange={handleChange}
                                                                    onEnter={handleEnter}
                                                                    {...{fieldItem}} />
                                        )
                                    case "select":
                                        return (
                                            <CustomerEditFieldSelect disabled={(disableAllFields || fieldItem.readonly)}
                                                                     defaultValue={fieldItem.value ? fieldItem.value : undefined}
                                                                     errorMessageId={errors[fieldItem.key]}
                                                                     key={fieldItem.key}
                                                                     onChange={handleChange}
                                                                     {...{fieldItem}} />
                                        )
                                    case "checkbox":
                                        return (
                                            <CustomerEditFieldCheckBox
                                                disabled={(disableAllFields || fieldItem.readonly)}
                                                defaultChecked={!!fieldItem.value}
                                                errorMessageId={errors[fieldItem.key]}
                                                key={fieldItem.key}
                                                onChange={handleChange}
                                                {...{fieldItem}} />
                                        )
                                    case "date":
                                        return (
                                            <CustomerEditFieldDate disabled={(disableAllFields || fieldItem.readonly)}
                                                                   defaultValue={fieldItem.value ? new Date(fieldItem.value).toISOString().substr(0, 10) : undefined}
                                                                   errorMessageId={errors[fieldItem.key]}
                                                                   key={fieldItem.key}
                                                                   onChange={handleChange}
                                                                   {...{fieldItem}} />
                                        )
                                    default:
                                        return
                                }
                            })}
                        </div>
                    )
                })}
                <AnimatePresence>
                    {smsError && <ErrorMessage
                      initial={{y: -20, height: 0}}
                      animate={{y: 0, height: "auto"}}
                      exit={{y: -20, height: 0}}
                      transition={{type: "tween", duration: 0.4}}
                    >
                        {smsError.status === ERROR_CODE_SMS_SOMETHING ?
                            <FormattedMessage id={"message_something_went_wrong"}
                                              defaultMessage={"Something went wrong."}/>
                            : smsError.status === ERROR_CODE_SMS_DUPLICATE_ACCOUNT ?
                                <FormattedMessage id={"message_duplicate_login"}
                                                  defaultMessage={"This login is already being used, please select another one."}/>
                                : smsError.status === ERROR_CODE_SMS_INVALID_PASSWORD_FORMAT ?
                                    <FormattedMessage id={"message_password_validation_error"}
                                                      defaultMessage={"Wrong password format."}/>
                                    : <FormattedMessage id={"message_something_went_wrong"}
                                                        defaultMessage={"Something went wrong."}/>}
                    </ErrorMessage>}
                </AnimatePresence>
                <PrimaryButton
                    disabled={disableAllFields}
                    label={intl.formatMessage({id: "label_submit", defaultMessage: "Submit"})}
                    onClick={handleSaveCustomer}
                />
                {isRegistration && (privacyUrl || termsUrl) &&
                  <TermsAndPolicy>
                      {(privacyUrl && termsUrl) &&
                        <>
                        <FormattedMessage
                          id={"label_privacy_and_terms_message_2"}
                          defaultMessage={"By signing up, you agree to the {s1} and {s2}"}
                          values={{
                            s1: <a href={privacyUrl} target={"_blank"}>{intl.formatMessage({id: "label_privacy_policy_in_message", defaultMessage: "Privacy Policy"})}</a>,
                            s2: <a href={termsUrl} target={"_blank"}>{intl.formatMessage({id: "label_terms_and_conditions_in_message", defaultMessage: "Terms & Conditions"})}</a>
                          }}
                          />
                          .
                        </>
                      }
                      {(privacyUrl && !termsUrl) &&
                        <>
                          <FormattedMessage
                            id={"label_privacy_only_message"}
                            defaultMessage={"By signing up, you agree to the {s}"}
                            values={{
                                s: <a href={privacyUrl} target={"_blank"}>{intl.formatMessage({id: "label_privacy_policy_in_message", defaultMessage: "Privacy Policy"})}</a>,
                            }}
                          />
                          .
                        </>
                      }
                      {(!privacyUrl && termsUrl) &&
                        <>
                          <FormattedMessage
                            id={"label_terms_only_message"}
                            defaultMessage={"By signing up, you agree to the {s}"}
                            values={{
                                s: <a href={privacyUrl} target={"_blank"}>{intl.formatMessage({id: "label_privacy_policy_in_message", defaultMessage: "Privacy Policy"})}</a>,
                            }}
                          />
                          .
                        </>
                      }
                  </TermsAndPolicy>
                }
            </CustomerEditContainer>
            : <Navigate to={"/"}/>
    );
};

export default CustomerEdit;
