import React, {Dispatch, SetStateAction} from "react";
import styled from "styled-components";
import {COLOR_NEUTRAL, COLOR_OFFWHITE} from "@motv-webapp/lib";
import {TYPEFACE_BODY, TYPEFACE_HEADLINE_3} from "@motv-webapp/lib";
import {setSessionStorage} from "@motv-webapp/lib";
import {isAndroid, isIOS} from "react-device-detect";
import {useAppSelector} from "@motv-webapp/redux";
import {portal_motv_portals_android, portal_motv_portals_ios} from "@motv-webapp/redux";
import {FormattedMessage} from "react-intl";
import {vendor_vendors_name} from "@motv-webapp/redux";

const MobileAppBannerContainer = styled.div(props => ({
    background: COLOR_OFFWHITE,
    borderRadius:"10px 10px 0 0",
    bottom:0,
    boxSizing:"border-box" as "border-box",
    color:COLOR_NEUTRAL,
    padding:"32px",
    position:"fixed" as "fixed",
    width:"100vw",
    zIndex:999,
    "& > h4": {
        ...TYPEFACE_HEADLINE_3,
    },
    "& > p": {
        ...TYPEFACE_BODY,
        margin:"16px 0 28px 0",
    },
}));

const DownloadButton = styled.button((props: {}) => ({
    ...TYPEFACE_BODY,
    background:COLOR_NEUTRAL,
    border: 0,
    borderRadius:"10px",
    color:COLOR_OFFWHITE,
    padding:"12px",
    width:"100%",
}));

const CloseButton = styled.button((props: {}) => ({
    background: "transparent",
    border: 0,
    cursor: "pointer",
    display: "flex",
    padding: 0,
    position: "absolute" as "absolute",
    top: "16px",
    right: "16px",
    "& img": {
        height: "20px",
        width: "20px",
    },
}));

type Props = {
    setShowMobileAppBanner: Dispatch<SetStateAction<boolean>>
}

const MobileAppBanner = ({setShowMobileAppBanner}:Props) => {
    const androidAppId = useAppSelector(portal_motv_portals_android)
    const iosAppId = useAppSelector(portal_motv_portals_ios)
    const vendorsName = useAppSelector(vendor_vendors_name)

    const handleCloseMobileAppBanner = () => {
        setShowMobileAppBanner(false)
        setSessionStorage("mobileAppBanner","mobileAppBanner")
    }

    const handleDownloadButtonClick = () => {
        if (isIOS) {
            window.location.replace(`https://apps.apple.com/app/${iosAppId}`)
        } else if (isAndroid){
            window.location.replace(`market://details?id=${androidAppId}`)
        }
    }

    return (
        <MobileAppBannerContainer>
            <h4>
                <FormattedMessage id={"message_mobile_app_heading"}
                                  defaultMessage={"{s1} is better on the app!"}
                                  values={{
                                      s1: vendorsName,
                                  }}/>
            </h4>
            <p>
                <FormattedMessage id={"message_mobile_app_message"}
                                  defaultMessage={"Enjoy the full experience of our TV in the {s1} app."}
                                  values={{
                                      s1: vendorsName,
                                  }}/>
            </p>
            <DownloadButton onClick={handleDownloadButtonClick}>
                <FormattedMessage id={"label_download_now"} defaultMessage={"Download now"}/>
            </DownloadButton>
            <CloseButton onClick={handleCloseMobileAppBanner}>
                <img src={"/images/icons/icon_close_black.svg"} alt={""}/>
            </CloseButton>
        </MobileAppBannerContainer>
    )
}

export default MobileAppBanner;
