import React, {useState} from 'react';
import {
    fetch_sms_devicesMotvApiConfirmRegistration,
} from "@motv-webapp/redux";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import {
    COLOR_NEUTRAL_90,
    COLOR_OFFWHITE,
    ERROR_CODE_SMS_UNKNOWN_REGISTRATION_TOKEN,
    INPUT_FIELD_TYPE_ENUM,
    isResponseSuccessful
} from "@motv-webapp/lib";
import {FormattedMessage, useIntl} from "react-intl";
import Input from "../InputFields/Input";
import PrimaryButton from "../Button/PrimaryButton";

const StaticText = styled.div(props => ({
    backgroundColor: COLOR_NEUTRAL_90,
    borderRadius: 6,
    color: COLOR_OFFWHITE,
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
    marginBottom: "2rem",
}));

type Props = {};

const CreateAccountGsmStep = ({}: Props) => {
    const [disableAllFields, setDisableAllFields] = useState(false)
    const [errorCode, setErrorCode] = useState<number|undefined>(undefined)
    const [gsmCode, setGsmCode] = useState("")
    const intl = useIntl()
    const navigate = useNavigate()

    const handleGsmSubmit = async () => {
        setDisableAllFields(true)
        await fetch_sms_devicesMotvApiConfirmRegistration({code: gsmCode}).then((res) => {
            if (!isResponseSuccessful(res)) {
                setErrorCode(res.status)
                setDisableAllFields(false)
                return
            }
            setDisableAllFields(false)
            navigate("/")
            return
        })
    }
    return (
        <>
            <StaticText>
                <FormattedMessage
                    id={"message_gsm_confirmation_sms_sent"}
                    defaultMessage={"We have sent you SMS with a confirmation code. Please check your messages."}
                />
            </StaticText>
            <Input
                errorMessageId={errorCode === ERROR_CODE_SMS_UNKNOWN_REGISTRATION_TOKEN ? "message_incorrect_code" : undefined}
                disabled={disableAllFields}
                onChange={setGsmCode}
                placeholder={intl.formatMessage({id: "label_code", defaultMessage: "Code"})}
                type={INPUT_FIELD_TYPE_ENUM.TEXT}
            />
            <PrimaryButton disabled={disableAllFields} label={intl.formatMessage({id: "label_submit", defaultMessage: "Submit"})} onClick={handleGsmSubmit} />
        </>
    );
};

export default CreateAccountGsmStep;
