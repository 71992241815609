import React, {useEffect, useRef, useState} from 'react';
import {imageResizeUrl, RecommendationCardEntity} from "@motv-webapp/lib";
import styled from "styled-components";
import {COLOR_WHITE, COLOR_WHITE_60} from "@motv-webapp/lib";
import {useIntl} from "react-intl";
import {isResponseSuccessful} from "@motv-webapp/lib";
import {fetch_mw_epgRatingGetImageByRating} from "@motv-webapp/redux";
import {useAppSelector} from "@motv-webapp/redux";
import {user_selectedLanguage} from "@motv-webapp/redux";
import {RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM} from "@motv-webapp/lib";
import {ADVERT_HOMEPAGE_ACTION_TYPE_ENUM} from "@motv-webapp/lib";
import {TIME_CONST_ONE_MINUTE} from "@motv-webapp/lib";
import {channel_channels} from "@motv-webapp/redux";
import {useNavigate} from "react-router-dom";
import {getUnixTime, isAfter, isBefore} from "date-fns";
import PrimaryButton from "../Button/PrimaryButton";
import {
    fetch_mw_vendorGetLockedChannelTextImage,
    fetch_mw_vendorGetLockedVodTextImage
} from "@motv-webapp/redux";
import {BUTTON_SIZE_ENUM, BUTTON_TYPE_ENUM} from "@motv-webapp/lib";
import {useWindowSize} from "react-use";

const MediaCardFullWidthContainer = styled.div((props: { cardImage: string | undefined | null }) => ({
    backgroundImage: `url(${props.cardImage})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: "80vh",
    position: "relative" as "relative",
}));

const MediaCardFullWidthVideoContainer = styled.div((props) => ({
    alignItems: "center",
    backgroundPosition: "center",
    backgroundSize: "cover",
    display: "flex",
    height: "80vh",
    justifyContent: "center",
    overflow: "hidden",
    position: "relative" as "relative",
    "& > video": {
        objectFit: "cover",
        minHeight: "100%",
        minWidth: "100%",
    }
}));

const ImageOverlay = styled.div(props => ({
    backgroundImage: "linear-gradient(0deg, rgba(16,17,19, 0.3) 0%, rgba(16, 17, 19, 0) 100%)",
    height: "100%",
    position: "absolute",
    width: "100%",
}));

const ImageShadow = styled.div(props => ({
    backgroundImage: "linear-gradient(0deg, rgba(16,17,19,1) 0%, rgba(16,17,19,0.3) 50%, rgba(16,17,19,0) 100%)",
    bottom: 0,
    height: "50%",
    position: "absolute",
    width: "100%",
}));

const MediaCardInfo = styled.div(props => ({
    bottom: "4rem",
    left: "4rem",
    position: "absolute",
    "@media (max-width: 800px)": {
        left: "2rem",
    }
}));

const EpisodeName = styled.p(props => ({
    fontWeight: 500,
    margin: "0 0 0.75rem 0",
}));

const MediaCardMediaInfo = styled.div(props => ({
    alignItems: "center",
    display: "flex",
    marginBottom: "1.5rem",
}));

const ChannelImage = styled.img(props => ({
    height: 24,
    marginRight: "0.5rem",
}));

const EpgInfo = styled.div(props => ({
    color: COLOR_WHITE_60,
    fontSize: "0.8rem",
    fontWeight: 500,
    marginRight: "0.5rem",
}));

const ImdbRating = styled.div(props => ({
    color: COLOR_WHITE_60,
    fontSize: "0.8rem",
    fontWeight: 500,
    marginRight: "0.5rem",
    "& > img": {
        marginRight: "0.5rem",
        verticalAlign: "sub",
    }
}));

const RatingImage = styled.img(props => ({
    height: 18,
}));

const ButtonContainer = styled.div(props => ({
    display: "flex",
    gap: 8,
}));

const VideoControlContainer = styled.div(props => ({
    bottom: "4rem",
    display: "flex",
    position: "absolute",
    right: "4rem",
    "@media (max-width: 800px)": {
        right: "2rem",
    }
}));

const VideoControlButton = styled.button(props => ({
    alignItems: "center",
    backgroundColor: "transparent",
    border: 0,
    borderRadius: 3,
    color: COLOR_WHITE,
    display: "flex",
    height: "3rem",
    fontSize: "0.8rem",
    fontWeight: "bold",
    padding: "0.375rem 0.5rem 0.375rem 0.375rem",
    "& > img": {
        marginRight: 4,
        height: "100%",
        opacity: 0.6,
    }
}));

type Props = {
    mediaCard: RecommendationCardEntity
};

const MediaCardFullWidth = ({mediaCard}: Props) => {
    const [lockedUrl, setLockedUrl] = useState("")
    const [ratingImage, setRatingImage] = useState(undefined)
    const [isPlaying, setIsPlaying] = useState(true)
    const [isMute, setIsMute] = useState(!mediaCard.video_sound_on)
    const [playable, setPlayable] = useState(false)
    const channels = useAppSelector(channel_channels)
    const language = useAppSelector(user_selectedLanguage)
    const intl = useIntl()
    const navigate = useNavigate()
    const videoElementRef = useRef<HTMLVideoElement>(null)
    const {width} = useWindowSize();

    useEffect(() => {
        if (mediaCard.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV) {
            if (!mediaCard.start
                || !channels?.find(channel => channel.channels_id === mediaCard.channels_id)?.customers_recording_length) return
            const now = Date.now();
            const start = Date.parse(mediaCard.start);
            setPlayable(
                start < now &&
                now - channels.find(channel => channel.channels_id === mediaCard.channels_id)!.customers_recording_length! * TIME_CONST_ONE_MINUTE < start
            );
        } else if (mediaCard.type !== RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.CATEGORY
            && mediaCard.type !== RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.CHANNEL
            && mediaCard.type !== RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.PERSON
            && mediaCard.type !== RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.GENRE
            && mediaCard.type !== RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.IMAGE
            && mediaCard.type !== RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.VIDEO
            && mediaCard.type !== RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.ADMOB
        ) {
            setPlayable(true)
        }
    }, [])

    useEffect(() => {
        mediaCard.rating && fetch_mw_epgRatingGetImageByRating({rating: mediaCard.rating})
            .then(response => {
                if (isResponseSuccessful(response) && response.response !== null) {
                    setRatingImage(response.response)
                }
            })
    }, [mediaCard.rating])

    useEffect(() => {
        if (mediaCard?.locked) {
            if (mediaCard.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.VOD) {
                fetch_mw_vendorGetLockedVodTextImage({vodsId: mediaCard.id!})
                    .then(res => {
                            if (isResponseSuccessful(res)) {
                                setLockedUrl(res.response.vendors_locked_item_url)
                            }
                        }
                    )
            } else if (mediaCard.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV) {
                fetch_mw_vendorGetLockedChannelTextImage({channelsId: mediaCard.channels_id!})
                    .then(res => {
                            if (isResponseSuccessful(res)) {
                                setLockedUrl(res.response.vendors_locked_item_url)
                            }
                        }
                    )
            }
        }
    }, [mediaCard])

    const handlePlayPause = () => {
        if (videoElementRef.current?.paused) {
            videoElementRef.current.play()
            setIsPlaying(true)
        } else {
            videoElementRef.current?.pause()
            setIsPlaying(false)
        }
    }

    const handleMuteUnmute = () => {
        if (videoElementRef.current?.muted) {
            setIsMute(false)
        } else {
            setIsMute(true)
        }
    }

    const handleClickInfo = () => {
        switch (mediaCard.type) {
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV:
                navigate(`/event/${mediaCard.id}`)
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.RECORDING:
                navigate(`/recording/${mediaCard.id}`)
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.VOD:
                navigate(`/vod/${mediaCard.id}`)
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.YOUTUBE:
                navigate(`/vod/${mediaCard.id}`)
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.IMAGE:
                window.location.href = mediaCard.action!
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.VIDEO:
                window.location.href = mediaCard.action!
                break
            default:
                return
        }
    }

    const handleClickPlay = () => {
        switch (mediaCard.type) {
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV:
                if (!mediaCard.start || !mediaCard.end) return
                let now = new Date()
                let start = new Date(mediaCard.start)
                let end = new Date(mediaCard.end)
                isAfter(now, start) && isBefore(now, end)
                    ? navigate(`/tv/player/${mediaCard.channels_id}`)
                    : navigate(`/tv/player/${mediaCard.channels_id}/${getUnixTime(start)}`)
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.RECORDING:
                navigate(`/recording/player/${mediaCard.id}`)
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.VOD:
                navigate(`/vod/player/${mediaCard.id}`)
                break
            default:
                return
        }
    }

    const handleClickBuy = () => {
        window.location.href = lockedUrl
    }

    return (
        mediaCard.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.VIDEO
            ? (
                <MediaCardFullWidthVideoContainer>
                    <video
                        autoPlay
                        src={mediaCard.video!}
                        muted={isMute}
                        poster={
                            mediaCard.image_widescreen
                                ? mediaCard.image_widescreen
                                : mediaCard.image!
                        }
                        ref={videoElementRef}
                        loop={!!mediaCard.video_loop}
                    />
                    <ImageShadow/>
                    <MediaCardInfo>
                        <h2>{mediaCard.title}</h2>
                        {mediaCard.episode && <EpisodeName>{mediaCard.episode}</EpisodeName>}
                        <MediaCardMediaInfo>
                            {mediaCard.channels_logo && <ChannelImage src={mediaCard.channels_logo} alt={""}/>}
                            {mediaCard.start && <EpgInfo>{new Intl.DateTimeFormat(language, {
                                month: 'long', day: 'numeric',
                                hour: 'numeric', minute: 'numeric',
                                hour12: false,
                            }).format(Date.parse(mediaCard.start))}</EpgInfo>}
                            {mediaCard.imdb_rating &&
                              <ImdbRating><img src={"/images/icons/icon_imdb.png"} alt={""}/>
                                  {mediaCard.imdb_rating}/10
                              </ImdbRating>
                            }
                            {ratingImage && <RatingImage src={ratingImage} alt={""}/>}
                        </MediaCardMediaInfo>
                        <ButtonContainer>
                            {mediaCard.action_type === ADVERT_HOMEPAGE_ACTION_TYPE_ENUM.URL &&
                              <PrimaryButton
                                beforeImage={"/images/icons/icon_info.svg"}
                                buttonSize={BUTTON_SIZE_ENUM.SMALL}
                                buttonType={BUTTON_TYPE_ENUM.SECONDARY}
                                label={mediaCard.video_label ? mediaCard.video_label : intl.formatMessage({id: "label_show_info", defaultMessage: "Info"})}
                                onClick={handleClickInfo}
                              />
                            }
                        </ButtonContainer>
                    </MediaCardInfo>
                    <VideoControlContainer>
                        <VideoControlButton onClick={handlePlayPause}>
                            {isPlaying ? <img src={"/images/icons/icon_pause.svg"} alt={""}/> :
                                <img src={"/images/icons/icon_play.svg"} alt={""}/>}
                        </VideoControlButton>
                        <VideoControlButton onClick={handleMuteUnmute}>
                            {isMute ? <img src={"/images/icons/icon_sound_mute.svg"} alt={""}/> :
                                <img src={"/images/icons/icon_sound.svg"} alt={""}/>}
                        </VideoControlButton>
                    </VideoControlContainer>
                </MediaCardFullWidthVideoContainer>
            )
            : (<MediaCardFullWidthContainer
                cardImage={
                    imageResizeUrl(mediaCard.image_widescreen
                        ? mediaCard.image_widescreen
                        : mediaCard.image, width)
                }
            >
                <ImageOverlay/>
                <ImageShadow/>
                <MediaCardInfo>
                    {mediaCard.type !== RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.IMAGE && <h2>{mediaCard.title}</h2>}
                    {mediaCard.episode && <EpisodeName>{mediaCard.episode}</EpisodeName>}
                    <MediaCardMediaInfo>
                        {mediaCard.channels_logo && <ChannelImage src={mediaCard.channels_logo} alt={""}/>}
                        {mediaCard.start && <EpgInfo>{new Intl.DateTimeFormat(language, {
                            month: 'long', day: 'numeric',
                            hour: 'numeric', minute: 'numeric',
                            hour12: false,
                        }).format(Date.parse(mediaCard.start))}</EpgInfo>}
                        {mediaCard.imdb_rating &&
                          <ImdbRating><img src={"/images/icons/icon_imdb.png"} alt={""}/>
                              {mediaCard.imdb_rating}/10
                          </ImdbRating>
                        }
                        {ratingImage && <RatingImage src={ratingImage} alt={""}/>}
                    </MediaCardMediaInfo>
                    <ButtonContainer>
                        {!!mediaCard?.locked &&
                          <PrimaryButton
                            beforeImage={"/images/icons/icon_dollar.svg"}
                            buttonSize={BUTTON_SIZE_ENUM.SMALL}
                            buttonType={BUTTON_TYPE_ENUM.OFFWHITE}
                            label={intl.formatMessage({id: "label_buy", defaultMessage: "Buy"})}
                            onClick={handleClickBuy}
                          />}
                        {playable && !mediaCard?.locked &&
                          <PrimaryButton
                            beforeImage={"/images/icons/icon_play.svg"}
                            buttonSize={BUTTON_SIZE_ENUM.SMALL}
                            buttonType={BUTTON_TYPE_ENUM.OFFWHITE}
                            label={intl.formatMessage({id: "label_play", defaultMessage: "Play"})}
                            onClick={handleClickPlay}
                          />
                        }
                        {(mediaCard.action_type === ADVERT_HOMEPAGE_ACTION_TYPE_ENUM.URL
                                || mediaCard.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV
                                || mediaCard.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.RECORDING
                                || mediaCard.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.VOD
                                || mediaCard.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.YOUTUBE
                            ) &&
                          <PrimaryButton
                            beforeImage={"/images/icons/icon_info.svg"}
                            buttonSize={BUTTON_SIZE_ENUM.SMALL}
                            buttonType={BUTTON_TYPE_ENUM.SECONDARY}
                            label={intl.formatMessage({id: "label_show_info", defaultMessage: "Info"})}
                            onClick={handleClickInfo}
                          />
                        }
                    </ButtonContainer>
                </MediaCardInfo>
            </MediaCardFullWidthContainer>)
    );
};

export default MediaCardFullWidth;
