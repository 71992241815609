"use strict";
exports.__esModule = true;
exports.CHANNEL_CARD_WIDTH = exports.CHANNEL_CARD_HEIGHT = exports.LARGE_WIDE_WIDTH = exports.LARGE_WIDE_HEIGHT = exports.LARGE_WIDTH = exports.LARGE_HEIGHT = exports.SMALL_WIDE_WIDTH = exports.SMALL_WIDE_HEIGHT = exports.NORMAL_WIDE_WIDTH = exports.NORMAL_WIDE_HEIGHT = exports.NORMAL_WIDTH = exports.NORMAL_HEIGHT = void 0;
exports.NORMAL_HEIGHT = 200;
exports.NORMAL_WIDTH = 150;
exports.NORMAL_WIDE_HEIGHT = 140;
exports.NORMAL_WIDE_WIDTH = 250;
exports.SMALL_WIDE_HEIGHT = 100;
exports.SMALL_WIDE_WIDTH = 180;
exports.LARGE_HEIGHT = 320;
exports.LARGE_WIDTH = 240;
exports.LARGE_WIDE_HEIGHT = 260;
exports.LARGE_WIDE_WIDTH = 460;
exports.CHANNEL_CARD_HEIGHT = 146;
exports.CHANNEL_CARD_WIDTH = 146;
