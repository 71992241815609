import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useAppSelector} from "@motv-webapp/redux";
import {channel_channels} from "@motv-webapp/redux";
import ChannelListItem from "./ChannelListItem";
import ChannelListFilter from "./ChannelListFilter";
import {TYPEFACE_CAPTION_BOLD} from "@motv-webapp/lib";
import {SubscribedChannelEntity} from "@motv-webapp/lib";
import ChannelReorderList from "./ChannelReorderList";
import {CHANNEL_TYPE_ENUM} from "@motv-webapp/lib";
import {FormattedMessage, useIntl} from "react-intl";
import Error from "../ErrorHandle/Error";

const ChannelListContainer = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    marginBottom: 90,
    marginLeft: "4rem",
    "@media (max-width: 800px)": {
        marginLeft: 32
    }
}));

const ChannelDragAndDropInfoContainer = styled.div((props) => ({
    ...TYPEFACE_CAPTION_BOLD,
    alignItems: "center",
    display: "flex",
    marginBottom:"32px",
    "& img": {
        height: "20px",
        marginRight: "8px",
    },
}));

const ChannelsContainer = styled.div(props =>({
    display: "flex",
    flexWrap: "wrap",
    gap: "2rem 1rem",
}));

type Props = {
    isChannelReorderEnabled:boolean
};

const ChannelList = ({isChannelReorderEnabled}: Props) => {
    const [typeFilter, setTypeFilter] = useState<string | undefined>(undefined)
    const [categoryFilter, setCategoryFilter] = useState<number | undefined>(undefined)
    const [channelOrderItems, setChannelOrderItems] = useState<SubscribedChannelEntity[]>([])
    const [radioOrderItems, setRadioOrderItems] = useState<SubscribedChannelEntity[]>([])
    const [filteredChannels, setFilteredChannels] = useState<SubscribedChannelEntity[]>([])
    const channels = useAppSelector(channel_channels)
    const intl = useIntl()

    useEffect(() => {
        if (!channels) return
        let channelsFiltered = channels.filter(channel => channel.channels_type === CHANNEL_TYPE_ENUM.CHANNEL)
        let radiosFiltered = channels.filter(channel => channel.channels_type === CHANNEL_TYPE_ENUM.RADIO)
        setChannelOrderItems(channelsFiltered)
        setRadioOrderItems(radiosFiltered)
    }, [channels])

     useEffect(() => {
         if (isChannelReorderEnabled) {
             setTypeFilter(undefined)
             setCategoryFilter(undefined)
         }
    }, [isChannelReorderEnabled])

    useEffect(() => {
        if (!channels) return
        if (!!typeFilter) {
            setFilteredChannels(channels.filter(item => item.channels_type === typeFilter))
        } else if (!!categoryFilter) {
            setFilteredChannels(channels.filter(item => item.channels_categories?.includes(categoryFilter)))
        } else (
            setFilteredChannels(channels)
        )
    }, [channels, typeFilter, categoryFilter])

    return (
        <>
            {channels && !!channels.length ? (
                <ChannelListContainer data-testid={"ChannelListContainer"}>
                    {isChannelReorderEnabled ? (
                        <ChannelDragAndDropInfoContainer>
                            <img src={"/images/icons/icon_info.svg"} alt={""}/>
                            <div>
                                <FormattedMessage id="label_drag_drop" defaultMessage="Drag and drop channel cards to reorder"/>
                            </div>
                        </ChannelDragAndDropInfoContainer>
                    ) : (
                        <ChannelListFilter {...{setTypeFilter, setCategoryFilter}} />
                    )}
                    {!isChannelReorderEnabled ? (
                        <ChannelsContainer>
                            {filteredChannels.map((channel) => {
                                    return (
                                        <ChannelListItem isChannelReorderEnabled={isChannelReorderEnabled}
                                                         key={channel.channels_id} {...{channel}} />
                                    )
                            })}
                        </ChannelsContainer>
                    ) : (
                        <>
                            <ChannelReorderList isChannelList={true} channels={channelOrderItems}
                                                isChannelReorderEnabled={isChannelReorderEnabled}/>
                            <ChannelReorderList channels={radioOrderItems}
                                                isChannelReorderEnabled={isChannelReorderEnabled}/>
                        </>
                    )}
                </ChannelListContainer>
            ) : (
                <Error src={"/images/icons/icon_ghost_filled.svg"} labelHeadline={intl.formatMessage({id: "label_no_channels_purchased", defaultMessage: "You do not have any channels purchased"})}/>
                )}
        </>

    );
};

export default ChannelList;
