"use strict";
exports.__esModule = true;
exports.CHANNEL_BROADCAST_TYPE_ENUM = exports.PROFILE_SD_NAME_ENUM = exports.TEMPLATE_ENCRYPTION_ENUM = exports.TEMPLATE_CODEC_ENUM = exports.CHANNEL_STREAM_TYPE_ENUM = exports.CHANNEL_SOURCE_TYPE_ENUM = exports.CONTENT_TYPE_ENUM = void 0;
var CONTENT_TYPE_ENUM;
(function (CONTENT_TYPE_ENUM) {
    CONTENT_TYPE_ENUM["LIVE"] = "live";
    CONTENT_TYPE_ENUM["TIMESHIFT"] = "timeshift";
    CONTENT_TYPE_ENUM["CATCHUP"] = "catchup";
    CONTENT_TYPE_ENUM["RECORDING"] = "recording";
    CONTENT_TYPE_ENUM["VOD"] = "vod";
})(CONTENT_TYPE_ENUM = exports.CONTENT_TYPE_ENUM || (exports.CONTENT_TYPE_ENUM = {}));
var CHANNEL_SOURCE_TYPE_ENUM;
(function (CHANNEL_SOURCE_TYPE_ENUM) {
    CHANNEL_SOURCE_TYPE_ENUM["REGULAR"] = "regular";
    CHANNEL_SOURCE_TYPE_ENUM["HTTP"] = "http";
    CHANNEL_SOURCE_TYPE_ENUM["REMOTE"] = "remote";
})(CHANNEL_SOURCE_TYPE_ENUM = exports.CHANNEL_SOURCE_TYPE_ENUM || (exports.CHANNEL_SOURCE_TYPE_ENUM = {}));
var CHANNEL_STREAM_TYPE_ENUM;
(function (CHANNEL_STREAM_TYPE_ENUM) {
    CHANNEL_STREAM_TYPE_ENUM["IP"] = "IP";
    CHANNEL_STREAM_TYPE_ENUM["MCAST"] = "MCAST";
    CHANNEL_STREAM_TYPE_ENUM["BCAST"] = "BCAST";
})(CHANNEL_STREAM_TYPE_ENUM = exports.CHANNEL_STREAM_TYPE_ENUM || (exports.CHANNEL_STREAM_TYPE_ENUM = {}));
var TEMPLATE_CODEC_ENUM;
(function (TEMPLATE_CODEC_ENUM) {
    TEMPLATE_CODEC_ENUM["H264"] = "h264";
    TEMPLATE_CODEC_ENUM["HEVC"] = "hevc";
})(TEMPLATE_CODEC_ENUM = exports.TEMPLATE_CODEC_ENUM || (exports.TEMPLATE_CODEC_ENUM = {}));
var TEMPLATE_ENCRYPTION_ENUM;
(function (TEMPLATE_ENCRYPTION_ENUM) {
    TEMPLATE_ENCRYPTION_ENUM["CENC"] = "cenc";
    TEMPLATE_ENCRYPTION_ENUM["CBCS"] = "cbcs";
    TEMPLATE_ENCRYPTION_ENUM["CLEAR"] = "clear";
})(TEMPLATE_ENCRYPTION_ENUM = exports.TEMPLATE_ENCRYPTION_ENUM || (exports.TEMPLATE_ENCRYPTION_ENUM = {}));
var PROFILE_SD_NAME_ENUM;
(function (PROFILE_SD_NAME_ENUM) {
    PROFILE_SD_NAME_ENUM["SD_ONLY"] = "SD_ONLY";
    PROFILE_SD_NAME_ENUM["SD_HD"] = "SD_HD";
    PROFILE_SD_NAME_ENUM["SD_UHD1"] = "SD_UHD1";
    PROFILE_SD_NAME_ENUM["SD_UHD2"] = "SD_UHD2";
})(PROFILE_SD_NAME_ENUM = exports.PROFILE_SD_NAME_ENUM || (exports.PROFILE_SD_NAME_ENUM = {}));
var CHANNEL_BROADCAST_TYPE_ENUM;
(function (CHANNEL_BROADCAST_TYPE_ENUM) {
    CHANNEL_BROADCAST_TYPE_ENUM["TYPE_DVB_S"] = "TYPE_DVB_S";
    CHANNEL_BROADCAST_TYPE_ENUM["TYPE_DVB_C"] = "TYPE_DVB_C";
    CHANNEL_BROADCAST_TYPE_ENUM["TYPE_DVB_T"] = "TYPE_DVB_T";
    CHANNEL_BROADCAST_TYPE_ENUM["TYPE_DVB_T2"] = "TYPE_DVB_T2";
})(CHANNEL_BROADCAST_TYPE_ENUM = exports.CHANNEL_BROADCAST_TYPE_ENUM || (exports.CHANNEL_BROADCAST_TYPE_ENUM = {}));
