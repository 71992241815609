"use strict";
exports.__esModule = true;
exports.removeLocalStorage = exports.getLocalStorage = exports.setLocalStorage = void 0;
var setLocalStorage = function (name, value) {
    window.localStorage.setItem(name, value);
};
exports.setLocalStorage = setLocalStorage;
var getLocalStorage = function (name) {
    return window.localStorage.getItem(name) !== "undefined" ? window.localStorage.getItem(name) : undefined;
};
exports.getLocalStorage = getLocalStorage;
var removeLocalStorage = function (name) {
    window.localStorage.setItem(name, "");
};
exports.removeLocalStorage = removeLocalStorage;
