"use strict";
exports.__esModule = true;
exports.truncate = void 0;
var truncate = function (str, n, useWordBoundary) {
    if (useWordBoundary === void 0) { useWordBoundary = false; }
    if (str.length <= n) {
        return str;
    }
    var subString = str.substring(0, n - 1); // the original check
    return (useWordBoundary
        ? subString.substring(0, subString.lastIndexOf(" "))
        : subString) + "...";
};
exports.truncate = truncate;
