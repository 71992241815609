"use strict";
exports.__esModule = true;
exports.BUTTON_SIZE_ENUM = exports.BUTTON_TYPE_ENUM = void 0;
var BUTTON_TYPE_ENUM;
(function (BUTTON_TYPE_ENUM) {
    BUTTON_TYPE_ENUM["VENDOR"] = "vendor";
    BUTTON_TYPE_ENUM["OFFWHITE"] = "offwhite";
    BUTTON_TYPE_ENUM["SECONDARY"] = "secondary";
    BUTTON_TYPE_ENUM["TERTIARY"] = "tertiary";
})(BUTTON_TYPE_ENUM = exports.BUTTON_TYPE_ENUM || (exports.BUTTON_TYPE_ENUM = {}));
var BUTTON_SIZE_ENUM;
(function (BUTTON_SIZE_ENUM) {
    BUTTON_SIZE_ENUM["LARGE"] = "large";
    BUTTON_SIZE_ENUM["MEDIUM"] = "medium";
    BUTTON_SIZE_ENUM["SMALL"] = "small";
})(BUTTON_SIZE_ENUM = exports.BUTTON_SIZE_ENUM || (exports.BUTTON_SIZE_ENUM = {}));
