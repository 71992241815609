import React, {ChangeEvent, Dispatch, SetStateAction, useEffect, useState} from "react";
import styled from "styled-components";
import {fetch_mw_profileGetPredefinedProfileImages} from "@motv-webapp/redux";
import {isResponseSuccessful} from "@motv-webapp/lib";
import {useAppSelector} from "@motv-webapp/redux";
import {vendor_vendors_id} from "@motv-webapp/redux";
import {createFileFromUri} from "@motv-webapp/lib";
import ImagePicker from "../../../ProfileSelector/ImagePicker";
import {ProfileEntity} from "@motv-webapp/lib";

const ImagePickerContainer = styled.div(props => ({
    "& > span": {
        fontSize: "1.563rem",
        fontWeight: 500,
    },
    "@media (max-width: 870px)": {
        textAlign: "center"
    },
    width: "496px",
    "@media (max-width: 630px)": {
        width: "400px",
    },
    "@media (max-width: 550px)": {
        width: "288px",
    }
}));

type Props = {
    profile: ProfileEntity,
    setProfileToEdit: Dispatch<SetStateAction<ProfileEntity>>
    setIsEditImageDialogOpen: Dispatch<SetStateAction<boolean>>
    setProfilePictureChanged: Dispatch<SetStateAction<boolean>>
}

const SettingsImagePickerContainer = ({
                         profile,
                         setProfileToEdit,
                         setIsEditImageDialogOpen,
                         setProfilePictureChanged
                     }: Props) => {
    const [predefinedProfileImages, setPredefinedProfileImages] = useState([])
    const vendorsId = useAppSelector(vendor_vendors_id)


    const handleSelectNewPredefinedImage = (image: string) => {
        createFileFromUri(image).then((file: File) => {
            const reader = new FileReader();
            reader.addEventListener("load", function () {
                setProfileToEdit({...profile, ["image"]: reader.result ? reader.result as string : ""})
            });
            if (file) {
                reader.readAsDataURL(file);
            }
        });
        setIsEditImageDialogOpen(false);
        setProfilePictureChanged(true);
    }

    const handleUploadNewImage = (e: ChangeEvent<HTMLInputElement>) => {
        if (e?.target?.files?.length && e.target.files[0]) {
            const reader = new FileReader();
            reader.addEventListener("load", function () {
                setProfileToEdit({...profile, ["image"]: reader.result ? reader.result as string : ""})
            });
            reader.readAsDataURL(e.target.files[0]);
        }
        setIsEditImageDialogOpen(false);
        setProfilePictureChanged(true);
    }

    useEffect(() => {
        vendorsId && fetch_mw_profileGetPredefinedProfileImages({vendorsId:vendorsId})
            .then(response => {
                if (isResponseSuccessful(response)) {
                    setPredefinedProfileImages(response.response)
                }
            })
    }, []) //eslint-disable-line

    return (
        <ImagePickerContainer>
            <ImagePicker predefinedProfileImages={predefinedProfileImages}
                         handleSelectNewPredefinedImage={handleSelectNewPredefinedImage}
                         handleUploadNewImage={handleUploadNewImage}/>
        </ImagePickerContainer>
    )
}

export default SettingsImagePickerContainer;
