import React, {
    ChangeEvent,
    Dispatch,
    MouseEvent,
    RefObject,
    SetStateAction,
    useEffect,
    useRef,
    useState
} from 'react';
import {isRecommendationCardType} from "@motv-webapp/lib";
import SeekbarSlider from "../Slider/SeekbarSlider";
import {CONTENT_TYPE_ENUM} from "@motv-webapp/lib";
import {AnimatePresence, motion} from "framer-motion";
import Slider from "rc-slider";
import {
    COLOR_SECONDARY,
    COLOR_TERTIARY,
    COLOR_OFFWHITE_90,
    COLOR_WHITE
} from "@motv-webapp/lib";
import {FormattedMessage} from "react-intl";
import SubtitlesDialog from "./SubtitlesDialog";
import SettingsDialog from "./SettingsDialog";
import SideControllers from "./SideControllers";
import styled from "styled-components";
import {useAppSelector} from "@motv-webapp/redux";
import {universal_selectedItem} from "@motv-webapp/redux";
import {user_selectedLanguage} from "@motv-webapp/redux";
import {useNavigate} from "react-router-dom";
import {StreamUrlV2Entity} from "@motv-webapp/lib";
import {setLocalStorage} from "@motv-webapp/lib";
import {addDays, getUnixTime} from "date-fns";
import {variantTrackType} from "@motv-webapp/lib";
import {error_error_mw} from "@motv-webapp/redux";
import {fetch_mw_profileEventAudioChange, fetch_mw_profileEventSubtitleChange} from "@motv-webapp/redux";
import {PLAYER_SIDE_CONTROLLER_PANEL_ENUM} from "@motv-webapp/lib";

const BackController = styled.div((props: {adsInProgress: boolean}) => ({
    backgroundImage: props.adsInProgress ? "none" : "linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%)",
    height: props.adsInProgress ? "initial" : 360,
    left: 0,
    top: 0,
    position: "absolute" as "absolute",
    width: props.adsInProgress ? "initial" : "100%",
    zIndex: 998
}));

const BackButton = styled.button(props => ({
    background: "transparent",
    border: 0,
    height: 48,
    marginTop: 54,
    marginLeft: 72,
    width: 48,
    "& img": {
        height: "100%",
        width: "100%"
    },
    "@media (max-width: 600px)": {
        marginLeft: 12,
    }
}));

const Controllers = styled.div(props => ({
    backgroundImage: "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%)",
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    height: 360,
    justifyContent: "flex-end",
    position: "absolute",
    width: "100%",
    zIndex: 998,
}));

const EpgInfo = styled.div(props => ({
    color: COLOR_WHITE,
    display: "flex",
    flexDirection: "column",
    marginBottom: 45,
    marginLeft: 64,
    marginRight: 64,
    "& > h2": {
        marginBottom: 12,
    },
    "& > div": {
        alignItems: "center",
        display: "flex",
        "& > img": {
            height: 20,
            marginRight: 8,
        },
    },
    "@media (max-width: 600px)": {
        marginLeft: 12,
        marginRight: 12,
    }
}));

const TimeProgressBar = styled.div(props => ({
    alignItems: "center",
    display: "flex",
    marginBottom: 8,
    marginLeft: 64,
    marginRight: 64,
    "& > p": {
        color: COLOR_WHITE,
        fontSize: "0.8rem",
        fontWeight: 800,
        margin: "0 0 0 10px",
    },
    "@media (max-width: 600px)": {
        marginLeft: 12,
        marginRight: 12,
    }
}));

const ControlButtonsContainer = styled.div(props => ({
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 48,
    marginLeft: 64,
    marginRight: 64,
    position: "relative",
    "> div": {
        alignItems: "center",
        display: "flex",
    },
    "@media (max-width: 600px)": {
        marginLeft: 12,
        marginRight: 12,
    }
}));

const ControlButton = styled.button(props => ({
    background: "transparent",
    border: 0,
    height: 32,
    minHeight: 32,
    minWidth: 32,
    width: 32,
    "& img": {
        height: "100%",
        width: "100%"
    }
}));

const VolumeControl = styled.div(props => ({
    alignItems: "center",
    display: "flex",
    marginRight: 8,
}));

const VolumeSlider = styled(motion.div)(props => ({
    marginLeft: 10,
    marginRight: 16,
    width: 100,
}));

const ControlDialog = styled.div(props => ({
    background: COLOR_SECONDARY,
    bottom: 54,
    border: `1px solid ${COLOR_TERTIARY}`,
    borderRadius: 6,
    display: "flex",
    flexDirection: "column",
    padding: "24px 0",
    position: "absolute",
    right: 0,
    zIndex: 998,
}));

const LiveButton = styled.div((props: { hoverLive: boolean, isLive: boolean }) => ({
    alignItems: "center",
    color: (props.hoverLive || props.isLive) ? COLOR_WHITE : COLOR_OFFWHITE_90,
    cursor: "pointer",
    display: "flex",
    fontSize: "0.8rem",
    fontWeight: (props.hoverLive || props.isLive) ? "bold" : "normal",
    textTransform: "uppercase" as "uppercase",
    "& > div": {
        background: props.isLive ? "#ff0000" : "transparent",
        border: props.isLive ? 0 : `1px solid ${COLOR_OFFWHITE_90}`,
        borderRadius: "50%",
        height: 8,
        marginLeft: 6,
        width: 8,
    }
}));

type Props = {
    adsInProgress: boolean
    containerRef: RefObject<HTMLDivElement>
    currentTime: number
    endOfPlaybackOverlay: boolean
    handleForward: () => void
    handlePlayButton: () => void
    handleRewind: () => void
    handleShowActionIcon: (url: string) => void
    handleVolumeChange: (newValue: number | number[]) => void
    isAbrEnabled: boolean
    liveEdge: number
    mediaId: string | undefined
    playerType: "vod" | "tv" | "recording"
    playerRef: RefObject<any>
    setCurrentTime: Dispatch<SetStateAction<number>>
    setSubtitleTrack: Dispatch<SetStateAction<variantTrackType | undefined>>
    setVariantTrack: Dispatch<SetStateAction<variantTrackType | undefined>>
    setVariantTrackQuality: (e: MouseEvent<HTMLDivElement>, newBitrate: number) => void
    sideControllersPanel: PLAYER_SIDE_CONTROLLER_PANEL_ENUM | undefined,
    setSideControllersPanel: Dispatch<SetStateAction<PLAYER_SIDE_CONTROLLER_PANEL_ENUM|undefined>>,
    streamDetails: StreamUrlV2Entity | undefined
    subtitleTrack: variantTrackType | undefined
    uptimeThumbnails: number
    variantTrack: variantTrackType | undefined
    volume: number
    videoRef: RefObject<HTMLVideoElement>
};

const PlayerControllers = ({
                               adsInProgress,
                               containerRef,
                               currentTime,
                               endOfPlaybackOverlay,
                               handleForward,
                               handlePlayButton,
                               handleRewind,
                               handleShowActionIcon,
                               handleVolumeChange,
                               isAbrEnabled,
                               liveEdge,
                               mediaId,
                               playerType,
                               playerRef,
                               setCurrentTime,
                               setSubtitleTrack,
                               setVariantTrack,
                               setVariantTrackQuality,
                               sideControllersPanel,
                               setSideControllersPanel,
                               streamDetails,
                               subtitleTrack,
                               uptimeThumbnails,
                               variantTrack,
                               volume,
                               videoRef,
                           }: Props) => {
    const [hoverLive, setHoverLive] = useState(false)
    const [isFullscreen, setIsFullscreen] = useState(!!document.fullscreenElement)
    const [isPlaying, setIsPlaying] = useState(!videoRef.current?.paused)
    const [playbackRate, setPlaybackRate] = useState(1)
    const [settingsOpen, setSettingsOpen] = useState(false)
    const [subtitlesOpen, setSubtitlesOpen] = useState(false)
    const [volumeBarDrag, setVolumeBarDrag] = useState(false)
    const [volumeBarVisible, setVolumeBarVisible] = useState(false)
    const [volumeMute, setVolumeMute] = useState(false)
    const volumeBarRef = useRef<HTMLInputElement | null>(null);
    const navigate = useNavigate()
    const currentlyPlaying = useAppSelector(universal_selectedItem)
    const language = useAppSelector(user_selectedLanguage)
    const mwError = useAppSelector(error_error_mw)

    useEffect(() => {
        if (videoRef.current) videoRef.current.playbackRate = playbackRate
    }, [playbackRate])

    const handleClickLive = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        if (streamDetails?.type === CONTENT_TYPE_ENUM.LIVE) {
            // @ts-ignore
            playerRef.current?.goToLive()
        } else {
            // @ts-ignore
            playerRef.current?.destroy()
            setCurrentTime(0)
            navigate(`/tv/player/${mediaId}`, {replace: true})
        }
    }

    const handleGoBack = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        sideControllersPanel
            ? setSideControllersPanel(undefined)
            // : customHistory.action === "REPLACE" ? navigate("/") : navigate(-1)
            : navigate(-1)
    }

    const handleMouseEnterHoverLive = () => {
        setHoverLive(true)
    }

    const handleMouseEnterLeaveLive = () => {
        setHoverLive(false)
    }

    const handleMute = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        if (videoRef.current?.volume === 0) {
            videoRef.current!.volume = volume
            handleShowActionIcon("/images/icons/icon_sound.svg")
            setLocalStorage("playerMute", JSON.stringify({
                playerMute: false,
                expiration: getUnixTime(addDays(Date.now(), 1)) * 1000
            }))
        } else {
            videoRef.current!.volume = 0
            handleShowActionIcon("/images/icons/icon_sound_mute.svg")
            setLocalStorage("playerMute", JSON.stringify({
                playerMute: true,
                expiration: getUnixTime(addDays(Date.now(), 1)) * 1000
            }))
        }
    }

    const handlePictureInPicture = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        // @ts-ignore
        videoRef.current?.requestPictureInPicture()
            .catch(error => {
                console.log("Picture in picture failed with error: ", error)
            });
    }

    const handleProgressSlider = (e: ChangeEvent<HTMLInputElement>) => {
        if (playerType === "tv" && streamDetails?.type === CONTENT_TYPE_ENUM.LIVE) {
            if (parseInt(e.target.value) > liveEdge) {
                // @ts-ignore
                playerRef.current?.goToLive()
            } else {
                // @ts-ignore
                if (currentlyPlaying.start) videoRef.current!.currentTime = (playerRef.current.seekRange()!.end - (Date.now() - (getUnixTime(new Date(currentlyPlaying.start)) * 1000)) / 1000) + Number(e.target.value)
            }
            setCurrentTime(parseInt(e.target.value));
        } else {
            videoRef.current!.currentTime = parseInt(e.target.value);
            setCurrentTime(parseInt(e.target.value));
        }
    }

    const handleSettings = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        setSubtitlesOpen(false)
        settingsOpen ? setSettingsOpen(false) : setSettingsOpen(true)
    }

    const handleSubtitles = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        setSettingsOpen(false)
        subtitlesOpen ? setSubtitlesOpen(false) : setSubtitlesOpen(true)
    }

    const handleVolumeOnMouseEnter = () => {
        setVolumeBarVisible(true)
    }

    const handleVolumeOnMouseLeave = () => {
        setVolumeBarVisible(false)
    }

    const handleVolumeStartDrag = () => {
        setVolumeBarDrag(true)
    }

    const handleVolumeStopDrag = () => {
        setVolumeBarDrag(false)
    }

    const hideSubtitles = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        // @ts-ignore
        playerRef.current?.setTextTrackVisibility(false)
        setSubtitleTrack(undefined)
        currentlyPlaying && isRecommendationCardType(currentlyPlaying) && fetch_mw_profileEventSubtitleChange(
            {
                language: null,
                ...(playerType === "vod") ? ({vodsId: currentlyPlaying.id}) : ({channelsId: currentlyPlaying.channels_id})
            })
        setSubtitlesOpen(false)
    }

    const setVariantTrackAudio = (e: MouseEvent<HTMLDivElement>, newLanguage: string) => {
        e.stopPropagation()
        // @ts-ignore
        const currentVariantTrack = playerRef.current?.getVariantTracks().find((track: variantTrackType) => track.active)
        // @ts-ignore
        const newVariantTrack = playerRef.current?.getVariantTracks().find((track: variantTrackType) => track.language.toLowerCase() === newLanguage.toLowerCase() && track.videoBandwidth === currentVariantTrack.videoBandwidth)
        // @ts-ignore
        playerRef.current?.selectVariantTrack(newVariantTrack, true)
        setVariantTrack(newVariantTrack)
        currentlyPlaying && isRecommendationCardType(currentlyPlaying) && fetch_mw_profileEventAudioChange(
            {
                language: newLanguage,
                ...(playerType === "vod") ? ({vodsId: currentlyPlaying.id}) : ({channelsId: currentlyPlaying.channels_id})
            })
        setSubtitlesOpen(false)
    }

    const setVariantTrackSubtitle = (e: MouseEvent<HTMLDivElement>, newLanguage: string) => {
        e.stopPropagation()
        // @ts-ignore
        playerRef.current?.setTextTrackVisibility(true)
        // @ts-ignore
        playerRef.current?.selectTextLanguage(newLanguage)
        // @ts-ignore
        setSubtitleTrack(playerRef.current?.getTextTracks()?.find((trackItem: variantTrackType) => trackItem.language === newLanguage))
        currentlyPlaying && isRecommendationCardType(currentlyPlaying) && fetch_mw_profileEventSubtitleChange(
            {
                language: newLanguage,
                ...(playerType === "vod") ? ({vodsId: currentlyPlaying.id}) : ({channelsId: currentlyPlaying.channels_id})
            })
        setSubtitlesOpen(false)
    }

    // FULLSCREEN HANDLERS START
    const handleFullscreen = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        containerRef.current?.requestFullscreen()
    }

    const handleFullscreenExit = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        document.exitFullscreen()
    }

    const updateIsFullscreen = () => {
        if (!!document.fullscreenElement) {
            setIsFullscreen(true)
        } else {
            setIsFullscreen(false)
        }
    }

    useEffect(() => {
        if (!videoRef.current) return
        document.addEventListener('fullscreenchange', updateIsFullscreen);
        return () => {
            document.removeEventListener('fullscreenchange', updateIsFullscreen);
        }
    }, [videoRef.current])
    // FULLSCREEN HANDLERS END

    // LISTENERS START
    const handlePlayListener = () => {
        setIsPlaying(true)
    }

    const handlePauseListener = () => {
        setIsPlaying(false)
    }

    const handleVolumeChangeListener = () => {
        videoRef.current?.volume === 0 ? setVolumeMute(true) : setVolumeMute(false)
        if (volumeBarRef.current) volumeBarRef.current.value = videoRef.current!.volume.toString()
    }

    useEffect(() => {
        videoRef.current?.addEventListener("play", handlePlayListener);
        videoRef.current?.addEventListener("pause", handlePauseListener);
        videoRef.current?.addEventListener("volumechange", handleVolumeChangeListener);
        return () => {
            videoRef.current?.removeEventListener("play", handlePlayListener);
            videoRef.current?.removeEventListener("pause", handlePauseListener);
            videoRef.current?.removeEventListener("volumechange", handleVolumeChangeListener);
        }
    }, [])
    //LISTENERS END


    return (
        <>
            <BackController {...{adsInProgress}} >
                <BackButton onClick={handleGoBack}>
                    <img src={sideControllersPanel ? "/images/icons/icon_close.svg" : "/images/icons/icon_arrow_back.svg"}
                         alt={""}
                    />
                </BackButton>
            </BackController>
            {!adsInProgress && !mwError && !sideControllersPanel && !endOfPlaybackOverlay && <Controllers>
                {currentlyPlaying && isRecommendationCardType(currentlyPlaying) &&
                  <EpgInfo>
                      {currentlyPlaying?.title &&
                        <h2>{currentlyPlaying.title}</h2>}
                    <div>
                        {currentlyPlaying?.channels_logo &&
                          <img src={currentlyPlaying.channels_logo} alt={""}/>
                        }
                        {currentlyPlaying?.start && <h4>{new Intl.DateTimeFormat(language, {
                            month: 'long', day: 'numeric',
                            hour: 'numeric', minute: 'numeric',
                            hour12: false,
                        }).format(Date.parse(currentlyPlaying.start))}</h4>}
                    </div>
                  </EpgInfo>}
              <TimeProgressBar
                onClick={(e: MouseEvent<HTMLDivElement>) => e.stopPropagation()}
                onMouseDown={() => videoRef.current?.pause()}
                onMouseUp={() => videoRef.current?.play()}
              >
                  {currentlyPlaying && isRecommendationCardType(currentlyPlaying) && videoRef.current?.duration &&
                    <SeekbarSlider
                      enableSeeking={true}
                      min={0}
                      max={streamDetails?.type === CONTENT_TYPE_ENUM.LIVE ? currentlyPlaying.duration! : videoRef.current.duration}
                      onChange={handleProgressSlider}
                      value={currentTime}
                      // liveEdge={liveEdge > (streamDetails?.type === CONTENT_TYPE_ENUM.LIVE ? currentlyPlaying.duration! : videoRef.current.duration) ? streamDetails?.type === CONTENT_TYPE_ENUM.LIVE ? currentlyPlaying.duration! : videoRef.current.duration : liveEdge}
                      tooltip={new Date(((currentTime ? currentTime : 0))*1000).toISOString().substring(11, 19)}
                        {...{liveEdge, streamDetails, uptimeThumbnails}}
                    />
                  }
                  {currentlyPlaying && isRecommendationCardType(currentlyPlaying) && videoRef.current?.duration &&
                    <p>
                        {new Date((streamDetails?.type === CONTENT_TYPE_ENUM.LIVE ? currentlyPlaying.duration! : videoRef.current.duration) * 1000).toISOString().substring(11, 19)}
                    </p>}
              </TimeProgressBar>
              <ControlButtonsContainer>
                <div>
                  <ControlButton onClick={handlePlayButton}>
                    <img
                      src={isPlaying ? "/images/icons/icon_pause_player.svg" : "/images/icons/icon_play_player.svg"}
                      alt={""}/>
                  </ControlButton>
                  <ControlButton onClick={handleRewind}>
                    <img src={"/images/icons/icon_rewind10.svg"} alt={""}/>
                  </ControlButton>
                  <ControlButton onClick={handleForward}>
                    <img src={"/images/icons/icon_forward10.svg"} alt={""}/>
                  </ControlButton>
                  <VolumeControl onClick={(e: MouseEvent<HTMLDivElement>) => e.stopPropagation()}
                                 onMouseEnter={handleVolumeOnMouseEnter}
                                 onMouseLeave={handleVolumeOnMouseLeave}>
                    <ControlButton onClick={handleMute}>
                      <img
                        src={volumeMute ? "/images/icons/icon_volume_mute.svg" : "/images/icons/icon_volume.svg"}
                        alt={""}/>
                    </ControlButton>
                    <AnimatePresence>
                        {(volumeBarVisible || volumeBarDrag) &&
                          <VolumeSlider
                            initial={{width: 0}}
                            animate={{width: 100}}
                            exit={{width: 0}}
                            transition={{duration: 0.2}}
                          >
                            <Slider
                              ref={volumeBarRef}
                              handleStyle={{
                                  backgroundColor: COLOR_WHITE,
                                  borderColor: COLOR_WHITE,
                                  boxShadow: "-2px 2px 4px 0 rgba(0, 0, 0, 0.25)",
                                  height: 12,
                                  marginTop: -3,
                                  opacity: 1,
                                  width: 12,
                              }}
                              min={0}
                              max={100}
                              railStyle={{
                                  background: "#a3a3a3",
                                  height: 6,
                              }}
                              trackStyle={{
                                  background: COLOR_WHITE,
                              }}
                              onBeforeChange={handleVolumeStartDrag}
                              onChange={handleVolumeChange}
                              onAfterChange={handleVolumeStopDrag}
                              value={volumeMute ? 0 : volume * 100}
                            />
                          </VolumeSlider>
                        }
                    </AnimatePresence>
                  </VolumeControl>
                    {playerType === "tv" && <LiveButton
                      onClick={handleClickLive}
                      onMouseEnter={handleMouseEnterHoverLive}
                      onMouseLeave={handleMouseEnterLeaveLive}
                      isLive={streamDetails?.type === CONTENT_TYPE_ENUM.LIVE && (liveEdge - currentTime) < 30}
                      {...{hoverLive}} >
                      <FormattedMessage id={"label_live"} defaultMessage={"Live"}/>
                      <div/>
                    </LiveButton>}
                </div>
                <div>
                  <ControlButton onClick={handleSubtitles}>
                    <img src={"/images/icons/icon_subtitle.svg"} alt={""}/>
                  </ControlButton>
                  <ControlButton onClick={handleSettings}>
                    <img src={"/images/icons/icon_settings.svg"} alt={""}/>
                  </ControlButton>
                    {/*@ts-ignore*/}
                    {!!videoRef.current?.requestPictureInPicture &&
                      <ControlButton onClick={handlePictureInPicture}>
                        <img src={"/images/icons/icon_picture_in_picture.svg"} alt={""}/>
                      </ControlButton>
                    }
                    {isFullscreen
                        ? <ControlButton onClick={handleFullscreenExit}>
                            <img src={"/images/icons/icon_fullscreen_exit.svg"} alt={""}/>
                        </ControlButton>
                        : <ControlButton onClick={handleFullscreen}>
                            <img src={"/images/icons/icon_fullscreen.svg"} alt={""}/>
                        </ControlButton>}
                </div>
                  {subtitlesOpen &&
                    <ControlDialog>
                        {streamDetails && variantTrack && <SubtitlesDialog {...{
                            hideSubtitles,
                            playerRef,
                            setVariantTrackAudio,
                            setVariantTrackSubtitle,
                            streamDetails,
                            subtitleTrack,
                            variantTrack
                        }} />}
                    </ControlDialog>
                  }
                  {settingsOpen &&
                    <ControlDialog>
                        {streamDetails && variantTrack && <SettingsDialog {...{
                            isAbrEnabled,
                            playerRef,
                            setSettingsOpen,
                            setVariantTrackQuality,
                            streamDetails,
                            playbackRate,
                            setPlaybackRate,
                            variantTrack
                        }} />}
                    </ControlDialog>
                  }
              </ControlButtonsContainer>
            </Controllers>}
            {window.innerWidth > 800 && !adsInProgress && !endOfPlaybackOverlay &&
                <SideControllers {...{sideControllersPanel, setSideControllersPanel}} />
            }
        </>
    );
};

export default PlayerControllers;
