import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {COLOR_WHITE} from "@motv-webapp/lib";
import {useAppSelector} from "@motv-webapp/redux";
import {vendor_vendors_accent_color} from "@motv-webapp/redux";

const StepperContainer = styled.div(props => ({
    display: "flex",
    marginBottom: "2rem",
}));

const FirstStep = styled.div((props: { accentColor: string | null | undefined, completed: boolean }) => ({
    backgroundColor: props.completed ? props.accentColor ? props.accentColor : "#0089FF" : "transparent",
    border: "4px solid",
    borderColor: props.completed ? props.accentColor ? props.accentColor : "#0089FF" : COLOR_WHITE,
    borderRadius: "50%",
    boxSizing: "border-box" as "border-box",
    height: "1.25rem",
    transition: "all 0.8s ease",
    width: "1.25rem",
}));

const Step = styled.div(props => ({
    alignItems: "center",
    display: "flex",
}));

const StepRoad = styled.div((props: { accentColor: string | null | undefined, completed: boolean }) => ({
    border: "2px solid",
    borderColor: props.completed ? props.accentColor ? props.accentColor : "#0089FF" : COLOR_WHITE,
    boxSizing: "border-box" as "border-box",
    height: 0,
    transition: "all 0.8s ease",
    width: "3rem",
}));

const StepPin = styled.div((props: { accentColor: string | null | undefined, completed: boolean }) => ({
    backgroundColor: props.completed ? props.accentColor ? props.accentColor : "#0089FF" : "transparent",
    border: "4px solid",
    borderColor: props.completed ? props.accentColor ? props.accentColor : "#0089FF" : COLOR_WHITE,
    borderRadius: "50%",
    boxSizing: "border-box" as "border-box",
    height: "1.25rem",
    position: "relative" as "relative",
    transition: "all 0.8s ease",
    width: "1.25rem",
}));

type Props = {
    numberOfSteps: number
    stepsFinished: number
};

const Stepper = ({numberOfSteps, stepsFinished}: Props) => {
    const accentColor = useAppSelector(vendor_vendors_accent_color)
    const [steps, setSteps] = useState<JSX.Element[]>([])

    useEffect(() => {
        const newArray = []
        for (let i = 2; i <= numberOfSteps; i++) {
            newArray.push(
                <Step key={i}>
                    <StepRoad completed={stepsFinished >= (i - 1)} {...{accentColor}} />
                    <StepPin completed={stepsFinished >= i} {...{accentColor}} />
                </Step>
            )
        }
        setSteps(newArray)
    }, [numberOfSteps, stepsFinished])

    return (
        <StepperContainer>
            <FirstStep completed={stepsFinished > 0} {...{accentColor}} />
            {steps}
        </StepperContainer>
    );
};

export default Stepper;
