import {FormattedMessage} from "react-intl";
import {isToday, isTomorrow, isYesterday} from "date-fns";
import {useAppSelector, user_selectedLanguage} from "@motv-webapp/redux";

type Props = {
    date: string
}
const ReadableDate = ({date}: Props) => {
    const language = useAppSelector(user_selectedLanguage)

    const renderReadableDate = () => {
      if (isYesterday(new Date(date))) {
          return <FormattedMessage id="label_yesterday" defaultMessage="Yesterday"/>
      } else if (isToday(new Date(date))) {
          return <FormattedMessage id="label_today" defaultMessage="Today"/>
      } else if (isTomorrow(new Date(date))){
          return <FormattedMessage id="label_tomorrow" defaultMessage="Tomorrow"/>
      } else return new Intl.DateTimeFormat(language).format(new Date(date))
    }

    return (
        <>
            {renderReadableDate()}
        </>
    );
};

export default ReadableDate;