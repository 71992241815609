import React, {ReactNode} from "react";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from '@dnd-kit/utilities';

type Props = {
    children: ReactNode
    id: number
}

const SortableItem = ({children, id} : Props) => {

    const {
        attributes,
        listeners,
        setNodeRef,
        transition,
        transform,
        isDragging
    } = useSortable({
        id: id
    });

    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
        zIndex: isDragging ? "100" : "auto",
        opacity: isDragging ? 0.3 : 1
    };

    return (
            <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
                {children}
            </div>
    )
}

export default SortableItem;