import React, {ReactElement} from 'react';
import styled from "styled-components";
import {COLOR_SECONDARY, COLOR_BLACK, COLOR_WHITE} from "@motv-webapp/lib";

const StyledButton = styled.button((props: {backgroundColor: string | undefined}) =>({
 background: props.backgroundColor ? props.backgroundColor : COLOR_SECONDARY,
 border: 0,
 borderRadius: 20,
 color: COLOR_WHITE,
 fontSize: "0.8rem",
 fontWeight: "bold",
 marginRight: "0.5rem",
 padding: "0.5rem 1rem",
 whiteSpace: "nowrap" as "nowrap",
 "&.selectedFilter": {
  background: COLOR_WHITE,
  color: COLOR_BLACK,
 }
}));

type Props = {
 backgroundColor?: string | undefined
 children: ReactElement | string
 id?: string
 onClick: () => void
};

const FilterButton = ({backgroundColor, id, children, onClick}: Props) => {
 return (
  <StyledButton {...{backgroundColor, id, onClick}} >
   {children}
  </StyledButton>
 );
};

export default FilterButton;
