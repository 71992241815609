import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import styled from "styled-components";
import Input from "../../../InputFields/Input";
import {INPUT_FIELD_TYPE_ENUM} from "@motv-webapp/lib";
import {SubscribedChannelEntity} from "@motv-webapp/lib";
import AllowedChannelCardBox from "./AllowedChannelCardBox";
import {useAppSelector} from "@motv-webapp/redux";
import {channel_channels,} from "@motv-webapp/redux";
import {ProfileEntity} from "@motv-webapp/lib";
import {AnimatePresence, motion} from "framer-motion";
import {TYPEFACE_BODY_MEDIUM, TYPEFACE_WEB_BODY_2} from "@motv-webapp/lib";
import PrimaryButton from "../../../Button/PrimaryButton";
import {BUTTON_SIZE_ENUM, BUTTON_TYPE_ENUM} from "@motv-webapp/lib";

const AllowedChannelsContainer = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
}));

const ControllerWrapper = styled.div(props => ({
    display: "flex",
    justifyContent: "space-between",
}));

const FilterControl = styled.div(props => ({
    ...TYPEFACE_BODY_MEDIUM,
    display: "flex",
    width: "100%",
    padding: 0,
    marginTop: 8,
}));

const CheckboxContainer = styled.div(props => ({
    display: "flex",
    flexDirection: "column"
}));

const ButtonContainer = styled(motion.div)(props => ({
    alignSelf: "center",
    display: "flex",
}));

const ParentalControlLabel = styled(motion.div)(props => ({
    ...TYPEFACE_WEB_BODY_2,
    marginBottom: 16,
    overflow: "hidden"
}));

const ChannelCardContainer = styled.div(props => ({
    display: "flex",
    flexWrap: "wrap",
    gap: "16px",
}));

type Props = {
    profile: ProfileEntity
    allowedChannels: number[]
    setAllowedChannels: Dispatch<SetStateAction<number[]>>
    isParentalControlEnabled: boolean
    setIsParentalControlEnabled: Dispatch<SetStateAction<boolean>>
    setWhiteListedChannelsChanged: Dispatch<SetStateAction<boolean>>
}

const AllowedChannels = ({
                             profile,
                             allowedChannels,
                             setAllowedChannels,
                             isParentalControlEnabled,
                             setIsParentalControlEnabled, setWhiteListedChannelsChanged
                         }: Props) => {
    const [channels, setChannels] = useState([] as SubscribedChannelEntity[])
    const intl = useIntl();
    const allChannels = useAppSelector(channel_channels)

    const handleChangeChecked = () => {
        setIsParentalControlEnabled(!isParentalControlEnabled)
        setWhiteListedChannelsChanged(true)
    }

    const handleSelectAll = () => {
        allChannels && setAllowedChannels(allChannels.map(channel => channel.channels_id!))
        setWhiteListedChannelsChanged(true)
    }

    const handleDeselectAll = () => {
        setAllowedChannels([])
        setWhiteListedChannelsChanged(true)
    }

    useEffect(() => {
        allChannels && setChannels(allChannels)
    }, [allChannels])

    return (
        <AllowedChannelsContainer>
            <ControllerWrapper>
                <CheckboxContainer>
                    <FilterControl>
                        <Input errorMessageId={""} placeholder={intl.formatMessage({
                            id: "label_filter_channels",
                            defaultMessage: "Filter channels"
                        })}
                               checked={isParentalControlEnabled}
                               marginBottom={12}
                               type={INPUT_FIELD_TYPE_ENUM.CHECKBOX} onChangeChecked={handleChangeChecked}/>
                    </FilterControl>
                    <AnimatePresence>
                        {isParentalControlEnabled &&
                          <ParentalControlLabel
                            initial={{height: 0}}
                            animate={{height: "auto"}}
                            exit={{height: 0}}
                            transition={{duration: 0.2}}
                          >
                            <FormattedMessage id={"label_settings_profile_parental_control_channels"}
                                              defaultMessage={"Select channels you wish to allow the profile to view"}/>
                          </ParentalControlLabel>
                        }
                    </AnimatePresence>
                </CheckboxContainer>
                <AnimatePresence>
                    {isParentalControlEnabled &&
                      <ButtonContainer
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: 0.2}}
                    >
                        <PrimaryButton
                          beforeImage={"/images/icons/icon_check_circle_outline.svg"}
                          buttonSize={BUTTON_SIZE_ENUM.SMALL}
                          buttonType={BUTTON_TYPE_ENUM.TERTIARY}
                          label={intl.formatMessage({id: "label_select_all", defaultMessage: "Select all"})}
                          onClick={handleSelectAll}
                        />
                        <PrimaryButton
                          beforeImage={"/images/icons/icon_block.svg"}
                          buttonSize={BUTTON_SIZE_ENUM.SMALL}
                          buttonType={BUTTON_TYPE_ENUM.TERTIARY}
                          label={intl.formatMessage({id: "label_deselect_all", defaultMessage: "Deselect all"})}
                          onClick={handleDeselectAll}
                        />
                    </ButtonContainer>}
                </AnimatePresence>
            </ControllerWrapper>
            <ChannelCardContainer>
                {channels && channels.map(channel => (
                    <AllowedChannelCardBox profile={profile} allowedChannels={allowedChannels} channel={channel}
                                           setWhiteListedChannelsChanged={setWhiteListedChannelsChanged}
                                           setAllowedChannels={setAllowedChannels}
                                           isParentalControlEnabled={isParentalControlEnabled}
                                           key={channel.channels_id}/>
                ))}
            </ChannelCardContainer>
        </AllowedChannelsContainer>
    )
}

export default AllowedChannels;
