import React from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import styled from "styled-components";
import {COLOR_NEUTRAL_90, COLOR_WHITE} from "@motv-webapp/lib";
import {TYPEFACE_HEADLINE_2, TYPEFACE_TITLE} from "@motv-webapp/lib";
import {useAppSelector} from "@motv-webapp/redux";
import {error_error_mw, error_same_error_mw_counter} from "@motv-webapp/redux";
import Countdown from "../Countdown/Countdown";
import PrimaryButton from "../Button/PrimaryButton";
import {BUTTON_TYPE_ENUM} from "@motv-webapp/lib";

const ErrorPageContainer = styled.div(props => ({
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: "100vh",
    zIndex: 996
}));

const ErrorTitle = styled.h1(props => ({
    ...TYPEFACE_TITLE,
    color: COLOR_WHITE,
    marginBottom: 16,
    marginTop: 0,
}));

const ErrorSubtitle = styled.div(props => ({
    ...TYPEFACE_HEADLINE_2,
    color: COLOR_WHITE,
    marginBottom: 42,
    marginLeft: 20,
    marginRight: 20,
    textAlign: "center",
}));

const PlayerError = () => {
    const mwError = useAppSelector(error_error_mw)
    const sameErrorCounter = useAppSelector(error_same_error_mw_counter)
    const intl = useIntl()

    const handleClickReload = () => {
        window.location.reload()
    }
    return (
        <ErrorPageContainer>
            <Countdown color={COLOR_NEUTRAL_90} onCountdownFinished={handleClickReload} secondsToCountdown={!sameErrorCounter ? 5 : sameErrorCounter * 10 < 100 ? sameErrorCounter * 10 : 90} sizeInPixels={380}/>
            <ErrorTitle>
                <FormattedMessage id={"message_something_went_wrong"} defaultMessage={"Something went wrong."}/>
            </ErrorTitle>
            <ErrorSubtitle>
                {mwError && mwError.status === 902 ?
                    <FormattedMessage
                        id={"mw_exception_902"}
                        defaultMessage={"This content is currently unavailable."}
                    />
                    : mwError && mwError.status === 903 ?
                        <FormattedMessage
                            id={"mw_exception_903"}
                            defaultMessage={"You are not authorized to watch this content."}
                        />
                        : mwError && mwError.status === 910 ?
                            <FormattedMessage
                                id={"mw_exception_910"}
                                defaultMessage={"You are too young to watch this content."}
                            />
                        : mwError && mwError.status === 914 ?
                            <FormattedMessage
                                id={"mw_exception_914"}
                                defaultMessage={"This content is not available within your network."}
                            />
                            : mwError && mwError.status === 1001 ?
                                <FormattedMessage
                                    id={"mw_exception_1001"}
                                    defaultMessage={"You are not authorized to play this VOD."}
                                />
                                : mwError && mwError.status === 1500 ?
                                    <FormattedMessage
                                        id={"mw_exception_1500"}
                                        defaultMessage={"Maximum of devices exceeded. Please consider removing some."}
                                    />
                                    : mwError && mwError.status === 1502 ?
                                        <FormattedMessage
                                            id={"mw_exception_1502"}
                                            defaultMessage={"Maximum of concurrent devices exceeded. Please turn off other application which is using this account and retry."}
                                        />
                                        : <FormattedMessage id={"message_something_went_wrong"}
                                                            defaultMessage={"Something went wrong."}/>
                }
            </ErrorSubtitle>
            <PrimaryButton
                buttonType={BUTTON_TYPE_ENUM.OFFWHITE}
                label={intl.formatMessage({id: "label_try_again", defaultMessage: "Try again"})}
                onClick={handleClickReload}
            />
        </ErrorPageContainer>
    );
};

export default PlayerError;
