"use strict";
exports.__esModule = true;
exports.LANGUAGE_DICTIONARY = exports.LANGUAGE_FRENCH_LABEL = exports.LANGUAGE_FRENCH_ABBR = exports.LANGUAGE_GERMAN_LABEL = exports.LANGUAGE_GERMAN_ABBR = exports.LANGUAGE_MONGOLIAN_LABEL = exports.LANGUAGE_MONGOLIAN_ABBR = exports.LANGUAGE_SPANISH_LABEL = exports.LANGUAGE_SPANISH_ABBR = exports.LANGUAGE_PORTUGAL_LABEL = exports.LANGUAGE_PORTUGAL_ABBR = exports.LANGUAGE_CZECH_LABEL = exports.LANGUAGE_CZECH_ABBR = exports.LANGUAGE_ENGLISH_LABEL = exports.LANGUAGE_ENGLISH_ABBR = void 0;
exports.LANGUAGE_ENGLISH_ABBR = "en";
exports.LANGUAGE_ENGLISH_LABEL = "English";
exports.LANGUAGE_CZECH_ABBR = "cs";
exports.LANGUAGE_CZECH_LABEL = "Čeština";
exports.LANGUAGE_PORTUGAL_ABBR = "pt";
exports.LANGUAGE_PORTUGAL_LABEL = "Português";
exports.LANGUAGE_SPANISH_ABBR = "es";
exports.LANGUAGE_SPANISH_LABEL = "Español";
exports.LANGUAGE_MONGOLIAN_ABBR = "mn";
exports.LANGUAGE_MONGOLIAN_LABEL = "Монгол";
exports.LANGUAGE_GERMAN_ABBR = "de";
exports.LANGUAGE_GERMAN_LABEL = "Deutsch";
exports.LANGUAGE_FRENCH_ABBR = "fr";
exports.LANGUAGE_FRENCH_LABEL = "Français";
exports.LANGUAGE_DICTIONARY = {
    "cs": "Čeština",
    "de": "Deutsch",
    "en": "English",
    "es": "Español",
    "mn": "Монгол",
    "pt": "Português"
};
