import React from 'react';
import styled from "styled-components";
import {useAppSelector} from "@motv-webapp/redux";
import {vendor_vendors_accent_color} from "@motv-webapp/redux";

const ProgressBarContainer = styled.div(props =>({
    background: "rgba(255, 255, 255, 0.15)",
    bottom: 0,
    borderRadius: "0 0 6px 6px",
    height: 6,
    position: "absolute",
    width: "100%",
}));

const Progress = styled.div((props: {accentColor: string | null | undefined, percentageWatched: number}) =>({
    background: `${props.accentColor}`,
    borderRadius: "0 6px 6px 6px",
    height: "100%",
    width: `${props.percentageWatched}%`
}));

type Props = {
    percentageWatched: number
};

const MediaCardProgressBar = ({percentageWatched}: Props) => {
    const accentColor = useAppSelector(vendor_vendors_accent_color)
 return (
  <ProgressBarContainer>
        <Progress {...{accentColor, percentageWatched}} />
  </ProgressBarContainer>
 );
};

export default MediaCardProgressBar;
