import ChannelList from "../components/Channels/ChannelList";
import Header from "../components/Header/Header";
import PrimaryButton from "../components/Button/PrimaryButton";
import React, {useEffect, useState} from 'react';
import styled, {createGlobalStyle} from "styled-components";
import {FormattedMessage, useIntl} from "react-intl";
import {formatISO} from "date-fns";
import {isDesktop} from "react-device-detect";
import {useInterval} from "react-use";
import {
    BUTTON_SIZE_ENUM,
    BUTTON_TYPE_ENUM,
    COLOR_NEUTRAL,
    COLOR_WHITE,
    TIME_CONST_ONE_MINUTE,
} from "@motv-webapp/lib";
import {
    channelGetChannelCategories,
    channelGetSubscribedAndLockedChannels,
    channel_channels,
    epgGetUpdatedEventsV2,
    resetChannelOrder,
    setClearCacheFalse,
    universalGetFavouriteChannels,
    universalGetMyList,
    universalGetMyRecordings,
    universal_clearCache,
    useAppDispatch,
    useAppSelector,
} from "@motv-webapp/redux";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${COLOR_NEUTRAL};
  }

  ,
`

const TransparentSelectorContainer = styled.div`
  *::selection {
    background-color: transparent;
  }
`
const ChannelsPageContainer = styled.div(props => ({
    background: COLOR_NEUTRAL,
    color: COLOR_WHITE,
    minHeight: "100vh",
    overflowX: "hidden",
}));


const ChannelsHeadlineContainer = styled.div(props => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    margin: "100px 64px 32px 64px",
    "@media (max-width: 800px)": {
        margin: "100px 32px 32px 32px",
    },
    "@media (max-width: 600px)": {
        flexDirection: "column",
        gap: "24px"
    },
}));

const ChannelsHeadline = styled.h1(props => ({
    color: COLOR_WHITE,
    padding: 0,
    margin: 0
}));

const ReorderButtonsContainer = styled.div(props => ({
    display: "flex",
    justifyContent: "flex-end",
    width: "100%"
}));

const ChannelsPage = () => {
    const dispatch = useAppDispatch()
    const clearCache = useAppSelector(universal_clearCache)
    const [isChannelReorderEnabled, setIsChannelReorderEnabled] = useState<boolean>(false)
    const intl = useIntl()
    const channels = useAppSelector(channel_channels)

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
    }, [])

    useEffect(() => {
        dispatch(channelGetSubscribedAndLockedChannels({body: {}}))
        dispatch(universalGetMyList())
        dispatch(universalGetMyRecordings())
        dispatch(universalGetFavouriteChannels())
        dispatch(epgGetUpdatedEventsV2({timestamp: 0, from: formatISO(new Date()), to: formatISO(new Date())}))
        dispatch(channelGetChannelCategories({}))
    }, []) //eslint-disable-line

    useEffect(() => {
        if (clearCache) {
            dispatch(channelGetSubscribedAndLockedChannels({body: {}, ignoreCache: true}))
            dispatch(universalGetMyList())
            dispatch(universalGetMyRecordings())
            dispatch(universalGetFavouriteChannels())
            dispatch(epgGetUpdatedEventsV2({timestamp: 0, from: formatISO(new Date()), to: formatISO(new Date())}))
            dispatch(channelGetChannelCategories({ignoreCache: true}))
            dispatch(setClearCacheFalse())
        }
    }, [clearCache]) //eslint-disable-line

    useInterval(
        () => {
            dispatch(epgGetUpdatedEventsV2({timestamp: 0, from: formatISO(new Date()), to: formatISO(new Date())}))
        },
        TIME_CONST_ONE_MINUTE
    );

    const handleEnableChannelReorder = () => {
        setIsChannelReorderEnabled(!isChannelReorderEnabled)
    }

    useEffect(() => {
        !isChannelReorderEnabled && dispatch(channelGetSubscribedAndLockedChannels({body: {}, ignoreCache: true}))
    }, [isChannelReorderEnabled]) // eslint-disable-line

    const handleResetChannelOrder = () => {
        dispatch(resetChannelOrder({}))
        setIsChannelReorderEnabled(false)
    }

    return (
        <TransparentSelectorContainer>
            <ChannelsPageContainer>
                <GlobalStyle/>
                <Header/>
                <ChannelsHeadlineContainer>
                    <ChannelsHeadline>
                        <FormattedMessage id={"label_channels"} defaultMessage={"Channels"}/>
                    </ChannelsHeadline>
                    {isDesktop && !!channels?.length && (
                        <ReorderButtonsContainer>
                            {isChannelReorderEnabled && (
                                <PrimaryButton
                                    beforeImage={"/images/icons/icon_list_numbered.svg"}
                                    buttonSize={BUTTON_SIZE_ENUM.SMALL}
                                    buttonType={BUTTON_TYPE_ENUM.TERTIARY}
                                    label={intl.formatMessage({
                                        id: "label_settings_channel_order_default",
                                        defaultMessage: "Default order"
                                    })}
                                    onClick={handleResetChannelOrder}
                                />
                            )}
                            <PrimaryButton
                                beforeImage={isChannelReorderEnabled ? "/images/icons/icon_tick.svg" : "/images/icons/icon_sort.svg"}
                                buttonSize={BUTTON_SIZE_ENUM.SMALL}
                                buttonType={BUTTON_TYPE_ENUM.TERTIARY}
                                label={isChannelReorderEnabled
                                    ? intl.formatMessage({
                                        id: "label_done",
                                        defaultMessage: "Done"
                                    })
                                    : intl.formatMessage({
                                        id: "label_sort_channels",
                                        defaultMessage: "Sort channels"
                                    })}
                                onClick={handleEnableChannelReorder}
                            />
                        </ReorderButtonsContainer>
                    )}
                </ChannelsHeadlineContainer>
                <ChannelList isChannelReorderEnabled={isChannelReorderEnabled}/>
            </ChannelsPageContainer>
        </TransparentSelectorContainer>

    );
};

export default ChannelsPage;
