import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import styled from "styled-components";
import {useAppSelector} from "@motv-webapp/redux";
import {channel_channels} from "@motv-webapp/redux";
import {addDays, isSameDay, startOfDay, subDays} from "date-fns";
import {user_selectedLanguage} from "@motv-webapp/redux";
import GuideDatePickerButton from "./GuideDatePickerButton";
import {useIntl} from "react-intl";
import {
    COLOR_NEUTRAL,
    COLOR_NEUTRAL_90,
} from "@motv-webapp/lib";
import ScrollContainer from "react-indiana-drag-scroll";
import {setLocalStorage} from "@motv-webapp/lib";

const GuideDatePickerContainer = styled(ScrollContainer).attrs({className: "scroll-container"})(props => ({
    background: COLOR_NEUTRAL,
    borderBottom: `2px solid ${COLOR_NEUTRAL_90}`,
    borderTop: `2px solid ${COLOR_NEUTRAL_90}`,
    display: "flex",
    gap: 32,
    justifyContent: "center",
    padding: "0 64px 0 64px",
    "@media (max-width: 870px)": {
        justifyContent: "initial",
    }
}));

type Props = {
    selectedDate: Date
    setSelectedDate: Dispatch<SetStateAction<Date>>
};

const GuideDatePicker = ({selectedDate, setSelectedDate}: Props) => {
    const [maxDaysToPast, setMaxDaysToPast] = useState(2)
    const [timeline, setTimeline] = useState<Date[]>([])
    const channels = useAppSelector(channel_channels)
    const language = useAppSelector(user_selectedLanguage)
    const intl = useIntl()

    useEffect(() => {
        if (!channels) return
        setMaxDaysToPast(Math.round(Math.max(...channels.map(channel => channel.channels_recording_length!)) / 60 / 24)) // convert minutes to days
    }, [channels])

    useEffect(() => {
        const firstDay = subDays(startOfDay(Date.now()), maxDaysToPast)
        let dayToPush = startOfDay(firstDay)
        let arrayOfDays: Date[] = []
        while (!isSameDay(dayToPush, addDays(startOfDay(Date.now()), 5))) {
            arrayOfDays.push(dayToPush)
            dayToPush = addDays(dayToPush, 1)
        }
        setTimeline(arrayOfDays)
    }, [maxDaysToPast])

    const handleSelectDate = (date:Date) => () => {
        setSelectedDate(date)
        setLocalStorage("tvGuideSelectedDate", date.toString())
    }

    return (
        <GuideDatePickerContainer>
            {timeline.map((date) => {
                if (isSameDay(date.getTime(), Date.now())) {
                    return <GuideDatePickerButton active={isSameDay(selectedDate, date)}
                                                  dateNumber={new Intl.DateTimeFormat(language, {day: "numeric"}).format(date)}
                                                  key={date.toISOString()}
                                                  onClick={handleSelectDate(date)}
                                                  weekday={intl.formatMessage({id: "label_today", defaultMessage: "Today"})} />
                } else {
                    return <GuideDatePickerButton active={isSameDay(selectedDate, date)}
                                                  dateNumber={new Intl.DateTimeFormat(language, {day: "numeric"}).format(date)}
                                                  key={date.toISOString()}
                                                  onClick={handleSelectDate(date)}
                                                  weekday={new Intl.DateTimeFormat(language, {weekday: 'short'}).format(date)} />
                }
            })}
        </GuideDatePickerContainer>
    );
};

export default GuideDatePicker;
