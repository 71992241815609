import React, {useEffect, useState} from 'react';
import {RecommendationCardEntity, RecommendationRowEntity} from "@motv-webapp/lib";
import styled from "styled-components";
import MediaCardProgressBar from "./MediaCardProgressBar";
import MediaCardActionBar from "./MediaCardActionBar";
import {AnimatePresence, motion, useAnimation} from "framer-motion";
import {useAppDispatch, useAppSelector} from "@motv-webapp/redux";
import {user_selectedLanguage} from "@motv-webapp/redux";
import {COLOR_WHITE_60, COLOR_WHITE_80} from "@motv-webapp/lib";
import {
    RECOMMENDATION_ENGINE_ROW_STYLE_LARGE,
    RECOMMENDATION_ENGINE_ROW_STYLE_LARGE_WIDE,
    RECOMMENDATION_ENGINE_ROW_STYLE_NORMAL_WIDE,
    RECOMMENDATION_ENGINE_ROW_STYLE_SMALL_WIDE
} from "@motv-webapp/lib";
import {
    LARGE_HEIGHT,
    LARGE_WIDE_HEIGHT,
    LARGE_WIDE_WIDTH,
    LARGE_WIDTH,
    NORMAL_HEIGHT,
    NORMAL_WIDE_HEIGHT,
    NORMAL_WIDE_WIDTH,
    NORMAL_WIDTH,
    SMALL_WIDE_HEIGHT,
    SMALL_WIDE_WIDTH
} from "@motv-webapp/lib";
import ImageCard from "./ImageCard";
import {RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM} from "@motv-webapp/lib";
import {useNavigate} from "react-router-dom";
import MediaCardBadge from "./MediaCardBadge";
import {imageResizeUrl} from "@motv-webapp/lib";
import { useInView } from "react-intersection-observer";
import {searchOpenFalse} from "@motv-webapp/redux";

const MediaCardContainer = styled.div(props => ({}));

const MediaCardBox = styled(motion.div)(props => ({
    backgroundPosition: "center",
    backgroundSize: "cover",
    borderRadius: 6,
    cursor: "pointer",
    position: "relative" as "relative",
}));

const MediaCardInfo = styled(motion.div)(props => ({
    boxSizing: "border-box",
    height: "2rem",
    marginTop: "0.75rem",
    position: "relative",
    width: "100%",
    zIndex: 300,
}));

const MediaCardInfoTitle = styled.p(props => ({
    margin: "0 0 0.5rem 0",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    whiteSpace: "nowrap"
}));

const MediaCardInfoEpisode = styled(motion.p)(props => ({
    color: COLOR_WHITE_80,
    fontSize: "0.8rem",
    fontWeight: 500,
    margin: "0 0 0.5rem 0"
}));

const MediaCardInfoStartAndLogoContainer = styled.div(props => ({
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between"
}));

const MediaCardInfoStart = styled(motion.p)(props => ({
    color: COLOR_WHITE_60,
    fontSize: "0.8rem",
    fontWeight: 500,
    margin: 0,
}));

const ChannelLogo = styled(motion.img)(props => ({
    maxHeight: 30,
    maxWidth: 40,
}));

type Props = {
    mediaCard: RecommendationCardEntity
    row: RecommendationRowEntity
};

const MediaCard = ({mediaCard, row}: Props) => {
    const [hover, setHover] = useState(false)
    const [imageWidth, setImageWidth] = useState(0)
    const [loaded, setLoaded] = useState(false)
    const controls = useAnimation()
    const controlsFiller = useAnimation()
    const language = useAppSelector(user_selectedLanguage)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const { ref, inView } = useInView({
        threshold: 0
    });

    useEffect(() => {
        if (inView) setLoaded(true)
    }, [inView])

    useEffect(() => {
        if (hover) {
            controls.start({
                scale: 1.1
            })
            controlsFiller.start({
                height: "calc(200px * 0.05)",
            })
        } else {
            controls.start({
                scale: 1.0
            })
            controlsFiller.start({
                height: 0,
            })
        }
    }, [hover])

    useEffect(() => {
        setImageWidth(row.style === RECOMMENDATION_ENGINE_ROW_STYLE_NORMAL_WIDE ? NORMAL_WIDE_WIDTH
            : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_SMALL_WIDE ? SMALL_WIDE_WIDTH
                : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_LARGE ? LARGE_WIDTH
                    : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_LARGE_WIDE ? LARGE_WIDE_WIDTH : NORMAL_WIDTH)
    }, [row.style])

    const handleOnMouseEnter = () => {
        setHover(true)
    }

    const handleOnMouseLeave = () => {
        setHover(false)
    }

    const handleClick = () => {
        switch (mediaCard.type) {
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.TV:
                navigate(`/event/${mediaCard.id}`)
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.RECORDING:
                navigate(`/recording/${mediaCard.id}`)
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.VOD:
                navigate(`/vod/${mediaCard.id}`)
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.YOUTUBE:
                navigate(`/vod/${mediaCard.id}`)
                break
            case RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.CATEGORY:
                navigate(`/category/${mediaCard.id}`)
                break
            default:
                return
        }
        dispatch(searchOpenFalse())
    }

    return (mediaCard.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.IMAGE ? (
            <ImageCard key={row.id + "-" + mediaCard.id} {...{mediaCard, row}} />
        ) : (
            <MediaCardContainer
                                onMouseEnter={handleOnMouseEnter}
                                onMouseLeave={handleOnMouseLeave}
                                ref={ref}
                                style={{
                                    width: row.style === RECOMMENDATION_ENGINE_ROW_STYLE_NORMAL_WIDE ? NORMAL_WIDE_WIDTH
                                        : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_SMALL_WIDE ? SMALL_WIDE_WIDTH
                                            : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_LARGE ? LARGE_WIDTH
                                                : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_LARGE_WIDE ? LARGE_WIDE_WIDTH : NORMAL_WIDTH,
                                }}>
                <MediaCardBox animate={controls} onClick={handleClick}
                              style={{
                                  backgroundImage: `url(${(((row.style === RECOMMENDATION_ENGINE_ROW_STYLE_NORMAL_WIDE
                                      || row.style === RECOMMENDATION_ENGINE_ROW_STYLE_SMALL_WIDE
                                      || row.style === RECOMMENDATION_ENGINE_ROW_STYLE_LARGE_WIDE) && mediaCard.image_widescreen) )
                                      ? loaded ? imageResizeUrl(mediaCard.image_widescreen, imageWidth) : "/images/icons/icon_vod.svg" : loaded ? imageResizeUrl(mediaCard.image, imageWidth) : "/images/icons/icon_vod.svg"})`,
                                  height: row.style === RECOMMENDATION_ENGINE_ROW_STYLE_NORMAL_WIDE ? NORMAL_WIDE_HEIGHT
                                      : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_SMALL_WIDE ? SMALL_WIDE_HEIGHT
                                          : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_LARGE ? LARGE_HEIGHT
                                              : row.style === RECOMMENDATION_ENGINE_ROW_STYLE_LARGE_WIDE ? LARGE_WIDE_HEIGHT : NORMAL_HEIGHT,
                                  width: imageWidth,
                                  zIndex: hover ? 100 : 0,
                              }}>
                    {hover && <MediaCardActionBar {...{mediaCard}} />}
                    {mediaCard.follow && mediaCard.duration &&
                      <MediaCardProgressBar percentageWatched={mediaCard.follow / mediaCard.duration * 100}/>
                    }
                    {mediaCard.type === RECOMMENDATION_ENGINE_CARD_ASSET_TYPE_ENUM.YOUTUBE &&
                      <MediaCardBadge type={"youtube"}/>
                    }
                    {!!mediaCard.locked &&
                      <MediaCardBadge type={"locked"}/>
                    }
                </MediaCardBox>
                <MediaCardInfo>
                    <AnimatePresence>
                        {hover && <motion.div key={`filler-${mediaCard.id}`} animate={controlsFiller}/>}
                        {mediaCard.title && <MediaCardInfoTitle>{mediaCard.title}</MediaCardInfoTitle>}
                        {mediaCard.episode && hover && <MediaCardInfoEpisode
                          key={`episode-${mediaCard.id}`}
                          initial={{opacity: 0}}
                          animate={{opacity: 1}}
                          exit={{opacity: 0}}
                          transition={{
                              type: "spring",
                              duration: 0.3
                          }}>
                            {mediaCard.episode}
                        </MediaCardInfoEpisode>}
                        {hover && <MediaCardInfoStartAndLogoContainer key={`container-${mediaCard.id}`} >
                            {mediaCard.start && <MediaCardInfoStart key={`start-${mediaCard.id}`}
                                                                    initial={{opacity: 0}}
                                                                    animate={{opacity: 1}}
                                                                    exit={{opacity: 0}}
                                                                    transition={{
                                                                        type: "spring",
                                                                        duration: 0.3
                                                                    }}>{new Intl.DateTimeFormat(language, {
                                month: 'long', day: 'numeric',
                                hour: 'numeric', minute: 'numeric',
                                hour12: false,
                            }).format(Date.parse(mediaCard.start))}</MediaCardInfoStart>}
                            {mediaCard.channels_logo && <ChannelLogo src={imageResizeUrl(mediaCard.channels_logo, 40)}
                                                                     alt={""}
                                                                     key={`channelLogo-${mediaCard.id}`}
                                                                     initial={{opacity: 0}}
                                                                     animate={{opacity: 1}}
                                                                     exit={{opacity: 0}}
                                                                     transition={{
                                                                         type: "spring",
                                                                         duration: 0.3
                                                                     }}/>}
                        </MediaCardInfoStartAndLogoContainer>}
                    </AnimatePresence>
                </MediaCardInfo>
            </MediaCardContainer>
        )
    );
};

export default MediaCard;
