import React from 'react';
import LanguageButton from "../Button/LanguageButton";
import styled from "styled-components";
import {customHistory} from "@motv-webapp/lib";
import {logOutUser} from "@motv-webapp/redux";
import {useAppDispatch} from "@motv-webapp/redux";
import {useNavigate} from "react-router-dom";

const Header = styled.div(props => ({
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    position: "absolute",
    top: "4rem",
    width: "100%",
}));

type Props = {
    disableGoBack?: boolean,
    logoutOnBack?: boolean
};

const LoginHeader = ({disableGoBack = false, logoutOnBack = false}: Props) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const handleGoBack = () => {
        if (logoutOnBack) {
            dispatch(logOutUser())
        }
        customHistory.action === "REPLACE" ? navigate("/") : navigate(-1)
    }

    return (
        <Header>
            {!disableGoBack
                ? <img src={"/images/icons/icon_arrow_back.svg"} alt={"back"}
                       style={{width: "3rem", height: "3rem", marginLeft: "2rem", cursor: "pointer"}}
                       onClick={handleGoBack}/>
                : <div>&nbsp;</div>
            }
            <LanguageButton style={{marginRight: "2rem"}}/>
        </Header>
    );
};

export default LoginHeader;
