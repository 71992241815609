import React, {useEffect, useState} from "react";
import ProgressCircle from "./ProgressCircle";
import styled from "styled-components";
import {FormattedMessage} from "react-intl";
import {
    customerGetData,
    useAppDispatch, useAppSelector,
    user_customers_recording_length,
    user_customers_recording_used,
    vendor_vendors_accent_color
} from "@motv-webapp/redux";
import {
    COLOR_OFFWHITE_70,
    COLOR_OFFWHITE_90, COLOR_PRIMARY_100,
    TYPEFACE_CAPTION_BOLD,
    TYPEFACE_WEB_BODY_2_MEDIUM,
    TYPEFACE_WEB_CAPTION_2_BOLD
} from "@motv-webapp/lib";

const RecordingSpaceContainer = styled.div((props: {hover:boolean}) => ({
    alignItems:"center",
    cursor:"pointer",
    display: "flex",
    gap:16,
    width:"max-content",
}));

const RecordingSpaceInfoContainer = styled.div(props => ({
    display: "flex",
    flexDirection:"column",
    gap:8,
}));
const RecordingSpaceInfoTitleContainer = styled.div(props => ({
    ...TYPEFACE_CAPTION_BOLD,
    alignItems:"center",
    color:COLOR_OFFWHITE_70,
    display: "flex",
    gap:6,
    "& img": {
        height:6,
        width:6,
        marginTop:1,
    }
}));
const RecordingSpaceInfoUsed = styled.div(props => ({
    ...TYPEFACE_WEB_BODY_2_MEDIUM,
    color:COLOR_OFFWHITE_90,
}));

type Props = {
    handleToggleDetails: () => void

}

const RecordingSpaceComponent = ({handleToggleDetails}: Props) => {
    const [hover, setHover] = useState(false)
    const accentColor = useAppSelector(vendor_vendors_accent_color)
    const recordingLength = useAppSelector(user_customers_recording_length)
    const recordingUsed = useAppSelector(user_customers_recording_used)
    const dispatch = useAppDispatch()

    const handleHover = () => {
        setHover(true)
    }
    const handleExitHover = () => {
        setHover(false)
    }

    useEffect(() => {
        dispatch(customerGetData({}))
    }, [])

    return (
        <RecordingSpaceContainer hover={hover} onMouseEnter={handleHover} onMouseLeave={handleExitHover} onClick={handleToggleDetails}>
            <ProgressCircle
                percents={(!!recordingUsed && recordingLength) ? Math.round(recordingUsed / recordingLength * 100) : 0}
                strokeColor={accentColor ? accentColor : COLOR_PRIMARY_100} strokeWidth={10} color={COLOR_OFFWHITE_90} typeface={TYPEFACE_WEB_CAPTION_2_BOLD}/>
            <RecordingSpaceInfoContainer>
                <RecordingSpaceInfoTitleContainer>
                    <FormattedMessage id={"label_recording_space"} defaultMessage={"Recording space"}/>
                    <img src={"/images/icons/icon_chevron_forward.svg"} alt={""}/>
                </RecordingSpaceInfoTitleContainer>
                <RecordingSpaceInfoUsed>
                    <FormattedMessage id={"label_recording_space_used_of_total"}
                                      defaultMessage={"Used {s1} of {s2} minutes"} values={{
                        s1: recordingUsed,
                        s2: recordingLength,
                    }}/>
                </RecordingSpaceInfoUsed>
            </RecordingSpaceInfoContainer>
        </RecordingSpaceContainer>
    )
}

export default RecordingSpaceComponent;