import React, {ReactNode} from 'react';
import styled from "styled-components";
import {COLOR_NEUTRAL, COLOR_SECONDARY} from "@motv-webapp/lib";
import OutsideClickHandler from "react-outside-click-handler";

const DialogContainer = styled.div((props:{solidBackground: boolean}) => ({
    alignItems: "center",
    background: props.solidBackground ? COLOR_NEUTRAL : "transparent",
    backdropFilter: "blur(10px)",
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    left: 0,
    position: "fixed" as "fixed",
    top: 0,
    width: "100vw",
    zIndex: 951,
}));

const DialogContent = styled.div((props:{overridePadding: string | number}) => ({
    backgroundColor: COLOR_SECONDARY,
    borderRadius: "12px",
    display: "flex" as "flex",
    flexDirection: "column" as "column",
    justifyContent: "center" as "center",
    maxWidth: "80vw",
    maxHeight: "85vh",
    minWidth: 250,
    minHeight: 250,
    overflowY: "auto" as "auto",
    position: "relative" as "relative",
    padding: props.overridePadding,
    "@media (max-width: 500px)": {
        padding: "4rem 1.5rem 2rem 1.5rem",
    }
}));

type Props = {
    children: ReactNode
    handleCloseDialog: () => void
    solidBackground?: boolean
    overridePadding?: string | number

};

const Dialog = ({children, handleCloseDialog, solidBackground = false, overridePadding = "4rem 4.5rem"}: Props) => {
    return (
        <DialogContainer {...{solidBackground}} >
            <OutsideClickHandler
                onOutsideClick={() => {
                    handleCloseDialog()
                }}
            >
                <DialogContent {...{overridePadding}}>
                    {children}
                </DialogContent>
            </OutsideClickHandler>
        </DialogContainer>
    );
};

export default Dialog;
