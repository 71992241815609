import PrimaryButton from "../components/Button/PrimaryButton";
import React from 'react';
import styled, {createGlobalStyle} from "styled-components";
import {FormattedMessage, useIntl} from "react-intl";
import {
    BUTTON_TYPE_ENUM,
    COLOR_NEUTRAL,
    COLOR_NEUTRAL_90,
    COLOR_WHITE,
    TYPEFACE_HEADLINE_2,
    TYPEFACE_TITLE
} from "@motv-webapp/lib";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${COLOR_NEUTRAL};
  }

  ,
`

const ErrorPageContainer = styled.div(props => ({
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: "100vh"
}));

const Emoticon = styled.div(props => ({
    color: COLOR_NEUTRAL_90,
    fontSize: "25rem",
    fontWeight: "bold",
    fontStretch: "condensed",
}));

const ErrorTitle = styled.h1(props => ({
    ...TYPEFACE_TITLE,
    color: COLOR_WHITE,
    marginBottom: 16,
    marginTop: 0,
}));

const ErrorSubtitle = styled.div(props => ({
    ...TYPEFACE_HEADLINE_2,
    color: COLOR_WHITE,
    marginBottom: 42,
}));

const ErrorPage = () => {
    const intl = useIntl()

    const handleClickReload = () => {
        window.location.reload()
    }

    return (
        <ErrorPageContainer>
            <GlobalStyle/>
            <Emoticon>:/</Emoticon>
            <ErrorTitle>
                <FormattedMessage id={"message_something_went_wrong"} defaultMessage={"Something went wrong."}/>
            </ErrorTitle>
            <ErrorSubtitle>
                <FormattedMessage
                    id={"message_provider_connection_failed"}
                    defaultMessage={"Connection to the provider failed. Please, try again later."}/>
            </ErrorSubtitle>
            <PrimaryButton
                buttonType={BUTTON_TYPE_ENUM.OFFWHITE}
                label={intl.formatMessage({id: "label_try_again", defaultMessage: "Try again"})}
                onClick={handleClickReload}
            />
        </ErrorPageContainer>
    );
};

export default ErrorPage;
