"use strict";
exports.__esModule = true;
exports.RECOMMENDATION_ENGINE_ROW_STYLE_FULL_WIDTH = exports.RECOMMENDATION_ENGINE_ROW_STYLE_LARGE = exports.RECOMMENDATION_ENGINE_ROW_STYLE_LARGE_WIDE = exports.RECOMMENDATION_ENGINE_ROW_STYLE_NORMAL_WIDE = exports.RECOMMENDATION_ENGINE_ROW_STYLE_SMALL_WIDE = exports.RECOMMENDATION_ENGINE_ROW_STYLE_NORMAL = exports.RECOMMENDATION_ENGINE_ROW_STYLE_CONDENSED = void 0;
exports.RECOMMENDATION_ENGINE_ROW_STYLE_CONDENSED = "condensed";
exports.RECOMMENDATION_ENGINE_ROW_STYLE_NORMAL = "normal";
exports.RECOMMENDATION_ENGINE_ROW_STYLE_SMALL_WIDE = "small_wide";
exports.RECOMMENDATION_ENGINE_ROW_STYLE_NORMAL_WIDE = "normal_wide";
exports.RECOMMENDATION_ENGINE_ROW_STYLE_LARGE_WIDE = "large_wide";
exports.RECOMMENDATION_ENGINE_ROW_STYLE_LARGE = "large";
exports.RECOMMENDATION_ENGINE_ROW_STYLE_FULL_WIDTH = "full_width";
