import React, {useEffect, useState} from "react";
import {differenceInMinutes, formatISO, isAfter, isBefore, startOfDay, subHours} from "date-fns";
import TVGuideRowStyleItem from "./TVGuideRowStyleItem";
import {SubscribedChannelEntity} from "@motv-webapp/lib";
import {useAppSelector} from "@motv-webapp/redux";
import {channel_epg} from "@motv-webapp/redux";
import styled from "styled-components";

const EpgRow = styled.div((props: { leftMargin: number, MINUTE_TO_PIXELS: number }) => ({
    boxSizing: "border-box" as "border-box",
    display: "flex",
    height: "72px",
    marginLeft: -props.leftMargin - (30 * props.MINUTE_TO_PIXELS),
}));

type Props = {
    channel: SubscribedChannelEntity
    selectedDate: Date
    MINUTE_TO_PIXELS: number
};

const TVGuideRowStyleRow = ({channel, selectedDate, MINUTE_TO_PIXELS}: Props) => {
    const epgItems = useAppSelector(channel_epg)
    const [leftMargin, setLeftMargin] = useState(0);

    useEffect(() => {
        let earliestTime = formatISO(subHours(startOfDay(selectedDate), 1));
        epgItems && epgItems.filter(item => (item.channels_id === channel.channels_id) &&
            (isAfter(Date.parse(item.end!), subHours(startOfDay(selectedDate), 1))
            )).forEach((item) => {
            if (isBefore(Date.parse(item.start!), Date.parse(earliestTime))) {
                earliestTime = item.start!
            }
        })

        if (isBefore(Date.parse(earliestTime), startOfDay(selectedDate))) {
            setLeftMargin(differenceInMinutes(subHours(startOfDay(selectedDate), 1), new Date(earliestTime)) * MINUTE_TO_PIXELS)
        } else {
            setLeftMargin(0)
        }
    }, [epgItems])

    return (
        <EpgRow MINUTE_TO_PIXELS={MINUTE_TO_PIXELS} leftMargin={leftMargin}>
            {epgItems && epgItems.filter(item => (item.channels_id === channel.channels_id) &&
                (isAfter(Date.parse(item.end!), subHours(startOfDay(selectedDate), 1))
                )).map((item) => (
                <TVGuideRowStyleItem MINUTE_TO_PIXELS={MINUTE_TO_PIXELS} selectedDate={selectedDate} key={item.id}
                                     mediaItem={item}/>
            ))}
        </EpgRow>
    )
}

export default TVGuideRowStyleRow;
