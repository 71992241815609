import {AnimatePresence, motion} from 'framer-motion';
import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import styled from "styled-components";
import {COLOR_WHITE} from "@motv-webapp/lib";
import {FormattedMessage} from "react-intl";
import LoginHeader from "./LoginHeader";
import Stepper from "../Stepper/Stepper";
import ForgottenPasswordFormStepOne from "./ForgottenPasswordFormStepOne";
import ForgottenPasswordFormStepTwo from "./ForgottenPasswordFormStepTwo";
import Div100vh from "react-div-100vh";
import {useParams} from "react-router-dom";

const ForgottenPasswordPanelContainer = styled(motion(Div100vh))(props => ({
    alignItems: "center",
    backgroundColor: "transparent",
    color: COLOR_WHITE,
    display: "flex",
    justifyContent: "space-around",
    position: "absolute",
    right: 0,
    width: "40vw",
    "& h1": {
        marginBottom: "2rem",
    },
    "@media (max-width: 800px)": {
        alignItems: "flex-start",
        overflowY: "scroll",
        width: "100vw",
    }
}));

const ForgottenPasswordPanelContent = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    width: "20rem",
    "& h1": {
        marginTop: 0,
    },
    "@media (max-width: 800px)": {
        margin: "8rem 0 6rem 0",
    }
}));

type Props = {
    setActivePanel: Dispatch<SetStateAction<string>>,
    PANEL_LOGIN: string,
    PANEL_FORGOTTEN_PASSWORD: string,
};

const ForgottenPasswordPanel = ({setActivePanel, PANEL_LOGIN, PANEL_FORGOTTEN_PASSWORD}: Props) => {
    const [stepsFinished, setStepsFinished] = useState(0)
    const {step} = useParams()

    useEffect(() => {
        if (step) {
            setStepsFinished(parseInt(step))
        }
    }, [step])

    return (
        <ForgottenPasswordPanelContainer
            initial={{x: "100%"}}
            animate={{x: 0}}
            exit={{x: "100%"}}
            transition={{type: "spring", duration: 1}}
        >
            <LoginHeader />
            <ForgottenPasswordPanelContent>
                <h1>
                    <FormattedMessage id={"reset_password"} defaultMessage={"Reset password"}/>
                </h1>
                <Stepper numberOfSteps={2} stepsFinished={stepsFinished}/>
                <AnimatePresence>
                    {stepsFinished < 1 && <ForgottenPasswordFormStepOne {...{setStepsFinished, PANEL_FORGOTTEN_PASSWORD}} />}
                    {stepsFinished >= 1 &&
                      <ForgottenPasswordFormStepTwo {...{setStepsFinished, setActivePanel, PANEL_LOGIN}} />}
                </AnimatePresence>

            </ForgottenPasswordPanelContent>
        </ForgottenPasswordPanelContainer>
    );
};

export default ForgottenPasswordPanel;
